import { PhysicalLocation } from '../../API';
import { DeleteLocationsRequest } from '../../generated-sources/internal-api/models/DeleteLocationsRequest';
import { DeleteLocationsResponse } from '../../generated-sources/internal-api/models/DeleteLocationsResponse';
import { StatusFilter } from '../../types/types';

export type LocationsResponse = {
  locations: PhysicalLocation[];
};

export type LocationResponse = {
  location: PhysicalLocation;
};

export type LocationImportType = {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
};

export type LocationFilter = {
  city: string[];
  state: string[];
  country: string[];
  status: StatusFilter[];
};

export type FilterStatusOption = {
  value: StatusFilter;
  label: string;
};

export type DropdownOption = {
  value: string;
  label: string;
};

export type LocationsByIdType = {
  [key: string]: PhysicalLocation;
};

export type Coordinates = {
  lat: number;
  lng: number;
};

export type DistanceMiKmType = {
  distanceKM: number;
  distanceMI: number;
};

export type LocationWithDistance = PhysicalLocation & DistanceMiKmType;

export type LocationAvailabilityOption = {
  label: string;
  description: string;
  value: string;
  search: string[];
};

export enum CsvLocationError {
  FIELDS_NOT_TRANSFERRED = 'FIELDS_NOT_TRANSFERRED',
  INVALID_COUNTRY_FORMAT = 'INVALID_COUNTRY_FORMAT',
}

export type DeleteLocationsRequestPayload = Exclude<DeleteLocationsRequest, 'userContext' | 'encryptedUserId'>;
export type DeleteLocationsResponseDependences = Exclude<DeleteLocationsResponse, 'userContext'>;
