export const Availability = Object.freeze({
  ACTIVE_ON: `Aktiv am`,
  BOOKING_PAGE: `Buchungsseite`,
  BOOKING_PAGES: `Buchungsseiten`,
  BOOKING_TEMPLATES: `Buchungsvorlagen für Meeting Booker`,
  CLONE_SCHEDULE_TITLE: `Name des geklonten Zeitplans`,
  DATE_OVERRIDE_BUTTON: `Neue Aufhebung`,
  DATE_OVERRIDE_DESCRIPTION: `Fügen Sie unten Ausnahmen zu diesem Zeitplan hinzu:`,
  DATE_OVERRIDE_MODAL_HOURS: `Verfügbare Stunden`,
  DATE_OVERRIDE_MODAL_PROMPT: `Wählen Sie das Datum (oder die Daten):`,
  DATE_OVERRIDE_MODAL_UNAVAILABLE: `Klicken Sie auf Speichern, um dieses Datum (oder diese Daten) als nicht verfügbar zu blockieren.`,
  DATE_OVERRIDE_TITLE: `Datumüberschreibung hinzufügen`,
  DATES_OVERLAPPING_TEXT: `Einige Daten wurden bereits überschrieben.`,
  DEFAULT_AVAILABILITY_NAME: `Arbeitszeiten`,
  DELETE_DESCRIPTION: `Sind Sie sicher, dass Sie löschen möchten`,
  DELETE_MESSAGE: `wird als der neue Standardzeitplan festgelegt.`,
  DESCRIPTION: `Wählen Sie unten einen Zeitplan zum Bearbeiten oder erstellen Sie einen neuen, den Sie auf Ihren Buchungsseiten anwenden können`,
  DIALOG_EDIT_NAME_TITLE: `Zeitplantitel bearbeiten`,
  DIALOG_NEW_NAME_TITLE: `Neuer Zeitplanname`,
  DIALOG_PLACEHOLDER: `Arbeitszeiten, Exklusive Stunden, etc...`,
  DIALOG_TITLE: `Zeitplantitel`,
  DUPLICATE_NAME_MESSAGE: `Ein Zeitplan mit diesem Namen existiert bereits`,
  EDIT_NAME: `Namen bearbeiten`,
  IS_DEFAULT: `Standardzeitplan`,
  NEW_SCHEDULE: `Neuer Zeitplan`,
  SAVE_AVAILABILITY: `Verfügbarkeit speichern`,
  SET_AS_DEFAULT: `Als Standard festlegen`,
  SET_WEEKLY_HOURS: `Definieren Sie Ihre wöchentlichen Stunden:`,
  TIME_INTERVAL_OVERLAPPING: `Zeitintervalle dürfen nicht überlappen.`,
  TIME_INVALID_TEXT: `Wählen Sie eine Endzeit, die später als die Startzeit ist`,
  TIME_ZONE: `Zeitzone`,
  TITLE: `Verfügbarkeit`,
  TOOLTIP: `Verwalten Sie Ihre Verfügbarkeit mit Arbeitsplänen`,
  FIRST_ADMIN_MESSAGE: `Der "Teamverfügbarkeits"-Plan wird auf alle neu eingeladenen Benutzer angewendet. Wenn Sie als Administrator Ihren eigenen Zeitplan festlegen möchten, klicken Sie auf "Neuer Zeitplan" und erstellen Sie Ihren eigenen Zeitplan.`,
  SCHEDULE: `Zeitplan`,
  OVERRIDES: `Überschreibungen`,
  AVAILABLE_HOURS: `Verfügbare Stunden`,
  ACTIVE_ON_PLACEHOLDER: `Gilt für alle Buchungsseiten`,
  AVAILABLE_DESCRIPTION: `Zusätzliche Stunden für diesen Zeitplan`,
  UNAVAILABLE_DESCRIPTION: `Termine, die nicht verfügbar sind`,
  DELETE_WARNING_MESSAGE: `Diese Aktion kann nicht rückgängig gemacht werden.`,
  DELETE_TITLE: `Zeitplan löschen`,
  DATE_OVERRIDE_AVAILABLE_TITLE: `Verfügbare Überschreibungen hinzufügen`,
  DATE_OVERRIDE_UNAVAILABLE_TITLE: `Nicht verfügbare Termine hinzufügen`,
  SPECIFY_WHAT_SCHEDULE_USED_FOR: `Geben Sie an, wofür der Zeitplan verwendet wird! In Fällen ohne Spezifikationen wird nur Ihr Standardzeitplan verwendet.`,
  SET_DEFAULT_ONLY_SCHEDULE_NOT_SPECIFIC: `Nur Zeitpläne, die nicht spezifisch für Buchungsseiten, Buchungsvorlagen oder Standorte sind, können als Standard festgelegt werden.`,
  NEED_ANOTHER_NOT_SPECIFIC_SCHEDULE_FOR_REMOVAL: `Wenn Sie den Standardzeitplan entfernen, müssen Sie mindestens einen anderen Zeitplan ohne Filter haben.`,
  LOCKED_TOOLTIP: `Um die Verfügbarkeit zu bearbeiten, wechseln Sie die Arbeitsbereiche.`,
});

export const AvailabilityToastsNotifications = Object.freeze({
  CREATE_AVAILABILITY_ERROR_MESSAGE: `Fehler beim Anfordern der Erstellung der Verfügbarkeit`,
  CREATE_AVAILABILITY_SUCCESS_MESSAGE: `Verfügbarkeit erfolgreich erstellt`,
  DELETE_AVAILABILITY_ERROR_MESSAGE: `Fehler beim Anfordern der Löschung der Verfügbarkeit`,
  DELETE_AVAILABILITY_SUCCESS_MESSAGE: `Verfügbarkeit erfolgreich gelöscht`,
  GET_AVAILABILITY_ERROR_MESSAGE: `Fehler beim Anfordern der Verfügbarkeit`,
  SAVE_AVAILABILITY_ERROR_MESSAGE: `Fehler beim Anfordern der Speicherung der Verfügbarkeit`,
  SAVE_AVAILABILITY_SUCCESS_MESSAGE: `Änderungen gespeichert`,
  SET_DEFAULT_AVAILABILITY_ERROR_MESSAGE: `Fehler beim Anfordern der Festlegung der Standardverfügbarkeit`,
  SET_DEFAULT_AVAILABILITY_SUCCESS_MESSAGE: `Änderungen gespeichert`,
});

export const AvailabilityWeekDays = Object.freeze({
  MONDAY_SHORT: 'Mo',
  TUESDAY_SHORT: 'Di',
  WEDNESDAY_SHORT: 'Mi',
  THURSDAY_SHORT: 'Do',
  FRIDAY_SHORT: 'Fr',
  SATURDAY_SHORT: 'Sa',
  SUNDAY_SHORT: 'So',
});
