import { API, Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { listUserData } from '../../graphql/queries';
import { ListUserDataResponse } from '../../types/interfaces';
import { AuthPoC } from './AuthPoC';
import { userSettingsActions } from '../../store/userSettings';
import { IntegrationType } from '../../API';
import { Button } from 'primereact/button';
import { errorActions } from '../../store/error/actions';
import { ErrorCodes } from '../../store/error/types';
import { API_PUBLIC, excludeUrls } from '../../types/constants';

export const Tests = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (excludeUrls.some((url) => window.location.href.includes(url))) {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
    }
  }, []);

  const createUserEvent = async () => {
    await API.post('publicApi', `${API_PUBLIC}/CreateUserEvent`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        event: {
          userId: 'fbd427af-7b95-4d9b-9a92-b2e5b4e8eab0',
          eventDate: '2024-01-15',
          startTime: '2024-01-15T18:00:00+03:00',
          endTime: '2024-01-15T18:45:00+03:00',
          //location: '',
          // name: 'TestTest',
          hosts: ['kirillvasilovic@gmail.com'],
          bookingPageId: 'b1db73f6-432d-4d50-957c-9fc8a5fa46fa',
          bookingPageName: 'testBookingPageName',
          bookingTemplateId: '5feb03da-a5df-4ff5-8155-1f14b3bb4adc',
          integrations: [],
          guestEmails: ['kirillvasilovich@sumoscheduler.com'],
          description: '',
          attendee: {
            name: 'AttendeeName',
            email: 'kirillvasilovic@gmail.com',
          },
          timeZone: 'Europe/Minsk',
          canceled: {
            isCanceled: false,
            note: 'note',
          },
        },
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const updateUserEvent = async () => {
    await API.post('publicApi', `${API_PUBLIC}/updateUserEvent`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        event: {
          eventId: '0638ab58-bb92-45a4-ad48-46944b7829b5',
          tenant: '440f04bb-f459-4857-9643-4dae56f5313a',
          userId: 'fbd427af-7b95-4d9b-9a92-b2e5b4e8eab0',
          eventDate: '2024-01-16',
          startTime: '2024-01-16T18:00:00+03:00',
          endTime: '2024-01-16T18:45:00+03:00',
          //location: '',
          // name: 'TestTest',
          hosts: ['kirillvasilovic@gmail.com'],
          bookingPageId: 'b1db73f6-432d-4d50-957c-9fc8a5fa46fa',
          bookingPageName: 'testBookingPageName',
          bookingTemplateId: '5feb03da-a5df-4ff5-8155-1f14b3bb4adc',
          integrations: [],
          guestEmails: ['kirillvasilovich@sumoscheduler.com'],
          description: '',
          attendee: {
            name: 'AttendeeName2',
            email: 'kirillvasilovic@gmail.com',
          },
          timeZone: 'Europe/Minsk',
          canceled: {
            isCanceled: false,
            note: 'note',
          },
        },
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  //SO-334
  const deleteUserData = async () => {
    await API.post('publicApi', `${API_PUBLIC}/deleteUserData`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        email: 'kirillvasilovic@gmail.com',
        tenantId: 'e3301065-635e-4d83-bd99-316e14a266ff',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const deleteAndCreateUser = async () => {
    await API.post('publicApi', `${API_PUBLIC}/deleteAndCreateUser`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        email: 'kirillvasilovich@sumoscheduler.com',
        newTenantId: 'SUMO1',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getStaffList = async () => {
    await API.post('publicApi', `${API_PUBLIC}/opsconsole/getStaffList`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {},
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const upsertStaff = async () => {
    await API.post('publicApi', `${API_PUBLIC}/opsconsole/upsertStaff`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        email: 'kirillvasilovic@gmail.com',
        userId: 'b4685488-60e1-701f-5a3d-6ff45320ca28',
        userName: 'Bob Marley',
        roleName: 'MAIN_ADMIN',
        status: 'ACTIVE',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const deleteStaff = async () => {
    await API.post('publicApi', `${API_PUBLIC}/opsconsole/deleteStaff`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        email: 'kirillvasilovich2@sumoscheduler.com',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getOrgs = async () => {
    await API.post('publicApi', `${API_PUBLIC}/opsconsole/getOrgs`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {},
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getAllAccounts = async () => {
    await API.post('publicApi', `${API_PUBLIC}/opsconsole/getAllAccounts`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {},
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getTenantsByAccountId = async () => {
    await API.post('publicApi', `${API_PUBLIC}/opsconsole/getTenantsByAccountId`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        id: 'e0014d91-3ec4-4246-bd57-b81ca16ce6c2',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const upsertAccount = async () => {
    await API.post('publicApi', `${API_PUBLIC}/upsertAccount`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        id: '9a49fdb2-c0b9-4455-9e4d-fe5296292ccf',
        name: 'gmail.com22222',
        tenantIds: ['1111'],
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const deleteAccount = async () => {
    await API.post('publicApi', `${API_PUBLIC}/deleteAccount`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        id: '9ad5dbe4-f0d0-4a65-9cff-b6425380f492',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getOrgDetails = async () => {
    await API.post('publicApi', `${API_PUBLIC}/opsconsole/getOrgDetails`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        tenantId: '4c3c461d-ee0a-4b1b-abf2-16a2d241c701',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getUserDetailsList = async () => {
    await API.post('publicApi', `${API_PUBLIC}/opsconsole/getUserDetailsList`, {
      headers: {
        // 'Authorization': `Bπearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {},
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getUserDetails = async () => {
    await API.post('publicApi', `${API_PUBLIC}/opsconsole/getUserDetails`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        email: 'kirillvasilovic@gmail.com',
        tenantId: 'e3301065-635e-4d83-bd99-316e14a266ff',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const sendUsersInviteLinks = async () => {
    //example of input adminData
    const amdinData = [
      {
        email: 'kirillvasilovich@sumoscheduler.com',
        roleId: '1cc5f21c-c857-4018-9cbd-423e51b3ed46',
        status: 'Invite Sent',
        team: ['testTeam1'],
        bookingTemplate: ['fccce7e7-2b52-48a2-8b97-6fdcab48aefa'],
        tenantId: `5b57e548-c386-4614-877f-5c3ceffaad97`,
        workspaceIds: ['f25cba25-7175-4f5c-ab33-bfe1a9778b64'],
      },
    ];
    // const inputData = JSON.stringify(amdinData);
    await API.post('publicApi', `${API_PUBLIC}/sendUsersInviteLinks`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        data: amdinData,
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getUserDataByTenant = async () => {
    const tenant = '2c7cfb13-34ce-465e-9218-8aacddca2d88';
    try {
      const userData = (
        (await API.graphql({
          query: listUserData,
          variables: {
            tenant: `${tenant}`,
          },
        })) as ListUserDataResponse
      ).data.listUserData?.items;

      console.log('userData ' + JSON.stringify(userData));
    } catch (e) {
      console.log(e);
    }
  };

  const updateLicense = async () => {
    await API.post('publicApi', `${API_PUBLIC}/updateLicense`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        id: 'db378d9f-90e7-44b7-a58d-c7c231a6394d',
        tenantId: '440f04bb-f459-4857-9643-4dae56f5313a',
        assigned: 55,
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  //so-361
  // was removed in 1.6.0
  // const getLoggedUserDetails = async () => {
  //   await API.post('publicApi', `${API_PUBLIC}/getLoggedUserDetails`, {
  //     headers: {
  //       // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
  //     },
  //     body: {
  //       encryptedUserId:
  //         'ead623f7bab988f1ca1fab7b6b15f9af:4ec7d94ca37956dfd5bc171291da7b7907bbb5259516dc4f487c0c55eabaae90ad3ba126e867534ca8871d2c3e2d82f0',
  //     },
  //   })
  //     .then((response) => {
  //       console.log(response);
  //     })
  //     .catch((reason) => alert(JSON.stringify(reason)));
  // };

  const Authentication = async () => {
    await API.post('publicApi', `${API_PUBLIC}/authentication`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        //encryptedUserId: "S1HPNPVBLKS4I8dhn8PLuA==:r24wPj5pfAonvyHmkanthmvNVSvYNMkawSQSGE6XuAZ/adhFI0Lltgg/wOZQaBZD",
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };
  const getListUserDataByUserId = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getListUserDataByUserId`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        userId: '36a04b9c-5f66-4739-af22-c79fef5b58f9',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  //so-410
  const editAdminData = async () => {
    await API.post('publicApi', `${API_PUBLIC}/updateAdminData`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        email: 'kirillvasilovich@sumoscheduler.com',
        roleId: '1cc5f21c-c857-4018-9cbd-423e51b3ed46',
        status: 'Active',
        team: ['testTeam2'],
        bookingTemplate: ['fccce7e7-2b52-48a2-8b97-6fdcab48aefa'],
        tenantId: `5b57e548-c386-4614-877f-5c3ceffaad97`,
        workspaceIds: ['f25cba25-7175-4f5c-ab33-bfe1a9778b64'],
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const updateTenant = async () => {
    await API.post('publicApi', `${API_PUBLIC}/updateTenant`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        type: 'delete workspace', //update//create
        tenant: {
          tenantId: 'c8ee46e2-bcd7-470c-83a1-1dbcfc5ecbf7',
          // name: 'New test edit name',
          // isActive: 'false',
          workspace: {
            id: 'b711741f-9fc1-4d77-ab5a-7d9a1259fba1',
            name: 'testWorkspace15',
            isActive: 'false',
            isPhoneRequired: 'true',
          },

          // license: {
          //   type: 'Trial', //General
          //   isActive: 'true',
          //   startDate: '2023-09-25',
          //   endDate: '2023-09-28',
          // },
        },
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const deleteTenant = async () => {
    await API.post('publicApi', `${API_PUBLIC}/deleteTenant`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        tenantId: '9c8d1cf5-3527-451b-950f-6e421ad72965',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const createTenantRecords = async () => {
    const tenantData = [
      {
        name: 'Expired License Test Tenant Name',
        isActive: 'true',
        license: {
          type: 'Trial', //General
          isActive: 'true',
          startDate: '2023-09-25',
          endDate: '2023-09-28',
        },
      },
      {
        name: 'Expired License Test Tenant Name1',
        isActive: 'true',
        license: {
          type: 'General', //Trial
          isActive: 'true',
          startDate: '2023-09-25',
          endDate: '2023-09-27',
        },
        tenantStyle: {
          avatar: 'testAvatar',
          backgroundImage: 'testBackground',
          lookAndFeel: {
            siteColorPrimary: 'siteColorPrimary',
            siteColorButtons: 'siteColorButtons',
            siteColorBackground: 'siteColorBackground',
            fontColorHeader: 'fontColorHeader',
            fontColorSubHeader: 'fontColorSubHeader',
            fontColorMainText: 'fontColorMainText',
            fontHeader: 'fontHeader',
            fontSubheader: 'fontSubheader',
            fontMainText: 'fontMainText',
            CSSStyled: 'CSSStyled',
            FooterHTML: 'FooterHTML',
          },
          defaultWeeklyHours: [
            {
              type: 'SUN',
              time: [
                {
                  from: '02:05',
                  to: '03:05',
                },
              ],
            },
          ],
        },
      },
    ];

    await API.post('publicApi', `${API_PUBLIC}/createTenantRecords`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        data: tenantData,
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  //so-387
  const getAdminDataByTenantId = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getAdminDataByTenant`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        tenantId: 'SUMO1',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const upsertBookingTemplate = async () => {
    await API.post('publicApi', `${API_PUBLIC}/upsertBookingTemplate`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        enabled: 'true',
        workspaceId: '8444f141-6c8c-4111-bee5-c1524e9dc012',
        who: {
          hosts: [{ userId: '80c873b2-6abc-453d-ad79-69b0ccfdd820', email: 'kirillvasilovic@gmail.com' }],
        },
        notifications: {
          confirmations: {
            subject: 'Confirmed: <Meeting Name> with <Host Name> on <Meeting Date>',
            body: 'confirmations Meeting Name <Guest Full Name>, <br> Your <Meeting Name> with <Host Name> at <Meeting Time> on <Meeting Date> is booked. ',
          },
          cancelations: {
            subject: 'cancelations: <Meeting Name> with <Host Name> on <Meeting Date>',
            body: 'cancelations Meeting Name <Guest Full Name>, <br> Your <Meeting Name> with <Host Name> at <Meeting Time> on <Meeting Date> is booked. ',
          },
          reminders: {
            subject: 'reminders: <Meeting Name> with <Host Name> on <Meeting Date>',
            body: 'reminders Meeting Name <Guest Full Name>, <br> Your <Meeting Name> with <Host Name> at <Meeting Time> on <Meeting Date> is booked. ',
            timing: ['10', '10000'],
          },
        },
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const upsertBookingPage = async () => {
    await API.post('publicApi', `${API_PUBLIC}/upsertBookingPage`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        id: '2e1f3cc0-a48f-4680-976f-c1ef74199b3b',
        link: '30m',
        name: 'testNmae2',
        isActive: 'false',
        workspaceId: '212',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const upsertInboxBookingPage = async () => {
    await API.post('publicApi', `${API_PUBLIC}/upsertInboxBookingPage`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        bookingPage: {
          workspaceId: '5b1f760f-303b-4e00-9e27-4f559c39486e',
          adminOnly: false,
          calendar: {
            calendarFormat: 'MONTH_VIEW',
            timeFormat: 'HOUR_12',
            timeInterval: '15',
            timeZoneType: 'DETECT_INVITEE',
            topOfInterval: true,
          },
          confirmationsPage: {
            anotherEventEnabled: true,
            cancelEnabled: true,
            customLinks: [],
            rescheduleEnabled: true,
            type: 'OUR_PAGE',
          },
          displayId: 'BP-1',
          enterNote: false,
          inputFields: [],
          inviteOthers: false,
          lastModify: 'SUMO1 System',
          version: '0',
          what: {
            color: '#5d9bfe',
            customName: '30 Minute Meeting',
            isActive: true,
          },
          when: {
            dateRange: {
              from: '1725319405000',
              to: '1726010605000',
              count: '30',
              type: 'SPECIFIC_DATES',
            },
            duration: {
              count: '30',
              timeUnit: 'MINUTE',
            },
            scheduleBuffer: {
              count: '12',
              timeUnit: 'HOUR',
            },
          },
          where: {
            defaultLocationType: 'VIDEO_CONFERENCE',
            inPersonType: 'CUSTOM_ADDRESS',
            locations: [],
            locationTypes: ['PHONE_CALL', 'VIDEO_CONFERENCE'],
            phoneCallType: 'HOST_PHONE_NUMBER',
            skipTheWhereStep: true,
            videoConferenceType: 'GOOGLE_MEET',
          },
          who: {
            cohosts: [],
            guests: [],
            host: [],
            hostAssignmentMode: 'ROUND_ROBIN',
            hostMembers: [
              {
                id: '54983468-9061-70ce-c4d4-edb84e26b3b4',
                subType: 'USER',
                type: 'SUMO1',
              },
            ],
            hostRescheduleWith: 'ASSIGNED_HOST',
          },
        },
        dayLimit: 4,
        spotLimit: 2,
        timeZone: 'Europe/Minsk',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const deleteBookingTemplate = async () => {
    await API.post('publicApi', `${API_PUBLIC}/deleteBookingTemplate`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: [
        {
          id: '55a9abac-c432-41ec-9ebb-e8c2745fe6ed',
          workspaceId: '222',
        },
      ],
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const deleteBookingPage = async () => {
    await API.post('publicApi', `${API_PUBLIC}/deleteBookingPage`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: [
        {
          id: 'ed40c39a-31f6-49d4-8395-48f8d64a9ebc',
          workspaceId: '212',
        },
      ],
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getBookingTemplateRecords = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getBookingTemplate`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        workspaceId: 'ac92868f-4e04-47f0-ae45-1d70f6d611e4',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getActions = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getActions`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        actionTypes: ['MEETING_CREATED'],
        startDate: '1527433048043',
        endDate: '2727433048043',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getBookingPageRecords = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getBookingPage`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        workspaceId: 'ac92868f-4e04-47f0-ae45-1d70f6d611e4',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const connectToZoom = async () => {
    await API.post('publicApi', `${API_PUBLIC}/connectToZoom`, {
      body: {
        code: 'DdwYft2iIrRcwYMooTRTTCWmoHgjezFHg',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const createShortLink = async () => {
    await API.post('publicApi', `${API_PUBLIC}/createShortLink`, {
      body: {
        id: 'b1db73f6-432d-4d50-957c-9fc8a5fa46fa',
        workspaceId: '9b610593-a2cb-494f-b15b-582438b29aa1',
        urls: ['http://localhost:3000/b1db73f6-432d-4d50-957c-9fc8a5fa46fa'],
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const connectMeet = async () => {
    dispatch(
      userSettingsActions.addIntegration({
        type: IntegrationType.GOOGLE_MEET,
      })
    );
    dispatch(userSettingsActions.saveUserSettingsRequest({ showToast: true }));
  };

  const getUserData = async () => {
    const idToken = (await Auth.currentSession()).getIdToken().payload;
    try {
      console.log(
        'AMAZON_COGNITO_USER_POOLS ' +
          idToken.sub +
          ' ' +
          (
            (await API.graphql({
              query: listUserData,
              variables: {
                userId: `${idToken.sub}`,
              },
              authMode: 'AMAZON_COGNITO_USER_POOLS',
            })) as ListUserDataResponse
          ).data.listUserData?.items.length
      );
    } catch (e) {
      console.log('AMAZON_COGNITO_USER_POOLS ' + idToken.sub + ' ' + e);
    }

    try {
      console.log(
        'AWS_IAM ' +
          idToken.sub +
          ' ' +
          (
            (await API.graphql({
              query: listUserData,
              variables: {
                userId: `${idToken.sub}`,
              },
              authMode: 'AWS_IAM',
            })) as ListUserDataResponse
          ).data.listUserData?.items.length
      );
    } catch (e) {
      console.log('AWS_IAM ' + idToken.sub + ' ' + e);
    }
    try {
      console.log(
        'AMAZON_COGNITO_USER_POOLS ' +
          'antonovich' +
          ' ' +
          (
            (await API.graphql({
              query: listUserData,
              variables: {
                userId: `kirillvasilovich`,
              },
              authMode: 'AMAZON_COGNITO_USER_POOLS',
            })) as ListUserDataResponse
          ).data.listUserData?.items.length
      );
    } catch (e) {
      console.log('AMAZON_COGNITO_USER_POOLS ' + 'antonovich' + ' ' + e);
    }
    try {
      console.log(
        'AWS_IAM ' +
          'antonovich' +
          ' ' +
          (
            (await API.graphql({
              query: listUserData,
              variables: {
                userId: `antonovich`,
              },
              authMode: 'AWS_IAM',
            })) as ListUserDataResponse
          ).data.listUserData?.items.length
      );
    } catch (e) {
      console.log('AWS_IAM ' + 'antonovich' + ' ' + e);
    }
  };

  // const [imageUrl, setImageUrl] = useState('');

  const getTenantByTenantId = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getTenantByTenantId`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        tenantId: '90fa6371-ae34-4147-9927-d9067c001451',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getTenantByTenantIdJava = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getTenantByTenantIdJava`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        tenantId: '90fa6371-ae34-4147-9927-d9067c001451',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const cancelEventRecord = async () => {
    await API.post('publicApi', `${API_PUBLIC}/cancelEvent`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        eventRecord: {
          eventDate: '2023-12-26',
          eventId: '9e3e4be8-0f51-46a4-b8c4-effb8702b5ed',
          userId: 'd23627f8-6242-4cb1-83db-80c445853f00',
          note: 'some reason',
        },
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const upsertRole = async () => {
    await API.post('publicApi', `${API_PUBLIC}/upsertRole`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        id: '5b7150bd-9264-4755-a7d5-f824d8be1202',
        tenantId: 'bba5f67d-de72-4feb-b951-07476902c22d',
        name: 'test name4',
        isActive: 'true',
        isCustom: 'true',
        hasChromeAccess: 'false',
        availability: ['READ', 'CREATE'],
        users: ['READ', 'CREATE'],
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const deleteRole = async () => {
    await API.post('publicApi', `${API_PUBLIC}/deleteRole`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        roleId: '712ce78b-4f01-4835-a5a9-ef446e031967',
        tenantId: 'd630bc60-92de-4124-9096-fafc2a470944',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getRoleById = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getRoleById`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        roleId: 'ef55f779-019a-4211-bc6b-7fc1ef6e643b',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const generateCookies = async () => {
    await API.post('publicApi', `${API_PUBLIC}/generateCookies`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        userId: '4af3d593-1d08-4f0c-8e55-8a92a04ba4f9',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getRolesByTenantId = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getRolesByTenantId`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        tenantId: '794d7365-9452-4820-bf71-82ebf8451850',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getLicenses = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getLicenses`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        tenantId: '7e5ebc44-7231-4f37-90f7-213899c10198',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const deleteAvailability = async () => {
    await API.post('publicApi', `${API_PUBLIC}/deleteAvailability`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        userId: '44c8c458-10f1-7043-2c6a-7587df85aed9',
        link: 'f4367dd8-1519-4792-a974-8b536170a2a5',
        tenant: 'b40fbf85-2aeb-4131-a5e7-cf35d7118d5b',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const editUserEvent = async () => {
    await API.post('publicApi', `${API_PUBLIC}/editUserEvent`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        userId: '',
        eventDate: '',
        eventId: '',
        tenant: '',
        bookingPageId: '',
        bookingPageName: '',
        bookingTemplateId: '',
        description: 'NEW DESCRIPTION',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getBookedMeetings = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getBookedMeetings`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {},
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const createAvailability = async () => {
    await API.post('publicApi', `${API_PUBLIC}/createAvailability`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        userId: '44c8c458-10f1-7043-2c6a-7587df85aed9',
        tenant: 'b40fbf85-2aeb-4131-a5e7-cf35d7118d5b',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const deleteEnvData = async () => {
    await API.post('publicApi', `${API_PUBLIC}/clearEnvData`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {},
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const sendUIEmails = async () => {
    await API.post('publicApi', `${API_PUBLIC}/sendUIEmails`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        toAddresses: ['kirillvasilovic@gmail.com'],
        subjectText: 'subjectText test',
        bodyText: '<h2>bodyText test<h2>',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const deleteLocations = async () => {
    await API.post('publicApi', `${API_PUBLIC}/deleteLocations`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        ids: ['d8cd4904-2921-4486-893f-0ee7ed73b6ad'],
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getLocationById = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getLocationById`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        id: 'd8cd4904-2921-4486-893f-0ee7ed73b6ad',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getLocationsByWorkspaceId = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getLocationsByWorkspaceId`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        workspaceId: '75ed488d-febc-4e7d-aba3-df9dbb685142',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const upsertLocations = async () => {
    await API.post('publicApi', `${API_PUBLIC}/upsertLocations`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        locations: [
          {
            workspaceId: '75ed488d-febc-4e7d-aba3-df9dbb685142',
            name: 'test name2',
            active: true,
            address: {
              name: 'name',
              zip: 'tttt',
              state: 'state',
              country: 'country',
            },
          },
        ],
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const updateUserData = async () => {
    await API.post('publicApi', `${API_PUBLIC}/updateUserData`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        userId: '44c8c458-10f1-7043-2c6a-7587df85aed9',
        tenant: 'b40fbf85-2aeb-4131-a5e7-cf35d7118d5b',
        link: '25db0d72-5f76-47c0-ab76-9752cc19c0d7',
        recordType: '',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const upsertTeam = async () => {
    await API.post('publicApi', `${API_PUBLIC}/upsertTeam`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        team: {
          id: '9a881498-2fb4-4792-8871-3bd9c84620b0',
          workspaceId: '82966eb9-8152-463c-b984-c0f26be94095',
          name: 'TestTeam 23',
          active: 'true',
          workspacesForRead: ['82966eb9-8152-463c-b984-c0f26be94095'],
        },
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getTeamsByWorkpaceId = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getTeamsByWorkspaceId`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        workspaceId: '4091e2df-4f41-4ae4-b204-7dc5fd21ebc0',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const deleteTeam = async () => {
    await API.post('publicApi', `${API_PUBLIC}/deleteTeam`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        id: '9a881498-2fb4-4792-8871-3bd9c84620b0',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const upsertSmartAlert = async () => {
    await API.post('publicApi', `${API_PUBLIC}/upsertSmartAlert`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        smartAlert: {
          id: '',
          workspaceId: 'd23c1ffac-445e-4114-a44a-092fb8660529',
          name: 'notification alert',
          icon: 'test icon',
          isHide: false,
          shareWith: {
            workspaceIds: ['9cdb59a6-36fc-46d2-b27c-942f5d0405cb'],
            userIds: [],
          },
          whenSend: {
            eventSendType: 'EVENT_BEFORE_START',
            delay: 3,
            immediately: true,
          },
          whomSend: {
            emailToInvitee: {
              isActive: true,
              subject: 'test subject',
              body: 'test subject',
            },
            emailToHost: {
              isActive: true,
              subject: 'test subject',
              body: 'test subject',
            },
            smsToInvitee: {
              isActive: true,
              subject: 'test subject',
              body: 'test subject',
            },
            smsToHost: {
              isActive: true,
              subject: 'test subject',
              body: 'test subject',
            },
          },
          createdBy: 'test createdBy',
        },
        bookingPageIds: ['436bbd91-dac8-43af-9046-bf5e9dec1f2c'],
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const upsertSmartAlertTemplate = async () => {
    await API.post('publicApi', `${API_PUBLIC}/upsertSmartAlertTemplate`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        smartAlertTemplate: {
          id: '',
          workspaceId: 'd5dbfcab-617f-4e66-9c94-7851c31736ca',
          name: 'test name2222',
          description: '22223',
          icon: 'test icon',
          isHide: false,
          shareWith: {
            workspaceIds: ['222', '333'],
            userIds: ['222', '333'],
          },
          whenSend: {
            eventSendType: 'EVENT_BEFORE_START',
            delay: 23,
            immediately: false,
          },
          whomSend: {
            emailToInvitee: false,
            emailToHost: false,
            smsToInvitee: false,
            smsToHost: false,
          },
          createdBy: 'test createdBy',
        },
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getSmartAlertById = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getSmartAlertById`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        id: '38e267ad-b98f-400f-8ba1-c23cc1dd5af3',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const getSmartTypesByWorkspaceId = async () => {
    await API.post('publicApi', `${API_PUBLIC}/getSmartTypesByWorkspaceId`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        workspaceId: '23c1ffac-445e-4114-a44a-092fb8660529',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const deleteSmartAlert = async () => {
    await API.post('publicApi', `${API_PUBLIC}/deleteSmartAlert`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        id: '2ae05504-320b-499d-862c-bf73dc76ad8c',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const deleteSmartAlertTemplate = async () => {
    await API.post('publicApi', `${API_PUBLIC}/deleteSmartAlertTemplate`, {
      headers: {
        // 'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        id: 'b77a2b97-3b08-4c90-90e3-933debd21013',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  const forceAllFeatureRollouts = async () => {
    await API.post('publicApi', `${API_PUBLIC}/forceAllFeatureRollouts`, {
      body: {},
    })
      .then((response) => {
        console.log(response);
      })
      .catch((reason) => alert(JSON.stringify(reason)));
  };

  // async function onFileUpload(e: ChangeEvent<HTMLInputElement>) {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const file = e.target.files[0];
  //     try {
  //       /**
  //        * protected: Readable by all users, but writable only by the creating user.
  //        * Files are stored under protected/{user_identity_id}/ where the user_identity_id corresponds
  //        * to the unique Amazon Cognito Identity ID for that user.
  //        */
  //       const result = await Storage.put(file.name, file, {
  //         level: 'protected',
  //         contentType: file.type,
  //         progressCallback: (progress) => {
  //           console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
  //         },
  //       });
  //       const imageUrl = (await Storage.get(result.key, { level: 'protected' })).split('?')[0]; //remove access-related attributes as /protected/* files available for read for everyone
  //       setImageUrl(imageUrl);
  //     } catch (error) {
  //       console.log('Error uploading file: ', error);
  //     }
  //   }
  // }

  return (
    <div className="flex flex-wrap gap-6">
      <div className="flex flex-column align-items-center gap-1">
        <div className="text-xl">Auth PoC</div>
        <AuthPoC />
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <div className="text-xl">Common</div>
        <Button label="send emails from ui" onClick={sendUIEmails}></Button>
        <Button label="get action logs" onClick={getActions}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <div className="text-xl">CLEAR DATA</div>
        <Button label="DELETE ENV DATA" onClick={deleteEnvData}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <div className="text-xl">User Data</div>
        <Button label="Get user data" onClick={getUserData}></Button>
        <Button label="edit admin data" onClick={editAdminData}></Button>
        {/* <Button label="get Logged user details" onClick={getLoggedUserDetails}></Button> */}
        <Button label="delete and create user" onClick={deleteAndCreateUser}></Button>
        <Button label="Authentication" onClick={Authentication}></Button>

        <Button label="get List UserData By UserId" onClick={getListUserDataByUserId}></Button>
        <Button label="delete availability" onClick={deleteAvailability}></Button>
        <Button label="create availability" onClick={createAvailability}></Button>
        <Button label="update user data" onClick={updateUserData}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <div className="text-xl">Tenant Data</div>
        <Button label="send invite links" onClick={sendUsersInviteLinks}></Button>
        <Button label="get user data by tenant" onClick={getUserDataByTenant}></Button>
        <Button label="create tenant records" onClick={createTenantRecords}></Button>
        <Button label="get Admin Data By tenant" onClick={getAdminDataByTenantId}></Button>
        <Button label="update tenant" onClick={updateTenant}></Button>
        <Button label="delete tenant" onClick={deleteTenant}></Button>
        <Button label="get tenant by tenant id" onClick={getTenantByTenantId}></Button>
        <Button label="get tenant by tenant id java" onClick={getTenantByTenantIdJava}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <div className="text-xl">Teams</div>
        <Button label="upsert Team" onClick={upsertTeam}></Button>
        <Button label="get Teams By WorkpaceId" onClick={getTeamsByWorkpaceId}></Button>
        <Button label="delete Team" onClick={deleteTeam}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <div className="text-xl">Alerts</div>
        <Button label="upsert smart alert" onClick={upsertSmartAlert}></Button>
        <Button label="upsert smart alert template" onClick={upsertSmartAlertTemplate}></Button>
        <Button label="get SmartAlert By Id" onClick={getSmartAlertById}></Button>
        <Button label="get SmartTypes By Workspace Id" onClick={getSmartTypesByWorkspaceId}></Button>
        <Button label="delete Smart Alert" onClick={deleteSmartAlert}></Button>
        <Button label="delete Smart Alert Template" onClick={deleteSmartAlertTemplate}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <div className="text-xl">Events</div>
        <Button label="create user event" onClick={createUserEvent}></Button>
        <Button label="update user event" onClick={updateUserEvent}></Button>
        <Button label="Cancel user event" onClick={cancelEventRecord}></Button>
        <Button label="edit user event" onClick={editUserEvent}></Button>
        <Button label="get booked meetings" onClick={getBookedMeetings}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <div className="text-xl">Integrations</div>
        <Button label="connect to Zoom" onClick={connectToZoom}></Button>
        <Button label="Connect meet" onClick={connectMeet}></Button>
        <Button label="Create short links" onClick={createShortLink}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <div className="text-xl">Integrations</div>
        <Button label="upsert bookingTemplate" onClick={upsertBookingTemplate}></Button>
        <Button label="upsert bookingPage" onClick={upsertBookingPage}></Button>
        <Button label="upsert  inbox bookingPage" onClick={upsertInboxBookingPage}></Button>
        <Button label="delete bookingTemplate" onClick={deleteBookingTemplate}></Button>
        <Button label="delete bookingPage" onClick={deleteBookingPage}></Button>
        <Button label="get bookingTemplateRecords by workspace id " onClick={getBookingTemplateRecords}></Button>
        <Button label="get bookingPageRecords by workspace id" onClick={getBookingPageRecords}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <div className="text-xl">Roles</div>
        <Button label="upsert role" onClick={upsertRole}></Button>
        <Button label="delete role" onClick={deleteRole}></Button>
        <Button label="get role by Id" onClick={getRoleById}></Button>
        <Button label="get roles by tenant Id" onClick={getRolesByTenantId}></Button>
        <Button label="get licenses by tenant id" onClick={getLicenses}></Button>
        <Button label="update license" onClick={updateLicense}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <div className="text-xl">Accounts</div>
        <Button label="get all Accounts" onClick={getAllAccounts}></Button>
        <Button label="get tenants by  AccountId" onClick={getTenantsByAccountId}></Button>
        <Button label="upsert account" onClick={upsertAccount}></Button>
        <Button label="remove account" onClick={deleteAccount}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <div className="text-xl">OPS Console</div>
        <Button label="get orgs" onClick={getOrgs}></Button>
        <Button label="get org details" onClick={getOrgDetails}></Button>
        <Button label="get list user details" onClick={getUserDetailsList}></Button>
        <Button label="get user details" onClick={getUserDetails}></Button>
        <Button label="delete user data" onClick={deleteUserData}></Button>
        <Button label="upsert staff" onClick={upsertStaff}></Button>
        <Button label="get staff list" onClick={getStaffList}></Button>
        <Button label="delete staff" onClick={deleteStaff}></Button>
        <Button label="generate cookies" onClick={generateCookies}></Button>
        <Button label="Force feature rollouts" onClick={forceAllFeatureRollouts}></Button>
      </div>

      <div className="flex flex-column align-items-center gap-1">
        <div className="text-xl">Locations</div>
        <Button label="delete Locations" onClick={deleteLocations}></Button>
        <Button label="get Location By Id" onClick={getLocationById}></Button>
        <Button label="get Locations By WorkspaceId" onClick={getLocationsByWorkspaceId}></Button>
        <Button label="upsert Locations" onClick={upsertLocations}></Button>
      </div>

      {/* <div className="flex flex-column align-items-center gap-1">
        <div className="text-xl">File Upload</div>
        <input type="file" accept="image/*" onChange={onFileUpload} />
        {imageUrl && <img src={imageUrl} />}
      </div> */}
    </div>
  );
};
