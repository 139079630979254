import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../../../store/modals';
import { rolesActions } from '../../../store/roles';
import { usersActions, usersSelectors } from '../../../store/users';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import { Modal } from '../../common';
import labels from './labels';
import { modalActions, modalSelectors } from './modal';

export const DeleteAccount: FC = () => {
  const dispatch = useDispatch();

  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  // const userId = useSelector(authenticationSelectors.selectUserId);
  // const users = useSelector(usersSelectors.selectUsers);
  const isFetching = useSelector(userSettingsSelectors.selectIsFetching);
  const activeSuperAdmins = useSelector(usersSelectors.selectActiveSuperAdmins);
  // const superAdminRoleIds = useSelector(rolesSelectors.selectSuperAdminIds);
  const isModalOpen = useSelector(modalSelectors.selectIsModalOpen);

  const [isConfirmDelete, setConfirmDelete] = useState(false);
  const [isSelectedDeletionType, setSelectedDeletionType] = useState(false);
  const [isTransferOwnership, setTransferOwnership] = useState(true);
  const [newOwnership, setNewOwnership] = useState<string | undefined>();

  const isLastSuperAdmin = isSuperAdmin && activeSuperAdmins.length <= 1;

  useEffect(() => {
    updateUsersAndRoles();
  }, []);
  useEffect(() => {
    if (isModalOpen) {
      updateUsersAndRoles();
    }
  }, [isModalOpen]);

  const updateUsersAndRoles = () => {
    if (isSuperAdmin) {
      dispatch(usersActions.getUsersRequest());
      dispatch(rolesActions.getRolesRequest());
    }
  };

  const openModal = () => {
    dispatch(modalActions.openModal());
  };

  const closeModal = () => {
    dispatch(modalActions.closeModal());
    setConfirmDelete(false);
    setSelectedDeletionType(false);
    setTransferOwnership(true);
  };

  const handleDeleteAccount = () => {
    dispatch(
      userSettingsActions.deleteAccountRequest({
        newOwnership,
        isDeleteTenant: isLastSuperAdmin ? !isTransferOwnership : false,
      })
    );
  };

  // const getUsers = () => {
  //   return users
  //     .filter(
  //       (user) =>
  //         user.status === AdminDataStatus.ACTIVE && user.userId !== userId && !superAdminRoleIds.includes(user.roleId)
  //     )
  //     .map((user) => ({
  //       value: user.userId,
  //       label: user.email,
  //     }));
  // };

  const deletingEntitiesListLabels = labels.listDeletingEntities.split('\\p');
  if (isLastSuperAdmin && isSelectedDeletionType && !isTransferOwnership) {
    deletingEntitiesListLabels.push(...labels.additionalListDeletingOrgEntities.split('\\p').filter((item) => item));
  }

  return (
    <>
      <div className="flex flex-row flex-between">
        <div className="text-title-xs-med mt-2">{labels.warningDelete}</div>
        <Button
          onClick={openModal}
          label={labels.deleteAccount}
          severity="danger"
          className="bg-tomato-main text-white min-w-150px"
        />
      </div>

      <Modal.Container name={ModalName.DELETE_ACCOUNT_MODAL}>
        {/* <Modal.Header>
          <div className="font-bold">
            {isSelectedDeletionType && !isTransferOwnership ? labels.deleteAccountAndOrg : labels.deleteAccount}
          </div>
        </Modal.Header>
        <div className="w-30rem p-fluid">
          {isLastSuperAdmin && !isSelectedDeletionType && (
            <div>
              <div className="flex flex-row">
                <RadioButton
                  className="mt-1"
                  value={true}
                  checked={isTransferOwnership}
                  onChange={(e) => setTransferOwnership(e.target.value)}
                />
                <div className="ml-3">
                  <div className="text-title-s-med">{labels.transferOwnershipTitle}</div>
                  <p className="text-body-lg-reg mt-1">{labels.transferOwnershipDescription}</p>
                </div>
              </div>
              <div className="flex flex-row">
                <RadioButton
                  className="mt-1"
                  value={false}
                  checked={!isTransferOwnership}
                  onChange={(e) => setTransferOwnership(e.target.value)}
                />
                <div className="ml-3">
                  <div className="text-title-s-med">{labels.deleteAccountAndOrgTitle}</div>
                  <p className="text-body-lg-reg mt-1">{labels.deleteAccountAndOrgDescription}</p>
                </div>
              </div>
            </div>
          )}

          {isLastSuperAdmin && isSelectedDeletionType && isTransferOwnership && (
            <div className="mb-4">
              {!getUsers().length && (
                <Tooltip target=".tooltip-message" content={labels.needInviteNewUserToOrg} position="top" />
              )}

              <div className="mb-10px text-title-xs-med text-heavy-100">{labels.transferOwnership}</div>
              <div className="tooltip-message">
                <Dropdown
                  value={newOwnership}
                  onChange={(e) => setNewOwnership(e.target.value)}
                  optionLabel="label"
                  optionValue="value"
                  options={getUsers()}
                  disabled={!getUsers().length}
                />
              </div>
            </div>
          )}

          {(!isLastSuperAdmin || isSelectedDeletionType) && (
            <div className="text-body-lg-reg">
              <div>
                {isLastSuperAdmin && isSelectedDeletionType && !isTransferOwnership
                  ? labels.deleteAccountAndOrgModalDescription
                  : labels.deleteAccountModalDescription}
              </div>
              <div className="mt-3">
                {isLastSuperAdmin && isSelectedDeletionType && !isTransferOwnership
                  ? labels.listDeletingAccountAndOrgEntitiesTitle
                  : labels.listDeletingEntitiesTitle}
                :{' '}
              </div>
              <ul className="mt-1">
                {deletingEntitiesListLabels.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
          )}

          {(!isLastSuperAdmin || isSelectedDeletionType) && (
            <div className="flex flex-column mt-4">
              <label htmlFor="deleteToConfirm" dangerouslySetInnerHTML={{ __html: labels.deleteToConfirm }} />
              <InputText
                className="mt-2"
                id="deleteToConfirm"
                onChange={(event) => setConfirmDelete(event.target.value === labels.deleteConfirmWord)}
              />
            </div>
          )}
        </div> */}

        <Modal.Header>
          <div className="font-bold">{labels.deleteAccountAndOrg}</div>
        </Modal.Header>

        <div className="w-30rem p-fluid">
          <div className="text-body-lg-reg">
            <div>{labels.deleteAccountAndOrgModalDescription}</div>
            <div className="mt-3">{labels.listDeletingAccountAndOrgEntitiesTitle}</div>
            <ul className="mt-1">
              {deletingEntitiesListLabels.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>

          <div className="flex flex-column mt-4">
            <label htmlFor="deleteToConfirm" dangerouslySetInnerHTML={{ __html: labels.deleteToConfirm }} />
            <InputText
              className="mt-2"
              id="deleteToConfirm"
              onChange={(event) => setConfirmDelete(event.target.value === labels.deleteConfirmWord)}
            />
          </div>
        </div>

        <Modal.Buttons>
          {/* {isLastSuperAdmin && !isSelectedDeletionType ? (
            <Button
              label={labels.next}
              severity="danger"
              className="bg-tomato-main text-white"
              onClick={() => {
                setSelectedDeletionType(true);
              }}
            />
          ) : (
            <Button
              label={labels.yesDelete}
              severity="danger"
              className="bg-tomato-main text-white"
              onClick={handleDeleteAccount}
              disabled={!isConfirmDelete || (isLastSuperAdmin && isTransferOwnership && !newOwnership) || isFetching}
            />
          )} */}

          <Button
            label={labels.yesDelete}
            severity="danger"
            className="bg-tomato-main text-white"
            onClick={handleDeleteAccount}
            disabled={!isConfirmDelete || isFetching}
          />
          <Button label={labels.noCancel} onClick={closeModal} outlined />
        </Modal.Buttons>
      </Modal.Container>
    </>
  );
};
