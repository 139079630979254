import { t } from '../../../i18n/i18n';

export default {
  activeOn: t('Availability:ACTIVE_ON'),
  bookingPages: t('Availability:BOOKING_PAGES'),
  clone: t('Common:CLONE'),
  cloneTitle: t('Availability:CLONE_SCHEDULE_TITLE'),
  cancel: t('Common:CANCEL'),
  delete: t('Availability:DELETE_TITLE'),
  deleteDesc: t('Availability:DELETE_DESCRIPTION'),
  deleteMessage: t('Availability:DELETE_MESSAGE'),
  dialogTitle: t('Availability:DIALOG_TITLE'),
  dialogPlaceholder: t('Availability:DIALOG_PLACEHOLDER'),
  dialogEditTitleName: t('Availability:DIALOG_EDIT_NAME_TITLE'),
  duplicateNameMessage: t('Availability:DUPLICATE_NAME_MESSAGE'),
  editName: t('Availability:EDIT_NAME'),
  bookingPage: t('Availability:BOOKING_PAGE'),
  isDefault: t('Availability:IS_DEFAULT'),
  timeZone: t('Availability:TIME_ZONE'),
  setDefault: t('Availability:SET_AS_DEFAULT'),
  setWeeklyHours: t('Availability:SET_WEEKLY_HOURS'),
  saveAvailability: t('Availability:SAVE_AVAILABILITY'),
  schedule: t('Availability:SCHEDULE'),
  overrides: t('Availability:OVERRIDES'),
  confirm: t('Common:CONFIRM'),
  noCancel: t('Common:NO_CANCEL'),
  yesDelete: t('Common:YES_DELETE'),
  deleteWarningMessage: t('Availability:DELETE_WARNING_MESSAGE'),
  firstAdminMessage: t('Availability:FIRST_ADMIN_MESSAGE'),
  specifyWhatScheduleUsedFor: t('Availability:SPECIFY_WHAT_SCHEDULE_USED_FOR'),
  setDefaultOnlyScheduleNotSpecific: t('Availability:SET_DEFAULT_ONLY_SCHEDULE_NOT_SPECIFIC'),
  needAnotherNotSpecificScheduleForRemoval: t('Availability:NEED_ANOTHER_NOT_SPECIFIC_SCHEDULE_FOR_REMOVAL'),
  lockedTooltip: t('Availability:LOCKED_TOOLTIP'),
};
