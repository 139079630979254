import { Dropdown } from 'primereact/dropdown';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sumoLogo from '../../../assets/images/sumoLogo.svg';
import sumoLogoWhite from '../../../assets/images/sumoLogoWhite.svg';
import { Path } from '../../../routing';
import { authenticationActions, authenticationSelectors } from '../../../store/authentication';
import { userSettingsSelectors } from '../../../store/userSettings';
import { workspacesSelectors } from '../../../store/workspaces';
import { ViewAsUserWarningBlock } from '../../opsConsole';
import { SumoistHelper } from '../sumoistHelper/SumoistHelper';
import labels from './labels';
import { NavigationMenu } from './NavigationMenu';
import { UserMenu } from './UserMenu';

export const Menu = () => {
  const dispatch = useDispatch();
  const isOpsConsole = useSelector(authenticationSelectors.selectIsOpsConsole);
  const userWasDeleted = useSelector(authenticationSelectors.selectUserWasDeleted);
  const selectedWorkspaceId = useSelector(authenticationSelectors.selectWorkspaceId);
  const userWorkspaces = useSelector(userSettingsSelectors.selectUserWorkspaces);
  const userWorkspacesOptions = useSelector(workspacesSelectors.selectWorkspaceOptionsByIds(userWorkspaces));
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);
  const isTrialLicenseExpired = useSelector(authenticationSelectors.selectIsTrialLicenseExpired);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const trialExpirationMessage = useSelector(authenticationSelectors.selectTrialExpirationMessage);
  const paidExpirationMessage = useSelector(authenticationSelectors.selectPaidExpirationMessage);
  const showExpirationUserMessage = useSelector(authenticationSelectors.selectShowExpirationUserMessage);
  const isViewAsUserMode = useSelector(authenticationSelectors.selectIsViewAsUser);

  // const accountVisible = Boolean(isQuickSetupFinished && !isOpsConsole && isLicenseActive);

  useEffect(() => {
    userWasDeleted && handleSignOut();
  }, [userWasDeleted]);

  const handleWorkspaceChange = (value: string) => {
    dispatch(authenticationActions.updateUserDataCore({ workspaceId: value }));
    window.location.reload();
  };

  const handleSignOut = () => {
    dispatch(authenticationActions.logoutUserRequest(Path.Landing));
  };

  return (
    <div className="flex flex-column gap-16px p-24px min-h-full">
      <div className="flex">
        <img src={isOpsConsole ? sumoLogoWhite : sumoLogo} className="h-30px" />
      </div>

      {!isOpsConsole && (userWorkspaces?.length || 0) > 1 && (
        <Dropdown
          options={userWorkspacesOptions}
          optionValue="id"
          optionLabel="name"
          value={selectedWorkspaceId}
          dropdownIcon={`${userWorkspacesOptions?.length ? 'pi pi-chevron-down' : null}`}
          valueTemplate={(option) =>
            userWorkspacesOptions?.length ? (
              <div>{option?.name}</div>
            ) : (
              <div className="w-4rem text-right">
                <span className="text-lg vertical-align-middle pi pi-spinner pi-spin" />
              </div>
            )
          }
          placeholder="Loading..." // no need in labels - never shown, used to hide dropdown standard css class
          disabled={!userWorkspacesOptions?.length}
          onChange={(e) => handleWorkspaceChange(e.value)}
        />
      )}

      {isLicenseActive === false || isQuickSetupFinished || isOpsConsole ? (
        <div className="flex-1 mt-8px mb-24px">
          <NavigationMenu />
        </div>
      ) : (
        <div className="flex-1" />
      )}

      {isViewAsUserMode && !isOpsConsole && <ViewAsUserWarningBlock />}
      {isQuickSetupFinished && !isOpsConsole && isLicenseActive && (
        <>
          {trialExpirationMessage && (
            <SumoistHelper
              title={trialExpirationMessage}
              message={labels.trialMessage}
              buttonUrl={isSuperAdmin ? Path.Billing : undefined}
              buttonLabel={labels.buyLicenses}
            />
          )}
          {isSuperAdmin && paidExpirationMessage && (
            <SumoistHelper message={paidExpirationMessage} buttonUrl={Path.Billing} buttonLabel={labels.myBillings} />
          )}
          {showExpirationUserMessage && <SumoistHelper message={paidExpirationMessage} />}
        </>
      )}
      {/* Trial License Expired */}
      {!isOpsConsole && isTrialLicenseExpired && (
        <SumoistHelper
          title={labels.expired}
          message={labels.trialIsOver}
          buttonUrl={isSuperAdmin ? Path.Billing : undefined}
          buttonLabel={labels.buyLicenses}
        />
      )}

      <UserMenu />
    </div>
  );
};
