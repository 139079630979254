import { GuideType } from './types';

export enum PromoActionTypes {
  SHOW_GUIDE = 'promo/SHOW_GUIDE',
  HIDE_GUIDE = 'promo/HIDE_GUIDE',
  RUN_GUIDE = 'promo/RUN_GUIDE',
  STOP_GUIDE = 'promo/STOP_GUIDE',
  TOGGLE_GUIDE = 'promo/TOGGLE_GUIDE',
  SHOW_OVERLAY = 'promo/SHOW_OVERLAY',
  HIDE_OVERLAY = 'promo/HIDE_OVERLAY',
  TOGGLE_OVERLAY = 'promo/TOGGLE_OVERLAY',
  SET_GUIDE_TYPE = 'promo/SET_GUIDE_TYPE',
  SET_GUIDE_STEP_INDEX = 'promo/SET_GUIDE_STEP_INDEX',
}

export type PromoAction =
  | { type: PromoActionTypes.SHOW_GUIDE }
  | { type: PromoActionTypes.HIDE_GUIDE }
  | { type: PromoActionTypes.RUN_GUIDE }
  | { type: PromoActionTypes.STOP_GUIDE }
  | { type: PromoActionTypes.TOGGLE_GUIDE }
  | { type: PromoActionTypes.SHOW_OVERLAY }
  | { type: PromoActionTypes.HIDE_OVERLAY }
  | { type: PromoActionTypes.TOGGLE_OVERLAY }
  | { type: PromoActionTypes.SET_GUIDE_TYPE; payload: GuideType }
  | { type: PromoActionTypes.SET_GUIDE_STEP_INDEX; payload: number };

const showGuide = (): PromoAction => ({
  type: PromoActionTypes.SHOW_GUIDE,
});

const hideGuide = (): PromoAction => ({
  type: PromoActionTypes.HIDE_GUIDE,
});

const runGuide = (): PromoAction => ({
  type: PromoActionTypes.RUN_GUIDE,
});

const stopGuide = (): PromoAction => ({
  type: PromoActionTypes.STOP_GUIDE,
});

const toggleGuide = (): PromoAction => ({
  type: PromoActionTypes.TOGGLE_GUIDE,
});

const setGuideStepIndex = (step: number): PromoAction => ({
  type: PromoActionTypes.SET_GUIDE_STEP_INDEX,
  payload: step,
});

const showOverlay = (): PromoAction => ({
  type: PromoActionTypes.SHOW_OVERLAY,
});

const hideOverlay = (): PromoAction => ({
  type: PromoActionTypes.HIDE_OVERLAY,
});

const toggleOverlay = (): PromoAction => ({
  type: PromoActionTypes.TOGGLE_OVERLAY,
});

const setGuideType = (type: GuideType): PromoAction => ({
  type: PromoActionTypes.SET_GUIDE_TYPE,
  payload: type,
});

export const promoActions = {
  showGuide,
  hideGuide,
  runGuide,
  stopGuide,
  toggleGuide,
  setGuideStepIndex,
  showOverlay,
  hideOverlay,
  toggleOverlay,
  setGuideType,
};
