import {
  BookingCalendarInput,
  BookingPageLabelsInput,
  BookingPageStyleInput,
  BookingPageWhatInput,
  ConfirmationsPageInput,
  CustomFieldInput,
  DateRangeInput,
  LocationType,
  MemberType,
  NotificationsInput,
  TimeInput,
  UpdateBookingPageInput,
  WhenInput,
  WhereInput,
  WhoInput,
} from '../../API';
import { CLONE_NAME_POSTFIX } from '../../types/constants';
import { MemberSubType } from '../../types/types';
import { authenticationSelectors } from '../authentication';
import { bookingTemplatesSelectors } from '../bookingTemplates';
import { AppThunk } from '../rootStore';
import { DEFAULT_BOOKING_PAGE_DATA } from './constants';
import { cloneModalActions, confirmOverrideModalActions } from './modal';
import { bookingPageSelectors } from './selectors';
import { AccordionIndexes, GetBookingPagePayload } from './types';

export enum bookingPagesActionTypes {
  GET_BOOKING_PAGES_REQUEST = 'bookingPages/GET_BOOKING_PAGES_REQUEST',
  GET_BOOKING_PAGES_SUCCESS = 'bookingPages/GET_BOOKING_PAGES_SUCCESS',
  GET_BOOKING_PAGES_FAIL = 'bookingPages/GET_BOOKING_PAGES_FAIL',
  GET_BOOKING_PAGES_PAGE_REQUEST = 'bookingPages/GET_BOOKING_PAGES_PAGE_REQUEST',
  GET_BOOKING_PAGE_REQUEST = 'bookingPages/GET_BOOKING_PAGE_REQUEST',
  GET_BOOKING_PAGE_SUCCESS = 'bookingPages/GET_BOOKING_PAGE_SUCCESS',
  GET_BOOKING_PAGE_FAIL = 'bookingPages/GET_BOOKING_PAGE_FAIL',
  CREATE_BOOKING_PAGE_REQUEST = 'bookingPages/CREATE_BOOKING_PAGE_REQUEST',
  CREATE_BOOKING_PAGE_SUCCESS = 'bookingPages/CREATE_BOOKING_PAGE_SUCCESS',
  CREATE_BOOKING_PAGE_FAIL = 'bookingPages/CREATE_BOOKING_PAGE_FAIL',
  ACTIVATE_BOOKING_PAGE_REQUEST = 'bookingPages/ACTIVATE_BOOKING_PAGE_REQUEST',
  ACTIVATE_BOOKING_PAGE_SUCCESS = 'bookingPages/ACTIVATE_BOOKING_PAGE_SUCCESS',
  ACTIVATE_BOOKING_PAGE_FAIL = 'bookingPages/ACTIVATE_BOOKING_PAGE_FAIL',
  CLONE_BOOKING_PAGE_REQUEST = 'bookingPages/CLONE_BOOKING_PAGE_REQUEST',
  CLONE_BOOKING_PAGE_SUCCESS = 'bookingPages/CLONE_BOOKING_PAGE_SUCCESS',
  CLONE_BOOKING_PAGE_FAIL = 'bookingPages/CLONE_BOOKING_PAGE_FAIL',
  SAVE_BOOKING_PAGE_REQUEST = 'bookingPages/SAVE_BOOKING_PAGE_REQUEST',
  SAVE_BOOKING_PAGE_SUCCESS = 'bookingPages/SAVE_BOOKING_PAGE_SUCCESS',
  SAVE_BOOKING_PAGE_FAIL = 'bookingPages/SAVE_BOOKING_PAGE_FAIL',
  DELETE_BOOKING_PAGES_REQUEST = 'bookingPages/DELETE_BOOKING_PAGES_REQUEST',
  DELETE_BOOKING_PAGES_SUCCESS = 'bookingPages/DELETE_BOOKING_PAGES_SUCCESS',
  DELETE_BOOKING_PAGES_FAIL = 'bookingPages/DELETE_BOOKING_PAGES_FAIL',
  SELECT_BOOKING_PAGE = 'bookingPages/SELECT_BOOKING_PAGE',
  UNSELECT_BOOKING_PAGE = 'bookingPages/UNSELECT_BOOKING_PAGE',
  UNSELECT_ALL_BOOKING_PAGES = 'bookingPages/UNSELECT_ALL_BOOKING_PAGES',
  SET_BOOKING_PAGE = 'bookingPages/SET_BOOKING_PAGE',
  UPDATE_RECORD = 'bookingPages/UPDATE_RECORD',
  UPDATE_HOW_STEP = 'bookingPages/UPDATE_HOW_STEP',
  UPDATE_CALENDAR_STEP = 'bookingPages/UPDATE_CALENDAR_STEP',
  UPDATE_STYLE_STEP = 'bookingPages/UPDATE_STYLE_STEP',
  SET_CLONE_NAME = 'bookingPages/SET_CLONE_NAME',
  SELECT_TIME_ZONES = 'bookingPages/SELECT_TIME_ZONES',
  CLEAR_BOOKING_PAGE = 'bookingPages/CLEAR_BOOKING_PAGE',
  UPDATE_WHAT_STEP = 'bookingPages/UPDATE_WHAT_STEP',
  UPDATE_WHO_STEP = 'bookingPages/UPDATE_WHO_STEP',
  UPDATE_WHERE_STEP = 'bookingPages/UPDATE_WHERE_STEP',
  TOGGLE_WHERE_LOCATION_TYPE = 'bookingPages/TOGGLE_WHERE_LOCATION_TYPE',
  UPDATE_WHEN_STEP = 'bookingPages/UPDATE_WHEN_STEP',
  UPDATE_WHEN_DURATION = 'bookingPages/UPDATE_WHEN_DURATION',
  UPDATE_WHEN_DATE_RANGE = 'bookingPages/UPDATE_WHEN_DATE_RANGE',
  UPDATE_WHEN_SCHEDULE_BUFFER = 'bookingPages/UPDATE_WHEN_SCHEDULE_BUFFER',
  SET_BOOKING_PAGE_FIELDS = 'bookingPages/SET_BOOKING_PAGE_FIELDS',
  ADD_MEETING_TYPE_CUSTOM_FIELD = 'bookingPages/ADD_MEETING_TYPE_CUSTOM_FIELD',
  REMOVE_MEETING_TYPE_CUSTOM_FIELD = 'bookingPages/REMOVE_MEETING_TYPE_CUSTOM_FIELD',
  UPDATE_MEETING_TYPE_CUSTOM_FIELD = 'bookingPages/UPDATE_MEETING_TYPE_CUSTOM_FIELD',
  UPDATE_NOTIFICATIONS_STEP = 'bookingPages/UPDATE_NOTIFICATIONS_STEP',
  UPDATE_CONFIRMATION_STEP = 'bookingPages/UPDATE_CONFIRMATION_STEP',
  SET_IS_CUSTOM_BOOKING_PAGE = 'bookingPages/SET_IS_CUSTOM_BOOKING_PAGE',
  UPDATE_ACCORDION_INDEXES = 'bookingPages/UPDATE_ACCORDION_INDEXES',
  SET_FILTER_BY_USER = 'bookingPages/SET_FILTER_BY_USER',
  SHORT_LINKS_SUCCESS = 'bookingPages/SHORT_LINKS_SUCCESS',
  SHORT_LINKS_FAIL = 'bookingPages/SHORT_LINKS_FAIL',
  UPDATE_SMART_ALERTS = 'bookingPages/UPDATE_SMART_ALERTS',
  SET_ADD_TO_WEBSITE_LINK = 'bookingPages/SET_ADD_TO_WEBSITE_LINK',
}

export type BookingPagesAction =
  | { type: bookingPagesActionTypes.GET_BOOKING_PAGES_REQUEST }
  | { type: bookingPagesActionTypes.GET_BOOKING_PAGES_SUCCESS; payload: UpdateBookingPageInput[] }
  | { type: bookingPagesActionTypes.GET_BOOKING_PAGES_FAIL; error: unknown }
  | { type: bookingPagesActionTypes.GET_BOOKING_PAGES_PAGE_REQUEST; isInitialVisit: boolean }
  | { type: bookingPagesActionTypes.GET_BOOKING_PAGE_REQUEST; payload: GetBookingPagePayload }
  | { type: bookingPagesActionTypes.GET_BOOKING_PAGE_SUCCESS; payload: UpdateBookingPageInput }
  | { type: bookingPagesActionTypes.GET_BOOKING_PAGE_FAIL; error: unknown }
  | { type: bookingPagesActionTypes.CREATE_BOOKING_PAGE_REQUEST }
  | { type: bookingPagesActionTypes.CREATE_BOOKING_PAGE_SUCCESS }
  | { type: bookingPagesActionTypes.CREATE_BOOKING_PAGE_FAIL; error: unknown }
  | { type: bookingPagesActionTypes.ACTIVATE_BOOKING_PAGE_REQUEST; payload: UpdateBookingPageInput }
  | { type: bookingPagesActionTypes.ACTIVATE_BOOKING_PAGE_SUCCESS }
  | { type: bookingPagesActionTypes.ACTIVATE_BOOKING_PAGE_FAIL; error: unknown }
  | { type: bookingPagesActionTypes.CLONE_BOOKING_PAGE_REQUEST }
  | { type: bookingPagesActionTypes.CLONE_BOOKING_PAGE_SUCCESS }
  | { type: bookingPagesActionTypes.CLONE_BOOKING_PAGE_FAIL; error: unknown }
  | { type: bookingPagesActionTypes.SAVE_BOOKING_PAGE_REQUEST }
  | { type: bookingPagesActionTypes.SAVE_BOOKING_PAGE_SUCCESS }
  | { type: bookingPagesActionTypes.SAVE_BOOKING_PAGE_FAIL; error: unknown }
  | { type: bookingPagesActionTypes.DELETE_BOOKING_PAGES_REQUEST }
  | { type: bookingPagesActionTypes.DELETE_BOOKING_PAGES_SUCCESS }
  | { type: bookingPagesActionTypes.DELETE_BOOKING_PAGES_FAIL; error: unknown }
  | { type: bookingPagesActionTypes.SELECT_BOOKING_PAGE; payload: string }
  | { type: bookingPagesActionTypes.UNSELECT_BOOKING_PAGE; payload: string }
  | { type: bookingPagesActionTypes.UNSELECT_ALL_BOOKING_PAGES }
  | { type: bookingPagesActionTypes.SET_BOOKING_PAGE; payload: UpdateBookingPageInput }
  | { type: bookingPagesActionTypes.UPDATE_RECORD; payload: Partial<UpdateBookingPageInput> }
  | { type: bookingPagesActionTypes.UPDATE_HOW_STEP; payload: Partial<BookingPageLabelsInput> }
  | { type: bookingPagesActionTypes.UPDATE_CALENDAR_STEP; payload: Partial<BookingCalendarInput> }
  | { type: bookingPagesActionTypes.UPDATE_STYLE_STEP; payload: Partial<BookingPageStyleInput> }
  | { type: bookingPagesActionTypes.SET_CLONE_NAME; payload: string }
  | { type: bookingPagesActionTypes.SELECT_TIME_ZONES; payload: string[] }
  | { type: bookingPagesActionTypes.CLEAR_BOOKING_PAGE }
  | { type: bookingPagesActionTypes.UPDATE_WHAT_STEP; payload: Partial<BookingPageWhatInput> }
  | { type: bookingPagesActionTypes.UPDATE_WHO_STEP; payload: Partial<WhoInput> }
  | { type: bookingPagesActionTypes.UPDATE_WHERE_STEP; payload: Partial<WhereInput> }
  | { type: bookingPagesActionTypes.TOGGLE_WHERE_LOCATION_TYPE; payload: LocationType }
  | { type: bookingPagesActionTypes.UPDATE_WHEN_STEP; payload: Partial<WhenInput> }
  | { type: bookingPagesActionTypes.UPDATE_WHEN_DURATION; payload: Partial<TimeInput> }
  | { type: bookingPagesActionTypes.UPDATE_WHEN_DATE_RANGE; payload: Partial<DateRangeInput> }
  | { type: bookingPagesActionTypes.UPDATE_WHEN_SCHEDULE_BUFFER; payload: Partial<TimeInput> }
  | { type: bookingPagesActionTypes.SET_BOOKING_PAGE_FIELDS; payload: (CustomFieldInput | null)[] }
  | { type: bookingPagesActionTypes.ADD_MEETING_TYPE_CUSTOM_FIELD; payload: CustomFieldInput }
  | { type: bookingPagesActionTypes.REMOVE_MEETING_TYPE_CUSTOM_FIELD; payload: string }
  | { type: bookingPagesActionTypes.UPDATE_MEETING_TYPE_CUSTOM_FIELD; payload: CustomFieldInput }
  | { type: bookingPagesActionTypes.UPDATE_NOTIFICATIONS_STEP; payload: Partial<NotificationsInput> }
  | { type: bookingPagesActionTypes.UPDATE_CONFIRMATION_STEP; payload: Partial<ConfirmationsPageInput> }
  | { type: bookingPagesActionTypes.SET_IS_CUSTOM_BOOKING_PAGE; payload: boolean }
  | { type: bookingPagesActionTypes.UPDATE_ACCORDION_INDEXES; payload: Partial<AccordionIndexes> }
  | { type: bookingPagesActionTypes.SET_FILTER_BY_USER; payload: string }
  | { type: bookingPagesActionTypes.SHORT_LINKS_SUCCESS }
  | { type: bookingPagesActionTypes.SHORT_LINKS_FAIL; error: unknown }
  | { type: bookingPagesActionTypes.UPDATE_SMART_ALERTS; payload: string }
  | { type: bookingPagesActionTypes.SET_ADD_TO_WEBSITE_LINK; payload: string };

const getBookingPagesRequest = (): BookingPagesAction => ({ type: bookingPagesActionTypes.GET_BOOKING_PAGES_REQUEST });
const getBookingPagesSuccess = (payload: UpdateBookingPageInput[]): BookingPagesAction => ({
  type: bookingPagesActionTypes.GET_BOOKING_PAGES_SUCCESS,
  payload,
});
const getBookingPagesFail = (error: unknown): BookingPagesAction => ({
  type: bookingPagesActionTypes.GET_BOOKING_PAGES_FAIL,
  error,
});
const getBookingPagesPageRequest = (isInitialVisit: boolean): BookingPagesAction => ({
  type: bookingPagesActionTypes.GET_BOOKING_PAGES_PAGE_REQUEST,
  isInitialVisit,
});
const getBookingPageRequest = (payload: GetBookingPagePayload): BookingPagesAction => ({
  type: bookingPagesActionTypes.GET_BOOKING_PAGE_REQUEST,
  payload,
});
const getBookingPageSuccess = (payload: UpdateBookingPageInput): BookingPagesAction => ({
  type: bookingPagesActionTypes.GET_BOOKING_PAGE_SUCCESS,
  payload,
});
const getBookingPageFail = (error: unknown): BookingPagesAction => ({
  type: bookingPagesActionTypes.GET_BOOKING_PAGE_FAIL,
  error,
});
const createBookingPageRequest = (): BookingPagesAction => ({
  type: bookingPagesActionTypes.CREATE_BOOKING_PAGE_REQUEST,
});
const createBookingPagesuccess = (): BookingPagesAction => ({
  type: bookingPagesActionTypes.CREATE_BOOKING_PAGE_SUCCESS,
});
const createBookingPageFail = (error: unknown): BookingPagesAction => ({
  type: bookingPagesActionTypes.CREATE_BOOKING_PAGE_FAIL,
  error,
});
const activateBookingPageRequest = (payload: UpdateBookingPageInput): BookingPagesAction => ({
  type: bookingPagesActionTypes.ACTIVATE_BOOKING_PAGE_REQUEST,
  payload,
});
const activateBookingPagesuccess = (): BookingPagesAction => ({
  type: bookingPagesActionTypes.ACTIVATE_BOOKING_PAGE_SUCCESS,
});
const activateBookingPageFail = (error: unknown): BookingPagesAction => ({
  type: bookingPagesActionTypes.ACTIVATE_BOOKING_PAGE_FAIL,
  error,
});
const cloneBookingPageRequest = (): BookingPagesAction => ({
  type: bookingPagesActionTypes.CLONE_BOOKING_PAGE_REQUEST,
});
const cloneBookingPagesuccess = (): BookingPagesAction => ({
  type: bookingPagesActionTypes.CLONE_BOOKING_PAGE_SUCCESS,
});
const cloneBookingPageFail = (error: unknown): BookingPagesAction => ({
  type: bookingPagesActionTypes.CLONE_BOOKING_PAGE_FAIL,
  error,
});
const saveBookingPageRequest = (): BookingPagesAction => ({
  type: bookingPagesActionTypes.SAVE_BOOKING_PAGE_REQUEST,
});
const saveBookingPagesuccess = (): BookingPagesAction => ({ type: bookingPagesActionTypes.SAVE_BOOKING_PAGE_SUCCESS });
const saveBookingPageFail = (error: unknown): BookingPagesAction => ({
  type: bookingPagesActionTypes.SAVE_BOOKING_PAGE_FAIL,
  error,
});
const deleteBookingPagesRequest = (): BookingPagesAction => ({
  type: bookingPagesActionTypes.DELETE_BOOKING_PAGES_REQUEST,
});
const deleteBookingPagesSuccess = (): BookingPagesAction => ({
  type: bookingPagesActionTypes.DELETE_BOOKING_PAGES_SUCCESS,
});
const deleteBookingPagesFail = (error: unknown): BookingPagesAction => ({
  type: bookingPagesActionTypes.DELETE_BOOKING_PAGES_FAIL,
  error,
});
const selectBookingPage = (payload: string): BookingPagesAction => ({
  type: bookingPagesActionTypes.SELECT_BOOKING_PAGE,
  payload,
});
const unselectBookingPage = (payload: string): BookingPagesAction => ({
  type: bookingPagesActionTypes.UNSELECT_BOOKING_PAGE,
  payload,
});
const unselectAllBookingPage = (): BookingPagesAction => ({ type: bookingPagesActionTypes.UNSELECT_ALL_BOOKING_PAGES });
const setBookingPage = (payload: UpdateBookingPageInput): BookingPagesAction => ({
  type: bookingPagesActionTypes.SET_BOOKING_PAGE,
  payload,
});
const updateRecord = (payload: Partial<UpdateBookingPageInput>): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_RECORD,
  payload,
});
const updateHowStep = (payload: Partial<BookingPageLabelsInput>): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_HOW_STEP,
  payload,
});
const updateCalendarStep = (payload: Partial<BookingCalendarInput>): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_CALENDAR_STEP,
  payload,
});
const updateStyleStep = (payload: Partial<BookingPageStyleInput>): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_STYLE_STEP,
  payload,
});
const setCloneName = (payload: string): BookingPagesAction => ({
  type: bookingPagesActionTypes.SET_CLONE_NAME,
  payload,
});
const selectTimeZones = (payload: string[]): BookingPagesAction => ({
  type: bookingPagesActionTypes.SELECT_TIME_ZONES,
  payload,
});
const clearBookingPage = (): BookingPagesAction => ({ type: bookingPagesActionTypes.CLEAR_BOOKING_PAGE });

const cloneBookingPageThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const name = bookingPageSelectors.selectWhatName(state) + CLONE_NAME_POSTFIX;
  dispatch(setCloneName(name));
  dispatch(cloneModalActions.openModal());
};

const updateWhatStep = (payload: Partial<BookingPageWhatInput>): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_WHAT_STEP,
  payload,
});
const updateWhoStep = (payload: Partial<WhoInput>): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_WHO_STEP,
  payload,
});

const updateWhereStep = (payload: Partial<WhereInput>): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_WHERE_STEP,
  payload,
});
const toggleWhereLocationType = (payload: LocationType): BookingPagesAction => ({
  type: bookingPagesActionTypes.TOGGLE_WHERE_LOCATION_TYPE,
  payload,
});

const updateWhenStep = (payload: Partial<WhenInput>): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_WHEN_STEP,
  payload,
});
const updateWhenDuration = (payload: Partial<TimeInput>): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_WHEN_DURATION,
  payload,
});
const updateWhenDateRange = (payload: Partial<DateRangeInput>): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_WHEN_DATE_RANGE,
  payload,
});
const updateWhenScheduleBuffer = (payload: Partial<TimeInput>): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_WHEN_SCHEDULE_BUFFER,
  payload,
});

const setCustomFields = (payload: (CustomFieldInput | null)[]): BookingPagesAction => ({
  type: bookingPagesActionTypes.SET_BOOKING_PAGE_FIELDS,
  payload,
});
const addCustomField = (payload: CustomFieldInput): BookingPagesAction => ({
  type: bookingPagesActionTypes.ADD_MEETING_TYPE_CUSTOM_FIELD,
  payload,
});
const removeCustomField = (payload: string): BookingPagesAction => ({
  type: bookingPagesActionTypes.REMOVE_MEETING_TYPE_CUSTOM_FIELD,
  payload,
});
const updateCustomField = (payload: CustomFieldInput): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_MEETING_TYPE_CUSTOM_FIELD,
  payload,
});

const updateNotificationsStep = (payload: Partial<NotificationsInput>): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_NOTIFICATIONS_STEP,
  payload,
});
const updateConfirmationStep = (payload: Partial<ConfirmationsPageInput>): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_CONFIRMATION_STEP,
  payload,
});

const updateAccordionIndexes = (payload: Partial<AccordionIndexes>): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_ACCORDION_INDEXES,
  payload,
});

const setFilterByUser = (payload: string): BookingPagesAction => ({
  type: bookingPagesActionTypes.SET_FILTER_BY_USER,
  payload,
});

// TODO: move to /thunks please
const uploadBookingTemplate = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const bookingTemplateId = bookingPageSelectors.selectBookingTemplateId(state);
  const isHostRequired = bookingPageSelectors.selectIsUserRequiredHost(state);
  const videoConferenceType = bookingPageSelectors.selectVideoConferenceType(state);
  const bookingPageWhat = bookingPageSelectors.selectWhat(state);
  const userId = authenticationSelectors.selectUserId(state);
  const bookingTemplate =
    (bookingTemplateId && bookingTemplatesSelectors.selectBookingTemplatesById(state)[bookingTemplateId]) || null;
  if (bookingTemplate) {
    dispatch(
      updateRecord({
        what: {
          ...bookingPageWhat,
          instructions: bookingTemplate.what?.instructions,
          color: bookingTemplate.what?.color,
        },
        who: {
          ...DEFAULT_BOOKING_PAGE_DATA.who,
          hostMembers: isHostRequired ? [{ id: userId, type: MemberType.SUMO1, subType: MemberSubType.USER }] : [],
        },
        where: { ...bookingTemplate.where, ...(isHostRequired ? { videoConferenceType } : {}) },
        when: bookingTemplate?.when,
        labels: bookingTemplate?.labels,
        style: bookingTemplate?.style,
        inputFields: bookingTemplate.inputFields,
        confirmationsPage: bookingTemplate.confirmationsPage,
        notifications: bookingTemplate.notifications,
        calendar: bookingTemplate?.calendar,
        inviteOthers: bookingTemplate?.inviteOthers,
        enterNote: bookingTemplate?.enterNote,
        smartAlertIds: bookingTemplate?.smartAlertIds,
      })
    );
  }
};

// TODO: move to /thunks please
const overrideBookingTemplate = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const bookingTemplateName = bookingPageSelectors.selectBookingTemplateName(state);
  dispatch(setCloneName(bookingTemplateName));
  dispatch(confirmOverrideModalActions.openModal());
};

// TODO: move to /thunks please
const setDefaultVideoConference = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const hostData = bookingPageSelectors.selectAllHostsData(state);
  if (hostData.length && hostData[0].defaultVideoIntegration) {
    dispatch(updateWhereStep({ videoConferenceType: hostData[0].defaultVideoIntegration }));
  }
};

const shortLinksSuccess = (): BookingPagesAction => ({
  type: bookingPagesActionTypes.SHORT_LINKS_SUCCESS,
});
const shortLinksFail = (error: unknown): BookingPagesAction => ({
  type: bookingPagesActionTypes.SHORT_LINKS_FAIL,
  error,
});

const updateSmartAlerts = (payload: string): BookingPagesAction => ({
  type: bookingPagesActionTypes.UPDATE_SMART_ALERTS,
  payload,
});

const setAddToWebsiteLink = (payload: string): BookingPagesAction => ({
  type: bookingPagesActionTypes.SET_ADD_TO_WEBSITE_LINK,
  payload,
});

export const bookingPagesActions = {
  getBookingPagesRequest,
  getBookingPagesSuccess,
  getBookingPagesFail,
  getBookingPagesPageRequest,
  getBookingPageRequest,
  getBookingPageSuccess,
  getBookingPageFail,
  createBookingPageRequest,
  createBookingPagesuccess,
  createBookingPageFail,
  activateBookingPageRequest,
  activateBookingPagesuccess,
  activateBookingPageFail,
  cloneBookingPageRequest,
  cloneBookingPagesuccess,
  cloneBookingPageFail,
  saveBookingPageRequest,
  saveBookingPagesuccess,
  saveBookingPageFail,
  deleteBookingPagesRequest,
  deleteBookingPagesSuccess,
  deleteBookingPagesFail,
  selectBookingPage,
  unselectBookingPage,
  unselectAllBookingPage,
  setBookingPage,
  updateRecord,
  updateHowStep,
  updateCalendarStep,
  updateStyleStep,
  setCloneName,
  selectTimeZones,
  clearBookingPage,
  cloneBookingPageThunk,
  updateWhatStep,
  updateWhoStep,
  updateWhereStep,
  toggleWhereLocationType,
  updateWhenStep,
  updateWhenDuration,
  updateWhenDateRange,
  updateWhenScheduleBuffer,
  addCustomField,
  setCustomFields,
  removeCustomField,
  updateCustomField,
  updateNotificationsStep,
  updateConfirmationStep,
  updateAccordionIndexes,
  setFilterByUser,
  uploadBookingTemplate,
  overrideBookingTemplate,
  setDefaultVideoConference,
  shortLinksSuccess,
  shortLinksFail,
  updateSmartAlerts,
  setAddToWebsiteLink,
};
