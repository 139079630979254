import { useDispatch, useSelector } from 'react-redux';
import { getAdoptionTotalAverage, ORGS_CHART_VALUES, orgsActions, orgsSelectors } from '../../../../store/opsConsole/orgs';
import { OPSConsoleChart } from '../../OPSConsoleChart/OPSConsoleChart';
import { ChartType, TooltipItem } from 'chart.js';
import labels from './labels';

export const OrgAdoptionChart = () => {
  const dispatch = useDispatch();
  const orgsChartData = useSelector(orgsSelectors.selectOrgChartData);
  const chartFilter = useSelector(orgsSelectors.selectOrgChartFilter);
  const orgLogsFetching = useSelector(orgsSelectors.selectOrgLogsFetching);
  const orgTotal = getAdoptionTotalAverage(orgsChartData);

  const handleFilterChange = (value: ORGS_CHART_VALUES) => {
    dispatch(orgsActions.getOrgLogsRequest(value));
  };

  return (
    <OPSConsoleChart
      data={orgsChartData}
      title={labels.title}
      total={`${orgTotal.toFixed(2)}%`}
      totalLabel={labels.total}
      isFethcing={orgLogsFetching}
      filter={chartFilter}
      handleFilterChange={handleFilterChange}
      totalTooltip={labels.tooltip}
      optionsScalesY={{ min: 0, max: 100 }}
      tooltipLabel={(data: TooltipItem<ChartType>) => {
        return data.formattedValue + '%';
      }}
    />
  );
};
