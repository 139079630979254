export const PublicBookingPage = Object.freeze({
  HOST: `Hôte`,
  HOST_PHONE: `Téléphone hôte`,
  EDIT_BUTTON: `Éditer`,
  MORE: `Plus`,
  TIME_ZONE_LABEL: `Fuseau horaire`,
  NAME_FIELD_LABEL: `Nom`,
  EMAIL_FIELD_LABEL: `E-mail`,
  INVITE_OTHERS_FIELD_LABEL: `Participants supplémentaires`,
  INVITE_OTHERS_FIELD_PLACEHOLDER: `Invitez d'autres personnes en saisissant leurs adresses e-mail séparées par des virgules`,
  NOTE_FOR_ATTENDEES_FIELD_LABEL: `Note pour les participants`,
  NOTE_FOR_ATTENDEES_FIELD_PLACEHOLDER: `Saisissez une note pour tous les participants`,
  NO_USERS_AVAILABLE_LABEL: `Aucun utilisateur n'est disponible à l'heure sélectionnée. Veuillez choisir une autre heure.`,
  DEACTIVATED_LABEL: `Le hôte a été désactivé. Veuillez demander un nouveau lien de réservation.`,
  CANCEL_TYPE_LABEL: `Pourquoi annuler ?`,
  CANCEL_REASON_LABEL: `Raison de l'annulation :`,
  IN_PERSON_LABEL: `En personne`,
  PHONE_LABEL: `Téléphone`,
  GOOGLE_MEET: `Google Meet`,
  ZOOM: `Zoom`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  ERROR: `Erreur`,
  BACK_TO_HOME: `Retour à l'accueil`,
  VIDEO_CONFERENCE_LABEL: `Conférence vidéo`,
  SLOTS_NO_FOUND: `Créneaux non trouvés`,
  VIEW_NEXT_MONTH: `Voir le mois prochain`,
  ALLOW_SMS: `Recevoir des rappels de rendez-vous par SMS ?`,
  BOOKING_PAGE_EXPIRED: `Cette page de réservation est expirée. Veuillez demander un nouveau lien de réservation.`,
  PERSONAL_INFO: `Informations personnelles`,
  ATTENDEES: `Participants`,
  ATTENDEE_EMAIL: `E-mail du participant`,
  ADD_ATTENDEES: `Ajouter des participants`,
  YOUR_ANSWERS: `Vos réponses`,
  MANAGE_BOOKING: `Gérer la réservation`,
  MODIFY_BOOKING: `Modifier la réservation`,
  CANCEL_POLICY: `Politique d'annulation`,
  LOCATIONS_PLACEHOLDER: `Entrez l'adresse`,
  LOADING_LABEL_1: `Création de la réunion`,
  LOADING_LABEL_2: `Synchronisation des calendriers`,
  LOADING_LABEL_3: `Envoi des messages`,
  MILES: ` miles`,
  KILOMETERS: ` kilomètres`,
  LEARN_MORE: `En savoir plus`,
  LOCATION_ACCESS_TOOLTIP: `Impossible d'accéder à votre emplacement`,
});

export const BookedMeetings = Object.freeze({
  ADD_NOTE: `Ajouter une note`,
  ATTENDEES: `Participants`,
  CANCELED: 'Annulé',
  CANCEL_MEETING_TITLE: `Annuler la réunion`,
  CANCEL_MEETING_DESCRIPTION: `Pourquoi annuler ?`,
  CANCEL_REASON: `Raison de l'annulation :`,
  DETAILS: `Détails`,
  BOOKED: `Réservé`,
  BOOKING_PAGE: `Page de réservation`,
  BOOKING_PAGE_PLACEHOLDER: `Sélectionnez la page de réservation`,
  EDIT_ATTENDEE_LIMIT: `Modifier la limite de participants`,
  GOOGLE_MEET: `Google Meet`,
  GUEST: `Invité`,
  GUEST_ANSWERS: `Réponses des invités`,
  HOSTS: `Hôtes`,
  IN_PERSON: `En personne`,
  LOCATION_TITLE: `Lieu`,
  NO_SCHEDULED_MEETINGS: `Vous n'avez aucune réunion réservée`,
  NO_LOCATION_GIVEN: `Aucun lieu indiqué`,
  NO_SHOW_LABEL: `Non-présent`,
  MARK_AS_NO_SHOW: `Marquer comme non-présent`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART1: `Êtes-vous sûr de vouloir marquer`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART2: `comme non-présent ?`,
  MARK_AS_NO_SHOW_TOOLTIP: `La réunion n'a pas encore commencé.`,
  MEETING: `Réunion`,
  MEETINGS: `Réunions`,
  INTERNAL_MEETING_NOTES: `Note de réunion interne`,
  INTERNAL_MEETING_NOTES_DESC: `Seul l'hôte verra cette note`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  NOTE_FROM_INVITEE: `Note de l'invité`,
  NO_ANSWERS: `Aucune réponse`,
  NO_ATTENDEES: `Aucun participant`,
  NO_NOTE: `Aucune note`,
  PAST: `Passé`,
  PHONE_CALL: `Appel téléphonique`,
  RESCHEDULE: `Reprogrammer`,
  REASON_UNEXPECTED: `Conflit inattendu`,
  REASON_INVITE: `Besoin d'inviter d'autres`,
  REASON_TIME: `Besoin de temps pour préparer`,
  REASON_OTHER: `Autre`,
  STATUS: `Statut`,
  STATUS_PLACEHOLDER: `Sélectionner le statut`,
  TEAM: `Équipe`,
  TITLE: `Réunions réservées`,
  THIS_MONTH: `Ce mois-ci`,
  UNDO_NO_SHOW: `Annuler non-présent`,
  UPCOMING: `À venir`,
  ZOOM: `Zoom`,
  VIDEO_CONFERENCE: `Vidéo-conférence`,
  WORKSPACE: `Espace de travail`,
});

export const BookedMeetingsToastsNotifications = Object.freeze({
  GET_SCHEDULED_MEETINGS_ERROR_TOAST: `Échec de la demande de réunion réservée`,
  CANCEL_SCHEDULED_MEETING_ERROR_MESSAGE: `Échec de la demande d'annulation de réunion`,
  CANCEL_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Réunion annulée avec succès`,
  UPDATE_SCHEDULED_MEETING_ERROR_TOAST: `Échec de la demande de mise à jour de réunion`,
  DELETE_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Réunion supprimée avec succès`,
  DELETE_SCHEDULED_MEETING_ERROR_MESSAGE: `Échec de la demande de suppression de réunion`,
});
