import { ScrollPanel } from 'primereact/scrollpanel';
import React from 'react';
import { useSelector } from 'react-redux';
import { authenticationSelectors } from '../../../store/authentication';
import { globalSelectors } from '../../../store/global';
import { Menu } from './Menu';

export const RainbowLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const isOpsConsole = useSelector(authenticationSelectors.selectIsOpsConsole);
  const fullScreen = useSelector(globalSelectors.selectFullScreen);

  return (
    <div className={`flex h-screen overflow-hidden ${isOpsConsole ? 'ops-console' : ''}`}>
      <div className="private-layout-menu">
        <ScrollPanel>
          <Menu />
        </ScrollPanel>
      </div>
      <div className="private-layout-container">
        <ScrollPanel>
          <div className={`private-layout-content ${fullScreen ? 'full-screen' : ''}`}>{children}</div>
        </ScrollPanel>
      </div>
    </div>
  );
};
