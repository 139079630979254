import { combineReducers } from 'redux';
import { AgendaType, EventSteps, ViewSettings } from './types';
import { EventActionTypes, EventAction } from './actions';
import { CreateUserEventInput, JourneyStepInput } from '../../API';
import { DEFAULT_AGENDA, DEFAULT_EVENT, DEFAULT_VIEW_DATE, DEFAULT_EVENT_STEPS } from './constants'; // PREVIEW_HOST
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import {
  calculateInitialEventLocation,
  calculateInitialTimeZone,
  calculateViewDate,
  filterJourneyStepForSave,
  findJourneyCurrentStepForView,
  isSpotsHaveSelectedTime,
  resetBookingPageSteps,
  setNewStep,
  setNextStep,
  setPreviousStep,
  updateJourneyField,
  updateSavedStepsField,
} from './utils';

const event = (state: CreateUserEventInput = DEFAULT_EVENT, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.UPDATE_EVENT:
      return { ...state, ...action.payload } as CreateUserEventInput;
    case EventActionTypes.SET_AGENDA:
    case EventActionTypes.GET_AGENDA_SUCCESS:
      return {
        ...state,
        startTime: isSpotsHaveSelectedTime(action.payload.spots, state.startTime) ? state.startTime : '',
        // eventDate: calculateEventDate(action.payload, state.eventDate),
        timeZone: calculateInitialTimeZone(action.payload, state.timeZone),
        inputFields: state.inputFields || action.payload.event?.inputFields || action.payload.bookingPage?.inputFields,
        location:
          state.location ||
          action.payload.event?.location ||
          (action.payload.bookingPage &&
            calculateInitialEventLocation(action.payload.bookingPage, action.payload.locations)),
      } as CreateUserEventInput;
    case EventActionTypes.SET_RESCHEDULE_DATA:
      return action.payload.event as CreateUserEventInput;
    case EventActionTypes.SET_SCHEDULED_MEETING:
      return action.payload as CreateUserEventInput;
    case EventActionTypes.SET_PREVIEW_DATA: {
      const { bookingPage } = action.payload;
      return {
        ...state,
        inputFields: bookingPage?.inputFields || state.inputFields,
        location: bookingPage ? calculateInitialEventLocation(bookingPage) : state.location,
      } as CreateUserEventInput;
    }
    case EventActionTypes.UPDATE_LOCATION:
      return {
        ...state,
        location: { ...state.location, ...action.payload },
      } as CreateUserEventInput;
    case EventActionTypes.UPDATE_CUSTOM_FIELD:
      return {
        ...state,
        inputFields: state.inputFields?.map((field) => (field?.id === action.payload.id ? action.payload : field)),
      } as CreateUserEventInput;
    case EventActionTypes.UPDATE_CANCELED:
      return {
        ...state,
        canceled: { ...state.canceled, ...action.payload },
      } as CreateUserEventInput;
    case EventActionTypes.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        ...action.payload.event,
      } as CreateUserEventInput;
    case EventActionTypes.CANCEL_EVENT_SUCCESS:
      return {
        ...state,
        canceled: { ...state.canceled, isCanceled: true },
      } as CreateUserEventInput;
    case EventActionTypes.RESET_BOOKING_PAGE_STEPS:
      return {
        ...state,
        timeZone: null,
        inputFields: null,
        location: null,
      } as CreateUserEventInput;
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_EVENT;
    default:
      return state;
  }
};

const agenda = (state: AgendaType = DEFAULT_AGENDA, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_AGENDA:
    case EventActionTypes.GET_AGENDA_SUCCESS:
    case EventActionTypes.SET_PREVIEW_DATA:
      return {
        ...state,
        // host: state.host || PREVIEW_HOST,
        ...action.payload,
      } as AgendaType;
    case EventActionTypes.SET_RESCHEDULE_DATA:
      return { ...state, bookingPage: action.payload.bookingPage || state.bookingPage } as AgendaType;
    case EventActionTypes.UPDATE_PREVIEW_LABEL:
      return {
        ...state,
        ...(state.journey
          ? {
              journey: {
                ...state.journey,
                labels: {
                  ...state.journey?.labels,
                  ...action.payload,
                },
              },
            }
          : {
              bookingPage: {
                ...state.bookingPage,
                labels: {
                  ...state.bookingPage?.labels,
                  ...action.payload,
                },
              },
            }),
      } as AgendaType;
    case EventActionTypes.UPDATE_PREVIEW_STYLE:
      return {
        ...state,
        ...(state.journey
          ? {
              journey: {
                ...state.journey,
                style: {
                  ...state.journey?.style,
                  ...action.payload,
                },
              },
            }
          : {
              bookingPage: {
                ...state.bookingPage,
                style: {
                  ...state.bookingPage?.style,
                  ...action.payload,
                },
              },
            }),
      } as AgendaType;
    case EventActionTypes.CREATE_EVENT_SUCCESS:
    case EventActionTypes.UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        event: action.payload.event,
        host: action.payload.host || state.host,
        cohosts: action.payload.cohosts || state.cohosts,
      } as AgendaType;
    case EventActionTypes.UPDATE_JOURNEY_PAGE_FIELD:
      return {
        ...state,
        journey: updateJourneyField(state.journey, action.payload.stepId, action.payload.field),
      };
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_AGENDA;
    default:
      return state;
  }
};

const bookingPageId = (state = '', action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_BOOKING_PAGE_ID:
      return action.payload;
    case EventActionTypes.SET_AGENDA:
    case EventActionTypes.GET_AGENDA_SUCCESS:
      return action.payload.bookingPage?.id || state;
    case EventActionTypes.SET_RESCHEDULE_DATA:
      return action.payload.bookingPage?.id || action.payload.event.bookingPageId || state;
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const eventId = (state = '', action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_EVENT_ID:
      return action.payload;
    case EventActionTypes.SET_AGENDA:
    case EventActionTypes.GET_AGENDA_SUCCESS:
    case EventActionTypes.SET_RESCHEDULE_DATA:
    case EventActionTypes.CREATE_EVENT_SUCCESS:
      return action.payload.event?.eventId || state;
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const agendaLoaded = (state = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.CREATE_EVENT_SUCCESS:
    case EventActionTypes.GET_AGENDA_SUCCESS:
      return true;
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const steps = (state = DEFAULT_EVENT_STEPS, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_EVENT_STEP:
      return setNewStep(state, action.payload);
    case EventActionTypes.SET_PREVIOUS_STEP:
      return setPreviousStep(state);
    case EventActionTypes.SET_NEXT_STEP:
      return setNextStep(state);
    case EventActionTypes.SET_RESCHEDULE_DATA:
      return setNewStep(DEFAULT_EVENT_STEPS, EventSteps.WHEN);
    case EventActionTypes.CREATE_EVENT_SUCCESS:
    case EventActionTypes.UPDATE_EVENT_SUCCESS:
      return setNewStep(state, EventSteps.BOOKED);
    case EventActionTypes.SET_SCHEDULED_MEETING:
      return action.payload?.canceled?.isCanceled ? setNewStep(DEFAULT_EVENT_STEPS, EventSteps.BOOKED) : state;
    case EventActionTypes.SET_PREVIEW_DATA:
      return action.payload.journey
        ? setNewStep(DEFAULT_EVENT_STEPS, EventSteps.JOURNEY)
        : setNewStep(DEFAULT_EVENT_STEPS, EventSteps.WHEN);
    case EventActionTypes.SET_JOURNEY_ID:
      return setNewStep(state, EventSteps.JOURNEY);
    case EventActionTypes.SET_GROUP_BOOKING_PAGE_ID:
      return setNewStep(state, EventSteps.GROUP);
    case EventActionTypes.SET_EVENT_ID:
      return setNewStep(state, EventSteps.BOOKED);
    case EventActionTypes.RESET_BOOKING_PAGE_STEPS:
      return resetBookingPageSteps(state);
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_EVENT_STEPS;
    default:
      return state;
  }
};

const journeySteps = (state: JourneyStepInput[] = [], action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_JOURNEY_STEPS:
      return action.payload;
    case EventActionTypes.GET_AGENDA_SUCCESS:
    case EventActionTypes.SET_PREVIEW_DATA:
      return action.payload.journey?.root ? [filterJourneyStepForSave(action.payload.journey?.root)] : state;
    case EventActionTypes.SELECT_JOURNEY_CARD: {
      const lastStep = findJourneyCurrentStepForView(state);
      const lastStepIndex = state.findIndex((step) => step.id === lastStep?.id);
      return [...state.slice(0, lastStepIndex), { ...lastStep, page: { ...lastStep?.page, cards: [action.payload] } }];
    }
    case EventActionTypes.UPDATE_JOURNEY_PAGE_FIELD:
      return updateSavedStepsField(state, action.payload.stepId, action.payload.field);
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const journeyId = (state = '', action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_JOURNEY_ID:
      return action.payload;
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const viewDate = (state: number = DEFAULT_VIEW_DATE, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_VIEW_DATE:
      return action.payload;
    case EventActionTypes.SET_RESCHEDULE_DATA:
      return calculateViewDate(action.payload.event.startTime || action.payload.event.eventDate);
    case EventActionTypes.SET_SCHEDULED_MEETING:
      return calculateViewDate(action.payload.startTime || action.payload.eventDate);
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_VIEW_DATE;
    default:
      return state;
  }
};

const isRescheduleMode = (state = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_RESCHEDULE_DATA:
    case EventActionTypes.SET_SCHEDULED_MEETING:
      return true;
    case EventActionTypes.SET_PREVIEW_DATA:
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isPreviewMode = (state = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_PREVIEW_DATA:
      return true;
    case EventActionTypes.SET_RESCHEDULE_DATA:
    case EventActionTypes.SET_SCHEDULED_MEETING:
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isReadOnly = (state = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_READ_ONLY:
      return action.payload;
    case EventActionTypes.SET_RESCHEDULE_DATA:
    case EventActionTypes.SET_SCHEDULED_MEETING:
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isHostReschedule = (state = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_RESCHEDULE_DATA:
      return true;
    case EventActionTypes.SET_PREVIEW_DATA:
    case EventActionTypes.SET_SCHEDULED_MEETING:
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isRescheduled = (state = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_IS_RESCHEDULED:
      return action.payload;
    case EventActionTypes.UPDATE_EVENT_SUCCESS:
      return true;
    case EventActionTypes.CANCEL_EVENT_SUCCESS:
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const groupBookingPageId = (state = '', action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_GROUP_BOOKING_PAGE_ID:
      return action.payload;
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const viewSettings = (state = {} as ViewSettings, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_VIEW_SETTINGS:
      return { ...state, ...action.payload };
    case EventActionTypes.CREATE_EVENT_SUCCESS:
      return { ...state, editKey: action.payload.event.editKey || null } as ViewSettings;
    case EventActionTypes.SET_RESCHEDULE_DATA:
      return { ...state, iframe: true } as ViewSettings;
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as ViewSettings;
    default:
      return state;
  }
};

const chosenHostMemberId = (state = '', action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_CHOSEN_HOST_ID:
      return action.payload;
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const isLocationOpened = (state = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.SET_IS_LOCATION_OPENED:
      return action.payload;
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isFetching = (state = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.GET_AGENDA_REQUEST:
    case EventActionTypes.CANCEL_EVENT_REQUEST:
      return true;
    case EventActionTypes.GET_AGENDA_SUCCESS:
    case EventActionTypes.GET_AGENDA_FAIL:
    case EventActionTypes.CANCEL_EVENT_SUCCESS:
    case EventActionTypes.CANCEL_EVENT_FAIL:
    case EventActionTypes.CREATE_EVENT_SUCCESS:
    case EventActionTypes.CREATE_EVENT_FAIL:
      return false;
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isUpsertEventFetching = (state = false, action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.CREATE_EVENT_REQUEST:
    case EventActionTypes.UPDATE_EVENT_REQUEST:
      return true;
    case EventActionTypes.CREATE_EVENT_SUCCESS:
    case EventActionTypes.CREATE_EVENT_FAIL:
    case EventActionTypes.UPDATE_EVENT_SUCCESS:
    case EventActionTypes.UPDATE_EVENT_FAIL:
      return false;
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state = '', action: EventAction | GlobalAction) => {
  switch (action.type) {
    case EventActionTypes.GET_AGENDA_REQUEST:
    case EventActionTypes.CREATE_EVENT_REQUEST:
    case EventActionTypes.CANCEL_EVENT_REQUEST:
    case EventActionTypes.UPDATE_EVENT_REQUEST:
      return '';
    case EventActionTypes.GET_AGENDA_FAIL:
    case EventActionTypes.CREATE_EVENT_FAIL:
    case EventActionTypes.CANCEL_EVENT_FAIL:
    case EventActionTypes.UPDATE_EVENT_FAIL:
      return action.error;
    case EventActionTypes.RESET_EVENT_STORE:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

export default combineReducers({
  event,
  agenda,
  bookingPageId,
  eventId,
  agendaLoaded,
  steps,
  journeySteps,
  journeyId,
  viewDate,
  isRescheduleMode,
  isPreviewMode,
  isReadOnly,
  isHostReschedule,
  isRescheduled,
  viewSettings,
  groupBookingPageId,
  chosenHostMemberId,
  isLocationOpened,
  isFetching,
  isUpsertEventFetching,
  error,
});
