import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { upsertAvailability } from '../availability';
import { UserSettingsKeys, userSettingsActions, userSettingsSelectors } from '../userSettings';
import { QuickSetupActionTypes, quickSetupActions } from './actions';
import { authenticationSelectors } from '../authentication';
import {
  CreateUserDataInput,
  LocationType,
  UpdateBookingPageInput,
  UserRecordType,
  UserSettingsInput,
} from '../../API';
import { quickSetupSelectors } from './selectors';
import { notificationsActions } from '../notifications';
import { SAVE_QUICK_SETUP_ERROR_TOAST, SAVE_QUICK_SETUP_SUCCESS_TOAST } from './constants';
import { saveUserSettings, uploadPublicFile } from '../userSettings/service';
import { saveWorkspace } from '../workspaces/service';
import { DEFAULT_WORKSPACE, DEFAULT_WORKSPACE_NAME, UpsertWorkspaceResponse, WorkspaceData } from '../workspaces';
import { createSelector } from 'reselect';
import { DEFAULT_BOOKING_PAGE_DATA, bookingPageSelectors } from '../bookingPages';
import { postBookingPage } from '../bookingPages/service';
import { workspacesActions } from '../workspaces/actions';
import { DEFAULT_BOOKING_TEMPLATE_DATA, bookingTemplatesSelectors } from '../bookingTemplates';
import { upsertBookingTemplate } from '../bookingTemplates/service';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { UploadFileData } from './types';
import { UserDataInputCreatedAt } from '../global/types';
import { handleServiceError } from '../utils/reduxUtils';
import { countriesTimeZonesService } from '../../services/CountriesTimeZoneService';
import { CURRENT_TIME_ZONE } from '../../types/constants';
import { BookingTemplate } from '../../generated-sources/internal-api/models/BookingTemplate';

const selectCreateUserDataInputRequest = createSelector(
  authenticationSelectors.selectTenantId,
  userSettingsSelectors.selectUserSettings,
  authenticationSelectors.selectUserId,
  authenticationSelectors.selectLink,
  userSettingsSelectors.selectStatistics,
  (tenantId, userSettings, userId, link, statistics) => ({
    userId,
    tenant: tenantId,
    link,
    recordType: UserRecordType.PROFILE,
    statistics,
    userSettings,
  })
);

const selectUserUpdateBookingPageRequest = createSelector(
  bookingPageSelectors.selectMyDefaultBookingPage,
  quickSetupSelectors.selectDefaultWorkspace,
  quickSetupSelectors.selectPhoneCalls,
  authenticationSelectors.selectIsThirdPartyGoogle,
  authenticationSelectors.selectIsThirdPartyMicrosoft,
  userSettingsSelectors.selectIsZoomConnected,
  userSettingsSelectors.selectDefaultVideoIntegration,
  (myDefaultBookingPage, defaultWorkspace, phoneDetails, hasGoogle, hasMicrosoft, hasZoom, defaultVideoIntegration) =>
    myDefaultBookingPage && defaultWorkspace
      ? ({
          ...myDefaultBookingPage,
          // update default record with translated values
          what: DEFAULT_BOOKING_PAGE_DATA.what,
          notifications: DEFAULT_BOOKING_PAGE_DATA.notifications,
          inputFields: DEFAULT_BOOKING_PAGE_DATA.inputFields,
          labels: DEFAULT_BOOKING_PAGE_DATA.labels,
          style: defaultWorkspace.style,
          where: {
            ...myDefaultBookingPage.where,
            locationTypes: [
              ...(phoneDetails?.phoneNumber ? [LocationType.PHONE_CALL] : []),
              ...(hasGoogle || hasMicrosoft || hasZoom ? [LocationType.VIDEO_CONFERENCE] : []),
            ],
            defaultLocationType:
              hasGoogle || hasMicrosoft || hasZoom ? LocationType.VIDEO_CONFERENCE : LocationType.PHONE_CALL,
            videoConferenceType: defaultVideoIntegration,
          },
        } as UpdateBookingPageInput)
      : null
);

const selectAdminUpdateBookingPageRequest = (logoImage: string, backgroundImage: string) =>
  createSelector(
    bookingPageSelectors.selectBookingPages,
    quickSetupSelectors.selectDefaultWorkspace,
    quickSetupSelectors.selectPhoneCalls,
    authenticationSelectors.selectIsThirdPartyGoogle,
    authenticationSelectors.selectIsThirdPartyMicrosoft,
    userSettingsSelectors.selectIsZoomConnected,
    userSettingsSelectors.selectDefaultVideoIntegration,
    (bookingPages, defaultWorkspace, phoneDetails, hasGoogle, hasMicrosoft, hasZoom, defaultVideoIntegration) =>
      bookingPages.length && defaultWorkspace
        ? ({
            ...bookingPages[0],
            // update default record with translated values
            what: DEFAULT_BOOKING_PAGE_DATA.what,
            notifications: DEFAULT_BOOKING_PAGE_DATA.notifications,
            inputFields: DEFAULT_BOOKING_PAGE_DATA.inputFields,
            labels: DEFAULT_BOOKING_PAGE_DATA.labels,
            style: {
              ...defaultWorkspace.style,
              logoImage,
              backgroundImage,
            },
            where: {
              ...bookingPages[0].where,
              locationTypes: [
                ...(phoneDetails?.phoneNumber ? [LocationType.PHONE_CALL] : []),
                ...(hasGoogle || hasMicrosoft || hasZoom ? [LocationType.VIDEO_CONFERENCE] : []),
              ],
              defaultLocationType:
                hasGoogle || hasMicrosoft || hasZoom ? LocationType.VIDEO_CONFERENCE : LocationType.PHONE_CALL,
              videoConferenceType: defaultVideoIntegration,
            },
          } as UpdateBookingPageInput)
        : null
  );

const selectUpdateBookingTemplateRequest = (logoImage: string, backgroundImage: string) =>
  createSelector(
    bookingTemplatesSelectors.selectBookingTemplates,
    quickSetupSelectors.selectDefaultWorkspace,
    quickSetupSelectors.selectPhoneCalls,
    authenticationSelectors.selectIsThirdPartyGoogle,
    authenticationSelectors.selectIsThirdPartyMicrosoft,
    userSettingsSelectors.selectIsZoomConnected,
    (bookingTemplates, defaultWorkspace, phoneDetails, hasGoogle, hasMicrosoft, hasZoom) =>
      bookingTemplates.length && bookingTemplates[0]
        ? ({
            ...bookingTemplates[0],
            // update default record with translated values
            what: DEFAULT_BOOKING_TEMPLATE_DATA.what,
            notifications: DEFAULT_BOOKING_TEMPLATE_DATA.notifications,
            inputFields: DEFAULT_BOOKING_TEMPLATE_DATA.inputFields,
            labels: DEFAULT_BOOKING_PAGE_DATA.labels,
            style: {
              ...defaultWorkspace.style,
              logoImage,
              backgroundImage,
            },
            where: {
              ...bookingTemplates[0].where,
              locationTypes: [
                ...(phoneDetails?.phoneNumber ? [LocationType.PHONE_CALL] : []),
                ...(hasGoogle || hasMicrosoft || hasZoom ? [LocationType.VIDEO_CONFERENCE] : []),
              ],
              defaultLocationType:
                hasGoogle || hasMicrosoft || hasZoom ? LocationType.VIDEO_CONFERENCE : LocationType.PHONE_CALL,
            },
          } as BookingTemplate)
        : null
  );

const selectUpdateUserSettingsRequest = (country: string) =>
  createSelector(quickSetupSelectors.selectPhoneCalls, (phoneDetails) => ({
    phoneNumber: phoneDetails.phoneNumber,
    phoneDetails: phoneDetails.phoneDetails,
    countryCode: phoneDetails.countryCode,
    country,
    isQuickSetupFinished: true,
  }));

function* uploadImageFiles() {
  let logoImage = '',
    backgroundImage = '';
  const path: string = yield select(authenticationSelectors.selectTenantFileFolderPath);
  const logoData: UploadFileData | undefined = yield select(quickSetupSelectors.selectLogoData);
  const backgroundData: UploadFileData | undefined = yield select(quickSetupSelectors.selectBackgroundData);

  if (logoData?.fileName) {
    const logoFile: Blob = yield fetch(logoData.url).then((res) => res.blob());
    logoImage = yield call(uploadPublicFile, new File([logoFile], logoData.fileName), path);
  }

  if (backgroundData?.fileName) {
    const backgroundFile: Blob = yield fetch(backgroundData.url).then((res) => res.blob());
    backgroundImage = yield call(uploadPublicFile, new File([backgroundFile], backgroundData.fileName), path);
  }
  return { logoImage, backgroundImage };
}

function* saveQuickSetupSaga() {
  try {
    const availability: UserDataInputCreatedAt = yield select(quickSetupSelectors.selectUserAvailability);
    const isFirstAdmin: boolean = yield select(userSettingsSelectors.selectIsFirstTenantUser);
    const userDataProfile: CreateUserDataInput = yield select(selectCreateUserDataInputRequest);
    const timezoneInf = countriesTimeZonesService.getTimezoneByName(CURRENT_TIME_ZONE);
    const autoCountryCode = timezoneInf ? timezoneInf.countries[0] : '';

    const profileUpdates: Partial<UserSettingsInput> = yield select(selectUpdateUserSettingsRequest(autoCountryCode));

    const userDataProfileForUpdate = {
      ...userDataProfile,
      userSettings: {
        ...userDataProfile.userSettings,
        ...profileUpdates,
      },
    };

    const updateSettingsTasks = [
      call(upsertAvailability, availability),
      call(saveUserSettings, userDataProfileForUpdate),
      put(userSettingsActions.updateUserSettings(profileUpdates)),
    ];

    if (isFirstAdmin) {
      const { logoImage, backgroundImage } = yield call(uploadImageFiles);
      const defaultWorkspace: WorkspaceData = yield select(quickSetupSelectors.selectDefaultWorkspace);
      const workspaceInput: UpsertWorkspaceResponse = {
        workspace: {
          ...defaultWorkspace,
          name: DEFAULT_WORKSPACE_NAME,
          labels: DEFAULT_WORKSPACE.labels,
          style: {
            ...defaultWorkspace.style,
            logoImage,
            backgroundImage,
          },
        },
      };

      const bookingPageUpdate: UpdateBookingPageInput | null = yield select(
        selectAdminUpdateBookingPageRequest(logoImage, backgroundImage)
      );
      const bookingTemplateUpdate: BookingTemplate | null = yield select(
        selectUpdateBookingTemplateRequest(logoImage, backgroundImage)
      );

      const adminTasks = [
        call(saveWorkspace, workspaceInput),
        ...(bookingPageUpdate ? [call(postBookingPage, bookingPageUpdate)] : []),
        ...(bookingTemplateUpdate ? [call(upsertBookingTemplate, bookingTemplateUpdate)] : []),
      ];

      yield all([...updateSettingsTasks, ...adminTasks]);
      yield put(workspacesActions.getWorkspacesRequest());
    } else {
      const bookingPageUpdate: UpdateBookingPageInput | null = yield select(selectUserUpdateBookingPageRequest);
      if (bookingPageUpdate) {
        updateSettingsTasks.push(
          call(function* () {
            yield call(postBookingPage, bookingPageUpdate, true);
          })
        );
      }
      yield all(updateSettingsTasks);
    }

    localStorage.removeItem(UserSettingsKeys.QUICK_SETUP_WEEKLY_HOURS);

    yield call(navigationService.navigateTo, Path.BookingPages);

    yield put(quickSetupActions.saveQuickSetupSuccess());
    yield put(notificationsActions.showToast(SAVE_QUICK_SETUP_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(quickSetupActions.saveQuickSetupFail(error));
    yield call(handleServiceError, error, SAVE_QUICK_SETUP_ERROR_TOAST);
  }
}

export function* watchQuickSetupSaga() {
  yield takeLatest(QuickSetupActionTypes.SAVE_QUICK_SETUP_REQUEST, saveQuickSetupSaga);
}
