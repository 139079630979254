export const Common = Object.freeze({
  ACCEPT_INVITE: `Einladung annehmen`,
  ACCESS_RESTRICT: `Entschuldigung, Sie sind nicht berechtigt, auf diese Seite zuzugreifen`,
  ACTIVATE: `Aktivieren`,
  ACTIVE: `Aktiv`,
  ADDRESS: `Adresse`,
  ALL: `Alle`,
  AND: `Und`,
  ARE_YOU_SURE: `Sind Sie sicher?`,
  AUTHORISE: `Autorisieren`,
  AVAILABLE: `Verfügbar`,
  BACK: `Zurück`,
  CALENDAR_DAYS: `Kalendertage`,
  CANCEL: `Abbrechen`,
  CHOOSE_DATE: `Datum wählen`,
  CITY: `Stadt`,
  CLEAR: `Löschen`,
  CLEAR_FILTER: `Filter löschen`,
  CLICK_HERE: `Klicken Sie hier`,
  CLONE: `Klonen`,
  CLONE_NAME_POSTFIX: ` (Kopie)`,
  CLOSE: `Schließen`,
  COMMON: `Gemeinsam`,
  COMPLETE: `Abschließen`,
  CONNECT: `Verbinden`,
  CONNECTED: `Verbunden`,
  CONFIRM: `Bestätigen`,
  CONFIRM_DELETE: `Löschen bestätigen`,
  COMING_SOON: `Demnächst`,
  COPY: `Kopieren`,
  COPY_LINK: `Link kopieren`,
  COPIED_LINK: `Kopiert!`,
  COUNTINUE: `Fortfahren`,
  COUNTRY: `Land`,
  CREATE: `Erstellen`,
  CUSTOM: `Benutzerdefiniert`,
  CUSTOMIZE: `Anpassen`,
  DATE: `Datum`,
  DATE_RANGE: `Datumsbereich`,
  DAYS: `Tage`,
  DEACTIVATE: `Deaktivieren`,
  DEFAULT: `Standard`,
  DELETE: `Löschen`,
  DELETED: `GELÖSCHT`,
  DESCRIPTION: `Beschreibung`,
  DISABLE: `Deaktivieren`,
  DISCONNECT: `Trennen`,
  DOWNLOAD: `Herunterladen`,
  EDIT: `Bearbeiten`,
  EMAIL: `E-Mail`,
  EMAIL_ALL_SMALL: `E-Mail`,
  EMPTY_ERROR: `Darf nicht leer sein`,
  EMPTY_LIST: `Diese Liste scheint leer zu sein.`,
  ENABLE: `Aktivieren`,
  ERROR: `Fehler`,
  EXPORT: `Exportieren`,
  FILTER: `Filtern`,
  FIRST_NAME: `Vorname`,
  FRIDAYS: `Freitage`,
  FROM: `von`,
  GOOGLE: `Google`,
  HOURS: `Stunden`,
  HOUR_SHORTENED: `Std`,
  INACTIVE: `Inaktiv`,
  INFO: `Information`,
  INTERNAL: `Intern`,
  LAST_NAME: `Nachname`,
  LEARN_MORE: `Mehr erfahren`,
  LOCKED: `Gesperrt`,
  LOGIN: `Anmelden`,
  LOG_OUT: `Abmelden`,
  MICROSOFT: `Microsoft`,
  MINUTE_SHORTENED: `Min`,
  MINUTES: `Minuten`,
  MONDAYS: `Montage`,
  MY: `Mein`,
  MY_ACCOUNT: `Mein Konto`,
  NAME: `Name`,
  NAME_IS_DUPLICATE: `Hoppla! Dieser Name wurde bereits verwendet. Bitte geben Sie einen anderen ein.`,
  NEXT: `Weiter`,
  NONE: `Keine`,
  NO: `Nein`,
  NO_CANCEL: `Nein, abbrechen`,
  NO_RESULTS_FOUND: `Keine Ergebnisse gefunden`,
  NEVER: 'Niemals',
  OFF: `Aus`,
  ON: `Ein`,
  ADD: `Hinzufügen`,
  ONLY_ONE_ACCOUNT: `Sie dürfen nur ein SUMO1-Konto pro E-Mail-Adresse haben.`,
  PASSWORD: `Passwort`,
  PHONE_NUMBER: `Telefonnummer`,
  PICTURE_FORMATS: `svg, jpg, gif, png`,
  PICTURE_SIZE: `Maximale Größe 5 MB`,
  PREVIEW: `Vorschau`,
  QUICK_SETUP: `Schnelle Einrichtung`,
  REGISTER: `Registrieren`,
  RESET: `Zurücksetzen`,
  RESET_FILTER: `Filter zurücksetzen`,
  ROLES: `Rollen`,
  SATURDAYS: `Samstage`,
  SAVE: `Speichern`,
  SAVE_CHANGES: `Änderungen speichern`,
  SAVE_AND_CLOSE: `Speichern und schließen`,
  SEARCH: `Suchen`,
  SECOND_SHORTENED: `Sek`,
  SELECT: `Auswählen`,
  SELECT_ALL: `Alles auswählen`,
  SELECTED: `Ausgewählt`,
  SET_UP: `Einrichten`,
  SHARE: `Teilen`,
  SIGN_OUT: `Abmelden`,
  SIGN_UP: `Registrieren`,
  STATE: `Bundesland`,
  STATUS: `Status`,
  SUCCESS: `Erfolg`,
  SUNDAYS: `Sonntage`,
  SUSPENDED: `Ausgesetzt`,
  TIMEZONE: `Zeitzone`,
  THURSDAYS: `Donnerstage`,
  TIMES: `Zeiten`,
  TODAY: `Heute`,
  TO: `bis`,
  FOR: `für`,
  TUESDAYS: `Dienstage`,
  TYPE: `Typ`,
  UNAVAILABLE: `Nicht verfügbar`,
  UNLINK: `Verbindung aufheben`,
  UNSAVED_CHANGES: `Alle nicht gespeicherten Änderungen gehen verloren.`,
  UNSELECT: `Abwählen`,
  UPLOAD: `Hochladen`,
  UPLOAD_PICTURE: `Bild hochladen`,
  USER: `Benutzer`,
  USERS: `Benutzer`,
  UPDATE: `Aktualisieren`,
  VIEW: `Anzeigen`,
  YES: `Ja`,
  YES_CANCEL: `Ja, Abbrechen`,
  YES_DELETE: `Ja, löschen`,
  WARNING: `Warnung`,
  WEDNESDAYS: `Mittwoche`,
  WEEKDAYS: `Wochentage`,
  TOTAL: `Total`,
  ZIP: `PLZ`,
  CHARACTERS: `Zeichen`,
});

export const ErrorPage = Object.freeze({
  TITLE_403: `403 - Zugriffsverweigerungsfehler`,
  TITLE_404: `404 - Seite nicht gefunden`,
  TITLE_500: `500 - Interner Serverfehler`,
  MESSAGE_403_1: `Sie haben keine Berechtigung zum Zugriff auf `,
  MESSAGE_403_2: ` auf diesem Server.`,
  MESSAGE_404_1: `Die angeforderte URL `,
  MESSAGE_404_2: ` wurde auf diesem Server nicht gefunden.`,
  MESSAGE_500_1: `Es gab einen Fehler. Das ist alles, was wir wissen. Bitte warten Sie und `,
  MESSAGE_500_2: `versuchen Sie erneut zu laden`,
  MESSAGE_500_3: `.`,
  HOME_BUTTON: `Startseite`,
});

export const PrivateRoute = Object.freeze({
  GUIDED_TOUR: `Geführte Tour`,
  ACCOUNT: `Konto`,
  ACCOUNT_SETTINGS: `Kontoeinstellungen`,
  PROFILE: `Profil`,
  ADMIN_SETTINGS: `Admin-Einstellungen`,
  AVAILABILITY: `Verfügbarkeit`,
  CALENDAR_CONNECTIONS: `Kalenderverbindungen`,
  HELP_CENTER: `Hilfe-Center`,
  HOME: `Startseite`,
  USER_SETUP: `Benutzereinrichtung`,
  USER_SETTINGS: `Benutzereinstellungen`,
  INTEGRATION: `Integration`,
});

export const PublicLayout = Object.freeze({
  FOOTER_TEXT_PART_1: `© Urheberrecht 2008-`,
  FOOTER_TEXT_PART_2: ` ForceBrain, Inc.  Alle Rechte vorbehalten.`,
  PRIVACY_POLICY: `Datenschutzrichtlinie`,
  SECURITY_POLICY: `Sicherheitsrichtlinie`,
  TERMS: `Nutzungsbedingungen`,
});

export const AppMenu = Object.freeze({
  ACCOUNTS_TITLE: `Konten`,
  BILLING_TITLE: `Abrechnung`,
  OPERATIONS_TITLE: `Betrieb`,
  ORGS_TITLE: `Organisationen`,
  SMART_ALERTS: `Intelligente Benachrichtigungen`,
  STAFF_TITLE: `Personal`,
  SUPPORT_TITLE: `Support`,
  TOOLS_TITLE: `Werkzeuge`,
  OPEN_MY_SUMO1: `Mein SUMO1 öffnen`,
  OPS_CONSOLE: `OPS-Konsole`,
  JOURNEY_TITLE: `Journey Builder`,
  MEETINGS_TITLE: `Besprechungen`,
  PEOPLE_TITLE: `Personen`,
  ORGANIZATION_TITLE: `Organisation`,
});

export const LicenseExpired = Object.freeze({
  BOOK_MEETING_DESCRIPTION: `Vereinbaren Sie einen Anruf oder ein virtuelles Treffen mit einem Experten`,
  BOOK_MEETING_TITLE: `Termin buchen`,
  CALL_DESCRIPTION_PART1: `Kontaktieren Sie uns unter 800-708-1790,`,
  CALL_DESCRIPTION_PART2: `Montag bis Freitag, 8 bis 17 Uhr EST`,
  CALL_TITLE: `Rufen Sie uns an`,
  CHAT_DESCRIPTION: `Klicken Sie auf das "Chatten Sie mit uns" Symbol unten rechts, um ein Gespräch zu beginnen`,
  CHAT_TITLE: `Jetzt chatten`,
  CONTACT_TITLE: `Kontaktieren Sie uns für Unterstützung`,
  DESCRIPTION_ADMIN_TRIAL: `Die kostenlose Testphase Ihrer Organisation ist abgelaufen. Sie können sie durch Klicken auf die untenstehende Option erweitern oder uns für weitere Informationen kontaktieren.`,
  DESCRIPTION_ADMIN_PAID: `Ihre Lizenz ist abgelaufen. Um SUMO1 ohne Unterbrechungen weiterhin nutzen zu können, erwerben Sie bitte eine neue Lizenz.`,
  DESCRIPTION_PART1_TRIAL: `Die kostenlose Testphase Ihrer Organisation ist abgelaufen. Kontaktieren Sie den Administrator Ihrer Organisation`,
  DESCRIPTION_PART1_PAID: `Der Lizenzzeitraum Ihrer Organisation ist abgelaufen. Kontaktieren Sie den Administrator Ihrer Organisation`,
  DESCRIPTION_PART2: `für weitere Unterstützung.`,
  EMAIL_DESCRIPTION: `Wenden Sie sich an experts@sumoscheduler.com, um Hilfe zu erhalten`,
  EMAIL_TITLE: `Schreiben Sie uns eine E-Mail`,
  EXPIRED: `Abgelaufen`,
  RENEW_LICENSES: `Lizenzen erneuern`,
  TITLE: `Lizenz abgelaufen`,
  TITLE_TRIAL: `Kostenlose Testphase abgelaufen`,
  TRIAL_IS_OVER: `Die Testphase ist vorbei`,
  UPGRADE_NOW: `Jetzt upgraden`,
  VIEW_PRICING_PAGE: `Preisseite anzeigen`,
});

export const BookingStats = Object.freeze({
  CANCELS: `Stornierungen`,
  MEETINGS_BOOKED: `Gebuchte Meetings`,
  REMINDERS_SENT: `Erinnerungen gesendet`,
  RESCHEDULES: `Neuzeitplanungen`,
  TITLE: `Meine Statistiken`,
  INSTALL_INBOX_PART1: `Installieren Sie die `,
  INSTALL_INBOX_PART2: `Chrome-Erweiterung`,
  INSTALL_INBOX_PART3: ` oder das `,
  INSTALL_INBOX_PART4: `Edge-Add-on`,
  INSTALL_INBOX_PART5: `, um Einladungslinks aus Gmail oder Outlook Web zu senden.`,
  INVITE_PEOPLE_DESCRIPTION: `Fügen Sie Benutzer zu SUMO1 hinzu, um von Co-Hosting, Team-Buchung, Buchung im Auftrag und mehr zu profitieren.`,
  INVITE_PEOPLE_TITLE: `Personen einladen`,
  CALENDAR_WARNING_DESCRIPTION: `Ihr Kalender ist nicht verbunden. Sie riskieren eine doppelte Buchung, wenn Sie keinen Kalender verbinden.`,
  CALENDAR_WARNING_BUTTON: `Kalender verbinden`,
  UPDATE_GOOGLE_INTEGRATION_DESCRIPTION: `Bitte aktualisieren Sie die Google-Integrationseinstellungen, um die volle Funktionalität der App zu nutzen!`,
  UPDATE_GOOGLE_INTEGRATION_BUTTON: `Google-Integration aktualisieren`,
});

export const StartEasyBooking = Object.freeze({
  STEP1_DESCRIPTION: `Fügen Sie einen "Buchen Sie mit mir"-Link zu Ihrer E-Mail-Signatur hinzu.`,
  STEP1_TITLE: `E-Mail-Signatur einrichten`,
  STEP2_DESCRIPTION: `Senden Sie eine Einladung zu einem Meeting an jemanden mit der Chrome-Erweiterung.`,
  STEP2_TITLE: `Einladung in einem Klick senden`,
  STEP3_DESCRIPTION: `Was sollten sie sonst noch am ersten Tag testen?`,
  STEP3_TITLE: `Eine andere Funktion testen`,
  TITLE: `Einfache Buchung starten`,
});

export const Table = Object.freeze({
  PAGE: `Seite`,
  OF: `von`,
  OUT_OF: `von insgesamt`,
});

export const IconPicker = Object.freeze({
  SELECT_TITLE: `Icon auswählen`,
  OPTIONAL: `optional`,
});
