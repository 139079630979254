export const PublicBookingPage = Object.freeze({
  HOST: `Gastgeber`,
  HOST_PHONE: `Gastgeber-Telefon`,
  EDIT_BUTTON: `Bearbeiten`,
  MORE: `Mehr`,
  TIME_ZONE_LABEL: `Zeitzone`,
  NAME_FIELD_LABEL: `Name`,
  EMAIL_FIELD_LABEL: `E-Mail`,
  INVITE_OTHERS_FIELD_LABEL: `Weitere Teilnehmer`,
  INVITE_OTHERS_FIELD_PLACEHOLDER: `Lade andere ein, indem du ihre E-Mail-Adressen durch Kommas getrennt eingibst`,
  NOTE_FOR_ATTENDEES_FIELD_LABEL: `Hinweis für Teilnehmer`,
  NOTE_FOR_ATTENDEES_FIELD_PLACEHOLDER: `Geben Sie einen Hinweis für alle Teilnehmer ein`,
  NO_USERS_AVAILABLE_LABEL: `Es sind keine Benutzer zur ausgewählten Zeit verfügbar. Bitte wählen Sie eine andere Zeit.`,
  DEACTIVATED_LABEL: `Der Gastgeber wurde deaktiviert. Bitte fordern Sie einen neuen Buchungslink an.`,
  CANCEL_TYPE_LABEL: `Warum absagen?`,
  CANCEL_REASON_LABEL: `Grund der Absage:`,
  IN_PERSON_LABEL: `Persönlich`,
  PHONE_LABEL: `Telefon`,
  GOOGLE_MEET: `Google Meet`,
  ZOOM: `Zoom`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  ERROR: `Fehler`,
  BACK_TO_HOME: `Zurück zur Startseite`,
  VIDEO_CONFERENCE_LABEL: `Videokonferenz`,
  SLOTS_NO_FOUND: `Keine Zeitfenster gefunden`,
  VIEW_NEXT_MONTH: `Nächsten Monat anzeigen`,
  ALLOW_SMS: `Terminerinnerungen per SMS erhalten?`,
  BOOKING_PAGE_EXPIRED: `Diese Buchungsseite ist abgelaufen. Bitte fordern Sie einen neuen Buchungslink an.`,
  PERSONAL_INFO: `Persönliche Informationen`,
  ATTENDEES: `Teilnehmer`,
  ATTENDEE_EMAIL: `Teilnehmer E-Mail`,
  ADD_ATTENDEES: `Teilnehmer hinzufügen`,
  YOUR_ANSWERS: `Ihre Antworten`,
  MANAGE_BOOKING: `Buchung verwalten`,
  MODIFY_BOOKING: `Buchung ändern`,
  CANCEL_POLICY: `Stornierungsrichtlinie`,
  LOCATIONS_PLACEHOLDER: `Adresse eingeben`,
  LOADING_LABEL_1: `Meeting erstellen`,
  LOADING_LABEL_2: `Kalender synchronisieren`,
  LOADING_LABEL_3: `Nachrichten senden`,
  MILES: ` Meilen`,
  KILOMETERS: ` Kilometer`,
  LEARN_MORE: `Mehr erfahren`,
  LOCATION_ACCESS_TOOLTIP: `Kann nicht auf Ihren Standort zugreifen`,
});

export const BookedMeetings = Object.freeze({
  ADD_NOTE: `Notiz hinzufügen`,
  ATTENDEES: `Teilnehmer`,
  CANCELED: 'Abgesagt',
  CANCEL_MEETING_TITLE: `Meeting absagen`,
  CANCEL_MEETING_DESCRIPTION: `Warum absagen?`,
  CANCEL_REASON: `Absagegrund:`,
  DETAILS: `Details`,
  BOOKED: `Gebucht`,
  BOOKING_PAGE: `Buchungsseite`,
  BOOKING_PAGE_PLACEHOLDER: `Buchungsseite auswählen`,
  EDIT_ATTENDEE_LIMIT: `Teilnehmerbegrenzung bearbeiten`,
  GOOGLE_MEET: `Google Meet`,
  GUEST: `Gast`,
  GUEST_ANSWERS: `Gastantworten`,
  HOSTS: `Gastgeber`,
  IN_PERSON: `Persönlich`,
  LOCATION_TITLE: `Ort`,
  NO_SCHEDULED_MEETINGS: `Sie haben keine gebuchten Meetings`,
  NO_LOCATION_GIVEN: `Kein Ort angegeben`,
  NO_SHOW_LABEL: `Nicht erschienen`,
  MARK_AS_NO_SHOW: `Als nicht erschienen markieren`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART1: `Sind Sie sicher, dass Sie`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART2: `als nicht erschienen markieren möchten?`,
  MARK_AS_NO_SHOW_TOOLTIP: `Das Meeting hat noch nicht begonnen.`,
  MEETING: `Meeting`,
  MEETINGS: `Meetings`,
  INTERNAL_MEETING_NOTES: `Interne Besprechungsnotiz`,
  INTERNAL_MEETING_NOTES_DESC: `Nur der Gastgeber wird diese Notiz sehen`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  NOTE_FROM_INVITEE: `Notiz vom Eingeladenen`,
  NO_ANSWERS: `Keine Antworten`,
  NO_ATTENDEES: `Keine Teilnehmer`,
  NO_NOTE: `Keine Notiz`,
  PAST: `Vergangen`,
  PHONE_CALL: `Telefonanruf`,
  RESCHEDULE: `Verschieben`,
  REASON_UNEXPECTED: `Unerwarteter Konflikt`,
  REASON_INVITE: `Muss andere einladen`,
  REASON_TIME: `Benötige Zeit zur Vorbereitung`,
  REASON_OTHER: `Andere`,
  STATUS: `Status`,
  STATUS_PLACEHOLDER: `Status auswählen`,
  TEAM: `Team`,
  TITLE: `Gebuchte Meetings`,
  THIS_MONTH: `Diesen Monat`,
  UNDO_NO_SHOW: `Nicht erschienen rückgängig machen`,
  UPCOMING: `Bevorstehend`,
  ZOOM: `Zoom`,
  VIDEO_CONFERENCE: `Videokonferenz`,
  WORKSPACE: `Arbeitsbereich`,
});

export const BookedMeetingsToastsNotifications = Object.freeze({
  GET_SCHEDULED_MEETINGS_ERROR_TOAST: `Fehler beim Abrufen gebuchter Meetings`,
  CANCEL_SCHEDULED_MEETING_ERROR_MESSAGE: `Fehler beim Absagen des Meetings`,
  CANCEL_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Meeting erfolgreich abgesagt`,
  UPDATE_SCHEDULED_MEETING_ERROR_TOAST: `Fehler beim Aktualisieren des Meetings`,
  DELETE_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Meeting erfolgreich gelöscht`,
  DELETE_SCHEDULED_MEETING_ERROR_MESSAGE: `Fehler beim Löschen des Meetings`,
});
