export const Billing = Object.freeze({
  ACCOUNT_SUSPENDED_PART1: `Sua conta está suspensa. Você deve`,
  ACCOUNT_SUSPENDED_PART2: `pagar sua fatura pendente`,
  ACCOUNT_SUSPENDED_PART3: `para reativar o serviço.`,
  ADD_SEATS: `Adicionar Assentos`,
  ADD_SEATS_DESCRIPTION_PART_1: `Adicionando`,
  ADD_SEATS_DESCRIPTION_PART_2: `assento(s) adicionará`,
  ADD_SEATS_DESCRIPTION_PART_3: `à sua`,
  ADD_SEATS_DESCRIPTION_PART_4: `fatura, a partir de`,
  ADD_SEATS_PRORATED_PART_1: `Para os dias restantes`,
  ADD_SEATS_PRORATED_PART_2: `até o próximo pagamento, será cobrado um valor proporcional de`,
  ADD_SEATS_PRORATED_PART_3: `hoje.`,
  AMOUNT: `Quantia`,
  ANNUAL_TITLE: `Anual`,
  ANNUALLY_TITLE: `anualmente`,
  BILL_IS_LATE_PART_1: `A fatura está atualmente`,
  BILL_IS_LATE_PART_2_MULTIPLE: `dias de atraso.`,
  BILL_IS_LATE_PART_2_SINGLE: `dia de atraso.`,
  BILLING_ADDRESS: `Endereço de Faturamento`,
  BILLING_DETAILS_DESCRIPTION: `Gerencie seu método de pagamento e endereço de faturamento`,
  BILLING_DETAILS_TITLE: `Detalhes do Faturamento`,
  BUY_LICENSES_MESSAGE: `Sua fatura será`,
  BY_INVOICE: `Por fatura`,
  CANCEL_SUBSCRIPTION: `Cancelar assinatura`,
  CANCEL_SUBSCRIPTION_MESSAGE: `Tem certeza de que deseja cancelar a assinatura?`,
  CANCELLATION_TEXT: `Sua assinatura está programada para ser cancelada em`,
  CANCELLATION_DESCRIPTION: `Você tem certeza de que deseja cancelar o alteração programada?`,
  CHANGE: `Alterar`,
  CHANGE_SUBSCRIPTION: `Alterar Assinatura`,
  CARD: `Cartão`,
  CHANGE_TERM: `Alterar Prazo`,
  CHECKOUT: `Finalizar Compra`,
  DAYS: `Dias`,
  DATE_ISSUE: `Data de Emissão`,
  DOWNLOAD: `Baixar`,
  EMAIL: `E-mail`,
  EXPIRE_MESSAGE_PART_1: `A fatura está`,
  EXPIRE_MESSAGE_PART_2: `dias de atraso. Sua organização ficará inoperante em`,
  EXPIRE_MESSAGE_PART_3: `dias.`,
  INVOICE: `Fatura`,
  INVOICE_DESCRIPTION: `Visualizar e baixar`,
  INVOICE_TITLE: `Histórico de Faturas`,
  LICENSE_MESSAGE_PART_1: `Você já tem`,
  LICENSE_MESSAGE_PART_2: `licenças atribuídas aos usuários. Por favor, insira mais`,
  LICENSE_MESSAGE_PART_3: `ou desative/exclua usuários para liberar licenças`,
  LICENSES_PLACEHOLDER: `Digite um número`,
  LICENSES_TITLE: `licenças`,
  LOADING_LABEL_1: `Conectando…`,
  LOADING_LABEL_2: `Adicionando Licenças`,
  MONTH_TITLE: `mês`,
  MONTHLY_TITLE: `Mensal`,
  NEXT_INVOICE_DUE: `Próxima Fatura Vencida`,
  NOTIFICATION_DESCRIPTION: `Receber notificações por e-mail para novas faturas`,
  NOTIFICATION_TITLE: `Notificações`,
  NUMBER_OF_LICENSES: `# Licenças`,
  ORDER_SUMMARY: `Resumo do Pedido`,
  PAY_NOW_TITLE: `Pagar Agora`,
  PAYMENT_METHOD: `Método de Pagamento`,
  PER_USER_MONTH: `por usuário / mês`,
  PLAN_SILVER: `Prata`,
  PLAN_TRIAL: `Teste`,
  RECEIVE_NOTIFICATIONS: `Receber notificações por e-mail para novas faturas`,
  REMOVE_SEATS: `Remover Assentos`,
  REMOVE_SEATS_DESCRIPTION_PART_1: `Removendo`,
  REMOVE_SEATS_DESCRIPTION_PART_2: `assento(s) reduzirá sua`,
  REMOVE_SEATS_DESCRIPTION_PART_3: `fatura por`,
  REMOVE_SEATS_DESCRIPTION_PART_4: `a partir de`,
  REMOVE_SEATS_NOTE_PART1: `Para remover mais de`,
  REMOVE_SEATS_NOTE_PART2: `assento(s) você pode desativar ou excluir usuários para liberar licenças.`,
  REACTIVATE: `Reativar`,
  SCHEDULED_CHANGES: `Alterações Agendadas para`,
  SCHEDULED_COLUMN_CHANGE_BY: `Alterado por`,
  SCHEDULED_COLUMN_DATE: `Data da Solicitação`,
  SCHEDULED_COLUMN_LABEL: `Alteração do Pedido`,
  SCHEDULED_SEATS_CHANGE_PART_1: `Reduzir`,
  SCHEDULED_SEATS_CHANGE_PART_2: `licenças`,
  SCHEDULED_TERM_CHANGE: `Prazo alterado para`,
  SEATS: `Assentos`,
  STATUS: `Status`,
  STATUS_DUE: `Devido`,
  STATUS_PAID: `Pago`,
  SUBSCRIPTION: `Assinatura`,
  TERM: `Prazo`,
  TERM_ANNUAL: `Anual`,
  TERM_MONTHLY: `Mensal`,
  TITLE: `Faturamento`,
  TRIAL_LICENSE_EXPIRED: `Sua licença SUMO1 expirou. Para continuar usando SUMO1 sem interrupções, compre uma nova licença.`,
  UNPAID_INVOICE_MESSAGE: `Você tem uma fatura em aberto.`,
  USERS: `Usuários`,
  USERS_OF: `de`,
  YOUR_PLAN: `Seu plano`,
  TAX: `Imposto`,
  HAVE_UNPAID_INVOICE: `Você tem uma fatura pendente.`,
  VIEW_BILLING: `Ver faturamento`,
  PAY_INVOICE: `Pagar fatura`,
  PAY_BY_INVOICE_MIN_VALUE_ORDER_TOOLTIP: `Para ativar o pagamento por fatura, seu pedido deve ser de pelo menos`,
});

export const BillingToastsNotifications = Object.freeze({
  ADD_SEATS_ERROR_TOAST: `Não foi possível adicionar os assentos`,
  ADD_SEATS_SUCCESS_TOAST: `Por favor, aguarde até 1 minuto antes de recebermos o seu pagamento`,
  ADD_SEATS_SUCCESS_TOAST_TITLE: `Assentos adicionados.`,
  BUY_LICENSE_SUCCESS_TOAST: `Assinatura criada com sucesso`,
  CHANGE_TERM_ERROR_TOAST: `Não foi possível alterar o prazo.`,
  CHANGE_TERM_SUCCESS_TOAST: `Prazo alterado com sucesso.`,
  CHARGEBEE_PORTAL_CLOSE_TOAST: `Por favor, aguarde até 1 minuto antes de recebermos se você fez alguma alteração, então reabra a página de faturamento novamente`,
  DELETE_CHANGE_ERROR_TOAST: `Não foi possível excluir a alteração agendada.`,
  DELETE_CHANGE_SUCCESS_TOAST: `Alteração agendada excluída com sucesso.`,
  GET_BILLING_ERROR_TOAST: `Erro de Conexão com ChargeBee`,
  GET_ITEM_TIERS_ERROR_TOAST: `Não foi possível obter os preços das licenças`,
  PAY_LATER_ERROR_TOAST: `O agendamento do pagamento falhou.`,
  REMOVE_SEATS_ERROR_TOAST: `Não foi possível remover os assentos`,
  REMOVE_SEATS_SUCCESS_TOAST: `Alterações programadas`,
  REMOVE_SEATS_SUCCESS_TOAST_TITLE_PART_1: `Reduzir`,
  REMOVE_SEATS_SUCCESS_TOAST_TITLE_PART_2: `assentos`,
  CALCULATE_TAXES_ERROR_TOAST: `Erro durante o cálculo do imposto`,
  REACTIVATE_SUBSCRIPTION_SUCCESS_TOAST: `Assinatura reativada com sucesso`,
  REACTIVATE_SUBSCRIPTION_ERROR_TOAST: `Falha ao ativar a assinatura. Por favor, atualize seu método de pagamento.`,
  PAYMENT_ERROR_TOAST: `Houve um problema com o cartão durante o pagamento, por favor verifique o cartão.`,
  DELETE_PAYMENT_SOURCE_SUCCESS_TOAST: `O cartão foi excluído com sucesso.`,
  DELETE_PAYMENT_SOURCE_ERROR_TOAST: `Erro ao excluir o cartão.`,
  REPEAT_PAYMENT_SUCCESS_TOAST: `A fatura foi paga com sucesso.`,
  REPEAT_PAY_NO_PAYMENT_SOURCES_ERROR_TOAST: `Você não tem cartões disponíveis para pagamento.`,
  REPEAT_PAY_INVOICE_NOT_COLLECTED_ERROR_TOAST: `Falha ao pagar uma ou mais faturas.`,
  REPEAT_PAYMENT_ERROR_TOAST: `Ocorreu um erro durante a tentativa de pagamento.`,
  UPDATE_PAYMENT_METHOD_SUCCESS_TOAST: `O método de pagamento foi alterado com sucesso.`,
  UPDATE_PAYMENT_METHOD_ERROR_TOAST: `Falha ao atualizar o método de pagamento.`,
});
