import 'chartjs-adapter-dayjs-4';
import { useDispatch, useSelector } from 'react-redux';
import { getLicenseTotal, ORGS_CHART_VALUES, orgsActions, orgsSelectors } from '../../../../store/opsConsole/orgs';
import labels from './labels';
import { OPSConsoleChart } from '../../OPSConsoleChart/OPSConsoleChart';
import { useCallback } from 'react';

export const OrgsChart = () => {
  const dispatch = useDispatch();
  const orgsChartData = useSelector(orgsSelectors.selectOrgsChartData);
  const chartFilter = useSelector(orgsSelectors.selectOrgsChartFilter);
  const orgsLogsFetching = useSelector(orgsSelectors.selectOrgsLogsFetching);
  const orgsTotal = getLicenseTotal(orgsChartData);

  const handleFilterChange = (value: ORGS_CHART_VALUES) => {
    dispatch(orgsActions.getOrgsLogsRequest(value));
  };

  const getTotalLabel = useCallback(() => {
    let label = labels.total;
    switch (chartFilter) {
      case ORGS_CHART_VALUES['1_MONTH']:
        label += ` ${labels.MTD}`;
        break;
      case ORGS_CHART_VALUES[`12_MONTHS`]:
        label += ` ${labels.YTD}`;
        break;
    }
    return label;
  }, [chartFilter]);

  return (
    <OPSConsoleChart
      data={orgsChartData}
      title={labels.title}
      total={orgsTotal.toFixed()}
      totalLabel={getTotalLabel()}
      isFethcing={orgsLogsFetching}
      filter={chartFilter}
      handleFilterChange={handleFilterChange}
    />
  );
};
