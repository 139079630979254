import { FunctionComponent, SVGProps } from 'react';
import {
  IconName,
  SmartAlert,
  SmartAlertTemplate,
  UpdateBookingPageInput,
  UpdateBookingTemplateInput,
} from '../../API';

export enum SmartTypes {
  ALERT = 'alert',
  TEMPLATE = 'template',
}
export type SmartTypesResponse = {
  smartAlerts: SmartAlert[];
  smartAlertTemplates: SmartAlertTemplate[];
};

export type SmartAlertResponse = {
  smartAlert: SmartAlert;
  bookingPages: UpdateBookingPageInput[];
  bookingTemplates: UpdateBookingTemplateInput[];
};

export type SmartAlertTemplateResponse = {
  smartAlertTemplate: SmartAlertTemplate;
};

export type UpsertSmartAlertResponse = {
  id: string;
};

export enum SmartAlertType {
  PERSONAL = 'PERSONAL',
  SHARED = 'SHARED',
  TEMPLATE = 'TEMPLATE',
}

export enum SmartAlertStatus {
  UNHIDDEN = 'UNHIDDEN',
  HIDDEN = 'HIDDEN',
}
export type SmartAlertFilterType = {
  userIds: string[];
  types: SmartAlertType[];
  status: SmartAlertStatus[];
};

export type SelectedAlertsType = {
  type: SmartTypes;
  id: string;
};

export type IconOption = {
  value: IconName;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
};

export enum UpsertSmartTypeFrom {
  BOOKING_PAGE = 'bookingPage',
  BOOKING_TEMPLATE = 'bookingTemplate',
  SMART_ALERT = 'smartAlert',
  NONE = 'none',
}

export type DeleteSmartTypeModalRequest = { selectedAlerts: SelectedAlertsType[]; name?: string };

export type SmartAlertsByIdType = { [key: string]: SmartAlert };

export type RemoveFromSmartAlertAndCreateNewType = {
  smartAlertId: string;
  upsertType: UpsertSmartTypeFrom;
  smartAlertName: string;
  bookingPageId?: string;
  bookingTemplateId?: string;
};
