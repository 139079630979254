import {Auth, Hub} from 'aws-amplify';
import React, {createContext, useContext, useState, useEffect, useRef, MutableRefObject} from 'react';
import { useLocation } from 'react-router-dom';

interface ISessionContext {
  isInitialVisit: boolean;
  isAuthenticated: boolean;
  isLogout: MutableRefObject<boolean>;
  setIsAuthenticated: (value: boolean) => void;
}

const SessionContext = createContext<ISessionContext | undefined>(undefined);

export const SessionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isInitialVisit, setIsInitialVisit] = useState<boolean>(true); // checking if the app was initialized on current url
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  const location = useLocation();

  const initialPath = useRef(location.pathname);
  const isLogout = useRef(false);

  useEffect(() => {
    if (location.pathname !== initialPath.current) {
      setIsInitialVisit(false);
    }
  }, [location]);

  useEffect(() => {
    // listen for storage changes if Congnito Auth is authenticated
    const handleStorageChange = () => {
      Auth.currentAuthenticatedUser()
        .then(() => setIsAuthenticated(true))
        .catch(() => setIsAuthenticated(false));
    };
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const listener = Hub.listen('auth', (data) => {
      const { payload } = data;

      switch (payload.event) {
        case 'signOut':
          isLogout.current = true;
          break;
      }
    });

    return () => {
      return listener();
    };
  }, []);

  return (
    <SessionContext.Provider value={{ isInitialVisit, isAuthenticated, setIsAuthenticated, isLogout }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};
