import { t } from '../../../i18n/i18n';

export default {
  no: t('Common:NO'),
  yesCancel: t('Common:YES_CANCEL'),
  change: t('Billing:CHANGE'),
  payInvoice: t('Billing:PAY_INVOICE'),
  cancelSubscription: t('Billing:CANCEL_SUBSCRIPTION'),
  cancelSubscriptionMessage: t('Billing:CANCEL_SUBSCRIPTION_MESSAGE'),
  reactivate: t('Billing:REACTIVATE'),
};
