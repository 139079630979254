export const CalendarConnections = Object.freeze({
  CALENDAR_INFO_MESSAGE: `Vous ne pouvez connecter qu'un seul calendrier`, // Pour augmenter cette limite, contactez les ventes pour mettre à jour votre niveau de licence.
  DESCRIPTION: `Connectez votre calendrier afin que SUMO sache quand vous êtes disponible et mette à jour votre calendrier à mesure que les rendez-vous sont réservés.`,
  DISCONNECT_MESSAGE: `Êtes-vous sûr de vouloir déconnecter votre calendrier ?`,
  DISCONNECT_MESSAGE_DESCRIPTION: `Les rendez-vous ne seront ni supprimés ni annulés.`,
  DISCONNECT_TITLE: `Déconnecter le Calendrier`,
  EXCHANGE_CALENDAR_DESCRIPTION: `Exchange Server 2013, 2016 ou 2019`,
  EXCHANGE_CALENDAR_TITLE: `Calendrier Exchange`,
  GOOGLE_CALENDAR_DESCRIPTION: `Gmail, G Suite, Google Workspace`,
  GOOGLE_CALENDAR_TITLE: `Calendrier Google`,
  MICROSOFT_CALENDAR_DESCRIPTION: `Office 365, Outlook.com, Live.com ou Hotmail Calendar`,
  MICROSOFT_CALENDAR_TITLE: `Calendrier Office 365`,
  SELECT_CALENDAR_TITLE: `Sélectionner le Calendrier`,
  TITLE: `Connexions de Calendrier`,
  TOOLTIP: `Connectez votre calendrier Google ou Microsoft à SUMO`,
});

export const CookieSettings = Object.freeze({
  AGREE_CLOSE: `Accepter et Fermer`,
  DESCRIPTION: `Lorsque vous visitez un site web, il a la capacité de stocker ou de récupérer certaines informations sur votre navigateur. Ces informations, souvent sous forme de cookies, peuvent concerner vos préférences, votre appareil ou d'autres détails. Leur principal objectif est de s'assurer que le site web fonctionne comme vous vous y attendez. Bien que ces informations ne vous identifient pas directement, elles peuvent améliorer votre expérience de navigation web en personnalisant le contenu. Nous comprenons et valorisons votre droit à la vie privée, c'est pourquoi nous vous offrons la possibilité de contrôler et de personnaliser vos préférences de cookies. En cliquant sur les différents en-têtes de catégorie, vous pouvez en savoir plus sur chaque type de cookie et modifier nos paramètres par défaut. Veuillez noter que le blocage de certains types de cookies peut affecter votre expérience générale sur le site et limiter les services que nous pouvons vous offrir.`,
  DESCRIPTION_FIRST_PART: `Lorsque vous visitez un site web, il a la capacité de stocker ou de récupérer certaines informations sur votre navigateur. Ces informations, souvent sous forme de cookies, peuvent concerner vos préférences, votre appareil ou d'autres détails. Leur principal objectif est de s'assurer que le site web fonctionne comme vous vous y attendez.`,
  DESCRIPTION_SECOND_PART: `Bien que ces informations ne vous identifient pas directement, elles peuvent améliorer votre expérience de navigation web en personnalisant le contenu. Nous comprenons et valorisons votre droit à la vie privée, c'est pourquoi nous vous offrons la possibilité de contrôler et de personnaliser vos préférences de cookies.`,
  DESCRIPTION_THIRD_PART: `En cliquant sur les différents en-têtes de catégorie, vous pouvez en savoir plus sur chaque type de cookie et modifier nos paramètres par défaut. Veuillez noter que le blocage de certains types de cookies peut affecter votre expérience générale sur le site et limiter les services que nous pouvons vous offrir.`,
  FUNCTIONAL_NAME: `Cookies Fonctionnels`,
  MANAGE_TITLE: `Gérer les Préférences de Consentement`,
  NECESSARY_ONLY: `Strictement Nécessaires Seulement`,
  PERFORMANCE_NAME: `Cookies de Performance`,
  STRICTLY_NAME: `Cookies Strictement Nécessaires`,
  TARGETING_NAME: `Cookies de Ciblage`,
  TITLE: `Paramètres des Cookies`,
  SHOW_ALL: `Afficher tout`,
  HIDE_ALL: `Masquer tout`,
});

export const Login = Object.freeze({
  GOOGLE_ACCOUNT: `Compte Google`,
  GOOGLE_ACCOUNTS_SWITCH: `Changer de Comptes Google`,
  GOOGLE_SWITCH: `Connexion avec Google`,
  GOOGLE_SWITCH_DESC: `Changer de l'authentification Microsoft à Google`,
  MICROSOFT_ACCOUNT: `Compte Microsoft`,
  MICROSOFT_ACCOUNTS_SWITCH: `Changer de Comptes Microsoft`,
  MICROSOFT_SWITCH: `Connexion avec Microsoft`,
  MICROSOFT_SWITCH_DESC: `Changer de l'authentification Google à Microsoft`,
  SWITCH_ACCOUNT_MESSAGE: `Votre compte SUMO1 perdra l'accès aux données disponibles uniquement via votre fournisseur d'origine. Vous devrez peut-être aussi redonner accès aux intégrations et calendriers.`,
  SWITCH_DESCRIPTION_PART1: `Changer de `,
  SWITCH_DESCRIPTION_PART2: ` authentification à `,
});

export const Role = Object.freeze({
  ROLE_DESCRIPTION: `Votre rôle actuel`,
});

export const AccountSettings = Object.freeze({
  DATE_FORMAT: `Format de Date`,
  LANGUAGE: `Langue`,
  NAME: `Nom`,
  WELCOME_MESSAGE: `Message de Bienvenue`,
  PHONE_NUMBER: `Numéro de Téléphone`,
  PROFILE_IMAGE: `Image de Profil`,
  PHONE_DETAILS: `Détails du Téléphone`,
  PHONE_DETAILS_PLACEHOLDER: `Ajoutez des informations supplémentaires ou des instructions ici.\r\nEx: J'ai hâte de vous rencontrer. Envoyez-moi un SMS à tout moment.`,
  TIME_FORMAT: `Format de l'Heure`,
  TIME_ZONE: `Fuseau Horaire`,
  COUNTRY: `Pays`,
});

export const Profile = Object.freeze({
  TITLE: `Profil`,
  ACCOUNT_SETTINGS: `Paramètres du Compte`,
  ACCOUNT_DESCRIPTION: `Informations de base et paramètres`,
  LOGIN: `Connexion`,
  LOGIN_DESCRIPTION: `Configurer le compte connecté`,
  MY_ROLE: `Mon Rôle`,
  MY_ROLE_DESCRIPTION: `Votre rôle et vos permissions actuels`,
  COOKIE_SETTINGS: `Paramètres des Cookies`,
  COOKIE_DESCRIPTION: `Cookies Nécessaires, de Performance, Fonctionnels, de Ciblage`,
  DELETE_ACCOUNT_DESCRIPTION: `Supprimez votre compte et toutes les données associées de votre organisation`,
});

export const DeleteAccount = Object.freeze({
  TITLE: `Supprimer le compte`,
  WARNING_DELETE: `Attention, cette action est permanente et ne peut pas être annulée.`,
  DELETE_ACCOUNT_AND_ORG: `Supprimer le compte et l'organisation`,
  YES_DELETE: `Oui, supprimer`,
  DELETE_TO_CONFIRM: `Tapez <b>Supprimer</b> pour confirmer.`,
  DELETE_CONFIRM_WORD: `Supprimer`,
  DELETE_ACCOUNT_MODAL_DESCRIPTION: `Supprimez vos données personnelles et toutes les données associées de votre organisation. Cette action est permanente et ne peut pas être annulée.`,
  DELETE_ACCOUNT_AND_ORG_MODAL_DESCRIPTION: `Supprimez votre compte et votre organisation. Cette action est permanente et ne peut pas être annulée.`,
  LIST_DELETING_ENTITIES_TITLE: `La suppression de votre compte entraînera les actions suivantes`,
  LIST_DELETING_ACCOUNT_AND_ORG_ENTITIES_TITLE: `La suppression de votre compte et de votre organisation entraînera les actions suivantes`,
  LIST_DELETING_ENTITIES: `Vous déconnecter de tous les appareils \\p Supprimer toutes les informations personnelles \\p Supprimer toutes les intégrations personnelles`,
  ADDITIONAL_LIST_DELETING_ORG_ENTITIES: `\\p Supprimer toutes les données de l'organisation`,
  TRANSFER_OWNERSHIP_TITLE: `Transférer la propriété et supprimer mon compte`,
  TRANSFER_OWNERSHIP_DESCRIPTION: `Avant de pouvoir supprimer votre compte, vous devez transférer la propriété à un autre membre de l'équipe pour qu'il puisse gérer l'organisation.`,
  DELETE_ACCOUNT_AND_ORG_TITLE: `Supprimer mon compte et dissoudre l'organisation`,
  DELETE_ACCOUNT_AND_ORG_DESCRIPTION: `Si vous ne souhaitez pas transférer la propriété, vous pouvez supprimer votre compte et dissoudre l'organisation.`,
  TRANSFER_OWNERSHIP: `Transférer la propriété`,
  NEED_INVITE_NEW_USER_TO_ORG: `Invitez quelqu'un dans votre organisation et acceptez l'invitation`,
});

export const UserSettingsToastsNotifications = Object.freeze({
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST_MESSAGE: `Échec de la demande de nouveau calendrier`,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST_MESSAGE: `Nouveau calendrier connecté avec succès !`,
  CONNECT_ZOOM_ERROR_TOAST_MESSAGE: `Échec de la demande de connexion de Zoom`,
  CONNECT_ZOOM_SUCCESS_TOAST_MESSAGE: `Zoom connecté avec succès !`,
  SAVE_USER_SETTINGS_ERROR_TOAST_MESSAGE: `Échec de la demande d'enregistrement des paramètres`,
  SAVE_USER_SETTINGS_SUCCESS_TOAST_MESSAGE: `Vos paramètres ont été enregistrés avec succès !`,
  SWITCH_ACCOUNT_SUCCESS_TOAST_MESSAGE: `Vous avez changé de compte avec succès`,
  SWITCH_ACCOUNT_ERROR_TOAST_MESSAGE: `Échec du changement de compte. Veuillez réessayer plus tard`,
  DELETE_ACCOUNT_ERROR_TOAST_MESSAGE: `Échec de la suppression du compte`,
});

export const Guide = Object.freeze({
  GET_STARTED: `Commencer avec SUMO1`,
  START: `Commencer`,

  BACK: `Retour`,
  CLOSE: `Fermer`,
  LAST: `Finaliser`,
  NEXT: `Suivant`,
  NEXT_LABEL_WITH_PROGRESS: `Suivant (Étape {step} sur {steps})`,
  OPEN_THE_DIALOG: `Ouvrir la boîte de dialogue`,
  SKIP: `Terminer le tour`,
  COMPLETE_TOUR: `Terminer le tour`,
  GO_TO: `Aller à`,
  START_TOUR: `Lancer le tour`,

  GUIDED_TOUR: `Tour guidé`,
  GUIDED_TOUR_DESCRIPTION: `Vous pouvez lancer le Tour guidé à tout moment et le parcourir.`,
  PROFILE_TITLE: `Gérer votre profil`,
  PROFILE_DESCRIPTION: `Vous pouvez gérer vos paramètres de profil utilisateur, y compris le langage, le fuseau horaire, votre rôle et vos permissions, etc.`,
  INTEGRATIONS_TITLE: `Connectez vos réunions de vidéo`,
  INTEGRATIONS_DESCRIPTION: `SUMO 1 a des intégrations prêtes à l'emploi pour CRM, réunions de vidéo, processeurs de paiement et plus encore.`,
  AVAILABILITY_TITLE: `Modifier vos heures de disponibilité`,
  AVAILABILITY_DESCRIPTION: `Vous pouvez modifier votre disponibilité ou créer de nouveaux horaires de disponibilité comme "Après-heures" ici.`,
  SUMO_INBOX_TITLE: `Installer SUMO Inbox`,
  SUMO_INBOX_DESCRIPTION: `SUMO Meeting Booker est une extension de navigateur que 90% des utilisateurs utilisent quotidiennement pour amener des fonctionnalités avancées de planification de rendez-vous de SUMO1 à Gmail et Outlook.`,
  SUMO_INBOX_LINK: `Installer l'extension de navigateur de SUMO1`,
  BOOKING_PAGES_TITLE: `Créer votre page de réservation`,
  BOOKING_PAGES_DESCRIPTION: `Vous pouvez créer des pages de réservation qui seront utilisées plus durablement. Par exemple, un lien de page de réservation pour votre signature électronique, votre site web, etc.`,
  BOOKED_MEETINGS_TITLE: `Comment voir les rendez-vous réservés`,
  BOOKED_MEETINGS_DESCRIPTION: `Voir une liste de rendez-vous planifiés, y compris l'option de résiliation, de marquer comme non-présent, de réagencer et plus encore.`,
  SMART_ALERTS_TITLE: `Optimiser votre agenda avec des alertes intelligentes`,
  SMART_ALERTS_DESCRIPTION: `Les pages de réservation incluent des alertes de base, comme envoyer un rappel par SMS 1 jour avant le rendez-vous. Les alertes intelligentes aident les utilisateurs à configurer des alertes de rendez-vous plus sophistiquées.`,
  BOOKING_TEMPLATES_TITLE: `Modèle de réservation`,
  BOOKING_TEMPLATES_DESCRIPTION: `Vous pouvez créer des modèles de réservation (également connus comme types de réunions) pour faciliter la réservation rapide d'un rendez-vous spécifique avec la personne appropriée, au lieu approprié et au moment approprié.`,
  TEAMS_TITLE: `Gérer vos équipes`,
  TEAMS_DESCRIPTION: `Vous pouvez créer des équipes (également connues comme groupes de ressources) pour vous assigner automatiquement à une réunion en fonction d'un mode d'affectation, comme Round-Robin.`,
  USERS_TITLE: `Inviter et gérer vos utilisateurs`,
  USERS_DESCRIPTION: `Vous pouvez inviter des utilisateurs à utiliser SUMO1 et activer ou désactiver des utilisateurs.`,
  ROLES_TITLE: `Gérer les permissions de vos collègues`,
  ROLES_DESCRIPTION: `Vous pouvez gérer les permissions de vos collègues pour vous assurer qu'ils ont les accès CRUD (créer, lire, mettre à jour, supprimer) requis.`,
  BILLING_TITLE: `Choisir votre plan et configurer votre paiement`,
  BILLING_DESCRIPTION: `Vous pouvez gérer votre abonnement SUMO1 vous-même, y compris la possibilité d'ajouter plus de licences si nécessaire.`,
});
