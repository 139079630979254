import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { TooltipRenderProps } from 'react-joyride';
import labels from './labels';
import { GuideStepData } from './types';

export const Tooltip: React.FC<TooltipRenderProps> = (props) => {
  const { backProps, closeProps, continuous, index, primaryProps, skipProps, step, tooltipProps, size } = props;
  const data = (step.data || {}) as GuideStepData | Record<string, never>;

  const skipLabel = data?.label?.skip ?? skipProps.title;
  const closeLabel = data?.label?.close ?? labels.close;
  const backLabel = data?.label?.back ?? backProps.title;
  const nextLabel = data?.label?.next ?? primaryProps.title;

  return (
    <div
      className={classNames(
        'tooltip',
        data?.styles?.tooltip?.className ?? 'relative bg-primary-white border-radius-14px border-none w-460px'
      )}
      {...tooltipProps}
    >
      <div
        className={classNames(
          'tooltip__header',
          data?.styles?.tooltip?.header?.className ??
            'flex justify-content-between p-20px border-none border-bottom-1 border-solid border-heavy-20'
        )}
      >
        {step.title ? (
          <p
            className={classNames(
              'tooltip__title',
              data?.styles?.tooltip?.header?.title?.className ?? 'text-title-lg-med m-0'
            )}
          >
            {step.title}
          </p>
        ) : null}
        <button
          className={classNames(
            'tooltip__button-close',
            data?.styles?.tooltip?.header?.close?.className ??
              'action-button absolute top-15px right-15px ml-auto bg-primary-white'
          )}
          {...closeProps}
        >
          <XMarkIcon className="icon-20px" />
        </button>
      </div>
      <div className={classNames('tooltip__body', data?.styles?.tooltip?.body?.className ?? 'text-body-lg-reg p-20px')}>
        {!data?.hideStepCounter ? (
          <div>
            <span
              className={classNames(
                'tooltip__step-counter',
                data?.styles?.tooltip?.body?.stepCounter?.className ??
                  'text-heavy-60 bg-heavy-10 py-6px px-8px border-radius-6px text-label-s-med'
              )}
            >
              {index + 1}/{size}
            </span>
          </div>
        ) : null}
        {step.content}
      </div>
      {!data?.hideBackButton || !data?.hideContinuousButton || !data?.hideSkipButton ? (
        <div
          className={classNames(
            'tooltip__footer',
            data?.styles?.tooltip?.footer?.className ??
              'flex flex-row justify-content-between p-20px border-none border-top-1 border-solid border-heavy-20'
          )}
        >
          <div
            className={classNames(
              'tooltip__spacer-left',
              data?.styles?.tooltip?.footer?.spacerLeft?.className ?? 'flex-left-center flex-row gap-2'
            )}
          >
            {!data?.hideSkipButton && skipLabel !== undefined ? (
              <Button
                link
                className={classNames(
                  'tooltip__button-skip',
                  data?.styles?.tooltip?.footer?.buttonSkip?.className ?? 'text-label-md-med cursor-pointer'
                )}
                {...skipProps}
              >
                {skipLabel}
              </Button>
            ) : null}
          </div>
          <div
            className={classNames(
              'tooltip__spacer-right',
              data?.styles?.tooltip?.footer?.spacerRight?.className ?? 'flex flex-row justify-content-between gap-2'
            )}
          >
            {data?.showCloseButton && closeLabel !== undefined ? (
              <Button
                link
                className={classNames(
                  'tooltip__button-close',
                  data?.styles?.tooltip?.footer?.buttonClose?.className ??
                    'px-3 text-heavy-80 text-label-md-med cursor-pointer'
                )}
                {...closeProps}
              >
                {closeLabel}
              </Button>
            ) : null}
            {!data?.hideBackButton && index > 0 && backLabel !== undefined ? (
              <Button
                link
                className={classNames(
                  'tooltip__button-back',
                  data?.styles?.tooltip?.footer?.buttonBack?.className ??
                    'text-heavy-80 text-label-md-med cursor-pointer px-3'
                )}
                {...backProps}
              >
                {backLabel}
              </Button>
            ) : null}
            {!data?.hideContinuousButton && continuous && nextLabel !== undefined ? (
              <Button
                className={classNames(
                  'tooltip__button-next',
                  data?.styles?.tooltip?.footer?.buttonNext?.className ?? 'text-label-md-med py-16px px-45px'
                )}
                {...primaryProps}
              >
                {nextLabel}
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};
