import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { editTeamModalActions, editTeamModalSelectors, teamsActions, teamsSelectors } from '../../../store/teams';
import { ChangeEvent, useEffect, useState } from 'react';
import labels from './labels';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { usersSelectors } from '../../../store/users';
import { userSettingsSelectors } from '../../../store/userSettings';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { SumoMultiSelect, SumoTooltip, WorkspaceCard } from '../../common';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { HostCard } from '../../common/hostCard/HostCard';
import { UserAdminDataFullRecord } from '../../../store/users/types';
import { authenticationSelectors } from '../../../store/authentication';
import { OptionType } from '../../../types/types';
import { ProgressSpinner } from 'primereact/progressspinner';

export const EditTeamModal = () => {
  const dispatch = useDispatch();
  const open = useSelector(editTeamModalSelectors.selectIsModalOpen);
  const selectedTeam = useSelector(teamsSelectors.selectSelectedTeam);
  const members = useSelector(teamsSelectors.selectMembers);
  const memberIds = useSelector(teamsSelectors.selectMembersIds);
  const users = useSelector(usersSelectors.selectLoggedUsersInCurrentWorkspace);
  const usersById = useSelector(usersSelectors.selectUsersById);

  const workspacesById = useSelector(authenticationSelectors.selectTenantWorkspacesById);
  const tenantWorkspacesOptions = useSelector(authenticationSelectors.selectTenantWorkspacesOptionItems);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const userWorkspaces = useSelector(userSettingsSelectors.selectUserWorkspaces) || [];
  const workspacesForRead = useSelector(teamsSelectors.selectWorkspacesForRead);
  const teamWorkspaceId = useSelector(teamsSelectors.selectWorkspaceId);

  const isExisting = useSelector(teamsSelectors.selectIsExistingTeam);
  const isFetching = useSelector(teamsSelectors.selectIsFetching);
  const isReadOnly = useSelector(userSettingsSelectors.selectIsReadOnlyTeam);
  const sharedForRead = useSelector(teamsSelectors.selectSharedForReadTeam);

  const isNameValid = useSelector(teamsSelectors.selectIsNameValid);
  const isNameDuplicate = useSelector(teamsSelectors.selectIsNameDuplicate);
  const [localTeamName, setLocalTeamName] = useState(selectedTeam.name || '');

  useEffect(() => {
    setLocalTeamName(selectedTeam.name || '');
  }, [selectedTeam]);

  const handleClose = () => {
    dispatch(editTeamModalActions.closeModal());
  };

  const handleSave = () => {
    dispatch(teamsActions.saveTeamRequest());
  };

  const handleChange = (name: string, value: boolean) => {
    dispatch(teamsActions.updateSelectedTeam({ [name]: value }));
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalTeamName(e.target.value);
  };

  const handleOnBlurName = () => {
    dispatch(teamsActions.updateSelectedTeam({ name: localTeamName.trim() }));
  };

  const confirmFooter = () => {
    return (
      <>
        <Button
          label={labels.save}
          className="min-w-120px"
          onClick={handleSave}
          disabled={!members.length || !isNameValid || isReadOnly || sharedForRead || isFetching}
        />
        <Button label={labels.cancel} text onClick={handleClose} />
      </>
    );
  };

  const isWorkspaceRemovable = (value: string) => {
    return isSuperAdmin || userWorkspaces.includes(value);
  };

  const handleWorkspaceListChange = (e: MultiSelectChangeEvent) => {
    dispatch(teamsActions.updateSelectedTeam({ workspacesForRead: e.value }));
  };

  const handleSelectAllWorkspaces = (checked: boolean) => {
    const freezedWorkspaces = workspacesForRead.filter((id) => !isWorkspaceRemovable(id));
    dispatch(
      teamsActions.updateSelectedTeam({
        workspacesForRead: checked
          ? [
              ...freezedWorkspaces,
              ...userWorkspaces.filter((id) => !freezedWorkspaces.includes(id) && teamWorkspaceId !== id),
            ]
          : freezedWorkspaces,
      })
    );
  };

  const removeWorkspaceListChange = (value: string) => {
    dispatch(teamsActions.updateSelectedTeam({ workspacesForRead: workspacesForRead.filter((id) => id !== value) }));
  };

  const handleUserListChange = (e: MultiSelectChangeEvent) => {
    dispatch(
      teamsActions.updateSelectedTeam({
        members: e.value.map((user: UserAdminDataFullRecord) => ({ userId: user.userId })),
      })
    );
  };

  const handleSelectAllUsers = (checked: boolean) => {
    dispatch(
      teamsActions.updateSelectedTeam({
        members: checked ? users.map((user) => ({ userId: user.userId })) : [],
      })
    );
  };

  const removeUserListChange = (value: string) => {
    dispatch(
      teamsActions.updateSelectedTeam({ members: memberIds.filter((id) => id !== value).map((id) => ({ userId: id })) })
    );
  };

  return (
    <Dialog
      header={
        <div className="flex-left-center gap-16px">
          <div>{isExisting ? labels.editTeam : labels.createTeam}</div>
          {isFetching && <ProgressSpinner className="w-32px h-32px mx-0 -my-6px" strokeWidth="5" />}
        </div>
      }
      footer={confirmFooter}
      visible={open}
      onHide={handleClose}
      className="w-700px"
      focusOnShow={false}
      draggable={false}
    >
      <div className="flex flex-column gap-24px">
        <div className="flex gap-8px py-12px px-16px w-full border-radius-8px bg-blue-soft">
          <InformationCircleIcon className="icon-18px text-blue-main flex-none" />
          <div className="flex-1 text-label-xs-reg">
            <b>{labels.roundRobinDescPar1}</b>
            {` - ${labels.roundRobinDescPar2}`}
          </div>
        </div>

        <div className="flex flex-column gap-10px">
          <div className="flex-left-center gap-6px">
            <div className="text-title-xs-med">{labels.teamName}</div>
            <SumoTooltip text={labels.teamNameTooltip} />
          </div>
          <div className="w-6 pr-28px flex-left-center gap-16px">
            <InputText
              value={localTeamName}
              onChange={handleNameChange}
              onBlur={handleOnBlurName}
              maxLength={MAX_LENGTH_NAME}
              placeholder={labels.teamNamePlaceholder}
              className={`w-full pr-20px ${isNameValid ? '' : 'p-invalid'}`}
              disabled={isReadOnly || sharedForRead}
            />
            <div className="w-0">
              <div className="flex-left-center gap-8px w-fit">
                <InputSwitch
                  name="active"
                  checked={!!selectedTeam.active}
                  onChange={(e) => handleChange(e.target.name, !!e.target.value)}
                  disabled={isReadOnly || sharedForRead}
                />
                <div className="flex-left-center gap-4px">
                  <div className="text-label-lg-reg text-heavy-80">{labels.active}</div>
                  <SumoTooltip text={labels.availableForUseTooltip} />
                </div>
              </div>
            </div>
          </div>
          {isNameDuplicate && <div className="text-body-s-reg text-tomato-main">{labels.duplicateName}</div>}
        </div>

        <div className="flex flex-column gap-10px">
          <div className="text-title-xs-med">{labels.workspaces}</div>
          <div className="flex flex-wrap -mx-20px -my-6px">
            <div className="w-12 lg:w-6 px-20px py-6px">
              <SumoMultiSelect
                className="pr-24px"
                onSelectAllChange={handleSelectAllWorkspaces}
                options={tenantWorkspacesOptions.filter((option) => option.value !== teamWorkspaceId)}
                value={workspacesForRead}
                onChange={handleWorkspaceListChange}
                filterPlaceholder={labels.workspacesPlaceholder}
                disabled={tenantWorkspacesOptions.length < 2 || isReadOnly || sharedForRead}
                templateType="workspace"
                optionDisabled={(option: OptionType) => !isWorkspaceRemovable(option.value)}
              />
            </div>
            <div className="w-12 lg:w-6 px-20px py-6px"></div>

            <div className="w-12 lg:w-6 px-20px py-6px">
              <WorkspaceCard
                workspace={workspacesById[teamWorkspaceId] || { id: teamWorkspaceId, name: labels.deleted }}
                info={labels.defaultWorkspaceTooltip}
              />
            </div>

            {workspacesForRead.map((workspaceId) => (
              <div key={workspaceId} className="w-12 lg:w-6 px-20px py-6px">
                <WorkspaceCard
                  workspace={workspacesById[workspaceId] || { id: workspaceId, name: labels.deleted }}
                  onRemove={
                    isWorkspaceRemovable(workspaceId) && !isReadOnly && !sharedForRead
                      ? removeWorkspaceListChange
                      : undefined
                  }
                />
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-column gap-10px">
          <div className="text-title-xs-med">{labels.members}</div>
          <div className="flex flex-wrap -mx-20px -my-6px">
            <div className="w-12 lg:w-6 px-20px py-6px">
              <SumoMultiSelect
                className="pr-24px"
                onSelectAllChange={handleSelectAllUsers}
                options={users}
                optionValue="id"
                value={memberIds.map((memberId) => usersById[memberId])}
                onChange={handleUserListChange}
                filterPlaceholder={labels.membersPlaceholder}
                disabled={isReadOnly || sharedForRead}
                templateType="user"
              />
            </div>
            <div className="w-12 lg:w-6 px-20px py-6px"></div>
            {memberIds.filter(userId => usersById[userId]).map((userId) => {
              const user = usersById[userId];
              return (
                <div key={userId} className="w-12 lg:w-6 px-20px py-6px">
                  <HostCard
                    host={{
                      label: user.fullName || '',
                      value: user.userId || '',
                      email: user.email,
                      avatar: user.avatar,
                      isTeam: false,
                      hasPhoneNumber: Boolean(user.phoneNumber),
                      integrationTypes: user.integrationTypes || [],
                    }}
                    onRemove={!isReadOnly && !sharedForRead ? removeUserListChange : undefined}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
