import { FC } from 'react';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { PaymentSource as PaymentSourceType } from '../../../store/billing';
import { DocumentChartBarIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ReactComponent as VisaIcon } from '../../../assets/icons/visa.svg';
import { ReactComponent as MastercardIcon } from '../../../assets/icons/mastercard.svg';
import { ReactComponent as StarIcon } from '../../../assets/icons/12-star.svg';
import labels from './labels';

interface IPaymentSourceProps {
  paymentSource: PaymentSourceType;
  isDefault: boolean;
  onEdit: () => void;
  onDelete: (sourceId: string) => void;
  showDelete: boolean;
}

export const PaymentSource: FC<IPaymentSourceProps> = ({
  paymentSource,
  isDefault,
  onEdit,
  onDelete,
  showDelete,
}: IPaymentSourceProps) => {
  return (
    <div className="flex-left-center gap-4px">
      <div className="flex-1 flex-left-center gap-8px px-8px h-40px border-1 border-heavy-20 border-radius-10px text-label-s-med ">
        {paymentSource.card ? (
          <>
            <div className="flex border-radius-4px bg-heavy-1 text-heavy-80 w-32px h-24px">
              {paymentSource.card.brand === 'visa' ? (
                <VisaIcon className="w-full h-full" />
              ) : paymentSource.card.brand === 'mastercard' ? (
                <MastercardIcon className="w-full h-full" />
              ) : null}
            </div>
            <div className="flex-1 w-0 overflow-hidden flex gap-8px">
              <div>{paymentSource.card.masked_number}</div>
              <div>{`${paymentSource.card.expiry_month}/${paymentSource.card.expiry_year}`}</div>
            </div>
          </>
        ) : (
          <>
            <div className="flex-center border-radius-4px bg-heavy-1 text-heavy-80 w-32px h-24px">
              <DocumentChartBarIcon className="icon-16px text-heavy-60" />
            </div>
            <div className="flex-1 w-0 overflow-hidden">{labels.byInvoice}</div>
          </>
        )}
        <div className="border-left-1 border-heavy-20 h-20px ml-auto" />
        <StarIcon className={classNames('icon-12px', isDefault ? 'text-blue-main' : 'text-heavy-20')} />
      </div>
      <div className="w-32px h-32px">
        {paymentSource.card && (
          <Button className="action-button" onClick={onEdit}>
            <PencilIcon className="icon-16px" />
          </Button>
        )}
      </div>
      <div className="w-32px h-32px">
        {showDelete && (
          <Button className="action-button" onClick={() => onDelete(paymentSource.id || '')}>
            <TrashIcon className="icon-16px" />
          </Button>
        )}
      </div>
    </div>
  );
};
