export const CalendarConnections = Object.freeze({
  CALENDAR_INFO_MESSAGE: `You may connect only 1 calendar`, // To increase this limit, contact sales to upgrade your license tier.
  DESCRIPTION: `Connect your calendar so SUMO knows when you're available and updates your calendar as appointments are booked.`,
  DISCONNECT_MESSAGE: `Are you sure you would like to disconnect your calendar?`,
  DISCONNECT_MESSAGE_DESCRIPTION: `Appointments will not be deleted or canceled.`,
  DISCONNECT_TITLE: `Disconnect Calendar`,
  EXCHANGE_CALENDAR_DESCRIPTION: `Exchange Server 2013, 2016, or 2019`,
  EXCHANGE_CALENDAR_TITLE: `Exchange Calendar`,
  GOOGLE_CALENDAR_DESCRIPTION: `Gmail, G Suite, Google Workspace`,
  GOOGLE_CALENDAR_TITLE: `Google Calendar`,
  MICROSOFT_CALENDAR_DESCRIPTION: `Office 365, Outlook.com, Live.com, or Hotmail Calendar`,
  MICROSOFT_CALENDAR_TITLE: `Office 365 Calendar`,
  SELECT_CALENDAR_TITLE: `Select Calendar`,
  TITLE: `Calendar Connections`,
  TOOLTIP: `Connect your Google or Microsoft calendar to SUMO`,
});

export const CookieSettings = Object.freeze({
  AGREE_CLOSE: `Agree and Close`,
  DESCRIPTION: `When you visit a website, it has the ability to store or retrieve certain information on your browser. This information, often in the form of cookies, can be about your preferences, device, or other details. Its primary purpose is to ensure that the website functions as you would expect. While this information does not typically identify you directly, it can enhance your web browsing experience by personalizing content. We understand and value your right to privacy, which is why we provide you with the option to control and customize your cookie preferences. By clicking on the various category headings, you can learn more about each type of cookie and modify our default settings. Please note that blocking certain types of cookies may impact your overall experience on the site and limit the services we can offer you.`,
  DESCRIPTION_FIRST_PART: `When you visit a website, it has the ability to store or retrieve certain information on your browser. This information, often in the form of cookies, can be about your preferences, device, or other details. Its primary purpose is to ensure that the website functions as you would expect.`,
  DESCRIPTION_SECOND_PART: `While this information does not typically identify you directly, it can enhance your web browsing experience by personalizing content. We understand and value your right to privacy, which is why we provide you with the option to control and customize your cookie preferences.`,
  DESCRIPTION_THIRD_PART: `By clicking on the various category headings, you can learn more about each type of cookie and modify our default settings. Please note that blocking certain types of cookies may impact your overall experience on the site and limit the services we can offer you.`,
  FUNCTIONAL_NAME: `Functional Cookies`,
  MANAGE_TITLE: `Manage Consent Preferences`,
  NECESSARY_ONLY: `Strictly Necessary Only`,
  PERFORMANCE_NAME: `Performance Cookies`,
  STRICTLY_NAME: `Strictly Necessary Cookies`,
  TARGETING_NAME: `Targeting Cookies`,
  TITLE: `Cookie Settings`,
  SHOW_ALL: `Show all`,
  HIDE_ALL: `Hide all`,
});

export const Login = Object.freeze({
  GOOGLE_ACCOUNT: `Google Account`,
  GOOGLE_ACCOUNTS_SWITCH: `Switch Google Accounts`,
  GOOGLE_SWITCH: `Login with Google`,
  GOOGLE_SWITCH_DESC: `Change from Microsoft authentication to Google`,
  MICROSOFT_ACCOUNT: `Microsoft Account`,
  MICROSOFT_ACCOUNTS_SWITCH: `Switch Microsoft Accounts`,
  MICROSOFT_SWITCH: `Login with Microsoft`,
  MICROSOFT_SWITCH_DESC: `Change from Google authentication to Microsoft`,
  SWITCH_ACCOUNT_MESSAGE: `Your SUMO1 account will lose access to data only available to you through your original provider. You may also need to re-grant access to integrations and calendars.`,
  SWITCH_DESCRIPTION_PART1: `Change from `,
  SWITCH_DESCRIPTION_PART2: ` authentication to `,
});

export const Role = Object.freeze({
  ROLE_DESCRIPTION: `Your current role`,
});

export const AccountSettings = Object.freeze({
  DATE_FORMAT: `Date Format`,
  LANGUAGE: `Language`,
  NAME: `Name`,
  WELCOME_MESSAGE: `Welcome Message`,
  PHONE_NUMBER: `Phone Number`,
  PROFILE_IMAGE: `Profile Image`,
  PHONE_DETAILS: `Phone Details`,
  PHONE_DETAILS_PLACEHOLDER: `Add additional info or instructions here.\r\nEx: I look forward to meeting. SMS Text me anytime.`,
  TIME_FORMAT: `Time Format`,
  TIME_ZONE: `Time Zone`,
  COUNTRY: `Country`,
});

export const Profile = Object.freeze({
  TITLE: `Profile`,
  ACCOUNT_SETTINGS: `Account Settings`,
  ACCOUNT_DESCRIPTION: `Basic information and settings`,
  LOGIN: `Login`,
  LOGIN_DESCRIPTION: `Setup connected account`,
  MY_ROLE: `My Role`,
  MY_ROLE_DESCRIPTION: `Your current role and permissions`,
  COOKIE_SETTINGS: `Cookie Settings`,
  COOKIE_DESCRIPTION: `Necessary, Performance, Functional, Targeting Cookies`,
  DELETE_ACCOUNT_DESCRIPTION: `Delete your account and all related data from your organization`,
});

export const DeleteAccount = Object.freeze({
  TITLE: `Delete Account`,
  WARNING_DELETE: `Careful, this action is permanent and cannot be undone.`,
  DELETE_ACCOUNT_AND_ORG: `Delete Account and Organization`,
  YES_DELETE: `Yes, delete`,
  DELETE_TO_CONFIRM: `Type <b>Delete</b> to confirm.`,
  DELETE_CONFIRM_WORD: `Delete`,
  DELETE_ACCOUNT_MODAL_DESCRIPTION: `Delete your personal data and all related data from your organization. This action is permanent and cannot be undone.`,
  DELETE_ACCOUNT_AND_ORG_MODAL_DESCRIPTION: `Delete your account and organization. This action is permanent and cannot be undone.`,
  LIST_DELETING_ENTITIES_TITLE: `Deleting your account will do the following`,
  LIST_DELETING_ACCOUNT_AND_ORG_ENTITIES_TITLE: `Deleting your account and organization will`,
  LIST_DELETING_ENTITIES: `Log you out on all devices \\p Delete all personal information \\p Delete all personal integrations`,
  ADDITIONAL_LIST_DELETING_ORG_ENTITIES: `\\p Delete all organization data`,
  TRANSFER_OWNERSHIP_TITLE: `Transfer ownership and delete my account`,
  TRANSFER_OWNERSHIP_DESCRIPTION: `Before you can delete your account, you must transfer ownership to another team member so they can manage the organization.`,
  DELETE_ACCOUNT_AND_ORG_TITLE: `Delete my account and disband organization`,
  DELETE_ACCOUNT_AND_ORG_DESCRIPTION: `if you don’t want to transfer ownership, you can delete your account and disband the organization.`,
  TRANSFER_OWNERSHIP: `Transfer ownership`,
  NEED_INVITE_NEW_USER_TO_ORG: `Invite somebody to you organization and accept the invite`,
});

export const UserSettingsToastsNotifications = Object.freeze({
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST_MESSAGE: `New calendar request fail`,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST_MESSAGE: `New calendar successfully connected!`,
  CONNECT_ZOOM_ERROR_TOAST_MESSAGE: `Zoom connected request fail`,
  CONNECT_ZOOM_SUCCESS_TOAST_MESSAGE: `Zoom connected successfully!`,
  SAVE_USER_SETTINGS_ERROR_TOAST_MESSAGE: `Settings save request fail`,
  SAVE_USER_SETTINGS_SUCCESS_TOAST_MESSAGE: `Your settings successfully saved!`,
  SWITCH_ACCOUNT_SUCCESS_TOAST_MESSAGE: `You successfully switch the account`,
  SWITCH_ACCOUNT_ERROR_TOAST_MESSAGE: `Switch account failed. Please try again later`,
  DELETE_ACCOUNT_ERROR_TOAST_MESSAGE: `Delete account fail`,
});

export const Guide = Object.freeze({
  GET_STARTED: `Get started with Sumo1`,
  START: `Start`,

  BACK: `Back`,
  CLOSE: `Close`,
  LAST: `Finish`,
  NEXT: `Next`,
  NEXT_LABEL_WITH_PROGRESS: `Next (Step {step} of {steps})`,
  OPEN: `Open the dialog`,
  SKIP: `Finish tour`,
  COMPLETE_TOUR: `Complete Tour`,
  GO_TO: `Go to`,
  START_TOUR: `Start a tour`,

  GUIDED_TOUR: `Guided Tour`,
  GUIDED_TOUR_DESCRIPTION: `You can launch the Guided Tour at any time and go through it.`,
  PROFILE_TITLE: `Manage your profile`,
  PROFILE_DESCRIPTION: `You can manage your user profile settings, including language, time zone, view your role permissions, and more.`,
  INTEGRATIONS_TITLE: `Connect your video conferencing`,
  INTEGRATIONS_DESCRIPTION: `SUMO 1 has out-of-box integrations for CRM, Video Conferences, Payment Processors, and more.`,
  AVAILABILITY_TITLE: `Modify your hours of availability`,
  AVAILABILITY_DESCRIPTION: `You may modify your availability or create new availability schedules like "Afterhours" here.`,
  SUMO_INBOX_TITLE: `Install SUMO Meeting Booker`,
  SUMO_INBOX_DESCRIPTION: `SUMO Meeting Booker is a browser extension that 90% of users use daily to bring powerful SUMO1 scheduling features to Gmail and Outlook.`,
  SUMO_INBOX_LINK: `Install SUMO1 Browser Extension`,
  BOOKING_PAGES_TITLE: `Create your booking page`,
  BOOKING_PAGES_DESCRIPTION: `You can create Booking Pages that will be used more permanently.  For example, a Booking Page link for your Email Signature, your Website, and more.`,
  BOOKED_MEETINGS_TITLE: `How to view booked meetings`,
  BOOKED_MEETINGS_DESCRIPTION: `View a list of upcoming and past meetings, including option to cancel, mark no-show, reschedule and more.`,
  SMART_ALERTS_TITLE: `Streamline your schedule with smart alerts`,
  SMART_ALERTS_DESCRIPTION: `Booking Pages include basic alerts, like send a Text Reminder 1 day before the meeting.  Smart Alerts help users setup more sophisticated meeting alerts.`,
  BOOKING_TEMPLATES_TITLE: `Booking Template`,
  BOOKING_TEMPLATES_DESCRIPTION: `You can create Booking Templates (also known as Meeting Types), making it easier to quickly book a specific type of meeting with the right person, at the right location, at the right time.`,
  TEAMS_TITLE: `Manage your teams`,
  TEAMS_DESCRIPTION: `You can create Teams (also known as Resource Pools) to be auto-assigned to a meeting based on an assignment mode, such as Round-Robin.`,
  JOURNEY_BUILDER_TITLE: `Schedule meetings instantly`,
  JOURNEY_BUILDER_DESCRIPTION: `Journey Builder is a tool you can use to create a more complex user engagement experience beyond a simple booking page.`,
  USERS_TITLE: `Invite and manage your users`,
  USERS_DESCRIPTION: `You can quickly invite users to use SUMO1 and activate or inactivate users.`,
  ROLES_TITLE: `Manage your teammates' permissions`,
  ROLES_DESCRIPTION: `You can manage user roles and permissions to ensure they have the proper CRUD access (create, read, update, delete) required.`,
  BILLING_TITLE: `Choose plan and set up billing`,
  BILLING_DESCRIPTION: `You can manage your SUMO1 subscription yourself, including adding more licenses as needed.`,
});
