import { t } from '../../../i18n/i18n';

export default {
  back: t('Common:BACK'),
  messageUser: t('QuickSetupSetupAvailabilityStep:MESSAGE_USER'),
  next: t('Common:NEXT'),
  setupDaysInputLabel: t('QuickSetupSetupAvailabilityStep:SETUP_DAYS_INPUT_LABEL'),
  setupHoursInputLabel: t('QuickSetupSetupAvailabilityStep:SETUP_HOURS_INPUT_LABEL'),
  stepMessage: t('QuickSetupSetupAvailabilityStep:STEP_MESSAGE'),
  timeInValidText: t('Availability:TIME_INVALID_TEXT'),
  titleUser: t('QuickSetupSetupAvailabilityStep:TITLE_USER'),
  minimumDaysErrorText: t('QuickSetupSetupAvailabilityStep:MINIMUM_DAYS_ERROR_TEXT'),
};
