import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';
import { SmartAlertModalRequest } from './types';

export const cloneModalActions = modalsActionsFactory(ModalName.BOOKING_TEMPLATE_CLONE_MODAL_NAME);
export const cloneModalSelectors = modalsSelectorsFactory(ModalName.BOOKING_TEMPLATE_CLONE_MODAL_NAME);

export const editCustomFieldModalActions = modalsActionsFactory(ModalName.EDIT_CUSTOM_FIELD_MODAL_NAME);
export const editCustomFieldModalSelectors = modalsSelectorsFactory(ModalName.EDIT_CUSTOM_FIELD_MODAL_NAME);

export const deleteBookingTemplatesModalActions = modalsActionsFactory(ModalName.DELETE_BOOKING_TEMPLATES_MODAL_NAME);
export const deleteBookingTemplatesModalSelectors = modalsSelectorsFactory(
  ModalName.DELETE_BOOKING_TEMPLATES_MODAL_NAME
);

export const smartAlertModalActions = modalsActionsFactory<SmartAlertModalRequest>(ModalName.SMART_ALERT_MODAL_NAME);
export const smartAlertModalSelectors = modalsSelectorsFactory<SmartAlertModalRequest>(
  ModalName.SMART_ALERT_MODAL_NAME
);
