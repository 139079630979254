import dayjs from 'dayjs';
import { WhereInput, LocationType, TimeFormat, InPersonType } from '../../API';
import { timeZoneItem } from '../../services/TimeZoneService';
import { NotificationTypes } from './types';
import {
  DEFAULT_CANCELATION_BODY,
  DEFAULT_CANCELATION_SUBJECT,
  DEFAULT_CONFIRMATION_BODY,
  DEFAULT_CONFIRMATION_SUBJECT,
  DEFAULT_FOLLOW_UP_BODY,
  DEFAULT_FOLLOW_UP_SUBJECT,
  DEFAULT_REMINDER_BODY,
  DEFAULT_REMINDER_SUBJECT,
  DEFAULT_RESCHEDULE_BODY,
  DEFAULT_RESCHEDULE_SUBJECT,
  DEFAULT_SMS_TEXT,
} from './constants';
import { FILE_COPY_POSTFIX } from '../../types/constants';

export const generateWhereLocationTypeToggle = (where: WhereInput, locationType: LocationType) => {
  const locationTypesState = where?.locationTypes || [];
  const defaultLocationTypeState = where?.defaultLocationType || null;

  const updatedLocationTypes = locationTypesState.includes(locationType)
    ? locationTypesState.filter((type) => type !== locationType)
    : [...locationTypesState, locationType];

  // prevent deactivating the only value
  const locationTypes = updatedLocationTypes.length ? updatedLocationTypes : locationTypesState;
  // update default location type if selected value is deactivated
  const defaultLocationType = locationTypes.includes(defaultLocationTypeState)
    ? defaultLocationTypeState
    : locationTypes[0];

  return { ...where, locationTypes, defaultLocationType } as WhereInput;
};

export const getDefaultSubjectByNotificationType = (type: NotificationTypes) => {
  switch (type) {
    case NotificationTypes.CONFIRMATION:
      return DEFAULT_CONFIRMATION_SUBJECT;
    case NotificationTypes.RESCHEDULE:
      return DEFAULT_RESCHEDULE_SUBJECT;
    case NotificationTypes.REMINDER:
      return DEFAULT_REMINDER_SUBJECT;
    case NotificationTypes.CANCELATION:
      return DEFAULT_CANCELATION_SUBJECT;
    case NotificationTypes.FOLLOW_UP:
      return DEFAULT_FOLLOW_UP_SUBJECT;
    default:
      return DEFAULT_CONFIRMATION_SUBJECT;
  }
};

export const getDefaultBodyByNotificationType = (type: NotificationTypes) => {
  switch (type) {
    case NotificationTypes.CONFIRMATION:
      return DEFAULT_CONFIRMATION_BODY;
    case NotificationTypes.RESCHEDULE:
      return DEFAULT_RESCHEDULE_BODY;
    case NotificationTypes.REMINDER:
      return DEFAULT_REMINDER_BODY;
    case NotificationTypes.CANCELATION:
      return DEFAULT_CANCELATION_BODY;
    case NotificationTypes.FOLLOW_UP:
      return DEFAULT_FOLLOW_UP_BODY;
    case NotificationTypes.SMS:
      return DEFAULT_SMS_TEXT;
    default:
      return DEFAULT_CONFIRMATION_BODY;
  }
};

export const generatePublicFileKey = (filePath: string | null | undefined) =>
  filePath ? filePath.substring(filePath.lastIndexOf('public/') + 7) : '';

export const generatePublicFileKeyCopy = (filePath: string | null | undefined) => {
  const fileName = generatePublicFileKey(filePath);
  if (!fileName) {
    return '';
  }
  return fileName.indexOf('.') ? fileName.replace('.', FILE_COPY_POSTFIX + '.') : fileName + FILE_COPY_POSTFIX;
};

export const assignTime = (timeZoneList: timeZoneItem[], UTC: number, timeFormat: TimeFormat | null | undefined) =>
  timeZoneList.map((timeZone) => ({
    ...timeZone,
    value: timeZone.timeZone,
    time: dayjs(UTC)
      .tz(timeZone.timeZone)
      .format(timeFormat !== TimeFormat.HOUR_12 ? 'HH:mm' : 'hh:mm A'),
  }));

export const isLocationUnassigned = (where: WhereInput | null | undefined) =>
  where?.locationTypes?.includes(LocationType.IN_PERSON) &&
  where.inPersonType === InPersonType.LOCATION &&
  !where.locations?.length;
