import { t } from '../../../i18n/i18n';

export default {
  unavailable: t('Common:UNAVAILABLE'),
  timeInValidText: t('Availability:TIME_INVALID_TEXT'),
  timeOverlappingText: t('Availability:TIME_INTERVAL_OVERLAPPING'),
  availableHours: t('Availability:AVAILABLE_HOURS'),
  activeOn: t('Availability:ACTIVE_ON'),
  activeOnPlaceholder: t('Availability:ACTIVE_ON_PLACEHOLDER'),
  location: t('Locations:LOCATION'),
  selectLocation: t('Locations:SELECT_LOCATION'),
  bookingPages: t('Availability:BOOKING_PAGES'),
  bookingTemplates: t('Availability:BOOKING_TEMPLATES'),
};
