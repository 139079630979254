import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { ArrowLeftIcon, ArrowRightIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { SectionHeader } from '../../components/common';
import labels from './labels';
import {
  ALLOWED_PAY_BY_INVOICE_OF_AMOUNT,
  billingActions,
  billingSelectors,
  CHARGEBEE_MONTHLY_TERM,
  CHARGEBEE_YEARLY_TERM,
  getPriceByQuantityTiersOneLicense,
  getTiersByTerm,
  getTotalAmount,
  PaymentMethod,
  Tier,
} from '../../store/billing';
import { authenticationSelectors } from '../../store/authentication';
import { Path } from '../../routing';
import { PayByCard, PayByInvoice } from '../../components/billing';
import { navigationService } from '../../services/NavigationService';
import { classNames } from 'primereact/utils';

export const BuyLicensesPage: React.FC = () => {
  const dispatch = useDispatch();

  const licensesAssigned = useSelector(authenticationSelectors.selectAssigned);
  const itemPrices = useSelector(billingSelectors.selectItemPrices);
  const currencyLabel = useSelector(billingSelectors.selectCurrencyLabel);
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);

  const [quantity, setQuantity] = useState<number>(licensesAssigned);
  const [term, setTerm] = useState(CHARGEBEE_MONTHLY_TERM);
  const [amount, setAmount] = useState(0);
  const [isSmallInvoiceAmount, setIsSmallInvoiceAmount] = useState(false);
  const [isPayStep, setPayStep] = useState(false);
  const [isShowBackButton, setShowBackButton] = useState(true);
  const [processCheckoutAbortController, setProcessCheckoutAbortController] = useState<AbortController | undefined>();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.CARD);

  const tiers: Tier[] = getTiersByTerm(itemPrices, term);

  useEffect(() => {
    dispatch(billingActions.getItemPricesRequest());
  }, []);

  useEffect(() => {
    setQuantity(licensesAssigned);
  }, [licensesAssigned]);

  useEffect(() => {
    const amount = getTotalAmount(quantity, tiers);
    setAmount(amount);
    setIsSmallInvoiceAmount(paymentMethod === PaymentMethod.INVOICE && amount < ALLOWED_PAY_BY_INVOICE_OF_AMOUNT);
  }, [quantity, term, paymentMethod]);

  const changeQuantity = (type: 'plus' | 'minus') => {
    setQuantity((prevState) => {
      const newValue = type === 'plus' ? prevState + 1 : prevState - 1;
      if (newValue <= 0) return 0;
      return newValue;
    });
  };

  const handleBack = () => {
    if (isPayStep) {
      if (processCheckoutAbortController) {
        processCheckoutAbortController.abort();
      }

      setPayStep(false);
    } else {
      navigationService.navigateTo(navigationService.getPreviousPath() || Path.Billing);
    }
  };

  const changePaymentMethod = (newPaymentMethod: PaymentMethod) => {
    setPaymentMethod(newPaymentMethod);
  };

  const handlePurchase = () => {
    setPayStep(true);

    if (paymentMethod === PaymentMethod.CARD) {
      const abortController = new AbortController();
      setProcessCheckoutAbortController(abortController);
    }
  };

  const priceOneLicenseForMonth = getPriceByQuantityTiersOneLicense(itemPrices, CHARGEBEE_MONTHLY_TERM, quantity);
  const priceOneLicenseForYear = getPriceByQuantityTiersOneLicense(itemPrices, CHARGEBEE_YEARLY_TERM, quantity);
  const discountOnPaymentForYear = (priceOneLicenseForMonth - priceOneLicenseForYear) * quantity * 12;

  return (
    <>
      {isShowBackButton && (
        <Button
          className={`button-blue button-text-line mb-16px ${
            isPayStep && paymentMethod === PaymentMethod.CARD ? 'ml-16px' : ''
          }`}
          text
          onClick={handleBack}
        >
          <ArrowLeftIcon className="icon-18px" />
          <div className="flex-1 pl-8px">{labels.back}</div>
        </Button>
      )}

      {!isPayStep && (
        <div className="sumo-card-bg flex flex-column pt-16px">
          <SectionHeader title={labels.title} hideButton />

          <div className="sumo-card flex flex-column gap-24px py-24px px-20px">
            <div className="flex flex-column gap-12px">
              <div className="text-body-s-reg text-heavy-80">1. {labels.licenses}</div>
              <div className="flex-left-center gap-16px">
                <Button
                  onClick={() => changeQuantity('minus')}
                  className="h-40px"
                  icon={<MinusIcon className="icon-18px" />}
                  outlined
                />
                <InputNumber
                  inputClassName="w-60px"
                  inputId="quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.value || 0)}
                  min={0}
                  className={!quantity || quantity < licensesAssigned ? 'p-invalid' : ''}
                />
                <Button
                  onClick={() => changeQuantity('plus')}
                  className="h-40px"
                  icon={<PlusIcon className="icon-18px" />}
                  outlined
                />
              </div>
              {quantity < licensesAssigned && (
                <div className="w-335px text-body-s-reg text-tomato-main">
                  {`${labels.licenseMessagePart1} ${licensesAssigned} ${labels.licenseMessagePart2}${
                    isLicenseActive ? labels.licenseMessagePart3 : ''
                  }.`}
                </div>
              )}
            </div>

            <div className="flex flex-column gap-12px">
              <div className="text-body-s-reg text-heavy-80">2. {labels.term}</div>
              <div
                className="sumo-card action-card flex gap-10px p-12px border-1 border-heavy-20 w-335px"
                onClick={() => setTerm(CHARGEBEE_MONTHLY_TERM)}
              >
                <div className="flex-none flex pt-2px">
                  <RadioButton inputId={CHARGEBEE_MONTHLY_TERM} checked={term === CHARGEBEE_MONTHLY_TERM} />
                </div>
                <div className="flex flex-column gap-8px pt-2px">
                  <div className="text-title-xs-med">{labels.monthly}</div>
                  <div className="text-body-s-reg text-heavy-60">
                    {currencyLabel}
                    {priceOneLicenseForMonth} {labels.berUserMonth}
                  </div>
                </div>
              </div>
              <div
                className="sumo-card action-card flex-between-center p-12px border-1 border-heavy-20 w-335px"
                onClick={() => setTerm(CHARGEBEE_YEARLY_TERM)}
              >
                <div className="flex gap-10px pt-2px">
                  <div className="flex-none flex">
                    <RadioButton inputId={CHARGEBEE_YEARLY_TERM} checked={term === CHARGEBEE_YEARLY_TERM} />
                  </div>
                  <div className="flex flex-column gap-8px">
                    <div className="text-title-xs-med">{labels.annually}</div>
                    <div className="text-body-s-reg text-heavy-60">
                      {currencyLabel}
                      {priceOneLicenseForYear} {labels.berUserMonth}
                    </div>
                  </div>
                </div>
                <div className="flex-center h-30px bg-cyan-main text-primary-white text-label-s-med border-radius-4px px-10px">
                  {labels.save} {currencyLabel}
                  {discountOnPaymentForYear}
                </div>
              </div>
            </div>

            <div className="flex flex-column gap-12px">
              <div className="text-body-s-reg text-heavy-80">3. {labels.paymentMethod}</div>
              <div className="flex-left-center">
                <RadioButton
                  inputId={PaymentMethod.CARD}
                  value={PaymentMethod.CARD}
                  checked={paymentMethod === PaymentMethod.CARD}
                  onChange={(e) => changePaymentMethod(e.target.value)}
                />
                <label htmlFor={PaymentMethod.CARD} className="text-title-xs-med px-10px cursor-pointer">
                  {labels.card}
                </label>
              </div>
              <div className="flex-left-center">
                <RadioButton
                  inputId={PaymentMethod.INVOICE}
                  value={PaymentMethod.INVOICE}
                  checked={paymentMethod === PaymentMethod.INVOICE}
                  onChange={(e) => changePaymentMethod(e.target.value)}
                />
                <label htmlFor={PaymentMethod.INVOICE} className="text-title-xs-med px-10px cursor-pointer">
                  {labels.byInvoice}
                </label>
              </div>
            </div>

            <div className="text-body-s-reg">
              <span>{labels.buyLicensesMessage}</span>
              <span className={classNames('font-bold', isSmallInvoiceAmount && 'text-tomato-main')}>
                {` ${currencyLabel}${amount} `}
              </span>
              <span>{term === CHARGEBEE_YEARLY_TERM ? labels.annually : labels.monthly}</span>
            </div>

            {isSmallInvoiceAmount && (
              <div className="text-body-s-reg text-tomato-main -mt-16px">
                {`${labels.payByInvoiceMinValueOrderTooltip} ${currencyLabel}${ALLOWED_PAY_BY_INVOICE_OF_AMOUNT}.`}
              </div>
            )}

            <div className="flex gap-24px">
              <Button
                onClick={handlePurchase}
                disabled={quantity < licensesAssigned || isSmallInvoiceAmount}
                className="flex-center gap-8px button-xl min-w-120px"
              >
                <div>{labels.next}</div>
                <ArrowRightIcon className="icon-18px" />
              </Button>
              <Button onClick={handleBack} label={labels.cancel} outlined className="button-xl min-w-120px" />
            </div>
          </div>
        </div>
      )}

      {isPayStep && paymentMethod === PaymentMethod.CARD && (
        <PayByCard
          quantity={quantity}
          term={term}
          isShowBackButton={(isShow: boolean) => setShowBackButton(isShow)}
          abortController={processCheckoutAbortController}
        />
      )}

      {isPayStep && paymentMethod === PaymentMethod.INVOICE && (
        <PayByInvoice quantity={quantity} term={term} handleBack={handleBack} />
      )}
    </>
  );
};
