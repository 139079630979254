import { useDispatch, useSelector } from 'react-redux';
import { VideoConferenceType } from '../../../API';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import labels from './labels';
import { Tooltip } from 'primereact/tooltip';
import { StarIcon as StarIconSolid } from '@heroicons/react/20/solid';

type DefaultVideoConferenceProps = {
  videoConferenceType: VideoConferenceType;
  hasLabel?: boolean;
};
export const DefaultVideoConference = ({ videoConferenceType, hasLabel }: DefaultVideoConferenceProps) => {
  const dispatch = useDispatch();
  const defaultVideoConference = useSelector(userSettingsSelectors.selectDefaultVideoIntegration);

  const handleDefaultClick = (videoConferenceType: VideoConferenceType) => {
    if (videoConferenceType !== defaultVideoConference) {
      dispatch(userSettingsActions.updateUserSettings({ defaultVideoIntegration: videoConferenceType }));
      dispatch(userSettingsActions.saveUserSettingsRequest({ showToast: true }));
    }
  };

  return hasLabel ? (
    <div className="flex-center gap-4px cursor-pointer" onClick={() => handleDefaultClick(videoConferenceType)}>
      <StarIconSolid
        width={18}
        height={18}
        className={defaultVideoConference === videoConferenceType ? 'text-blue-main' : 'text-heavy-40'}
      />
      <span className="text-heavy-80 text-label-xs-reg">{labels.default}</span>
    </div>
  ) : (
    <>
      <Tooltip target={`.default-${videoConferenceType}`} />
      <div
        className={`default-${videoConferenceType} cursor-pointer w-18px h-18px`}
        data-pr-position="right"
        data-pr-my="left+6 center-1"
        data-pr-tooltip={labels.defaultDescription}
        onClick={() => handleDefaultClick(videoConferenceType)}
      >
        <StarIconSolid
          width={18}
          height={18}
          className={defaultVideoConference === videoConferenceType ? 'text-blue-main' : 'text-heavy-40'}
        />
      </div>
    </>
  );
};
