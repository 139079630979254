import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { MenuItem } from 'primereact/menuitem';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { authenticationSelectors } from '../../../store/authentication';
import { promoSelectors } from '../../../store/promo';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import labels from './labels';
import { Menu } from 'primereact/menu';
import { menuSelectors } from '../../../store/menu';
import { classNames } from 'primereact/utils';
import { pathToMenuMapping } from '../../../routing/pathes';

export const NavigationMenu = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isOpsConsole = useSelector(authenticationSelectors.selectIsOpsConsole);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const isViewAsUserMode = useSelector(authenticationSelectors.selectIsViewAsUser);
  const isSumoONEAdmin = useSelector(authenticationSelectors.selectIsSumo1Admin);
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);
  const isMenuOpened = useSelector(userSettingsSelectors.selectIsMenuOpened);
  const isGuideRunning = useSelector(promoSelectors.selectIsGuideRunning);
  const navigationMenuItems = useSelector(menuSelectors.selectNavigationMenuItems);
  const hasPeopleSection = useSelector(menuSelectors.selectHasPeopleSection);

  const [meetingsOpen, setMeetingsOpen] = useState(true);
  const [toolsOpen, setToolsOpen] = useState(true);
  const [peopleOpen, setPeopleOpen] = useState(true);
  const [organizationOpen, setOrganizationOpen] = useState(true);
  const titleClass = 'p-0 pb-8px text-label-s-med text-heavy-60';

  const getMenuItems = (ids: string[], visible = true) =>
    navigationMenuItems
      .filter((item) => ids.includes(item.id || ''))
      .map((item) => ({
        ...item,
        visible,
        template: itemTemplate,
        data: React.createElement(item.data, { className: 'icon-20px' }),
      }));

  const isMenuItemSelected = (itemUrl: string | undefined) => {
    if (!itemUrl) {
      return false;
    }

    const itemPaths = [...(pathToMenuMapping[itemUrl] || []), itemUrl];

    return itemPaths.some((path) => location.pathname.startsWith(path.split(':')[0]));
  };

  const handleItemClick = () => {
    if (isMenuOpened && !isGuideRunning) {
      dispatch(userSettingsActions.setIsMenuOpened(false));
    }
  };

  const getTitleTemplate = (isOpen: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>) => {
    const MenuTitleTemplate: MenuItem['template'] = (item) => (
      <div
        className="flex-between-center pb-8px text-label-s-med text-heavy-60 hover-text-heavy-100 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>{item.label}</div>
        {isOpen ? <ChevronUpIcon className="icon-12px" /> : <ChevronDownIcon className="icon-12px" />}
      </div>
    );
    return MenuTitleTemplate;
  };

  const itemTemplate: MenuItem['template'] = (item) => (
    <Link
      to={item.url || ''}
      onClick={handleItemClick}
      id={item.id}
      className={classNames(
        'text-title-xs-med cursor-pointer',
        isOpsConsole ? 'hover-text-primary-white' : 'hover-text-heavy-80',
        isMenuItemSelected(item.url) ? (isOpsConsole ? 'text-primary-white' : 'text-heavy-100') : 'text-heavy-60'
      )}
    >
      <div className="flex-left-center gap-10px py-8px">
        {item.data || <i className={item.icon}></i>}
        <div>{item.label}</div>
      </div>
    </Link>
  );

  const spaceTemplate: MenuItem['template'] = () => <div className="h-24px"></div>;

  const getOpsConsoleModel = () =>
    [
      ...getMenuItems(['open-my-sumo1-link']),
      { template: spaceTemplate },
      {
        label: labels.supportTitle,
        className: titleClass,
        items: getMenuItems(['accounts-link', 'ops-console-link', 'users-link']),
      },
      { template: spaceTemplate },
      {
        label: labels.operationsTitle,
        className: titleClass,
        items: getMenuItems(['staff-link']),
      },
      { template: spaceTemplate },
    ] as MenuItem[];

  const getLicenseExpiredModel = () =>
    [
      ...(isSumoONEAdmin && !isViewAsUserMode
        ? [...getMenuItems(['ops-console-link']), { template: spaceTemplate }]
        : []),

      ...(isSuperAdmin
        ? [
            {
              label: labels.peopleTitle,
              className: titleClass,
              items: getMenuItems(['users-link']),
            },
            { template: spaceTemplate },
            {
              label: labels.organizationTitle,
              className: titleClass,
              items: getMenuItems(['billing-link']),
            },
            { template: spaceTemplate },
          ]
        : []),
    ] as MenuItem[];

  const getPrivateModel = () =>
    [
      ...(isSumoONEAdmin && !isViewAsUserMode
        ? [...getMenuItems(['ops-console-link']), { template: spaceTemplate }]
        : []),

      {
        label: labels.meetingsTitle,
        template: getTitleTemplate(meetingsOpen, setMeetingsOpen),
      },

      ...getMenuItems(['booking-pages-link', 'booked-meetings-link'], meetingsOpen),

      { template: spaceTemplate },

      {
        label: labels.toolsTitle,
        template: getTitleTemplate(toolsOpen, setToolsOpen),
      },

      ...getMenuItems(
        ['booking-templates-link', 'smart-alerts-link', 'journey-builder-link', 'integration-link'],
        toolsOpen
      ),

      { template: spaceTemplate },

      ...(hasPeopleSection
        ? [
            {
              label: labels.peopleTitle,
              template: getTitleTemplate(peopleOpen, setPeopleOpen),
            },

            ...getMenuItems(['users-link', 'teams-link'], peopleOpen),

            { template: spaceTemplate },
          ]
        : []),

      {
        label: labels.organizationTitle,
        template: getTitleTemplate(organizationOpen, setOrganizationOpen),
      },

      ...getMenuItems(['roles-link', 'workspaces-link', 'locations-link', 'billing-link'], organizationOpen),
    ] as MenuItem[];

  const getModel = () => {
    if (isOpsConsole) {
      return getOpsConsoleModel();
    }
    if (isLicenseActive === false) {
      return getLicenseExpiredModel();
    }
    return getPrivateModel();
  };

  return <Menu model={getModel()} className="w-full p-0 border-none" />;
};
