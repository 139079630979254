import { ViewColumnsIcon } from '@heroicons/react/24/outline';
import { InputSwitch } from 'primereact/inputswitch';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SmartAlertTemplate } from '../../../API';
import { TEMPLATES } from '../../../pages/smartAlerts/constants';
import { Path } from '../../../routing';
import { navigationService } from '../../../services/NavigationService';
import { AppDispatch } from '../../../store/rootStore';
import {
  DEFAULT_SMART_ALERT_TEMPLATE,
  smartAlertsActions,
  smartAlertsSelectors,
  SmartTypes,
} from '../../../store/smartAlerts';
import { userSettingsSelectors } from '../../../store/userSettings';
import { SmartAlertCard } from '../smartAlertCard/SmartAlertCard';
import labels from './labels';

export const SmartAlertPreSelect = () => {
  const dispatch = useDispatch<AppDispatch>();
  const customTemplates = useSelector(smartAlertsSelectors.selectSmartAlertTemplatesForCurrentUser);
  const isReadTemplate = useSelector(userSettingsSelectors.selectSmartAlertTemplatesRead);
  const [templates, setTemplates] = useState([...customTemplates, ...TEMPLATES]);
  const [isHideSystemTemplates, setIsHideSystemTemplates] = useState(false);

  const handleUseTemplate = (template: SmartAlertTemplate, smartAlertId: string) => {
    dispatch(smartAlertsActions.setBookingPageIds([]));
    dispatch(smartAlertsActions.setBookingTemplateIds([]));
    dispatch(
      smartAlertsActions.updateSmartAlert({
        name: template.name,
        icon: template.icon,
        isHide: template.isHide,
        // shareWith: template.shareWith,
        whenSend: template.whenSend,
        whomSend: template.whomSend,
      })
    );
    const path = Path.EditSmartAlert.replace(':smartType', SmartTypes.TEMPLATE).replace(':smartAlertId', smartAlertId);
    navigationService.navigateTo(path);
  };

  useEffect(() => {
    setTemplates(isHideSystemTemplates ? customTemplates : [...customTemplates, ...TEMPLATES]);
  }, [isHideSystemTemplates]);

  return (
    <div className="flex flex-column gap-24px">
      <div className="text-body-lg-reg text-heavy-60 pt-8px">{labels.description}</div>
      <div className="w-12 lg:w-6">
        <div
          className="sumo-card sumo-create-card action-card flex-center h-170px"
          onClick={() => handleUseTemplate(DEFAULT_SMART_ALERT_TEMPLATE, 'new')}
        >
          {labels.createCustomTitle}
        </div>
      </div>

      <div className="flex flex-column">
        <div className="flex flex-row p-8px text-label-s-med text-heavy-60">
          <div className="flex flex-row flex-left-center gap-6px">
            <ViewColumnsIcon className="icon-20px" />
            <div>{labels.templatesLabel}</div>
          </div>
          {customTemplates.length ? (
            <div className="flex flex-row flex-left-center gap-6px ml-auto">
              <InputSwitch
                inputId="hideSystemTemplates"
                checked={isHideSystemTemplates}
                onChange={(e) => setIsHideSystemTemplates(!!e.value)}
              />
              <label htmlFor="hideSystemTemplates" className="cursor-pointer">
                {labels.hideSystemTemplates}
              </label>
            </div>
          ) : null}
        </div>
        {!!templates.length && isReadTemplate ? (
          <div className="grid -m-8px">
            {templates.map((template) => (
              <div className="col-12 lg:col-6" key={template.id}>
                <SmartAlertCard
                  template={template}
                  useTemplate={() => handleUseTemplate(template, 'new')}
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};
