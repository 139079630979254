export const PublicBookingPage = Object.freeze({
  HOST: `Anfitrión`,
  HOST_PHONE: `Teléfono anfitrión`,
  EDIT_BUTTON: `Editar`,
  MORE: `Más`,
  TIME_ZONE_LABEL: `Zona horaria`,
  NAME_FIELD_LABEL: `Nombre`,
  EMAIL_FIELD_LABEL: `Correo electrónico`,
  INVITE_OTHERS_FIELD_LABEL: `Invitar a otros`,
  INVITE_OTHERS_FIELD_PLACEHOLDER: `Invita a otros ingresando sus direcciones de correo electrónico separadas por comas`,
  NOTE_FOR_ATTENDEES_FIELD_LABEL: `Nota para los asistentes`,
  NOTE_FOR_ATTENDEES_FIELD_PLACEHOLDER: `Ingresa una nota para todos los asistentes`,
  NO_USERS_AVAILABLE_LABEL: `No hay usuarios disponibles a la hora seleccionada. Por favor, seleccione otra hora.`,
  DEACTIVATED_LABEL: `El anfitrión ha sido desactivado. Por favor, solicite un nuevo enlace de reserva.`,
  CANCEL_TYPE_LABEL: `¿Por qué cancelar?`,
  CANCEL_REASON_LABEL: `Motivo de cancelación:`,
  IN_PERSON_LABEL: `En persona`,
  PHONE_LABEL: `Teléfono`,
  GOOGLE_MEET: `Google Meet`,
  ZOOM: `Zoom`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  ERROR: `Error`,
  BACK_TO_HOME: `Volver a casa`,
  VIDEO_CONFERENCE_LABEL: `Videoconferencia`,
  SLOTS_NO_FOUND: `Ranuras no encontradas`,
  VIEW_NEXT_MONTH: `Ver el próximo mes`,
  ALLOW_SMS: `¿Recibir recordatorios de citas por mensaje de texto?`,
  BOOKING_PAGE_EXPIRED: `Esta página de reserva ha caducado. Por favor, solicite un nuevo enlace de reserva.`,
  PERSONAL_INFO: `Información personal`,
  ATTENDEES: `Asistentes`,
  ATTENDEE_EMAIL: `Correo electrónico del asistente`,
  ADD_ATTENDEES: `Agregar asistentes`,
  YOUR_ANSWERS: `Tus respuestas`,
  MANAGE_BOOKING: `Gestionar reserva`,
  MODIFY_BOOKING: `Modificar reserva`,
  CANCEL_POLICY: `Política de cancelación`,
  LOCATIONS_PLACEHOLDER: `Introduzca dirección`,
  LOADING_LABEL_1: `Creando reunión`,
  LOADING_LABEL_2: `Sincronizando calendarios`,
  LOADING_LABEL_3: `Enviando notificaciones`,
  MILES: ` millas`,
  KILOMETERS: ` kilómetros`,
  LEARN_MORE: `Aprende más`,
  LOCATION_ACCESS_TOOLTIP: `No se puede acceder a tu ubicación`,
});

export const BookedMeetings = Object.freeze({
  ADD_NOTE: `Agregar nota`,
  ATTENDEES: `Asistentes`,
  CANCELED: 'Cancelado',
  CANCEL_MEETING_TITLE: `Cancelar reunión`,
  CANCEL_MEETING_DESCRIPTION: `¿Por qué cancelar?`,
  CANCEL_REASON: `Razón de la cancelación:`,
  DETAILS: `Detalles`,
  BOOKED: `Reservado`,
  BOOKING_PAGE: `Página de reservas`,
  BOOKING_PAGE_PLACEHOLDER: `Seleccionar página de reservas`,
  EDIT_ATTENDEE_LIMIT: `Editar límite de asistentes`,
  GOOGLE_MEET: `Google Meet`,
  GUEST: `Invitado`,
  GUEST_ANSWERS: `Respuestas de invitados`,
  HOSTS: `Anfitriones`,
  IN_PERSON: `En persona`,
  LOCATION_TITLE: `Ubicación`,
  NO_SCHEDULED_MEETINGS: `No tienes reuniones reservadas`,
  NO_LOCATION_GIVEN: `No se ha dado ubicación`,
  NO_SHOW_LABEL: `Ausente`,
  MARK_AS_NO_SHOW: `Marcar como ausente`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART1: `¿Estás seguro de que quieres marcar a`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART2: `como ausente?`,
  MARK_AS_NO_SHOW_TOOLTIP: `La reunión aún no ha comenzado.`,
  MEETING: `Reunión`,
  MEETINGS: `Reuniones`,
  INTERNAL_MEETING_NOTES: `Nota interna de la reunión`,
  INTERNAL_MEETING_NOTES_DESC: `Solo el anfitrión verá esta nota`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  NOTE_FROM_INVITEE: `Nota del invitado`,
  NO_ANSWERS: `Sin respuestas`,
  NO_ATTENDEES: `Sin asistentes`,
  NO_NOTE: `Sin nota`,
  PAST: `Pasado`,
  PHONE_CALL: `Llamada telefónica`,
  RESCHEDULE: `Reprogramar`,
  REASON_UNEXPECTED: `Conflicto inesperado`,
  REASON_INVITE: `Necesito invitar a otros`,
  REASON_TIME: `Necesito tiempo para preparar`,
  REASON_OTHER: `Otro`,
  STATUS: `Estado`,
  STATUS_PLACEHOLDER: `Seleccionar estado`,
  TEAM: `Equipo`,
  TITLE: `Reuniones reservadas`,
  THIS_MONTH: `Este mes`,
  UNDO_NO_SHOW: `Deshacer ausente`,
  UPCOMING: `Próximas`,
  ZOOM: `Zoom`,
  VIDEO_CONFERENCE: `Videoconferencia`,
  WORKSPACE: `Espacio de trabajo`,
});

export const BookedMeetingsToastsNotifications = Object.freeze({
  GET_SCHEDULED_MEETINGS_ERROR_TOAST: `Error al obtener la solicitud de eventos programados`,
  CANCEL_SCHEDULED_MEETING_ERROR_MESSAGE: `Error en la solicitud de cancelación de evento`,
  CANCEL_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Solicitud de cancelación de evento exitosa`,
  UPDATE_SCHEDULED_MEETING_ERROR_TOAST: `Error en la solicitud de actualización de evento`,
  DELETE_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Solicitud de eliminación de evento exitosa`,
  DELETE_SCHEDULED_MEETING_ERROR_MESSAGE: `Error en la solicitud de eliminación de evento`,
});
