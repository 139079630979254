import { handleAPIRequest } from '../utils/reduxUtils';
import {
  ChargeeBeeResponse,
  ItemPrices,
  BillingGetItemPricesResponse,
  DownloadResponse,
  updateCustomerRequest,
  BillingChangeSeatsOrTermRequestPayload,
} from './types';
import { API_PUBLIC } from '../../types/constants';
import {BillingCalculateTaxesRequest} from "../../generated-sources/internal-api/models/BillingCalculateTaxesRequest";
import {BillingCalculateTaxesResponse} from "../../generated-sources/internal-api/models/BillingCalculateTaxesResponse";
import {
  BillingDeletePaymentSourceRequest
} from "../../generated-sources/internal-api/models/BillingDeletePaymentSourceRequest";
import {
  BillingDeletePaymentSourceResponse
} from "../../generated-sources/internal-api/models/BillingDeletePaymentSourceResponse";
import {BillingRepeatPaymentResponse} from "../../generated-sources/internal-api/models/BillingRepeatPaymentResponse";
import {
  BillingUpdatePaymentMethodResponse
} from "../../generated-sources/internal-api/models/BillingUpdatePaymentMethodResponse";
import {
  BillingUpdatePaymentMethodRequest
} from "../../generated-sources/internal-api/models/BillingUpdatePaymentMethodRequest";
import {
  BillingChangeSeatsOrTermResponse
} from "../../generated-sources/internal-api/models/BillingChangeSeatsOrTermResponse";

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const cancelSubscription = async (): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/billing/cancelSubscription`, {});
};

export const changeSeatsOrTerm = async (body: BillingChangeSeatsOrTermRequestPayload): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/billing/changeSeatsOrTerm`, body);
};

export const deleteScheduledChange = async (scheduledChangeId: string): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/deletePaymentScheduledChange`, { scheduledChangeId });
};

export const downloadInvoice = async (invoiceId?: string): Promise<DownloadResponse> => {
  return handleAPIRequest<DownloadResponse>(`${API_PUBLIC}/billing/getInvoiceDownloadUrl`, { invoiceId });
};

export const getItemPrices = async (): Promise<ItemPrices[]> => {
  const response = await handleAPIRequest<BillingGetItemPricesResponse>(`${API_PUBLIC}/billing/getItemPrices`, {});
  return response.itemPrices;
};

export const getSubscriptionDetails = async (): Promise<ChargeeBeeResponse> => {
  return handleAPIRequest<ChargeeBeeResponse>(`${API_PUBLIC}/billing/getSubscriptionDetails`, {});
};

export const reactivateSubscription = async (): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/billing/reactivateSubscription`, {});
};

export const updateCustomer = async (request?: updateCustomerRequest): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/billing/updateCustomer`, request);
};

export const calculateTaxes = async (input: BillingCalculateTaxesRequest): Promise<BillingCalculateTaxesResponse> => {
  return handleAPIRequest<BillingCalculateTaxesResponse>(`${API_PUBLIC}/billing/calculateTaxes`, input);
};

export const deletePaymentSource = async (id: BillingDeletePaymentSourceRequest['id']): Promise<BillingDeletePaymentSourceResponse> => {
  return handleAPIRequest<BillingDeletePaymentSourceResponse>(`${API_PUBLIC}/billing/deletePaymentSource`, {id});
};

export const repeatPayment = async (): Promise<BillingRepeatPaymentResponse> => {
  return handleAPIRequest<BillingRepeatPaymentResponse>(`${API_PUBLIC}/billing/repeatPayment`, {});
};

export const updatePaymentMethod = async (paymentMethod: BillingUpdatePaymentMethodRequest['paymentMethod']): Promise<BillingUpdatePaymentMethodResponse> => {
  return handleAPIRequest<BillingUpdatePaymentMethodResponse>(`${API_PUBLIC}/billing/updatePaymentMethod`, {paymentMethod});
};