import { t } from '../../../i18n/i18n';

export default {
  close: t('Common:CLOSE'),
  attendees: t('PublicBookingPage:ATTENDEES'),
  more: t('PublicBookingPage:MORE'),
  edit: t('PublicBookingPage:EDIT_BUTTON'),
  inviteOthers: t('PublicBookingPage:INVITE_OTHERS_FIELD_LABEL'),
  noteForAttendees: t('PublicBookingPage:NOTE_FOR_ATTENDEES_FIELD_LABEL'),
  yourAnswers: t('PublicBookingPage:YOUR_ANSWERS'),
};
