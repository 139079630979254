import { combineReducers } from 'redux';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { LocationsAction, LocationsActionTypes } from './actions';
import { PhysicalLocation } from '../../API';
import { DEFAULT_FILTER, DEFAULT_PHYSICAL_LOCATION } from './constants';
import { DeleteLocationsResponseDependences } from './types';

const locations = (state: PhysicalLocation[] = [], action: LocationsAction | GlobalAction) => {
  switch (action.type) {
    case LocationsActionTypes.GET_LOCATIONS_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const location = (state = DEFAULT_PHYSICAL_LOCATION, action: LocationsAction | GlobalAction) => {
  switch (action.type) {
    case LocationsActionTypes.SET_LOCATION:
    case LocationsActionTypes.GET_LOCATION_SUCCESS:
      return action.payload;
    case LocationsActionTypes.UPDATE_LOCATION:
      return { ...state, ...action.payload };
    case LocationsActionTypes.UPDATE_ADDRESS:
      return { ...state, address: { ...state.address, ...action.payload } } as PhysicalLocation;
    case LocationsActionTypes.GET_LOCATIONS_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_PHYSICAL_LOCATION;
    default:
      return state;
  }
};

const cloneName = (state = '', action: LocationsAction | GlobalAction) => {
  switch (action.type) {
    case LocationsActionTypes.SET_CLONE_NAME:
      return action.payload;
    case LocationsActionTypes.GET_LOCATIONS_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const filter = (state = DEFAULT_FILTER, action: LocationsAction | GlobalAction) => {
  switch (action.type) {
    case LocationsActionTypes.SET_FILTER:
      return { ...state, ...action.payload };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_FILTER;
    default:
      return state;
  }
};

const selectedLocations = (state: PhysicalLocation[] = [], action: LocationsAction | GlobalAction) => {
  switch (action.type) {
    case LocationsActionTypes.SET_SELECTED_LOCATIONS:
      return action.payload;
    case LocationsActionTypes.GET_LOCATIONS_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const lastLoadTime = (state = 0, action: LocationsAction | GlobalAction) => {
  switch (action.type) {
    case LocationsActionTypes.GET_LOCATIONS_SUCCESS:
      return new Date().getTime();
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return 0;
    default:
      return state;
  }
};

const deleteDependences = (
  state = {} as DeleteLocationsResponseDependences,
  action: LocationsAction | GlobalAction
) => {
  switch (action.type) {
    case LocationsActionTypes.DELETE_LOCATIONS_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as DeleteLocationsResponseDependences;
    default:
      return state;
  }
};

const isFetching = (state = false, action: LocationsAction | GlobalAction) => {
  switch (action.type) {
    case LocationsActionTypes.GET_LOCATIONS_REQUEST:
    case LocationsActionTypes.DEACTIVATE_LOCATION_REQUEST:
    case LocationsActionTypes.CLONE_LOCATION_REQUEST:
    case LocationsActionTypes.DELETE_LOCATIONS_REQUEST:
    case LocationsActionTypes.IMPORT_LOCATIONS_REQUEST:
    case LocationsActionTypes.GET_LOCATION_REQUEST:
    case LocationsActionTypes.UPSERT_LOCATION_REQUEST:
      return true;
    case LocationsActionTypes.GET_LOCATIONS_SUCCESS:
    case LocationsActionTypes.GET_LOCATIONS_FAIL:
    case LocationsActionTypes.DEACTIVATE_LOCATION_SUCCESS:
    case LocationsActionTypes.DEACTIVATE_LOCATION_FAIL:
    case LocationsActionTypes.CLONE_LOCATION_SUCCESS:
    case LocationsActionTypes.CLONE_LOCATION_FAIL:
    case LocationsActionTypes.DELETE_LOCATIONS_SUCCESS:
    case LocationsActionTypes.DELETE_LOCATIONS_FAIL:
    case LocationsActionTypes.IMPORT_LOCATIONS_SUCCESS:
    case LocationsActionTypes.IMPORT_LOCATIONS_FAIL:
    case LocationsActionTypes.GET_LOCATION_SUCCESS:
    case LocationsActionTypes.GET_LOCATION_FAIL:
    case LocationsActionTypes.UPSERT_LOCATION_SUCCESS:
    case LocationsActionTypes.UPSERT_LOCATION_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};
const error = (state: unknown | null = null, action: LocationsAction | GlobalAction) => {
  switch (action.type) {
    case LocationsActionTypes.GET_LOCATIONS_FAIL:
    case LocationsActionTypes.DEACTIVATE_LOCATION_FAIL:
    case LocationsActionTypes.CLONE_LOCATION_FAIL:
    case LocationsActionTypes.DELETE_LOCATIONS_FAIL:
    case LocationsActionTypes.IMPORT_LOCATIONS_FAIL:
    case LocationsActionTypes.GET_LOCATION_FAIL:
    case LocationsActionTypes.UPSERT_LOCATION_FAIL:
      return action.error;
    case LocationsActionTypes.GET_LOCATIONS_REQUEST:
    case LocationsActionTypes.DEACTIVATE_LOCATION_REQUEST:
    case LocationsActionTypes.CLONE_LOCATION_REQUEST:
    case LocationsActionTypes.DELETE_LOCATIONS_REQUEST:
    case LocationsActionTypes.IMPORT_LOCATIONS_REQUEST:
    case LocationsActionTypes.GET_LOCATION_REQUEST:
    case LocationsActionTypes.UPSERT_LOCATION_REQUEST:
      return null;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  error,
  locations,
  location,
  cloneName,
  filter,
  selectedLocations,
  lastLoadTime,
  deleteDependences,
});
