import { Link } from 'react-router-dom';
import labels from './labels';
import { useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { XMarkIcon } from '@heroicons/react/24/outline';
import {billingSelectors} from "../../../store/billing";
import {useState} from "react";
import {Path} from "../../../routing";
import { authenticationSelectors } from '../../../store/authentication';

export const UnpaidInvoice = () => {
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);
  const isPresentUnpaidInvoices = useSelector(billingSelectors.selectIsPresentUnpaidInvoices);

  const [isOpenAlert, setOpenAlert] = useState(true);

  return (
    <>
      {isSuperAdmin && isLicenseActive && isPresentUnpaidInvoices && isOpenAlert && (
        <div className="sumo-card-bg bg-saffron-light flex flex-column gap-16px pt-24px mb-28px">
          <div className="flex-between-center">
            <div className="text-title-xs-med">
              <span> {labels.haveUnpaidInvoice} </span>
              <Link to={Path.Billing}>{labels.viewBilling}</Link>
            </div>
            <div className="flex-none action-button -mr-8px -my-8px">
              <XMarkIcon className="icon-20px" onClick={() => setOpenAlert(false)} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
