import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { PublicBookingPageContainer, PublicBookingPageSkeleton } from '../../components/publicBookingPage';
import { eventActions, eventSelectors, EventSteps } from '../../store/publicBookingPage';

export const PublicBookingPage = () => {
  const { eventId, journeyId, groupBookingPageId, bookingPageId } = useParams();
  const dispatch = useDispatch();
  const agendaLoaded = useSelector(eventSelectors.selectAgendaLoaded);
  const error = useSelector(eventSelectors.selectError);

  const [searchParams] = useSearchParams();
  const hideHeader = searchParams.get('hideHeader') === 'true';
  const hideCookie = searchParams.get('hideCookie') === 'true';
  const hidePageDetails = searchParams.get('hidePageDetails') === 'true';
  const iframe = searchParams.get('iframe') === 'true';
  const editKey = searchParams.get('editKey');

  useEffect(() => {
    if (eventId) {
      dispatch(eventActions.setEventId(eventId));
      dispatch(eventActions.setEventStep(EventSteps.BOOKED));
    } else if (journeyId) {
      dispatch(eventActions.setJourneyId(journeyId));
    } else if (groupBookingPageId) {
      dispatch(eventActions.setGroupBookingPageId(groupBookingPageId));
    } else if (bookingPageId) {
      dispatch(eventActions.setBookingPageId(bookingPageId));
    }

    dispatch(
      eventActions.setViewSettings({
        editKey,
        hideHeader,
        hideCookie,
        hidePageDetails,
        iframe
      })
    );

    dispatch(eventActions.getAgendaRequest());
  }, []);

  return (
    <div className="h-screen">
      {agendaLoaded || error ? <PublicBookingPageContainer /> : <PublicBookingPageSkeleton />}
    </div>
  );
};
