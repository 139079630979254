import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Calendar, CalendarChangeEvent, CalendarMonthChangeEvent, CalendarViewChangeEvent } from 'primereact/calendar';
import { eventSelectors, eventActions } from '../../../store/publicBookingPage';
import labels from './labels';
import i18n from '../../../i18n/i18n';
import { Nullable } from 'primereact/ts-helpers';
import { ScrollPanel } from 'primereact/scrollpanel';
import { AppDispatch } from '../../../store/rootStore';
import { eventThunks } from '../../../store/publicBookingPage/thunks';
import arraySupport from 'dayjs/plugin/arraySupport';
dayjs.extend(arraySupport);

export const PublicBookingPageDateTime = () => {
  const dispatch = useDispatch<AppDispatch>();
  // const isMobile = useSelector(eventSelectors.selectIsMobile);
  const isPreviewMode = useSelector(eventSelectors.selectIsPreviewMode);
  const viewDate = useSelector(eventSelectors.selectViewDate);
  const eventDate = useSelector(eventSelectors.selectEventDate);
  const startTime = useSelector(eventSelectors.selectStartTime);
  const availableTimes = useSelector(eventSelectors.selectAvailableTimes);
  const disabledDates = useSelector(eventSelectors.selectDisabledDates);
  const isUpserEventFetching = useSelector(eventSelectors.selectIsUpsertEventFetching);
  const calendarMaxDate = useSelector(eventSelectors.selectCalendarMaxDate);

  const minDate = new Date(); // today
  const [calendarViewDate, setCalendarViewDate] = useState(viewDate);

  const handleDateChange = (date: Nullable<string | Date>) => {
    dispatch(eventThunks.setEventDateThunk(date ? dayjs(date).local().format('YYYY-MM-DD') : ''));
  };

  // // select the first available date in current month
  // useEffect(() => {
  //   if (Object.keys(availableTimesByDate).length > 0) {
  //     const firstDateKey = Object.keys(availableTimesByDate)[0];
  //     const firstDateValue = availableTimesByDate[firstDateKey][0];
  //     const date = new Date(firstDateValue);
  //     handleDateChange(date);
  //     dispatch(eventActions.setViewDate(date.valueOf()));
  //   }
  // }, [availableTimesByDate]);

  const handleMonthChange = (e: CalendarMonthChangeEvent | CalendarViewChangeEvent) => {
    const getDateFromEvent = (event: CalendarMonthChangeEvent | CalendarViewChangeEvent): Date => {
      if ('month' in event && 'year' in event) {
        return new Date(event.year, event.month - 1, 1);
      } else if ('value' in event) {
        return event.value;
      }
      return new Date();
    };

    const adjustDateWithinRange = (date: Date): Date => {
      if (date < minDate) {
        return minDate;
      } else if (date > calendarMaxDate) {
        return calendarMaxDate;
      }
      return date;
    };

    let viewMonthDate = adjustDateWithinRange(getDateFromEvent(e));
    viewMonthDate.setHours(0, 0, 0, 0);
    viewMonthDate = dayjs
      .utc([viewMonthDate.getFullYear(), viewMonthDate.getMonth(), viewMonthDate.getDate()])
      .toDate();
    if (
      calendarViewDate.getUTCMonth() === viewMonthDate.getUTCMonth() &&
      calendarViewDate.getUTCFullYear() !== viewMonthDate.getUTCFullYear()
    ) {
      setCalendarViewDate(viewMonthDate);
      return; // disable updates on year switch
    }

    setCalendarViewDate(viewMonthDate);
    // handleDateChange(viewMonthDate);
    handleMonthUpdate(viewMonthDate);
  };

  const handleMonthUpdate = (date: Date) => {
    if (isPreviewMode) {
      return;
    }

    // ensure targetDate is different from the current viewDate
    if (viewDate.valueOf() !== date.valueOf()) {
      dispatch(eventActions.setViewDate(date.valueOf()));
      dispatch(eventActions.getAgendaRequest());
    }
  };

  const handleTimeButtonClick = (time: number) => {
    dispatch(eventThunks.setEventStartTimeThunk(time));
  };

  const handleViewNextMonth = () => {
    const currentYear = calendarViewDate.getFullYear();
    const currentMonth = calendarViewDate.getMonth();
    handleMonthChange({
      month: ((currentMonth + 1) % 12) + 1,
      year: currentMonth === 11 ? currentYear + 1 : currentYear,
    });
  };

  const getBtnClass = (comapreToDate: Date) =>
    viewDate.getUTCMonth() === comapreToDate.getUTCMonth() &&
    viewDate.getUTCFullYear() === comapreToDate.getUTCFullYear()
      ? 'p-disabled'
      : '';

  return (
    <div className="booking-time-container">
      <div className="booking-calendar">
        <Calendar
          inline
          className={`w-full pt-6px ${isUpserEventFetching ? 'pointer-events-none' : ''}`}
          panelClassName="border-0 p-0"
          locale={i18n.language}
          value={eventDate}
          viewDate={dayjs(viewDate).add(-dayjs().utcOffset(), 'm').toDate()}
          disabledDates={disabledDates}
          minDate={minDate}
          maxDate={calendarMaxDate}
          onChange={(e: CalendarChangeEvent) => (!Array.isArray(e.value) ? handleDateChange(e.value) : undefined)}
          onMonthChange={handleMonthChange}
          onViewDateChange={handleMonthChange}
          pt={{
            previousButton: {
              className: getBtnClass(minDate),
            },
            nextButton: {
              className: getBtnClass(calendarMaxDate),
            },
          }}
        />
      </div>
      <div className="booking-slots-container">
        <ScrollPanel>
          <div className="booking-slots">
            {availableTimes.length ? (
              availableTimes.map((spot, index) => (
                <div
                  key={index}
                  className={`time-slot-selector ${spot.value === startTime?.valueOf() ? 'selected' : ''} ${
                    isUpserEventFetching ? 'pointer-events-none' : ''
                  }`}
                  onClick={() => handleTimeButtonClick(spot.value)}
                >
                  {spot.value === startTime?.valueOf() && isUpserEventFetching ? (
                    <i className="pi pi-spin pi-spinner px-20px" />
                  ) : (
                    <>
                      <div className="text-title-xs-med">{spot.time}</div>
                      <div className="text-label-s-med">{spot.format}</div>
                    </>
                  )}
                </div>
              ))
            ) : (
              <>
                <div className="w-full flex justify-content-center mb-16px">{labels.slotsNoFound}</div>
                <div className="time-slot-selector" onClick={handleViewNextMonth}>
                  {labels.viewNextMonth}
                </div>
              </>
            )}
          </div>
        </ScrollPanel>
      </div>
    </div>
  );

  // return isMobile ? (
  //   <>
  //     <div className="pl-3 ml-1 -mr-1 mt-3">{renderSubHeaderLabel(dateLabel, handleDateLabelChange)}</div>
  //     <div className="card mb-0 border-1 surface-border shadow-none p-0">
  //       {isDateSelected ? (
  //         <div className="flex justify-content-between align-items-center px-3 py-2">
  //           <div>{dayjs(eventDate).format('dddd, MMMM D, YYYY')}</div>
  //           <div>
  //             <Button style={outlinedButtonStyle} label={labels.edit} outlined onClick={handleEditDate} />
  //           </div>
  //         </div>
  //       ) : (
  //         renderCalendar()
  //       )}
  //     </div>
  //     {isDateSelected && (
  //       <>
  //         <div className="pl-3 mt-3">{renderSubHeaderLabel(timeLabel, handleTimeLabelChange)}</div>
  //         <div className="card mb-0 border-1 surface-border shadow-none p-0 h-30rem">{renderTimeSlots()}</div>
  //       </>
  //     )}
  //   </>
  // ) : (
  //   <>
  //     <div className="booking-time-container">
  //       <div className="booking-calendar">{renderCalendar()}</div>
  //       <div className="flex-1">
  //         <ScrollPanel>{renderTimeSlots()}</ScrollPanel>
  //       </div>
  //     </div>
  //   </>
  // );
};
