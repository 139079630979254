import { Button } from 'primereact/button';
import { BuyLicensePageButton } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationSelectors } from '../../../store/authentication';
import { billingChangeModalActions, billingActions, billingSelectors } from '../../../store/billing';
import labels from './labels';
import { useState } from 'react';
import { ConfirmationModal } from '../../common';

export const BillingSubscriptionButtons = () => {
  const dispatch = useDispatch();
  const isTrialLicense = useSelector(authenticationSelectors.selectIsTrialLicense);
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);
  const isSubscriptionNonRenewing = useSelector(billingSelectors.selectIsStatusNonRenewing);
  const isSubscriptionCancelled = useSelector(billingSelectors.selectIsStatusCancelled);
  const isFetching = useSelector(billingSelectors.selectIsFetching);
  const isPresentUnpaidInvoices = useSelector(billingSelectors.selectIsPresentUnpaidInvoices);

  const commonWrapperClass = 'flex gap-20px mt-16px';

  const [showCancel, setShowCancel] = useState(false);

  const handleChange = () => {
    dispatch(billingChangeModalActions.openModal());
  };

  const handleShowCancel = () => {
    setShowCancel(true);
  };

  const handleHideCancel = () => {
    setShowCancel(false);
  };

  const handleCancel = () => {
    dispatch(billingActions.cancelSubscriptionRequest());
    handleHideCancel();
  };

  const handleReactivate = () => {
    dispatch(billingActions.reactivateSubscriptionRequest());
  };

  const repeatPaymentHandle = () => {
    dispatch(billingActions.repeatPaymentRequest());
  };

  if (isTrialLicense) {
    return (
      <div className={commonWrapperClass}>
        <BuyLicensePageButton />
      </div>
    );
  }

  if (!isLicenseActive && isPresentUnpaidInvoices) {
    return (
      <div className={commonWrapperClass}>
        <Button label={labels.payInvoice} onClick={repeatPaymentHandle} disabled={isFetching} />
      </div>
    );
  }

  if (isSubscriptionNonRenewing || isSubscriptionCancelled) {
    return (
      <div className={commonWrapperClass}>
        <Button label={labels.reactivate} onClick={handleReactivate} />
        {isSubscriptionNonRenewing && (
          <Button label={labels.change} outlined onClick={handleChange} disabled={isFetching} />
        )}
      </div>
    );
  }

  return (
    <>
      <ConfirmationModal
        visible={showCancel}
        title={labels.cancelSubscription}
        description={labels.cancelSubscriptionMessage}
        confirmButtonLabel={labels.yesCancel}
        cancelButtonLabel={labels.no}
        confirmButtonProps={{ className: 'button-tomato' }}
        cancelButtonProps={{ className: 'min-w-90px' }}
        onConfirm={handleCancel}
        onCancel={handleHideCancel}
        onClose={handleHideCancel}
      />
      <div className={commonWrapperClass}>
        <Button label={labels.change} onClick={handleChange} disabled={isFetching} />
        {isPresentUnpaidInvoices && (
          <Button label={labels.payInvoice} outlined onClick={repeatPaymentHandle} disabled={isFetching} />
        )}
        <Button label={labels.cancelSubscription} outlined disabled={isFetching} onClick={handleShowCancel} />
      </div>
    </>
  );
};
