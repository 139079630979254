import { Link } from 'react-router-dom';
import labels from './labels';
import { useDispatch, useSelector } from 'react-redux';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import urlConstants from '../../../shared/JSON/urlConstants.json';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { GoogleAuthButton } from '../../public';
import { ThirdPartyType } from '../../../store/authentication';
import { IntegrationType } from '../../../API';
import { CodeResponse } from '@react-oauth/google';
import { ProgressSpinner } from 'primereact/progressspinner';

export const InstallInbox = () => {
  const dispatch = useDispatch();
  const isInstallInboxClosed = useSelector(userSettingsSelectors.selectIsInstallInboxClosed);
  const isGoogleContactsConnected = useSelector(userSettingsSelectors.selectIsGoogleContactsConnected);
  const isFetching = useSelector(userSettingsSelectors.selectIsFetching);

  const handleClose = () => {
    dispatch(userSettingsActions.updateUserSettings({ isInstallInboxClosed: true }));
    dispatch(userSettingsActions.saveUserSettingsNoToastRequest());
  };

  const handleUpdateGoogleIntegration = (codeResponse: CodeResponse) => {
    dispatch(
      userSettingsActions.connectExternalCalendarRequest({
        type: ThirdPartyType.GOOGLE,
        integrationType: IntegrationType.GOOGLE_CALENDAR,
        codeResponse,
        switchAccount: true,
        login: false,
        updateIntegration: true,
      })
    );
  };

  return (
    <>
      {!isGoogleContactsConnected ? (
        <div className="sumo-card-bg flex flex-column gap-16px pt-24px mb-28px">
          <div className="flex justify-content-between">
            <div className="text-title-xs-med">{labels.updateGoogleIntegrationDescription}</div>
          </div>
          <div>
            <GoogleAuthButton
              className="button-xl"
              label={labels.updateGoogleIntegrationButton}
              hideIcon
              onSuccess={handleUpdateGoogleIntegration}
              disabled={isFetching}
            />
            {isFetching && <ProgressSpinner className="w-32px h-32px ml-16px" strokeWidth="5" />}
          </div>
        </div>
      ) : (
        !isInstallInboxClosed && (
          <div className="sumo-card-bg flex flex-column gap-16px pt-24px mb-28px">
            <div className="flex-between-center">
              <div className="text-title-xs-med">
                <span> {labels.installInboxPart1}</span>
                <Link target="_blank" to={urlConstants.INBOX_CHROME_EXTENSTION_URL}>
                  {labels.installInboxPart2}
                </Link>
                <span> {labels.installInboxPart3}</span>
                <Link target="_blank" to={urlConstants.INBOX_EDGE_ADD_ON_URL}>
                  {labels.installInboxPart4}
                </Link>
                <span> {labels.installInboxPart5}</span>
              </div>
              <div className="flex-none action-button -mr-8px -my-8px">
                <XMarkIcon className="icon-20px" onClick={handleClose} />
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};
