import { useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../../../store/modals';
import { Modal } from '../../common';
import { deleteLocationModalActions, locationsActions, locationsSelectors } from '../../../store/locations';
import labels from './labels';
import { Button } from 'primereact/button';
import { bookingPageSelectors } from '../../../store/bookingPages';
import { Link } from 'react-router-dom';
import { Path } from '../../../routing';
import { bookingTemplatesSelectors } from '../../../store/bookingTemplates';
import { usersSelectors } from '../../../store/users';

export const LocationsDeleteModal = () => {
  const dispatch = useDispatch();
  const selectedLocationIds = useSelector(locationsSelectors.selectSelectedLocationIds);
  const location = useSelector(locationsSelectors.selectLocation);
  const bookingPages = useSelector(bookingPageSelectors.selectLocationDependencesBP);
  const bookingTemplates = useSelector(bookingTemplatesSelectors.selectLocationDependencesBT);
  const users = useSelector(usersSelectors.selectLocationDependencesUsers);
  const warningMessage = useSelector(locationsSelectors.selectDeleteLocationWarningMessage);

  const handleConfirm = () => {
    dispatch(
      locationsActions.deleteLocationRequest({
        persist: true,
        ids: selectedLocationIds.length ? selectedLocationIds : [location.id],
      })
    );
  };

  const handleCancel = () => {
    dispatch(deleteLocationModalActions.closeModal());
  };

  return (
    <Modal.Container
      name={ModalName.DELETE_LOCATION_MODAL_NAME}
      closable={true}
      onClose={handleCancel}
      className="w-560px"
    >
      <Modal.Header>
        <div>{labels.delete}</div>
      </Modal.Header>
      <div className="mb-16px">{warningMessage}</div>
      <div className="max-h-200px mb-24px overflow-y-auto text-body-lg-reg">
        <div className="flex flex-column gap-16px">
          {!!bookingPages?.length && (
            <div className="flex flex-column gap-4px">
              <div className="text-label-s-med text-heavy-60">{labels.bookingPagesTitle}</div>
              {bookingPages.map((page) => (
                <div key={page?.id} className="text-body-lg-reg">
                  <Link to={Path.EditBookingPage.replace(':bookingPageId', page?.id || '')} target="_blank">
                    {page?.what?.customName || ''}
                  </Link>
                </div>
              ))}
            </div>
          )}
          {!!bookingTemplates?.length && (
            <div className="flex flex-column gap-4px">
              <div className="text-label-s-med text-heavy-60">{labels.bookingTemplatesTitle}</div>
              {bookingTemplates.map((template) => (
                <div key={template?.id} className="text-body-lg-reg">
                  <Link to={Path.EditBookingTemplate.replace(':bookingTemplateId', template?.id || '')} target="_blank">
                    {template?.what?.customName || ''}
                  </Link>
                </div>
              ))}
            </div>
          )}

          {!!users?.length && (
            <div className="flex flex-column gap-4px">
              <div className="text-label-s-med text-heavy-60">{labels.usersTitle}</div>
              {users.map((user) => (
                <div key={user?.userId} className="text-body-lg-reg">
                  {user?.fullName || user?.email}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <Modal.Buttons>
        <Button onClick={handleConfirm} severity="danger">
          {labels.delete}
        </Button>
        <Button onClick={handleCancel} text>
          {labels.cancel}
        </Button>
      </Modal.Buttons>
    </Modal.Container>
  );
};
