import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { billingActions, billingSelectors } from '../../store/billing';
import { userSettingsSelectors } from '../../store/userSettings';
import { authenticationSelectors } from '../../store/authentication';
import { errorActions } from '../../store/error/actions';
import { ErrorCodes } from '../../store/error/types';
import { BillingSubscription } from '../../components/billing/billingSubscription/BillingSubscription';
import { BillingInvoices } from '../../components/billing/billingInvoices/BillingInvoices';
import { BillingNotifications } from '../../components/billing/billingNotifications/BillingNotifications';
import { BillingDetails } from '../../components/billing/billingDetails/BillingDetails';
import { BillingAccordion } from '../../components/billing';
import labels from './labels';
import { SectionHeader } from '../../components/common';

export const Billing = () => {
  const dispatch = useDispatch();
  const isUserSettingReceived = useSelector(userSettingsSelectors.selectIsUserSettingsReceived);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const isTenantExpired = useSelector(authenticationSelectors.selectIsTenantExpired);
  const isTrialLicense = useSelector(authenticationSelectors.selectIsTrialLicense);
  const isRefreshTenantFetching = useSelector(billingSelectors.selectIsRefreshTenantFetching);
  const isPresentUnpaidInvoices = useSelector(billingSelectors.selectIsPresentUnpaidInvoices);

  const [isBillingOpen, setIsBillingOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(isPresentUnpaidInvoices);

  useEffect(() => {
    if (isUserSettingReceived) {
      if (isSuperAdmin && !isTrialLicense) {
        dispatch(billingActions.getBillingRequest());
        dispatch(billingActions.getItemPricesRequest());
      } else if (!isSuperAdmin) {
        dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
      }
    }
  }, [isUserSettingReceived]);

  useEffect(() => {
    setIsInvoiceOpen(isPresentUnpaidInvoices);
  }, [isPresentUnpaidInvoices]);

  return (
    <>
      {isTenantExpired && (
        <div className="card mb-3 bg-red-200 text-center">
          {labels.accountSuspendedPart1} <b>{labels.accountSuspendedPart2}</b> {labels.accountSuspendedPart3}
        </div>
      )}
      <div className="sumo-card-bg flex flex-column pt-16px">
        <div className="flex flex-column gap-24px">
          <SectionHeader loading={isRefreshTenantFetching} title={labels.title} hideButton hideBottomMargin />

          <BillingSubscription />

          {/* {generateAccordion('SMS', 'Top up your SMS credits', false, (isOpen) => console.log(isOpen), <div />)} */}

          <BillingAccordion
            isTrialLicense={isTrialLicense}
            title={labels.billingDetailsTitle}
            description={labels.billingDetailsDescription}
            isOpen={isBillingOpen}
            setIsOpen={setIsBillingOpen}
            content={<BillingDetails />}
          />

          <BillingAccordion
            isTrialLicense={isTrialLicense}
            title={labels.notificationsTitle}
            description={labels.notificationsDescription}
            isOpen={isNotificationsOpen}
            setIsOpen={setIsNotificationsOpen}
            content={<BillingNotifications />}
          />

          <BillingAccordion
            isTrialLicense={isTrialLicense}
            title={labels.invoiceTitle}
            description={labels.invoiceDescription}
            isOpen={isInvoiceOpen}
            setIsOpen={setIsInvoiceOpen}
            content={<BillingInvoices />}
          />
        </div>
      </div>
    </>
  );
};
