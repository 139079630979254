import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InPersonType, LocationType, PhoneCallType } from '../../../API';
import { formatEventDate, formatEventTime } from '../../../services/DateService';
import { eventActions, eventSelectors, EventSteps } from '../../../store/publicBookingPage';
import { Label, TimeZoneSelector } from '../../common';
import labels from './labels';
import { getInitials, getTimeUnitLabel, phoneNumberToReadableFormat } from '../../../services/utils';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  GlobeAltIcon,
  MapPinIcon,
  PhoneIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import { getFullAddress } from '../../../store/locations';
import { useRotatingText } from '../../../hooks/useRotatingText';
import ReactGA from 'react-ga4';
import { eventThunks } from '../../../store/publicBookingPage/thunks';
import { Avatar } from 'primereact/avatar';
import { CURRENT_TIME_ZONE } from '../../../types/constants';
import { classNames } from 'primereact/utils';

export const PublicBookingPageMeetingDetails = () => {
  const dispatch = useDispatch();
  const isEditable = useSelector(eventSelectors.selectIsEditable);
  const event = useSelector(eventSelectors.selectEvent);
  const timezone = useSelector(eventSelectors.selectTimeZone);
  const is12Hour = useSelector(eventSelectors.selectIs12Hour);
  const allHosts = useSelector(eventSelectors.selectAllHosts);
  const cohosts = useSelector(eventSelectors.selectSumo1Cohosts);
  const locationType = useSelector(eventSelectors.selectLocationType);
  const inPersonType = useSelector(eventSelectors.selectInPersonType);
  const phoneType = useSelector(eventSelectors.selectPhoneCallType);
  const customPhone = useSelector(eventSelectors.selectCustomPhone);
  const hostPhone = useSelector(eventSelectors.selectHostFullPhone);
  const customAddress = useSelector(eventSelectors.selectCustomAddress);
  const isLocationInPerson = useSelector(eventSelectors.selectIsLocationInPerson);
  const locationAddress = useSelector(eventSelectors.selectLocationAddress);
  const locationTypeLabel = useSelector(eventSelectors.selectLocationTypeLabel);
  const locationTypeOptions = useSelector(eventSelectors.selectLocationTypeOptions);
  const isLocationTypeEdit = useSelector(eventSelectors.selectIsLocationTypeEdit);
  const isUpserEventFetching = useSelector(eventSelectors.selectIsUpsertEventFetching);

  const isPreviewMode = useSelector(eventSelectors.selectIsPreviewMode);
  const isExisting = useSelector(eventSelectors.selectIsExisting);
  const isCanceled = useSelector(eventSelectors.selectEventIsCanceled);
  const bookButtonLabel = useSelector(eventSelectors.selectBookButtonLabel);
  const updateButtonLabel = useSelector(eventSelectors.selectUpdateButtonLabel);
  const confirmCancelButtonLabel = useSelector(eventSelectors.selectConfirmCancelButtonLabel);
  const showBookButton = useSelector(eventSelectors.selectShowBookButton);
  const showRescheduleBackButton = useSelector(eventSelectors.selectShowRescheduleBackButton);
  const showConfirmCancelButton = useSelector(eventSelectors.selectShowConfirmCancelButton);
  const isSaveAvailable = useSelector(eventSelectors.selectIsSaveAvailable);

  const duration = useSelector(eventSelectors.selectDuration);
  const timeZone = useSelector(eventSelectors.selectTimeZone);
  const timeZoneOptions = useSelector(eventSelectors.selectTimeZoneOptionsList);
  const isHostReschedule = useSelector(eventSelectors.selectIsHostReschedule);
  const showBackButton = useSelector(eventSelectors.selectShowBackButton);
  const showNextButton = useSelector(eventSelectors.selectShowNextButton);
  const showButtons = useSelector(eventSelectors.selectShowButtons);
  const isEditEvent = useSelector(eventSelectors.selectIsEditEvent);

  const agendaEvent = useSelector(eventSelectors.selectAgendaEvent);
  const eventDateInfo = useSelector(eventSelectors.selectEventDateInfo);
  const eventStartTimeInfo = useSelector(eventSelectors.selectEventStartTimeInfo);
  const eventEndTimeInfo = useSelector(eventSelectors.selectEventEndTimeInfo);
  const rotatingLoadingLabel = useRotatingText(
    [labels.loadingLabel1, labels.loadingLabel2, labels.loadingLabel3],
    isUpserEventFetching
  );

  const [isDetailsOpen, setIsDetailsOpen] = useState(true);

  const handleLocationTypeChange = (type: LocationType) => {
    dispatch(eventActions.updateLocation({ type }));
    dispatch(eventActions.getAgendaRequest());

    ReactGA.event({
      category: 'Booking',
      action: 'Change location type',
      label: type,
    });
  };

  const handleTimeZoneChange = (timeZone: string) => {
    dispatch(eventActions.updateEvent({ timeZone }));

    ReactGA.event({
      category: 'Booking',
      action: 'Change time zone',
      label: timeZone,
    });
  };

  const handleBack = () => {
    if (showRescheduleBackButton) {
      // set the initial dateTime on back
      dispatch(
        eventActions.updateEvent({
          timeZone: agendaEvent?.timeZone,
          eventDate: agendaEvent?.eventDate,
          startTime: agendaEvent?.startTime || undefined,
          endTime: agendaEvent?.endTime || undefined,
        })
      );
      dispatch(eventActions.setEventStep(EventSteps.BOOKED));
    } else {
      dispatch(eventActions.setPreviousStep());
    }
  };

  const handleNext = () => {
    dispatch(eventActions.setNextStep());
  };

  const handleEditTime = () => {
    if (isCanceled) {
      return;
    }
    dispatch(eventActions.setEventStep(EventSteps.WHEN));

    ReactGA.event({
      category: 'Booking',
      action: 'Edit time',
      label: `Booking page name: ${event.bookingPageName}. Booking page id: ${event.bookingPageId}`,
    });
  };

  const handleBookMeeting = () => {
    if (isPreviewMode) {
      return;
    }
    // TODO: remove any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch<any>(eventThunks.saveEventThunk());

    const actionName = isEditEvent ? 'Reschedule Booking' : 'Complete Booking';
    ReactGA.event({
      category: 'Booking',
      action: actionName,
      label: `Booking page name: ${event.bookingPageName}. Booking page id: ${event.bookingPageId}`,
    });
  };

  const handleConfirmCancelMeeting = () => {
    if (isPreviewMode) {
      return;
    }
    dispatch(eventActions.cancelEventRequest());

    ReactGA.event({
      category: 'Booking',
      action: 'Cancel Booking',
      label: `Booking page name: ${event.bookingPageName}. Booking page id: ${event.bookingPageId}`,
    });
  };

  const handleBookLabelChange = (value: string) => {
    dispatch(eventActions.updatePreviewLabel(isExisting ? { updateButtonLabel: value } : { bookButtonLabel: value }));
  };

  const handleConfirmCancelLabelChange = (confirmCancelButtonLabel: string) => {
    dispatch(eventActions.updatePreviewLabel({ confirmCancelButtonLabel }));
  };

  const generateLocationTypeIcon = (locationType: LocationType | null | undefined) => {
    switch (locationType) {
      case LocationType.IN_PERSON:
        return <MapPinIcon className="icon-24px" />;
      case LocationType.PHONE_CALL:
        return <PhoneIcon className="icon-24px" />;
      case LocationType.VIDEO_CONFERENCE:
        return <VideoCameraIcon className="icon-24px" />;
      default:
        return <></>;
    }
  };

  const getLocationInPersonTemplate = (clickable?: boolean) => {
    return (
      <div className="flex flex-column white-space-normal">
        <div>{locationTypeLabel}</div>
        <div
          className={classNames(
            'text-label-xs-reg text-blue-main py-4px -mb-4px',
            clickable ? 'hover-text-blue-dark cursor-pointer' : ''
          )}
          onClick={(e) => {
            if (clickable) {
              e.stopPropagation();
              dispatch(eventActions.setIsLocationOpened(true));
            }
          }}
        >
          {locationAddress ? getFullAddress(locationAddress) : 'Select Location'}
        </div>
      </div>
    );
  };

  const getLocation = () => {
    switch (locationType) {
      case LocationType.IN_PERSON: {
        switch (inPersonType) {
          case InPersonType.CUSTOM_ADDRESS:
            return customAddress;
        }
        break;
      }
      case LocationType.PHONE_CALL: {
        switch (phoneType) {
          case PhoneCallType.CUSTOM_PHONE:
            return phoneNumberToReadableFormat(customPhone);
          case PhoneCallType.HOST_PHONE_NUMBER:
            return phoneNumberToReadableFormat(hostPhone) || locationTypeLabel;
        }
        break;
      }
    }
    return locationTypeLabel;
  };

  const defineBookButtonLabel = () => {
    return isUpserEventFetching ? rotatingLoadingLabel : isExisting ? updateButtonLabel : bookButtonLabel;
  };

  return (
    <div className="booking-details">
      <div className="booking-hosts">
        {allHosts.map((host, index) => (
          <Avatar
            key={index + (host?.name || host?.email || '')}
            image={host?.avatar}
            size="xlarge"
            label={getInitials(host?.name || host?.email || '')}
            className="shadow-white-2px -mr-4px"
            style={{ zIndex: allHosts.length - index }}
          />
        ))}
      </div>
      <div className="flex justify-content-between">
        <div className="flex flex-column">
          <div className="text-title-s-med">
            <div>{allHosts[0]?.name}</div>
          </div>
          <div className="text-body-s-reg text-heavy-60">
            <div>
              {cohosts?.length ? cohosts.map((cohost) => cohost?.name || cohost?.email || '').join(', ') : labels.host}
            </div>
          </div>
        </div>
        <Button className="details-button" outlined onClick={() => setIsDetailsOpen((prev) => !prev)}>
          {isDetailsOpen ? <ChevronUpIcon className="icon-20px" /> : <ChevronDownIcon className="icon-20px" />}
        </Button>
      </div>

      {isDetailsOpen && (
        <>
          <div className="flex-left-center gap-8px pt-24px">
            <div className="flex-none flex-center w-40px h-40px bg-heavy-1 text-heavy-60 border-radius-8px">
              <ClockIcon className="icon-24px" />
            </div>
            <div className="text-label-lg-med">{`${duration?.count} ${getTimeUnitLabel(duration?.timeUnit)}`}</div>
          </div>

          <div className="flex-left-center gap-8px pt-6px">
            <div className="flex-none flex-center w-40px h-40px bg-heavy-1 text-heavy-60 border-radius-8px">
              {generateLocationTypeIcon(locationType)}
            </div>
            {isLocationTypeEdit ? (
              <Dropdown
                className="min-h-40px align-items-center border-primary-white border-radius-none overflow-hidden"
                value={locationType}
                options={locationTypeOptions}
                pt={{ input: { className: 'p-0 border-radius-none text-label-lg-med' } }}
                onChange={(e) => handleLocationTypeChange(e.value)}
                valueTemplate={isLocationInPerson ? getLocationInPersonTemplate(true) : undefined}
              />
            ) : (
              <div className="px-0 text-label-lg-med">
                {isLocationInPerson ? getLocationInPersonTemplate() : getLocation()}
              </div>
            )}
          </div>

          <div className="flex-left-center gap-8px pt-6px">
            <div className="flex-none flex-center w-40px h-40px bg-heavy-1 text-heavy-60 border-radius-8px">
              <GlobeAltIcon className="icon-24px" />
            </div>
            <TimeZoneSelector
              selectedMode="short"
              options={timeZoneOptions}
              onChange={handleTimeZoneChange}
              value={timeZone}
              disabled={isHostReschedule}
              className="min-h-40px align-items-center border-primary-white border-radius-none"
            />
          </div>

          {eventDateInfo && (
            <div className="flex-left-center gap-8px pt-6px">
              <div className="flex-center w-40px h-40px bg-heavy-1 text-heavy-60 border-radius-8px">
                <CalendarIcon className="icon-24px" />
              </div>
              <div className="flex flex-column gap-5px cursor-pointer hover-text-heavy-60" onClick={handleEditTime}>
                <div className="text-label-lg-med">{formatEventDate(eventDateInfo, CURRENT_TIME_ZONE)}</div>
                {eventStartTimeInfo && eventEndTimeInfo && (
                  <div className="text-label-xs-reg text-heavy-60">
                    {formatEventTime(eventStartTimeInfo, eventEndTimeInfo, timezone, is12Hour)}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}

      {showButtons && (
        <div className="booking-buttons">
          {(showBackButton || showRescheduleBackButton) && (
            <Button
              className="justify-content-center gap-8px button-xl p-0 w-48px h-48px"
              outlined
              onClick={handleBack}
              disabled={!isPreviewMode && isUpserEventFetching}
            >
              <ArrowLeftIcon className="icon-18px" />
            </Button>
          )}
          {showBookButton && (
            <Button
              className="button-book"
              disabled={!isPreviewMode && !isSaveAvailable}
              onClick={handleBookMeeting}
              loading={isUpserEventFetching}
              style={isUpserEventFetching ? { width: '190px', gap: '7px' } : undefined}
            >
              <Label
                textClassName="text-button-md-med"
                editable={isEditable}
                value={defineBookButtonLabel()}
                onBlurUpdate={handleBookLabelChange}
                onChange={handleBookLabelChange}
              />
            </Button>
          )}
          {showConfirmCancelButton && (
            <Button className="button-cancel button-xl" onClick={handleConfirmCancelMeeting}>
              <Label
                textClassName="text-button-md-med"
                editable={isEditable}
                value={confirmCancelButtonLabel}
                onChange={handleConfirmCancelLabelChange}
              />
            </Button>
          )}
          {showNextButton && (
            <Button
              className="justify-content-center gap-8px button-xl p-0 w-48px h-48px"
              outlined
              onClick={handleNext}
            >
              <ArrowRightIcon className="icon-18px" />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
