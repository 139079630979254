import { UpsertBookingTemplateRequest } from '../../generated-sources/internal-api/models/UpsertBookingTemplateRequest';
import { UpsertBookingTemplateResponse } from '../../generated-sources/internal-api/models/UpsertBookingTemplateResponse';
import { API_PUBLIC } from '../../types/constants';
import { handleAPIRequest } from '../utils/reduxUtils';
import { GetBookingTemplatesResponse } from './types';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getBookingTemplates = async (workspaceId: string): Promise<GetBookingTemplatesResponse> => {
  return handleAPIRequest<GetBookingTemplatesResponse>(`${API_PUBLIC}/getBookingTemplate`, { workspaceId });
};

export const getBookingTemplate = async (bookingTemplateId: string): Promise<GetBookingTemplatesResponse> => {
  return handleAPIRequest<GetBookingTemplatesResponse>(`${API_PUBLIC}/getBookingTemplate`, { bookingTemplateId });
};

export const upsertBookingTemplate = async (
  data: UpsertBookingTemplateRequest['data']
): Promise<UpsertBookingTemplateResponse> => {
  return handleAPIRequest<UpsertBookingTemplateResponse>(`${API_PUBLIC}/upsertBookingTemplate`, {
    data,
  });
};

export const deleteBookingTemplates = async (bookingTemplateIds: string[], workspaceId: string) => {
  return handleAPIRequest<void>(`${API_PUBLIC}/deleteBookingTemplate`, {
    data: bookingTemplateIds.map((id) => ({ id, workspaceId })),
  });
};
