import { ACTIONS, CallBackProps, Step } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Path } from '../../../routing';
import { menuSelectors } from '../../../store/menu';
import { GuideType, promoActions } from '../../../store/promo';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import { GUIDE_DELAY_MILLISECONDS } from '../../../types/constants';
import labels from './labels';
import type { GuideStepData } from './types';

export interface GuideStepsProps {
  all?: boolean;
  showAgain?: boolean;
}

export const getGuideSteps = ({ all, showAgain }: GuideStepsProps): Step[] => {
  const dispatch = useDispatch();
  const navigationMenuItems = useSelector(menuSelectors.selectNavigationMenuItems);
  const userMenuItems = useSelector(menuSelectors.selectUserMenuItems);
  const isMenuOpened = useSelector(userSettingsSelectors.selectIsMenuOpened);

  const getExistStep = (id: string) => {
    return {
      exist: [...navigationMenuItems, ...userMenuItems].some((item) => item.id === id),
      target: `#${id}`,
    };
  };

  const handleOpenMenuWithGuide = (callBackProps?: CallBackProps) => {
    console.log(!isMenuOpened, callBackProps?.action !== ACTIONS.CLOSE);
    if (!isMenuOpened && callBackProps?.action !== ACTIONS.CLOSE) {
      dispatch(promoActions.stopGuide());
      dispatch(userSettingsActions.setIsMenuOpened(true));
      dispatch(userSettingsActions.setIsGuiderPopupOverUserMenu(true));

      setTimeout(() => {
        dispatch(promoActions.runGuide());
      }, GUIDE_DELAY_MILLISECONDS);
    }
  };

  const handleCloseGuideAndMenu = () => {
    dispatch(userSettingsActions.setIsMenuOpened(false));
    dispatch(userSettingsActions.setIsGuiderPopupOverUserMenu(false));
  };

  const handleStopGuider = () => {
    dispatch(promoActions.stopGuide());
    handleCloseGuideAndMenu();
  };

  const SHOW_AGAIN_GUIDE_STEPS: (Omit<Step, 'data'> & { exist: boolean; data?: GuideStepData })[] = [
    {
      ...getExistStep('guided-tour-link'),
      title: labels.guidedTourTopTitle,
      content: (
        <>
          <div>{labels.guidedTourDescription}</div>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
      data: {
        hideStepCounter: true,
        hideSkipButton: true,
        showCloseButton: true,
        onEventStepAfter: () => {
          dispatch(promoActions.setGuideStepIndex(0));
          dispatch(promoActions.setGuideType(GuideType.ALL));
          handleOpenMenuWithGuide();
        },
        onEventTargetNotFound: handleOpenMenuWithGuide,
        styles: {
          tooltip: {
            footer: {
              buttonNext: {
                className: 'text-label-md-med py-16px px-22px',
              },
            },
          },
        },
        label: {
          next: labels.startTour,
        },
      },
    },
  ];

  const ALL_STEPS: (Omit<Step, 'data'> & { exist: boolean; data?: GuideStepData })[] = [
    {
      ...getExistStep('guided-tour-link'),
      title: labels.guidedTourTopTitle,
      content: (
        <>
          <div className="my-12px">{labels.guidedTourDescription}</div>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
      data: {
        onEventStepAfter: handleOpenMenuWithGuide,
        onEventTargetNotFound: handleOpenMenuWithGuide,
      },
    },
    {
      ...getExistStep('profile-link'),
      title: labels.profileTopTitle,
      content: (
        <>
          <div className="my-12px">{labels.profileDescription}</div>
          <Link to={Path.Account} onClick={handleStopGuider}>
            {labels.goTo} {labels.profileLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
      data: {
        onEventStepAfter: handleOpenMenuWithGuide,
        onEventTargetNotFound: handleOpenMenuWithGuide,
      },
    },
    {
      ...getExistStep('integration-link'),
      title: labels.integrationTopTitle,
      content: (
        <>
          <div className="my-12px">{labels.integrationDescription}</div>
          <Link to={Path.Integration} onClick={handleStopGuider}>
            {labels.add} {labels.integrationLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
      data: {
        onEventStepBefore: handleCloseGuideAndMenu,
        onEventStepAfter: handleOpenMenuWithGuide,
      },
    },
    {
      ...getExistStep('availability-link'),
      title: labels.availabilityTopTitle,
      content: (
        <>
          <div className="my-12px">{labels.availabilityDescription}</div>
          <Link to={Path.Availability} onClick={handleStopGuider}>
            {labels.goTo} {labels.availabilityLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
      data: {
        onEventStepAfter: handleOpenMenuWithGuide,
        onEventTargetNotFound: handleOpenMenuWithGuide,
      },
    },
    {
      exist: true,
      target: 'body',
      title: labels.sumoInboxTopTitle,
      content: (
        <>
          <div className="my-12px">{labels.sumoInboxDescription}</div>
          <Link to={Path.Integration} onClick={handleStopGuider}>
            {labels.sumoInboxLink}
          </Link>
        </>
      ),
      placement: 'center',
      disableBeacon: true,
      data: {
        onEventStepBefore: handleCloseGuideAndMenu,
        onEventStepAfter: (props) => {
          if (props.action === ACTIONS.PREV) {
            handleOpenMenuWithGuide();
          }
        },
      },
    },
    {
      ...getExistStep('booking-pages-link'),
      title: labels.bookingPagesTopTitle,
      content: (
        <>
          <div className="my-12px">{labels.bookingPagesDescription}</div>
          <Link to={Path.BookingPages} onClick={handleStopGuider}>
            {labels.goTo} {labels.bookingPagesLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('booked-meetings-link'),
      title: labels.bookedMeetingsTopTitle,
      content: (
        <>
          <div className="my-12px">{labels.bookedMeetingsDescription}</div>
          <Link to={Path.BookedMeetings} onClick={handleStopGuider}>
            {labels.goTo} {labels.bookedMeetingsLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('smart-alerts-link'),
      title: labels.smartAlertsTopTitle,
      content: (
        <>
          <div className="my-12px">{labels.smartAlertsDescription}</div>
          <Link to={Path.SmartAlerts} onClick={handleStopGuider}>
            {labels.goTo} {labels.smartAlertsLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('booking-templates-link'),
      title: labels.bookingTemplatesTopTitle,
      content: (
        <>
          <div className="my-12px">{labels.bookingTemplatesDescription}</div>
          <Link to={Path.BookingTemplates} onClick={handleStopGuider}>
            {labels.goTo} {labels.bookingTemplatesLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('teams-link'),
      title: labels.teamsTopTitle,
      content: (
        <>
          <div className="my-12px">{labels.teamsDescription}</div>
          <Link to={Path.Teams} onClick={handleStopGuider}>
            {labels.goTo} {labels.teamsLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('journey-builder-link'),
      title: labels.journeyBuilderTopTitle,
      content: (
        <>
          <div className="my-12px">{labels.journeyBuilderDescription}</div>
          <Link to={Path.Journeys} onClick={handleStopGuider}>
            {labels.goTo} {labels.journeyBuilderLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('users-link'),
      title: labels.usersTopTitle,
      content: (
        <>
          <div className="my-12px">{labels.usersDescription}</div>
          <Link to={Path.Users} onClick={handleStopGuider}>
            {labels.goTo} {labels.usersLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('roles-link'),
      title: labels.rolesTopTitle,
      content: (
        <>
          <div className="my-12px">{labels.rolesDescription}</div>
          <Link to={Path.Roles} onClick={handleStopGuider}>
            {labels.goTo} {labels.rolesLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('billing-link'),
      title: labels.billingTopTitle,
      content: (
        <>
          <div className="my-12px">{labels.billingDescription}</div>
          <Link to={Path.Billing} onClick={handleStopGuider}>
            {labels.goTo} {labels.billingLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
  ];

  if (all) {
    return ALL_STEPS.filter((item) => item.exist);
  }

  if (showAgain) {
    return SHOW_AGAIN_GUIDE_STEPS;
  }

  return [];
};
