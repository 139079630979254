import { EllipsisVerticalIcon, GlobeAltIcon, InformationCircleIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { TabPanel, TabView } from 'primereact/tabview';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AvailabilityOverride, AvailabilityTable } from '../';
import { ReactComponent as StarIcon } from '../../../assets/icons/12-star.svg';
import { useSession } from '../../../hooks/useSession';
import { deepEqual } from '../../../services/utils';
import {
  availabilityActions,
  availabilitySelectors,
  changeAvailabilityNameModalActions,
  changeAvailabilityNameModalSelectors,
} from '../../../store/availability';
import { bookingPagesActions } from '../../../store/bookingPages';
import { ModalName } from '../../../store/modals';
import { notificationsActions } from '../../../store/notifications';
import { AppDispatch } from '../../../store/rootStore';
import { userSettingsSelectors } from '../../../store/userSettings';
import { LIMIT_ENTITIES_FOR_TRIAL, MAX_LENGTH_NAME } from '../../../types/constants';
import { ConfirmationModal, Modal, UserAvatar } from '../../common';
import { ErrorText } from '../../common/errorText/ErrorText';
import labels from './labels';
import { authenticationSelectors, TRIAL_LIMIT_ERROR_TOAST } from '../../../store/authentication';
import { OrgType } from '../../../API';
import { Tooltip } from 'primereact/tooltip';
import { v4 as getId } from 'uuid';

export const AvailabilityRecord = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isInitialVisit } = useSession(); // checking if the app was initialized on current url
  const fullName = useSelector(userSettingsSelectors.selectFullName) || '';
  const avatarLink = useSelector(userSettingsSelectors.selectAvatar);
  const availabilities = useSelector(availabilitySelectors.selectAvailabilities);
  const id = useSelector(availabilitySelectors.selectAvailabilityId);
  const name = useSelector(availabilitySelectors.selectName);
  const isDefault = useSelector(availabilitySelectors.selectIsDefault);
  const cloneName = useSelector(availabilitySelectors.selectCloneName);
  const isNameDuplicate = useSelector(availabilitySelectors.selectIsNameDuplicate);
  const isNameValid = useSelector(availabilitySelectors.selectIsNameValid);
  const isCloneNameDuplicate = useSelector(availabilitySelectors.selectIsCloneNameDuplicate);
  const isCloneNameValid = useSelector(availabilitySelectors.selectIsCloneNameValid);
  const isWeeklyHoursValid = useSelector(availabilitySelectors.selectIsWeeklyHoursValid);
  const availabilitiesOptions = useSelector(availabilitySelectors.selectUserAvailabilitiesOptions);
  const selectedAvailability = useSelector(availabilitySelectors.selectAvailability);
  const selectedInitAvailability = useSelector(availabilitySelectors.selectInitAvailability);
  const oldestAvailability = useSelector(availabilitySelectors.selectOldestAvailability(id || ''));
  const isPresentFilters = useSelector(availabilitySelectors.selectIsPresentFilters);
  const tenantType = useSelector(authenticationSelectors.selectTenantType);

  const isUserSettingsReceived = useSelector(userSettingsSelectors.selectIsUserSettingsReceived);
  const isCreateAvail = useSelector(userSettingsSelectors.selectAvailabilityCreate);
  const isEditAvail = useSelector(userSettingsSelectors.selectAvailabilityEdit);
  const isDeleteAvail = useSelector(userSettingsSelectors.selectAvailabilityDelete);

  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);

  const timeZoneCity = useSelector(userSettingsSelectors.selectTimeZoneCity);
  const timeZoneUTC = useSelector(userSettingsSelectors.selectTimeZoneUTC);

  const isChangeNameModalOpen = useSelector(changeAvailabilityNameModalSelectors.selectIsModalOpen);
  const isLocked = useSelector(availabilitySelectors.selectIsAvailabilityLocked);

  const [isClone, setIsClone] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const menu = useRef<Menu>(null);

  useEffect(() => {
    if (isUserSettingsReceived && isLicenseActive) {
      dispatch(bookingPagesActions.getBookingPagesPageRequest(isInitialVisit));
    }
  }, [isUserSettingsReceived, isInitialVisit]);

  useEffect(() => {
    // if the system will found valid changes after 0.5sec it will update the availability
    const timer = setTimeout(() => {
      if (
        !deepEqual(selectedAvailability, selectedInitAvailability) &&
        isWeeklyHoursValid &&
        !isChangeNameModalOpen &&
        isEditAvail
      ) {
        dispatch(availabilityActions.saveAvailabilityRequest());
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [selectedAvailability, selectedInitAvailability, dispatch]);

  const handleEditName = () => {
    setIsClone(false);
    dispatch(availabilityActions.editAvailabilityNameThunk());
  };

  const handleCloneAvailability = () => {
    if (availabilitiesOptions.length >= LIMIT_ENTITIES_FOR_TRIAL && tenantType !== OrgType.TEST) {
      dispatch(notificationsActions.showToast(TRIAL_LIMIT_ERROR_TOAST));
    } else {
      setIsClone(true);
      dispatch(availabilityActions.cloneAvailabilityThunk());
    }
  };

  const handleDeleteAvailability = () => {
    dispatch(availabilityActions.deleteAvailabilityRequest(id as string));
    setIsConfirmModalOpen(false);
  };

  const handleNameChange = (name: string) => {
    dispatch(availabilityActions.setCloneName(name));
  };

  const handleNameDialogCancel = () => {
    dispatch(changeAvailabilityNameModalActions.closeModal());
  };

  const handleNameSave = () => {
    if (isClone) {
      dispatch(availabilityActions.cloneAvailabilityRequest());
    } else {
      dispatch(availabilityActions.updateAvailability({ name: cloneName }));
      dispatch(availabilityActions.saveAvailabilityRequest());
    }
    dispatch(changeAvailabilityNameModalActions.closeModal());
  };

  const handleSetAsDefault = () => {
    dispatch(availabilityActions.setDefaultAvailabilityRequest());
  };

  const templateTooltipMenuItem = (label: string, tooltip: string, handler: () => void, isDisable: boolean) => {
    const uniqueId = `id-${getId()}`;
    return (
      <>
        <Tooltip target={`#${uniqueId}`} className="max-w-240px" content={tooltip} />

        <div id={uniqueId} className="p-menuitem" role="none" data-pc-section="menuitem">
          <span
            onClick={handler}
            className={'p-menuitem-text p-menuitem-link' + (isDisable ? ' p-disabled' : '')}
            data-pc-section="label"
          >
            {label}
          </span>
        </div>
      </>
    );
  };

  const menuItems = [
    {
      label: labels.editName,
      command: handleEditName,
      visible: isEditAvail,
    },
    {
      label: labels.clone,
      command: handleCloneAvailability,
      visible: isCreateAvail,
    },
    ...(!isDefault
      ? ([
          {
            visible: isEditAvail,
            template: templateTooltipMenuItem(
              labels.setDefault,
              labels.setDefaultOnlyScheduleNotSpecific,
              handleSetAsDefault,
              isPresentFilters
            ),
          },
        ] as MenuItem[])
      : []),
    ...(availabilities.length > 1
      ? [
          isDefault
            ? {
                visible: isDeleteAvail,
                template: templateTooltipMenuItem(
                  labels.delete,
                  labels.needAnotherNotSpecificScheduleForRemoval,
                  () => setIsConfirmModalOpen(true),
                  !oldestAvailability
                ),
              }
            : {
                label: labels.delete,
                command: () => setIsConfirmModalOpen(true),
                visible: isDeleteAvail,
                disabled: !oldestAvailability,
              },
        ]
      : []),
  ] as MenuItem[];

  return (
    <div>
      <div className="sumo-card border-none flex flex-column">
        <div className="flex-left-center px-20px py-12px">
          <UserAvatar size="xlarge" name={fullName} avatarLink={avatarLink} className="w-48px h-48px" />
          <div className="flex flex-column gap-4px ml-16px">
            <div className="text-title-lg-med">{name}</div>
            {isDefault && (
              <div className="flex align-items-center gap-4px text-label-xs-reg text-heavy-60">
                <div>
                  <StarIcon width={12} height={12} />
                </div>
                <span>{labels.isDefault}</span>
              </div>
            )}
          </div>
          {(isEditAvail || isCreateAvail || (availabilities.length > 1 && isDeleteAvail)) && (
            <div
              className="action-button ml-6px"
              onClick={(e) => {
                e.stopPropagation();
                menu.current?.toggle(e);
              }}
            >
              <Menu ref={menu} model={menuItems} popup appendTo="self" className="scroll-menu" />
              <EllipsisVerticalIcon width={20} height={20} />
            </div>
          )}
          <div className="flex-left-center gap-8px ml-auto">
            <div className="flex-center h-40px w-40px bg-heavy-1 border-radius-6px">
              <GlobeAltIcon className="icon-24px text-heavy-60" />
            </div>
            <div className="flex flex-column gap-5px">
              <div className="text-label-lg-med">{timeZoneCity}</div>
              <div className="text-label-xs-reg text-heavy-60">{timeZoneUTC}</div>
            </div>
            {/*   <ChevronDownIcon width={16} hanging={16} className="text-heavy-50" /> {/* className to show disabled */}
          </div>
        </div>

        <div className="mb-10px border-bottom-1 border-heavy-20" />

        {!isDefault && !isPresentFilters && (
          <div className="flex gap-8px py-12px px-16px mx-20px border-radius-8px text-saffron-dark bg-saffron-light">
            <InformationCircleIcon className="icon-18px flex-none" />
            <div className="flex-1 text-label-xs-reg my-1">{labels.specifyWhatScheduleUsedFor}</div>
          </div>
        )}

        {isLocked && (
          <div className="flex-left-center gap-8px py-12px px-16px mx-20px bg-heavy-1 text-heavy-100 border-radius-8px">
            <LockClosedIcon className="icon-18px flex-none" />
            <div className="text-body-s-reg">{labels.lockedTooltip}</div>
          </div>
        )}

        <TabView>
          <TabPanel header={labels.schedule} disabled={isLocked}>
            <AvailabilityTable />
          </TabPanel>
          <TabPanel header={labels.overrides} disabled={isLocked}>
            <AvailabilityOverride />
          </TabPanel>
        </TabView>
      </div>

      <Modal.Container name={ModalName.CHANGE_AVAILABILITY_NAME} closable={true} onClose={handleNameDialogCancel}>
        <Modal.Header>
          <div>{isClone ? labels.cloneTitle : labels.dialogEditTitleName}</div>
        </Modal.Header>
        <div className="w-440px">
          <InputText
            type="text"
            value={cloneName}
            onChange={(e) => {
              handleNameChange(e.target.value);
            }}
            placeholder={labels.dialogPlaceholder}
            className={`w-full ${(isClone ? !isCloneNameValid : !isNameValid) && 'p-invalid'}`}
            maxLength={MAX_LENGTH_NAME}
          />
          {(isClone ? isCloneNameDuplicate : isNameDuplicate) && (
            <ErrorText text={labels.duplicateNameMessage} className="mt-10px" />
          )}
        </div>
        <Modal.Buttons>
          <Button onClick={handleNameSave} disabled={isClone ? !isCloneNameValid : !isNameValid} autoFocus>
            {labels.confirm}
          </Button>
          <Button onClick={handleNameDialogCancel} text>
            {labels.cancel}
          </Button>
        </Modal.Buttons>
      </Modal.Container>

      <ConfirmationModal
        visible={isConfirmModalOpen}
        title={labels.delete}
        description={`${labels.deleteDesc} "${name}"? ${labels.deleteWarningMessage}`}
        additionalText={[isDefault ? `"${oldestAvailability?.name}" ${labels.deleteMessage}` : '']}
        confirmButtonLabel={labels.yesDelete}
        cancelButtonLabel={labels.noCancel}
        onConfirm={handleDeleteAvailability}
        onCancel={() => setIsConfirmModalOpen(false)}
        onClose={() => setIsConfirmModalOpen(false)}
      />
    </div>
  );
};
