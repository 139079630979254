import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { globalSelectors } from '../global';
import { DropdownOption, LocationsByIdType } from './types';
import { userSettingsSelectors } from '../userSettings';
import { StatusFilter } from '../../types/types';
import { isOldData } from '../../services/utils';
import { countriesTimeZonesService } from '../../services/CountriesTimeZoneService';
import i18n, { t } from '../../i18n/i18n';

const locationsState = (state: State) => state.locations;

const selectIsFetching = createSelector(locationsState, (state) => state.isFetching);
const selectError = createSelector(locationsState, (state) => state.error);
const selectLocations = createSelector(locationsState, (state) => state.locations);
const selectLocation = createSelector(locationsState, (state) => state.location);
const selectCloneName = createSelector(locationsState, (state) => state.cloneName);
const selectFilter = createSelector(locationsState, (state) => state.filter);
const selectSelectedLocations = createSelector(locationsState, (state) => state.selectedLocations);
const selectLastLoadTime = createSelector(locationsState, (state) => state.lastLoadTime);
const selectDeleteDependences = createSelector(locationsState, (state) => state.deleteDependences);

const selectIsSpinnerFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);

const selectLocationIds = createSelector(selectLocations, (locations) => locations.map(location => location.id));

const selectFilterStatus = createSelector(selectFilter, (filter) => filter.status);
const selectFilterState = createSelector(selectFilter, (filter) => filter.state);
const selectFilterCountry = createSelector(selectFilter, (filter) => filter.country);
const selectFilterCity = createSelector(selectFilter, (filter) => filter.city);
const selectIsFilterInUse = createSelector(
  selectFilter,
  (filter) => filter.status.length != 2 || !!filter.state.length || !!filter.country.length || !!filter.city.length
);

const selectActiveLocationsList = (locationsId: string[]) =>
  createSelector(selectLocations, (locations) =>
    locations.filter((location) => location.active || locationsId.includes(location.id))
  );

const selectFilteredLocations = createSelector(selectLocations, selectFilter, (locations, filter) =>
  locations.filter(
    (location) =>
      ((location.active && filter.status.includes(StatusFilter.ACTIVE)) ||
        (!location.active && filter.status.includes(StatusFilter.INACTIVE))) &&
      (!filter.country.length || filter.country.includes(location.address?.country || '')) &&
      (!filter.state.length || filter.state.includes(location.address?.state || '')) &&
      (!filter.city.length || filter.city.includes(location.address?.city || ''))
  )
);

const selectSearchedLocations = createSelector(
  selectFilteredLocations,
  globalSelectors.selectLowercasedSearchString,
  (locations, searchString) =>
    (searchString
      ? locations.filter(
          (location) =>
            location.name?.toLowerCase().includes(searchString) ||
            location.address?.name?.toLowerCase().includes(searchString) ||
            location.address?.zip?.toLowerCase().includes(searchString) ||
            location.address?.city?.toLowerCase().includes(searchString)
        )
      : locations
    ).sort((a, b) => {
      const nameA = a.name || '';
      const nameB = b.name || '';
      return nameA.localeCompare(nameB);
    })
);

const selectIsCloneNameValid = createSelector(selectCloneName, (name) => Boolean(name));

const selectLocationId = createSelector(selectLocation, (location) => location.id);
const selectLocationName = createSelector(selectLocation, (location) => location.name);
const selectLocationAddress = createSelector(selectLocation, (location) => location.address?.name || '');

const selectFilterStateOptions = createSelector(selectLocations, (locations) =>
  locations.reduce((res, location) => {
    if (location.address?.state && !res.some((record) => record.value === location.address?.state))
      res.push({ label: location.address?.state, value: location.address?.state });
    return res;
  }, new Array<DropdownOption>())
);

const selectFilterCountryOptions = createSelector(selectLocations, (locations) =>
  locations.reduce((res, location) => {
    if (location.address?.country && !res.some((record) => record.value === location.address?.country))
      res.push({
        label:
          countriesTimeZonesService.getCountryName(location.address?.country || '', i18n.language) ||
          location.address?.country,
        value: location.address?.country,
      });
    return res;
  }, new Array<DropdownOption>())
);

const selectFilterCityOptions = createSelector(selectLocations, (locations) =>
  locations.reduce((res, location) => {
    if (location.address?.city && !res.some((record) => record.value === location.address?.city))
      res.push({ label: location.address?.city, value: location.address?.city });
    return res;
  }, new Array<DropdownOption>())
);

const selectSelectedLocationIds = createSelector(selectSelectedLocations, (locations) =>
  locations.map((location) => location.id)
);

const selectLocationsById = createSelector(selectLocations, (locations) =>
  locations.reduce((res, location) => {
    res[location.id] = location;
    return res;
  }, {} as LocationsByIdType)
);

const selectLocationReadOnly = createSelector(
  selectLocationId,
  userSettingsSelectors.selectLocationsRead,
  userSettingsSelectors.selectLocationsCreate,
  userSettingsSelectors.selectLocationsEdit,
  (id, isRead, isCreate, isEdit) => (id ? isRead && !isEdit : isRead && !isCreate)
);

const selectDeleteLocationWarningMessage = createSelector(
  selectDeleteDependences,
  selectSelectedLocationIds,
  selectLocationName,
  (dependences, selectedIds, locationName) => {
    const selectedCount = selectedIds.length;

    const locationLabel = selectedCount
      ? `${selectedCount} ${t('Locations:SELECTED_LOCATIONS')}`
      : t('Locations:SINGLE_LOCATION', { name: locationName });

    const userImpact = dependences.users?.length ? t('Locations:USER_IMPACT') : '';

    const hasBookingPages = dependences.bookingPages?.length;
    const hasBookingTemplates = dependences.bookingTemplates?.length;

    const bookingImpact =
      hasBookingPages || hasBookingTemplates
        ? `${userImpact ? ` ${t('Common:AND').toLowerCase()} ` : ''}${t('Locations:REMOVE_FROM')}`
        : '';

    const bookingDetails =
      (hasBookingPages && hasBookingTemplates
        ? t('Locations:BOOKING_PAGE_AND_TEMPLATE')
        : hasBookingPages
        ? t('BookingPages:TITLE').toLowerCase()
        : hasBookingTemplates
        ? t('BookingTemplates:TITLE').toLowerCase()
        : '') + ':';

    return t('Locations:WARNING_MESSAGE', {
      locationLabel,
      userImpact,
      bookingImpact,
      bookingDetails,
    });
  }
);

const selectIsLocationCoordinatesMissing = createSelector(
  selectLocation,
  (location) => !!location.id && !location.address?.lat && !location.address?.lng
);

export const locationsSelectors = {
  selectIsFetching,
  selectError,
  selectLocations,
  selectLocation,
  selectCloneName,
  selectFilter,
  selectSelectedLocations,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,
  selectDeleteDependences,
  selectLocationIds,

  selectFilterStatus,
  selectFilterCountry,
  selectFilterCity,
  selectFilterState,
  selectFilterCityOptions,
  selectIsFilterInUse,

  selectActiveLocationsList,
  selectFilteredLocations,
  selectSearchedLocations,
  selectIsCloneNameValid,

  selectLocationName,
  selectLocationAddress,

  selectFilterStateOptions,
  selectFilterCountryOptions,

  selectSelectedLocationIds,
  selectLocationsById,

  selectLocationReadOnly,
  selectDeleteLocationWarningMessage,

  selectIsLocationCoordinatesMissing,
};
