export const Common = Object.freeze({
  ACCEPT_INVITE: `Aceitar convite`,
  ACCESS_RESTRICT: `Desculpe, você não está autorizado a acessar esta página`,
  ACTIVATE: `Ativar`,
  ACTIVE: `Ativo`,
  ADDRESS: `Endereço`,
  ALL: `Todos`,
  AND: `E`,
  ARE_YOU_SURE: `Você tem certeza?`,
  AUTHORISE: `Autorizar`,
  AVAILABLE: `Disponível`,
  BACK: `Voltar`,
  CALENDAR_DAYS: `Dias de calendário`,
  CANCEL: `Cancelar`,
  CHOOSE_DATE: `Escolher data`,
  CITY: `Cidade`,
  CLEAR: `Limpar`,
  CLEAR_FILTER: `Limpar filtro`,
  CLICK_HERE: `Clique aqui`,
  CLONE: `Clonar`,
  CLONE_NAME_POSTFIX: ` (cópia)`,
  CLOSE: `Fechar`,
  COMMON: `Comum`,
  COMPLETE: `Completo`,
  CONNECT: `Conectar`,
  CONNECTED: `Conectado`,
  CONFIRM: `Confirmar`,
  CONFIRM_DELETE: `Confirmar exclusão`,
  COMING_SOON: `Em breve`,
  COPY: `Copiar`,
  COPY_LINK: `Copiar link`,
  COPIED_LINK: `Copiado!`,
  COUNTINUE: `Continuar`,
  COUNTRY: `País`,
  CREATE: `Criar`,
  CUSTOM: `Personalizado`,
  CUSTOMIZE: `Personalizar`,
  DATE: `Data`,
  DATE_RANGE: `Intervalo de datas`,
  DAYS: `dias`,
  DEACTIVATE: `Desativar`,
  DEFAULT: `Padrão`,
  DELETE: `Excluir`,
  DELETED: `EXCLUÍDO`,
  DESCRIPTION: `Descrição`,
  DISABLE: `Desativar`,
  DISCONNECT: `Desconectar`,
  DOWNLOAD: `Baixar`,
  EDIT: `Editar`,
  EMAIL: `E-mail`,
  EMAIL_ALL_SMALL: `e-mail`,
  EMPTY_ERROR: `Não pode ficar vazio`,
  EMPTY_LIST: `Parece que esta lista está vazia.`,
  ENABLE: `Ativar`,
  ERROR: `Erro`,
  EXPORT: `Exportar`,
  FILTER: `Filtrar`,
  FIRST_NAME: `Nome próprio`,
  FRIDAYS: `Sextas-feiras`,
  FROM: `de`,
  GOOGLE: `Google`,
  HOURS: `horas`,
  HOUR_SHORTENED: `hrs`,
  INACTIVE: `Inativo`,
  INFO: `Informação`,
  INTERNAL: `Interno`,
  LAST_NAME: `Apelido`,
  LEARN_MORE: `Saiba mais`,
  LOCKED: `Bloqueado`,
  LOGIN: `Login`,
  LOG_OUT: `Sair`,
  MICROSOFT: `Microsoft`,
  MINUTE_SHORTENED: `mins`,
  MINUTES: `minutos`,
  MONDAYS: `Segundas-feiras`,
  MY: `Meu`,
  MY_ACCOUNT: `Minha conta`,
  NAME: `Nome`,
  NAME_IS_DUPLICATE: `Ops! Este nome já foi usado. Por favor, insira um diferente.`,
  NEXT: `Próximo`,
  NONE: `Nenhum`,
  NO: `Não`,
  NO_CANCEL: `Não, cancelar`,
  NO_RESULTS_FOUND: `Nenhum resultado encontrado`,
  NEVER: 'Nunca',
  OFF: `Desligado`,
  ON: `Ligado`,
  ADD: `Adicionar`,
  ONLY_ONE_ACCOUNT: `Você só pode ter uma conta SUMO1 por endereço de e-mail.`,
  PASSWORD: `Senha`,
  PHONE_NUMBER: `Número de telefone`,
  PICTURE_FORMATS: `svg, jpg, gif, png`,
  PICTURE_SIZE: `Tamanho máximo 5 MB`,
  PREVIEW: `Visualização`,
  QUICK_SETUP: `Configuração rápida`,
  REGISTER: `Registrar`,
  RESET: `Redefinir`,
  RESET_FILTER: `Redefinir filtro`,
  ROLES: `Funções`,
  SATURDAYS: `Sábados`,
  SAVE: `Salvar`,
  SAVE_CHANGES: `Salvar alterações`,
  SAVE_AND_CLOSE: `Salvar e fechar`,
  SEARCH: `Buscar`,
  SECOND_SHORTENED: `seg`,
  SELECT: `Selecionar`,
  SELECT_ALL: `Selecionar tudo`,
  SELECTED: `Selecionado`,
  SET_UP: `Configurar`,
  SHARE: `Compartilhar`,
  SIGN_OUT: `Sair`,
  SIGN_UP: `Cadastrar-se`,
  STATE: `Estado`,
  STATUS: `Status`,
  SUCCESS: `Sucesso`,
  SUNDAYS: `Domingos`,
  SUSPENDED: `Suspenso`,
  TIMEZONE: `Fuso horário`,
  THURSDAYS: `Quintas-feiras`,
  TIMES: `Vezes`,
  TODAY: `Hoje`,
  TO: `para`,
  FOR: `para`,
  TUESDAYS: `Terças-feiras`,
  TYPE: `Tipo`,
  UNAVAILABLE: `Indisponível`,
  UNLINK: `Desvincular`,
  UNSAVED_CHANGES: `Todas as alterações não salvas serão perdidas.`,
  UNSELECT: `Desmarcar`,
  UPLOAD: `Enviar`,
  UPLOAD_PICTURE: `Enviar imagem`,
  USER: `Usuário`,
  USERS: `Usuários`,
  UPDATE: `Atualizar`,
  VIEW: `Visualizar`,
  YES: `Sim`,
  YES_CANCEL: `Sim, Cancelar`,
  YES_DELETE: `Sim, excluir`,
  WARNING: `Aviso`,
  WEDNESDAYS: `Quartas-feiras`,
  WEEKDAYS: `Dias da semana`,
  TOTAL: `Total`,
  ZIP: `CEP`,
  CHARACTERS: `Caracteres`,
});

export const ErrorPage = Object.freeze({
  TITLE_403: `403 - Erro proibido`,
  TITLE_404: `404 - Página não encontrada`,
  TITLE_500: `500 - Erro interno do servidor`,
  MESSAGE_403_1: `Você não tem permissão para acessar `,
  MESSAGE_403_2: ` neste servidor.`,
  MESSAGE_404_1: `A URL solicitada `,
  MESSAGE_404_2: ` não foi encontrada neste servidor.`,
  MESSAGE_500_1: `Houve um erro. Isso é tudo o que sabemos. Por favor, aguarde e `,
  MESSAGE_500_2: `tente recarregar`,
  MESSAGE_500_3: ` novamente.`,
  HOME_BUTTON: `Início`,
});

export const PrivateRoute = Object.freeze({
  GUIDED_TOUR: `Guided Tour`,
  ACCOUNT: `Conta`,
  ACCOUNT_SETTINGS: `Configurações da conta`,
  PROFILE: `Perfil`,
  ADMIN_SETTINGS: `Configurações de administrador`,
  AVAILABILITY: `Disponibilidade`,
  CALENDAR_CONNECTIONS: `Conexões de calendário`,
  HELP_CENTER: `Central de Ajuda`,
  HOME: `Início`,
  USER_SETUP: `Configuração do usuário`,
  USER_SETTINGS: `Configurações do usuário`,
  INTEGRATION: `Integração`,
});

export const PublicLayout = Object.freeze({
  FOOTER_TEXT_PART_1: `© Direitos autorais 2008-`,
  FOOTER_TEXT_PART_2: ` ForceBrain, Inc. Todos os direitos reservados.`,
  PRIVACY_POLICY: `Política de Privacidade`,
  SECURITY_POLICY: `Política de Segurança`,
  TERMS: `Termos`,
});

export const AppMenu = Object.freeze({
  ACCOUNTS_TITLE: `Contas`,
  BILLING_TITLE: `Faturamento`,
  OPERATIONS_TITLE: `Operações`,
  ORGS_TITLE: `Organizações`,
  SMART_ALERTS: `Alertas Inteligentes`,
  STAFF_TITLE: `Equipe`,
  SUPPORT_TITLE: `Suporte`,
  TOOLS_TITLE: `Ferramentas`,
  OPEN_MY_SUMO1: `Abrir Meu SUMO1`,
  OPS_CONSOLE: `Console OPS`,
  JOURNEY_TITLE: `Construtor de Jornada`,
  MEETINGS_TITLE: `Reuniões`,
  PEOPLE_TITLE: `Pessoas`,
  ORGANIZATION_TITLE: `Organização`,
});

export const LicenseExpired = Object.freeze({
  BOOK_MEETING_DESCRIPTION: `Agende uma chamada ou reunião virtual com um especialista`,
  BOOK_MEETING_TITLE: `Agendar uma reunião`,
  CALL_DESCRIPTION_PART1: `Entre em contato conosco pelo 800-708-1790,`,
  CALL_DESCRIPTION_PART2: `de segunda a sexta, das 8h às 17h EST`,
  CALL_TITLE: `Ligue para nós`,
  CHAT_DESCRIPTION: `Clique no ícone "Converse conosco" no canto inferior direito para iniciar a conversa`,
  CHAT_TITLE: `Converse agora`,
  CONTACT_TITLE: `Entre em contato conosco para obter assistência`,
  DESCRIPTION_ADMIN_TRIAL: `O período de teste gratuito da sua organização terminou. Você pode atualizá-lo clicando na opção abaixo ou nos contatar para mais informações.`,
  DESCRIPTION_ADMIN_PAID: `Sua licença expirou. Para continuar usando o SUMO1 sem interrupções, adquira uma nova licença.`,
  DESCRIPTION_PART1_TRIAL: `O período de teste gratuito da sua organização terminou. Entre em contato com o administrador da sua organização`,
  DESCRIPTION_PART1_PAID: `O período de licença da sua organização terminou. Entre em contato com o administrador da sua organização`,
  DESCRIPTION_PART2: `para mais assistência.`,
  EMAIL_DESCRIPTION: `Entre em contato com experts@sumoscheduler.com para obter ajuda`,
  EMAIL_TITLE: `Envie-nos um e-mail`,
  EXPIRED: `Expirado`,
  RENEW_LICENSES: `Renovar licenças`,
  TITLE: `Licença expirada`,
  TITLE_TRIAL: `Teste gratuito expirado`,
  TRIAL_IS_OVER: `O teste terminou`,
  UPGRADE_NOW: `Atualizar agora`,
  VIEW_PRICING_PAGE: `Ver página de preços`,
});

export const BookingStats = Object.freeze({
  CANCELS: `Cancelamentos`,
  MEETINGS_BOOKED: `Reuniões agendadas`,
  REMINDERS_SENT: `Lembretes enviados`,
  RESCHEDULES: `Reagendamentos`,
  TITLE: `Minhas Estatísticas`,
  INSTALL_INBOX_PART1: `Instale a `,
  INSTALL_INBOX_PART2: `extensão do Chrome`,
  INSTALL_INBOX_PART3: ` ou o `,
  INSTALL_INBOX_PART4: `complemento do Edge`,
  INSTALL_INBOX_PART5: ` para enviar links de convite do Gmail ou Outlook Web.`,
  INVITE_PEOPLE_DESCRIPTION: `Adicione usuários ao SUMO1 para aproveitar a co-organização, reserva de equipe, reserva em nome de e muito mais.`,
  INVITE_PEOPLE_TITLE: `Convidar pessoas`,
  CALENDAR_WARNING_DESCRIPTION: `Seu calendário não está conectado. Você corre o risco de fazer uma dupla reserva se não conectar um calendário.`,
  CALENDAR_WARNING_BUTTON: `Conectar calendário`,
  UPDATE_GOOGLE_INTEGRATION_DESCRIPTION: `Por favor, atualize as configurações de integração do Google para aproveitar ao máximo as funcionalidades da aplicação!`,
  UPDATE_GOOGLE_INTEGRATION_BUTTON: `Atualizar integração do Google`,
});

export const StartEasyBooking = Object.freeze({
  STEP1_DESCRIPTION: `Adicione um link "Agendar comigo" à sua assinatura de e-mail.`,
  STEP1_TITLE: `Configurar assinatura de e-mail`,
  STEP2_DESCRIPTION: `Envie um convite para uma reunião para alguém com a extensão do Chrome.`,
  STEP2_TITLE: `Enviar convite com um clique`,
  STEP3_DESCRIPTION: `O que mais eles deveriam testar desde o primeiro dia?`,
  STEP3_TITLE: `Testar outra funcionalidade`,
  TITLE: `Comece a agendar facilmente`,
});

export const Table = Object.freeze({
  PAGE: `Página`,
  OF: `de`,
  OUT_OF: `de`,
});

export const IconPicker = Object.freeze({
  SELECT_TITLE: `Selecionar um ícone`,
  OPTIONAL: `opcional`,
});
