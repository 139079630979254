import { combineReducers } from 'redux';
import { DEFAULT_ACCORDION_INDEXES, DEFAULT_BOOKING_PAGE_DATA, EMPTY_ACCORDION_INDEXES } from './constants';
import { bookingPagesActionTypes, BookingPagesAction } from './actions';
import { CustomFieldInput, UpdateBookingPageInput, WhoInput } from '../../API';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { generateWhereLocationTypeToggle } from '../bookingTemplates';
import { AccordionIndexes } from './types';

const customFields = (state: (CustomFieldInput | null)[] = [], action: BookingPagesAction | GlobalAction) => {
  switch (action.type) {
    case bookingPagesActionTypes.SET_BOOKING_PAGE_FIELDS:
      return action.payload;
    case bookingPagesActionTypes.ADD_MEETING_TYPE_CUSTOM_FIELD:
      return [...state, action.payload];
    case bookingPagesActionTypes.REMOVE_MEETING_TYPE_CUSTOM_FIELD:
      return state.filter((customField) => customField?.id !== action.payload);
    case bookingPagesActionTypes.UPDATE_MEETING_TYPE_CUSTOM_FIELD:
      return state.map((customField) => (customField?.id === action.payload.id ? action.payload : customField));
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const bookingPage = (
  state: UpdateBookingPageInput = DEFAULT_BOOKING_PAGE_DATA,
  action: BookingPagesAction | GlobalAction
) => {
  switch (action.type) {
    case bookingPagesActionTypes.GET_BOOKING_PAGE_SUCCESS:
    case bookingPagesActionTypes.SET_BOOKING_PAGE:
      return action.payload;
    case bookingPagesActionTypes.CLEAR_BOOKING_PAGE:
      return DEFAULT_BOOKING_PAGE_DATA;
    case bookingPagesActionTypes.UPDATE_RECORD:
      return { ...state, ...action.payload } as UpdateBookingPageInput;
    case bookingPagesActionTypes.UPDATE_HOW_STEP:
      return { ...state, labels: { ...state.labels, ...action.payload } } as UpdateBookingPageInput;
    case bookingPagesActionTypes.UPDATE_CALENDAR_STEP:
      return { ...state, calendar: { ...state.calendar, ...action.payload } } as UpdateBookingPageInput;
    case bookingPagesActionTypes.SELECT_TIME_ZONES:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          selectedTimeZone: action.payload,
          defaultTimeZone: action.payload.includes(state.calendar?.defaultTimeZone || '')
            ? state.calendar?.defaultTimeZone
            : action.payload[0],
        },
      } as UpdateBookingPageInput;
    case bookingPagesActionTypes.UPDATE_STYLE_STEP:
      return { ...state, style: { ...state.style, ...action.payload } } as UpdateBookingPageInput;
    case bookingPagesActionTypes.UPDATE_WHAT_STEP:
      return { ...state, what: { ...state.what, ...action.payload } } as UpdateBookingPageInput;
    case bookingPagesActionTypes.UPDATE_WHO_STEP:
      return { ...state, who: { ...state.who, ...action.payload } } as UpdateBookingPageInput;
    case bookingPagesActionTypes.UPDATE_WHERE_STEP:
      return { ...state, where: { ...state.where, ...action.payload } } as UpdateBookingPageInput;
    case bookingPagesActionTypes.TOGGLE_WHERE_LOCATION_TYPE:
      return {
        ...state,
        where: generateWhereLocationTypeToggle(state.where || {}, action.payload),
      } as UpdateBookingPageInput;
    case bookingPagesActionTypes.UPDATE_WHEN_STEP:
      return { ...state, when: { ...state.when, ...action.payload } } as UpdateBookingPageInput;
    case bookingPagesActionTypes.UPDATE_WHEN_DURATION:
      return {
        ...state,
        when: { ...state.when, duration: { ...state.when?.duration, ...action.payload } },
      } as UpdateBookingPageInput;
    case bookingPagesActionTypes.UPDATE_WHEN_DATE_RANGE:
      return {
        ...state,
        when: { ...state.when, dateRange: { ...state.when?.dateRange, ...action.payload } },
      } as UpdateBookingPageInput;
    case bookingPagesActionTypes.UPDATE_WHEN_SCHEDULE_BUFFER:
      return {
        ...state,
        when: { ...state.when, scheduleBuffer: { ...state.when?.scheduleBuffer, ...action.payload } },
      } as UpdateBookingPageInput;
    case bookingPagesActionTypes.ADD_MEETING_TYPE_CUSTOM_FIELD:
    case bookingPagesActionTypes.SET_BOOKING_PAGE_FIELDS:
    case bookingPagesActionTypes.REMOVE_MEETING_TYPE_CUSTOM_FIELD:
    case bookingPagesActionTypes.UPDATE_MEETING_TYPE_CUSTOM_FIELD:
      return {
        ...state,
        inputFields: customFields(state.inputFields as CustomFieldInput[], action),
      } as UpdateBookingPageInput;
    case bookingPagesActionTypes.UPDATE_NOTIFICATIONS_STEP:
      return { ...state, notifications: { ...state.notifications, ...action.payload } } as UpdateBookingPageInput;
    case bookingPagesActionTypes.UPDATE_CONFIRMATION_STEP:
      return {
        ...state,
        confirmationsPage: { ...state.confirmationsPage, ...action.payload },
      } as UpdateBookingPageInput;
    case bookingPagesActionTypes.UPDATE_SMART_ALERTS:
      return state.smartAlertIds?.includes(action.payload)
        ? ({
            ...state,
            smartAlertIds: state.smartAlertIds.filter((id) => id !== action.payload),
          } as UpdateBookingPageInput)
        : ({
            ...state,
            smartAlertIds: state.smartAlertIds ? [...state.smartAlertIds, action.payload] : [action.payload],
          } as UpdateBookingPageInput);
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_BOOKING_PAGE_DATA;
    default:
      return state;
  }
};

const recordWho = (state: WhoInput | null = null, action: BookingPagesAction | GlobalAction) => {
  switch (action.type) {
    case bookingPagesActionTypes.GET_BOOKING_PAGE_SUCCESS:
      return action.payload.who || null;
    case bookingPagesActionTypes.GET_BOOKING_PAGE_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const bookingPages = (state: UpdateBookingPageInput[] = [], action: BookingPagesAction | GlobalAction) => {
  switch (action.type) {
    case bookingPagesActionTypes.GET_BOOKING_PAGES_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const selectedBookingPages = (state: string[] = [], action: BookingPagesAction | GlobalAction) => {
  switch (action.type) {
    case bookingPagesActionTypes.SELECT_BOOKING_PAGE:
      return [...state, action.payload];
    case bookingPagesActionTypes.UNSELECT_BOOKING_PAGE:
      return state.filter((BookingPage) => BookingPage !== action.payload);
    case bookingPagesActionTypes.UNSELECT_ALL_BOOKING_PAGES:
    case bookingPagesActionTypes.CREATE_BOOKING_PAGE_SUCCESS:
    case bookingPagesActionTypes.GET_BOOKING_PAGES_PAGE_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const filterByUser = (state = '', action: BookingPagesAction | GlobalAction) => {
  switch (action.type) {
    case bookingPagesActionTypes.SET_FILTER_BY_USER:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const cloneName = (state = '', action: BookingPagesAction | GlobalAction) => {
  switch (action.type) {
    case bookingPagesActionTypes.SET_CLONE_NAME:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const isEdited = (state = false, action: BookingPagesAction | GlobalAction) => {
  switch (action.type) {
    case bookingPagesActionTypes.UPDATE_RECORD:
    case bookingPagesActionTypes.UPDATE_HOW_STEP:
    case bookingPagesActionTypes.UPDATE_CALENDAR_STEP:
    case bookingPagesActionTypes.UPDATE_STYLE_STEP:
    case bookingPagesActionTypes.UPDATE_WHAT_STEP:
    case bookingPagesActionTypes.UPDATE_WHO_STEP:
    case bookingPagesActionTypes.TOGGLE_WHERE_LOCATION_TYPE:
    case bookingPagesActionTypes.UPDATE_WHEN_STEP:
    case bookingPagesActionTypes.UPDATE_WHEN_DURATION:
    case bookingPagesActionTypes.UPDATE_WHEN_DATE_RANGE:
    case bookingPagesActionTypes.UPDATE_WHEN_SCHEDULE_BUFFER:
    case bookingPagesActionTypes.ADD_MEETING_TYPE_CUSTOM_FIELD:
    case bookingPagesActionTypes.SET_BOOKING_PAGE_FIELDS:
    case bookingPagesActionTypes.REMOVE_MEETING_TYPE_CUSTOM_FIELD:
    case bookingPagesActionTypes.UPDATE_MEETING_TYPE_CUSTOM_FIELD:
    case bookingPagesActionTypes.UPDATE_NOTIFICATIONS_STEP:
    case bookingPagesActionTypes.UPDATE_CONFIRMATION_STEP:
      return true;
    case bookingPagesActionTypes.UPDATE_WHERE_STEP:
      return !action.payload.customCountryCode; // need to skip isEdit for default countryCode set
    case bookingPagesActionTypes.GET_BOOKING_PAGE_SUCCESS:
    case bookingPagesActionTypes.SET_BOOKING_PAGE:
    case bookingPagesActionTypes.CREATE_BOOKING_PAGE_SUCCESS:
    case bookingPagesActionTypes.SAVE_BOOKING_PAGE_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isFetching = (state = false, action: BookingPagesAction | GlobalAction) => {
  switch (action.type) {
    case bookingPagesActionTypes.GET_BOOKING_PAGES_REQUEST:
    case bookingPagesActionTypes.GET_BOOKING_PAGES_PAGE_REQUEST:
    case bookingPagesActionTypes.GET_BOOKING_PAGE_REQUEST:
    case bookingPagesActionTypes.CREATE_BOOKING_PAGE_REQUEST:
    case bookingPagesActionTypes.CLONE_BOOKING_PAGE_REQUEST:
    case bookingPagesActionTypes.SAVE_BOOKING_PAGE_REQUEST:
    case bookingPagesActionTypes.DELETE_BOOKING_PAGES_REQUEST:
    case bookingPagesActionTypes.ACTIVATE_BOOKING_PAGE_REQUEST:
      return true;
    case bookingPagesActionTypes.GET_BOOKING_PAGES_SUCCESS:
    case bookingPagesActionTypes.GET_BOOKING_PAGES_FAIL:
    case bookingPagesActionTypes.GET_BOOKING_PAGE_SUCCESS:
    case bookingPagesActionTypes.GET_BOOKING_PAGE_FAIL:
    case bookingPagesActionTypes.CREATE_BOOKING_PAGE_SUCCESS:
    case bookingPagesActionTypes.CREATE_BOOKING_PAGE_FAIL:
    case bookingPagesActionTypes.CLONE_BOOKING_PAGE_SUCCESS:
    case bookingPagesActionTypes.CLONE_BOOKING_PAGE_FAIL:
    case bookingPagesActionTypes.SAVE_BOOKING_PAGE_SUCCESS:
    case bookingPagesActionTypes.SAVE_BOOKING_PAGE_FAIL:
    case bookingPagesActionTypes.DELETE_BOOKING_PAGES_SUCCESS:
    case bookingPagesActionTypes.DELETE_BOOKING_PAGES_FAIL:
    case bookingPagesActionTypes.ACTIVATE_BOOKING_PAGE_SUCCESS:
    case bookingPagesActionTypes.ACTIVATE_BOOKING_PAGE_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const accordionIndexes = (
  state: AccordionIndexes = DEFAULT_ACCORDION_INDEXES,
  action: BookingPagesAction | GlobalAction
) => {
  switch (action.type) {
    case bookingPagesActionTypes.UPDATE_ACCORDION_INDEXES:
      return { ...EMPTY_ACCORDION_INDEXES, ...action.payload };
    case bookingPagesActionTypes.GET_BOOKING_PAGES_PAGE_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_ACCORDION_INDEXES;
    default:
      return state;
  }
};

const lastLoadTime = (
  state = 0,
  action: BookingPagesAction | GlobalAction
) => {
  switch (action.type) {
    case bookingPagesActionTypes.GET_BOOKING_PAGES_SUCCESS:
      return (new Date()).getTime();
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return 0;
    default:
      return state;
  }
};

const error = (state: unknown | null = null, action: BookingPagesAction | GlobalAction) => {
  switch (action.type) {
    case bookingPagesActionTypes.GET_BOOKING_PAGES_REQUEST:
    case bookingPagesActionTypes.GET_BOOKING_PAGES_PAGE_REQUEST:
    case bookingPagesActionTypes.CREATE_BOOKING_PAGE_REQUEST:
    case bookingPagesActionTypes.CLONE_BOOKING_PAGE_REQUEST:
    case bookingPagesActionTypes.SAVE_BOOKING_PAGE_REQUEST:
    case bookingPagesActionTypes.DELETE_BOOKING_PAGES_REQUEST:
      return null;
    case bookingPagesActionTypes.GET_BOOKING_PAGES_FAIL:
    case bookingPagesActionTypes.CREATE_BOOKING_PAGE_FAIL:
    case bookingPagesActionTypes.CLONE_BOOKING_PAGE_FAIL:
    case bookingPagesActionTypes.SAVE_BOOKING_PAGE_FAIL:
    case bookingPagesActionTypes.DELETE_BOOKING_PAGES_FAIL:
      return action.error;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const addToWebSiteLink = (state = '', action: BookingPagesAction | GlobalAction) => {
  switch (action.type) {
    case bookingPagesActionTypes.SET_ADD_TO_WEBSITE_LINK:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

export default combineReducers({
  bookingPage,
  bookingPages,
  selectedBookingPages,
  filterByUser,
  cloneName,
  isEdited,
  isFetching,
  accordionIndexes,
  lastLoadTime,
  error,
  recordWho,
  addToWebSiteLink,
});
