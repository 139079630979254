export const PublicBookingPage = Object.freeze({
  HOST: `Anfitrião`,
  HOST_PHONE: `Telefone anfitrião`,
  EDIT_BUTTON: `Editar`,
  MORE: `Mais`,
  TIME_ZONE_LABEL: `Fuso Horário`,
  NAME_FIELD_LABEL: `Nome`,
  EMAIL_FIELD_LABEL: `E-mail`,
  INVITE_OTHERS_FIELD_LABEL: `Convidar Outros`,
  INVITE_OTHERS_FIELD_PLACEHOLDER: `Convide outros inserindo seus endereços de e-mail separados por vírgulas`,
  NOTE_FOR_ATTENDEES_FIELD_LABEL: `Nota para os Participantes`,
  NOTE_FOR_ATTENDEES_FIELD_PLACEHOLDER: `Digite uma nota para todos os participantes`,
  NO_USERS_AVAILABLE_LABEL: `Não há usuários disponíveis no horário selecionado. Por favor, selecione outro horário.`,
  DEACTIVATED_LABEL: `O anfitrião foi desativado. Por favor, solicite um novo link de reserva.`,
  CANCEL_TYPE_LABEL: `Por que cancelar?`,
  CANCEL_REASON_LABEL: `Motivo do Cancelamento:`,
  IN_PERSON_LABEL: `Presencial`,
  PHONE_LABEL: `Telefone`,
  GOOGLE_MEET: `Google Meet`,
  ZOOM: `Zoom`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  ERROR: `Erro`,
  BACK_TO_HOME: `Voltar para casa`,
  VIDEO_CONFERENCE_LABEL: `Videoconferência`,
  SLOTS_NO_FOUND: `Slots não encontradas`,
  VIEW_NEXT_MONTH: `Ver próximo mês`,
  ALLOW_SMS: `Receber lembretes de compromissos por SMS?`,
  BOOKING_PAGE_EXPIRED: `Esta página de reserva expirou. Por favor, solicite um novo link de reserva.`,
  PERSONAL_INFO: `Informações pessoais`,
  ATTENDEES: `Participantes`,
  ATTENDEE_EMAIL: `E-mail do participante`,
  ADD_ATTENDEES: `Adicionar participantes`,
  YOUR_ANSWERS: `Suas respostas`,
  MANAGE_BOOKING: `Gerenciar reserva`,
  MODIFY_BOOKING: `Modificar reserva`,
  CANCEL_POLICY: `Política de cancelamento`,
  LOCATIONS_PLACEHOLDER: `Digite o endereço`,
  LOADING_LABEL_1: `Criando reunião`,
  LOADING_LABEL_2: `Sincronizando calendários`,
  LOADING_LABEL_3: `Enviando notificações`,
  MILES: ` milhas`,
  KILOMETERS: ` quilômetros`,
  LEARN_MORE: `Saiba mais`,
  LOCATION_ACCESS_TOOLTIP: `Não é possível acessar sua localização`,
});

export const BookedMeetings = Object.freeze({
  ADD_NOTE: `Adicionar nota`,
  ATTENDEES: `Participantes`,
  CANCELED: 'Cancelado',
  CANCEL_MEETING_TITLE: `Cancelar reunião`,
  CANCEL_MEETING_DESCRIPTION: `Por que cancelar?`,
  CANCEL_REASON: `Motivo do cancelamento:`,
  DETAILS: `Detalhes`,
  BOOKED: `Reservado`,
  BOOKING_PAGE: `Página de reserva`,
  BOOKING_PAGE_PLACEHOLDER: `Selecionar página de reserva`,
  EDIT_ATTENDEE_LIMIT: `Editar limite de participantes`,
  GOOGLE_MEET: `Google Meet`,
  GUEST: `Convidado`,
  GUEST_ANSWERS: `Respostas do convidado`,
  HOSTS: `Anfitriões`,
  IN_PERSON: `Pessoalmente`,
  LOCATION_TITLE: `Localização`,
  NO_SCHEDULED_MEETINGS: `Você não tem reuniões reservadas`,
  NO_LOCATION_GIVEN: `Nenhuma localização fornecida`,
  NO_SHOW_LABEL: `Ausente`,
  MARK_AS_NO_SHOW: `Marcar como ausente`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART1: `Tem certeza de que deseja marcar`,
  MARK_AS_NO_SHOW_DESCRIPTION_PART2: `como ausente?`,
  MARK_AS_NO_SHOW_TOOLTIP: `A reunião ainda não começou.`,
  MEETING: `Reunião`,
  MEETINGS: `Reuniões`,
  INTERNAL_MEETING_NOTES: `Nota interna da reunião`,
  INTERNAL_MEETING_NOTES_DESC: `Apenas o anfitrião verá esta nota`,
  MICROSOFT_TEAMS: `Microsoft Teams`,
  NOTE_FROM_INVITEE: `Nota do convidado`,
  NO_ANSWERS: `Sem respostas`,
  NO_ATTENDEES: `Sem participantes`,
  NO_NOTE: `Sem nota`,
  PAST: `Passado`,
  PHONE_CALL: `Chamada telefônica`,
  RESCHEDULE: `Reagendar`,
  REASON_UNEXPECTED: `Conflito inesperado`,
  REASON_INVITE: `Preciso convidar outros`,
  REASON_TIME: `Preciso de tempo para preparar`,
  REASON_OTHER: `Outro`,
  STATUS: `Status`,
  STATUS_PLACEHOLDER: `Selecionar status`,
  TEAM: `Equipe`,
  TITLE: `Reuniões reservadas`,
  THIS_MONTH: `Este mês`,
  UNDO_NO_SHOW: `Desfazer ausente`,
  UPCOMING: `Próximas`,
  ZOOM: `Zoom`,
  VIDEO_CONFERENCE: `Videoconferência`,
  WORKSPACE: `Área de trabalho`,
});

export const BookedMeetingsToastsNotifications = Object.freeze({
  GET_SCHEDULED_MEETINGS_ERROR_TOAST: `Falha na solicitação de eventos agendados`,
  CANCEL_SCHEDULED_MEETING_ERROR_MESSAGE: `Falha na solicitação de cancelamento de evento`,
  CANCEL_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Evento cancelado com sucesso`,
  UPDATE_SCHEDULED_MEETING_ERROR_TOAST: `Falha na solicitação de atualização de evento`,
  DELETE_SCHEDULED_MEETING_SUCCESS_MESSAGE: `Evento excluído com sucesso`,
  DELETE_SCHEDULED_MEETING_ERROR_MESSAGE: `Falha na solicitação de exclusão de evento`,
});
