export const Availability = Object.freeze({
  ACTIVE_ON: `Activo en`,
  BOOKING_PAGE: `Página de Reservas`,
  BOOKING_PAGES: `Páginas de Reservas`,
  BOOKING_TEMPLATES: `Plantillas de Reservas para Meeting Booker`,
  CLONE_SCHEDULE_TITLE: `Nombre del horario clonado`,
  DATE_OVERRIDE_BUTTON: `Nueva Anulación`,
  DATE_OVERRIDE_DESCRIPTION: `Agregar excepciones a este horario a continuación:`,
  DATE_OVERRIDE_MODAL_HOURS: `Horas de disponibilidad`,
  DATE_OVERRIDE_MODAL_PROMPT: `Seleccione la fecha (o fechas):`,
  DATE_OVERRIDE_MODAL_UNAVAILABLE: `Haga clic en Guardar para bloquear esta fecha (o fechas) como no disponibles.`,
  DATE_OVERRIDE_TITLE: `Agregar anulación de fecha`,
  DATES_OVERLAPPING_TEXT: `Algunas fechas ya han sido anuladas.`,
  DEFAULT_AVAILABILITY_NAME: `Horario de trabajo`,
  DELETE_DESCRIPTION: `¿Está seguro de que desea eliminar`,
  DELETE_MESSAGE: `se establecerá como el nuevo horario predeterminado.`,
  DESCRIPTION: `Elija un horario a continuación para editar o cree uno nuevo que pueda aplicar a sus páginas de reservas`,
  DIALOG_EDIT_NAME_TITLE: `Editar nombre del horario`,
  DIALOG_NEW_NAME_TITLE: `Nuevo nombre del horario`,
  DIALOG_PLACEHOLDER: `Horas de trabajo, Horas exclusivas, etc...`,
  DIALOG_TITLE: `Nombre del horario`,
  DUPLICATE_NAME_MESSAGE: `Ya existe un horario con este nombre`,
  EDIT_NAME: `Editar nombre`,
  IS_DEFAULT: `Horario predeterminado`,
  NEW_SCHEDULE: `Nuevo Horario`,
  SAVE_AVAILABILITY: `Guardar disponibilidad`,
  SET_AS_DEFAULT: `Establecer como predeterminado`,
  SET_WEEKLY_HOURS: `Defina sus horas semanales:`,
  TIME_INTERVAL_OVERLAPPING: `Los intervalos de tiempo no pueden superponerse.`,
  TIME_INVALID_TEXT: `Elija una hora de finalización posterior a la hora de inicio`,
  TIME_ZONE: `Zona horaria`,
  TITLE: `Disponibilidad`,
  TOOLTIP: `Gestione su disponibilidad con horarios de trabajo`,
  FIRST_ADMIN_MESSAGE: `El horario de "Disponibilidad del equipo" se aplicará a todos los usuarios recién invitados. Si usted, como administrador, desea establecer su propio horario, haga clic en "Nuevo horario" y cree su propio horario.`,
  SCHEDULE: `Horario`,
  OVERRIDES: `Reemplazos`,
  AVAILABLE_HOURS: `Horas disponibles`,
  ACTIVE_ON_PLACEHOLDER: `Se aplica a todas las páginas de reserva.`,
  AVAILABLE_DESCRIPTION: `Horas adicionales para este horario`,
  UNAVAILABLE_DESCRIPTION: `Fechas que no estarán disponibles`,
  DELETE_WARNING_MESSAGE: `Esta acción no se puede deshacer.`,
  DELETE_TITLE: `Eliminar horario`,
  DATE_OVERRIDE_AVAILABLE_TITLE: `Agregar excepciones disponibles`,
  DATE_OVERRIDE_UNAVAILABLE_TITLE: `Agregar fechas no disponibles`,
  SPECIFY_WHAT_SCHEDULE_USED_FOR: `¡Especifique para qué se utiliza el horario! En casos sin especificaciones, solo se utilizará su horario predeterminado.`,
  SET_DEFAULT_ONLY_SCHEDULE_NOT_SPECIFIC: `Solo los horarios que no son específicos de las Páginas de Reserva, Plantillas de Reserva o Ubicaciones pueden ser establecidos como predeterminados.`,
  NEED_ANOTHER_NOT_SPECIFIC_SCHEDULE_FOR_REMOVAL: `Cuando elimines el horario predeterminado, debes tener al menos otro horario sin filtros.`,
  LOCKED_TOOLTIP: `Para editar la disponibilidad, cambia de espacio de trabajo.`,
});

export const AvailabilityToastsNotifications = Object.freeze({
  CREATE_AVAILABILITY_ERROR_MESSAGE: `Error al solicitar la creación de disponibilidad`,
  CREATE_AVAILABILITY_SUCCESS_MESSAGE: `Disponibilidad creada exitosamente`,
  DELETE_AVAILABILITY_ERROR_MESSAGE: `Error al solicitar la eliminación de disponibilidad`,
  DELETE_AVAILABILITY_SUCCESS_MESSAGE: `Disponibilidad eliminada exitosamente`,
  GET_AVAILABILITY_ERROR_MESSAGE: `Error al solicitar la disponibilidad`,
  SAVE_AVAILABILITY_ERROR_MESSAGE: `Error al solicitar guardar la disponibilidad`,
  SAVE_AVAILABILITY_SUCCESS_MESSAGE: `Cambios guardados`,
  SET_DEFAULT_AVAILABILITY_ERROR_MESSAGE: `Error al solicitar establecer la disponibilidad predeterminada`,
  SET_DEFAULT_AVAILABILITY_SUCCESS_MESSAGE: `Cambios guardados`,
});

export const AvailabilityWeekDays = Object.freeze({
  MONDAY_SHORT: 'Lun',
  TUESDAY_SHORT: 'Mar',
  WEDNESDAY_SHORT: 'Mié',
  THURSDAY_SHORT: 'Jue',
  FRIDAY_SHORT: 'Vie',
  SATURDAY_SHORT: 'Sáb',
  SUNDAY_SHORT: 'Dom',
});
