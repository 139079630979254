import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../common';
import {
  MAX_LENGTH_ADDRESS_ITEM,
  MAX_LENGTH_ADDRESS_LINE,
  MAX_LENGTH_NAME,
  MAX_LENGTH_ZIP_CODE,
} from '../../../types/constants';
import { STATE_COUNTRIES } from '../../../store/opsConsole/orgs';
import {
  billingActions,
  billingSelectors,
  CHARGEBEE_YEARLY_TERM,
  getPriceByQuantityTiersOneLicense,
  getTiersByTerm,
  getTotalAmount,
  Tier,
} from '../../../store/billing';
import labels from './labels';
import { countriesTimeZonesService } from '../../../services/CountriesTimeZoneService';
import { userSettingsSelectors } from '../../../store/userSettings';
import { AppDispatch } from '../../../store/rootStore';
import { getStatesByCountryCode } from '../../../services/CountryStateService';

interface IPayByInvoiceProps {
  quantity: number;
  term: string;
  handleBack: () => void;
}

export const PayByInvoice: FC<IPayByInvoiceProps> = ({ quantity, term, handleBack }: IPayByInvoiceProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { i18n } = useTranslation();

  const customerInfo = useSelector(userSettingsSelectors.selectCustomerInfo);
  const itemPrices = useSelector(billingSelectors.selectItemPrices);
  const currencyLabel = useSelector(billingSelectors.selectCurrencyLabel);
  const isFetching = useSelector(billingSelectors.selectIsFetching);
  const totalAmount = useSelector(billingSelectors.selectTotalAmount);
  const totalTax = useSelector(billingSelectors.selectTotalTax);

  const [firstName, setFirstName] = useState(customerInfo.first_name || '');
  const [lastName, setLastName] = useState(customerInfo.last_name || '');
  const [country, setCountry] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [stateCode, setStateCode] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [addressLine1, setAddressLine1] = useState<string>('');
  const [addressLine2, setAddressLine2] = useState<string>('');
  const [prevStateSnapshot, setPrevStateSnapshot] = useState('');

  const countriesOptions = countriesTimeZonesService.getCountriesOptions(i18n.language, STATE_COUNTRIES);
  const tiers: Tier[] = getTiersByTerm(itemPrices, term);
  const amount = getTotalAmount(quantity, tiers);
  const priceOneLicense = getPriceByQuantityTiersOneLicense(itemPrices, term, quantity);

  useEffect(() => {
    return () => {
      dispatch(billingActions.calculateTaxesReset());
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const snapshot = [country, stateCode, city, zip, addressLine1, addressLine2].join();

      if (prevStateSnapshot !== snapshot && country) {
        dispatch(
          billingActions.calculateTaxesRequest({
            term,
            quantity,
            address: {
              country,
              stateCode,
              city,
              zip,
              line1: addressLine1,
              line2: addressLine2,
            },
          })
        );

        setPrevStateSnapshot(snapshot);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [country, stateCode, city, zip, addressLine1, addressLine2, dispatch]);

  const handleConfirm = () => {
    dispatch(
      billingActions.payLaterRequest({
        term,
        quantity,
        billing_address: {
          first_name: firstName,
          last_name: lastName,
          line1: addressLine1,
          line2: addressLine2,
          city: city,
          state: state,
          state_code: stateCode,
          zip: zip,
          country: country,
        },
      })
    );
  };

  const handleChangeCountry = (event: DropdownChangeEvent) => {
    setCountry(event.target.value);
    setStateCode('');
    setState('');
  };

  const nextIsDisabled: boolean =
    !firstName ||
    !lastName ||
    !addressLine1 ||
    !city ||
    !zip ||
    (STATE_COUNTRIES.includes(country || '') && !stateCode) ||
    !country;

  return (
    <div className="flex flex-column sumo-card-bg pt-16px">
      <SectionHeader title={labels.checkout} hideButton />

      <div className="sumo-card flex flex-wrap flex-row">
        <div className="w-12 lg:w-12 pl-20px pt-24px text-title-s-med">{labels.billingAddress}</div>

        <div className="w-12 lg:w-6 p-24px">
          <div className="text-title-xs-med">
            <div className="p-fluid flex gap-2">
              <div className="flex-1">
                <label className="mb-2">{labels.firstName}</label>
                <InputText
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className={!firstName ? 'p-invalid mt-2' : 'mt-2'}
                  maxLength={MAX_LENGTH_NAME}
                />
              </div>
              <div className="flex-1">
                <label className="mb-2">{labels.lastName}</label>
                <InputText
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className={!lastName ? 'p-invalid mt-2' : 'mt-2'}
                  maxLength={MAX_LENGTH_NAME}
                />
              </div>
            </div>

            <div className="p-fluid mt-3">
              <label>{labels.addressLine1}</label>
              <InputText
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
                className={'mt-2 ' + (!addressLine1 ? 'p-invalid' : '')}
                maxLength={MAX_LENGTH_ADDRESS_LINE}
              />
            </div>

            <div className="p-fluid mt-3">
              <label>{labels.addressLine2}</label>
              <InputText
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
                className="mt-2"
                maxLength={MAX_LENGTH_ADDRESS_LINE}
              />
            </div>

            <div className="p-fluid mt-3 flex gap-2">
              <div className="flex-1">
                <label className="mb-2">{labels.city}</label>
                <InputText
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className={!city ? 'p-invalid mt-2' : 'mt-2'}
                  maxLength={MAX_LENGTH_ADDRESS_ITEM}
                />
              </div>
              <div className="flex-1">
                <label className="mb-2">{labels.zip}</label>
                <InputText
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                  className={!zip ? 'p-invalid mt-2' : 'mt-2'}
                  maxLength={MAX_LENGTH_ZIP_CODE}
                />
              </div>
            </div>
            <div className="p-fluid mt-3 mb-3 flex gap-2 overflow-hidden">
              <div className="flex-1 overflow-hidden">
                <label className="mb-2">{labels.country}</label>
                <Dropdown
                  id="inputcountry"
                  value={country}
                  onChange={handleChangeCountry}
                  optionLabel="label"
                  optionValue="value"
                  options={countriesOptions}
                  className={!country ? 'p-invalid mt-2' : 'mt-2'}
                  filter
                  filterBy="label, value"
                  style={{ maxHeight: '200px', overflowY: 'auto' }}
                />
              </div>
              <div className="flex-1 overflow-hidden">
                <label className="mb-2">{labels.state}</label>
                {STATE_COUNTRIES.includes(country || '') ? (
                  <Dropdown
                    id="selectStateCode"
                    value={stateCode}
                    onChange={(e) => setStateCode(e.target.value)}
                    optionLabel="name"
                    optionValue="code"
                    options={getStatesByCountryCode(country) || []}
                    className={!stateCode ? 'p-invalid mt-2' : 'mt-2'}
                    filter
                    filterBy="name, code"
                  />
                ) : (
                  <InputText
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    className="mt-2"
                    maxLength={MAX_LENGTH_ADDRESS_ITEM}
                  />
                )}
              </div>
            </div>
          </div>

          <Button
            onClick={handleConfirm}
            disabled={nextIsDisabled}
            label={labels.confirm}
            loading={isFetching}
            style={isFetching ? { width: '135px', gap: '7px' } : undefined}
          />
          <Button onClick={handleBack} className="ml-12px" outlined>
            {labels.cancel}
          </Button>
        </div>

        <div className="w-12 lg:w-6 p-24px">
          <div className="flex flex-column sumo-card-bg">
            <div className="text-title-s-med">{labels.orderSummary}</div>
            <div className="flex flex-between my-24px text-body-lg-reg">
              <div>
                {quantity} {labels.licenses} X {currencyLabel}
                {priceOneLicense} X {term === CHARGEBEE_YEARLY_TERM ? 12 : 1} {labels.month}
              </div>
              <div>
                {currencyLabel}
                {amount}
              </div>
            </div>
            <div className="h-line-heavy-blue-20 mb-24px" />
            <div className="flex flex-between">
              <div>
                <div className="text-body-lg-reg mb-2">{labels.tax}</div>
                <div className="text-title-lg-med">{labels.total}</div>
              </div>
              <div>
                <div className="text-body-lg-reg flex-right mb-2">
                  {currencyLabel}
                  {totalTax / 100}
                </div>
                <div className="text-title-lg-med">
                  {currencyLabel}
                  {totalAmount / 100 || amount}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
