import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TimeFromToInput, WeekDay, WeeklyHoursInput } from '../../../API';
import { AppDispatch } from '../../../store/rootStore';
import {
  DEFAULT_AVAILABILITY_TIME,
  WEEKDAY_OPTIONS_GLOBAL,
  WEEKDAY_OPTIONS_EUROPE,
  availabilitySelectors,
} from '../../../store/availability';
import { QuickSetupScreen } from '../';
import labels from './labels';
import { Divider } from 'primereact/divider';
import { Calendar } from 'primereact/calendar';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { calcFromToTimeError, formatDateHHMM } from '../../../services/DateService';
import { quickSetupActions, quickSetupNavigationActions, quickSetupSelectors } from '../../../store/quickSetup';
import { TimeFormat, UserSettingsKeys, userSettingsSelectors } from '../../../store/userSettings';
import { ArrowRightIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { AvailabilityModel } from '../../../generated-sources/internal-api/models/AvailabilityModel';

export const SetupAvailabilityStep = () => {
  const dispatch = useDispatch<AppDispatch>();
  const defaultAvailability = useSelector(availabilitySelectors.selectDefaultAvailability);
  const userAvailability = useSelector(quickSetupSelectors.selectUserAvailability);
  const userWeeklyHours = useSelector(quickSetupSelectors.selectUserAvailabilityWeeklyHours);
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;
  const isEditable = useSelector(userSettingsSelectors.selectAvailabilityEdit);

  const [time, setTime] = useState<TimeFromToInput>({ from: null, to: null });
  const [isTimeInvalid, setIsTimeInvalid] = useState(false);
  const [minimumDaysError, setMinimumDaysError] = useState(false);

  const [weeklyHours, setWeeklyHours] = useState<WeeklyHoursInput[]>(userWeeklyHours as WeeklyHoursInput[]);
  const savedWeeklyHours = localStorage.getItem(UserSettingsKeys.QUICK_SETUP_WEEKLY_HOURS);

  useEffect(() => {
    if ((!userAvailability || !userWeeklyHours) && defaultAvailability) {
      dispatch(quickSetupActions.setUserAvailability(defaultAvailability));
    }

    const weeklyHoursToSet: WeeklyHoursInput[] = savedWeeklyHours
      ? JSON.parse(savedWeeklyHours)
      : userWeeklyHours || [];
    setWeeklyHours(weeklyHoursToSet);

    const firstValidDay = weeklyHoursToSet.find((day: WeeklyHoursInput) => day?.time?.[0]?.from && day?.time?.[0]?.to);
    const timeToSet = determineTimeToSet(firstValidDay);

    setTime(timeToSet);
  }, [userWeeklyHours, defaultAvailability, savedWeeklyHours]);

  useEffect(() => {
    setIsTimeInvalid(calcFromToTimeError(time.from, time.to));
    setMinimumDaysError(weeklyHours.filter((day) => day.time && day.time.length > 0).length < 1);
  }, [time, weeklyHours]);

  const determineTimeToSet = (firstValidDay: WeeklyHoursInput | undefined) => {
    return firstValidDay && firstValidDay.time && firstValidDay.time[0]
      ? { from: firstValidDay.time[0].from as string, to: firstValidDay.time[0].to as string }
      : DEFAULT_AVAILABILITY_TIME;
  };

  const handleTimeChange = (input: Partial<TimeFromToInput>) => {
    const newTime = { ...time, ...input };
    setTime(newTime);
    setWeeklyHours(
      weeklyHours.length
        ? weeklyHours.map((day) => (day?.time?.length ? { ...day, time: [newTime] } : day))
        : weeklyHours
    );
  };

  const handleDayChange = (selectedTypes: WeekDay[]) => {
    const updatedWeeklyHours = weeklyHours.map((day) => {
      if (day?.type && selectedTypes.includes(day.type)) {
        return { ...day, time: [time] };
      } else {
        return { ...day, time: [] };
      }
    });
    setWeeklyHours(updatedWeeklyHours);
  };

  const availabilitySave = () => {
    const availability = {
      ...userAvailability,
      weeklyHours
    } as AvailabilityModel;

    localStorage.setItem(UserSettingsKeys.QUICK_SETUP_WEEKLY_HOURS, JSON.stringify(weeklyHours));

    dispatch(quickSetupActions.setUserAvailability(availability));
  };

  const handleBack = () => {
    availabilitySave();
    dispatch(quickSetupNavigationActions.navigateBack());
  };

  const handleNext = () => {
    if (!minimumDaysError) {
      availabilitySave();
      dispatch(quickSetupNavigationActions.navigateNext());
    }
  };

  const isEuropeTimeZone = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZone.includes('Europe');
  };

  return (
    <QuickSetupScreen.Container title={labels.stepMessage} titleIcon="👋">
      <div className="flex flex-column">
        <div className="text-display-md-med">{labels.titleUser}</div>
        <div className="text-body-lg-reg text-heavy-80 pt-2px pb-32px w-580px">
          {labels.messageUser}
        </div>

        <div className="flex align-items-center gap-6px text-label-s-med text-heavy-60 pb-16px">
          <CalendarIcon className="icon-20px" />
          <div>{labels.setupDaysInputLabel}</div>
        </div>

        <div className="w-fit">
          <SelectButton
            value={weeklyHours?.filter((el) => el?.time?.length).map((el) => el?.type)}
            onChange={(e) => handleDayChange(e.value)}
            options={isEuropeTimeZone() ? WEEKDAY_OPTIONS_EUROPE : WEEKDAY_OPTIONS_GLOBAL}
            optionLabel="label"
            className="weekday-button-bar"
            multiple
            disabled={!isEditable}
          />
          {minimumDaysError && (
            <div className="text-body-lg-reg text-tomato-dark pt-16px">{labels.minimumDaysErrorText}</div>
          )}
          <Divider className="my-20px" />
        </div>

        <div className="flex align-items-center gap-6px text-label-s-med text-heavy-60 pb-16px">
          <CalendarIcon className="icon-20px" />
          <div>{labels.setupHoursInputLabel}</div>
        </div>

        <div>
          <Calendar
            inputStyle={{ textAlign: 'center' }}
            className="w-8rem"
            dateFormat="HH:mm"
            hourFormat={timeFormat === TimeFormat.default ? '12' : '24'}
            value={new Date(`1970-01-01T${time.from}`)}
            timeOnly
            onChange={(e) =>
              e.target.value instanceof Date &&
              handleTimeChange({ from: formatDateHHMM(e.target.value, TimeFormat.military) })
            }
            disabled={!isEditable}
          />
          <span className="text-xl px-2">-</span>
          <Calendar
            inputStyle={{ textAlign: 'center' }}
            className="w-8rem"
            dateFormat="HH:mm"
            hourFormat={timeFormat === TimeFormat.default ? '12' : '24'}
            value={new Date(`1970-01-01T${time.to}`)}
            timeOnly
            onChange={(e) =>
              e.target.value instanceof Date &&
              handleTimeChange({ to: formatDateHHMM(e.target.value, TimeFormat.military) })
            }
            disabled={!isEditable}
          />
        </div>

        {isTimeInvalid && <div className="text-body-lg-reg text-tomato-dark pt-16px">{labels.timeInValidText}</div>}
      </div>
      <QuickSetupScreen.Buttons>
        <Button label={labels.back} className="min-w-120px flex-center button-xl" onClick={handleBack} outlined />
        <Button
          className="min-w-120px flex-center gap-8px button-xl"
          onClick={handleNext}
          disabled={isTimeInvalid || minimumDaysError}
        >
          <div>{labels.next}</div>
          <ArrowRightIcon className="icon-18px" />
        </Button>
      </QuickSetupScreen.Buttons>
    </QuickSetupScreen.Container>
  );
};
