import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { classNames } from 'primereact/utils';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Path } from '../../../routing';
import { authenticationActions, authenticationSelectors } from '../../../store/authentication';
import { menuSelectors } from '../../../store/menu';
import { promoActions, promoSelectors } from '../../../store/promo';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import { UserAvatar } from '../../common';
import labels from './labels';

export const UserMenu = () => {
  const menu = useRef<Menu>(null);
  const menuToggle = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const isOpsConsole = useSelector(authenticationSelectors.selectIsOpsConsole);
  const isMenuOpened = useSelector(userSettingsSelectors.selectIsMenuOpened);
  const isGuideRunning = useSelector(promoSelectors.selectIsGuideRunning);
  const avatarLink = useSelector(userSettingsSelectors.selectAvatar);
  const nameOrEmail = useSelector(userSettingsSelectors.selectNameOrEmail) || '';
  const roleName = useSelector(userSettingsSelectors.selectUserRoleName);
  const staffNameOrEmail = useSelector(authenticationSelectors.selectStaffNameOrEmail);
  const staffRoleName = useSelector(authenticationSelectors.selectSumo1AdminRoleLabel);
  const menuItems = useSelector(menuSelectors.selectUserMenuItems);
  const isGuiderPopupOverUserMenu = useSelector(userSettingsSelectors.selectIsGuiderPopupOverUserMenu);

  const name = isOpsConsole ? staffNameOrEmail : nameOrEmail;
  const role = isOpsConsole ? staffRoleName : roleName;

  const getMenuItems = (ids: string[], separator = false) => {
    const items = menuItems
      .filter((item) => ids.includes(item.id || ''))
      .map(
        (item) =>
          ({
            ...item,
            template: item.url ? linkTemplate : commandTemplate,
            data: React.createElement(item.data, { className: 'icon-20px' }),
            command:
              item.id === 'guided-tour-link'
                ? handleStartGuide
                : item.id === 'log-out-link'
                ? handleSignOut
                : undefined,
          } as MenuItem)
      );
    return separator && items.length ? [...items, { separator: true }] : items;
  };

  const handleCloseGuideAndMenu = () => {
    dispatch(userSettingsActions.setIsMenuOpened(false));
    dispatch(userSettingsActions.setIsGuiderPopupOverUserMenu(false));
  };

  const handleStopGuider = () => {
    dispatch(promoActions.stopGuide());
    handleCloseGuideAndMenu();
  };

  const handleToggleMenu = () => {
    if (isMenuOpened) {
      if (isGuiderPopupOverUserMenu) {
        handleStopGuider();
      } else {
        dispatch(userSettingsActions.setIsMenuOpened(false));
      }
    } else {
      dispatch(userSettingsActions.setIsMenuOpened(true));
    }
  };

  const handleLinkClick = () => {
    if (isMenuOpened && !isGuideRunning) {
      dispatch(userSettingsActions.setIsMenuOpened(false));
    }
  };

  const handleStartGuide = () => {
    if (isGuideRunning) {
      dispatch(promoActions.stopGuide());
      dispatch(userSettingsActions.setIsGuiderPopupOverUserMenu(false));
    } else {
      dispatch(promoActions.runGuide());
      dispatch(userSettingsActions.setIsGuiderPopupOverUserMenu(true));
    }
  };

  const handleSignOut = () => {
    dispatch(authenticationActions.logoutUserRequest(Path.Landing));
  };

  const getMenuTextClassName = (selected: boolean) =>
    classNames('flex-left-center gap-8px', selected ? 'text-label-md-med text-heavy-100' : 'p-menuitem-text');

  const linkTemplate: MenuItem['template'] = (item) => (
    <Link
      to={item.url || Path.Landing}
      onClick={handleLinkClick}
      target={item.target}
      className="p-menuitem-link px-16px py-10px"
      id={item.id}
    >
      <div className={getMenuTextClassName(location.pathname.startsWith(item.url || ''))}>
        {item.data}
        <span>{item.label}</span>
      </div>
    </Link>
  );

  const commandTemplate: MenuItem['template'] = (item) => (
    <div
      onClick={(event) => {
        if (item.command) {
          item.command({ originalEvent: event, item });
        }
      }}
      className="p-menuitem-link px-16px py-10px"
      id={item.id}
    >
      <div className={getMenuTextClassName(item.label === labels.guidedTour && isGuideRunning)}>
        {item.data}
        <span>{item.label}</span>
      </div>
    </div>
  );

  const getMenuModel = () => [
    ...getMenuItems(['guided-tour-link'], true),
    ...getMenuItems(['availability-link', 'calendar-connections-link'], true),
    ...getMenuItems(['profile-link', 'help-center-link'], true),
    ...getMenuItems(['log-out-link']),
  ];

  return (
    <div className="relative">
      <div
        ref={menuToggle}
        className={classNames(
          'flex align-items-center gap-8px border-radius-6px cursor-pointer',
          isOpsConsole ? 'text-primary-white hover-bg-heavy-80' : 'hover-bg-blue-light'
        )}
        onClick={handleToggleMenu}
      >
        <UserAvatar size="large" name={name} avatarLink={avatarLink} />
        <div className="flex flex-column gap-4px">
          <div className="text-label-s-med">{name}</div>
          <div className="text-label-xs-reg text-heavy-60">{role}</div>
        </div>
        <div className="ml-auto flex align-items-center">
          <ChevronUpDownIcon className="text-heavy-60 w-16px" />
        </div>
      </div>

      <Menu
        ref={menu}
        model={getMenuModel()}
        className={classNames(
          // `absolute bottom-40px shadow-level-2`,
          // accountVisible ? 'w-fit' : 'w-240px',
          'account-menu',
          isMenuOpened ? 'block' : 'hidden',
          isGuiderPopupOverUserMenu ? 'popup-guide-over' : null
        )}
      />
    </div>
  );
};
