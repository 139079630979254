import { handleAPIRequest } from '../utils/reduxUtils';
import { API_PUBLIC } from '../../types/constants';
import { DeleteLocationsRequestPayload, LocationResponse, LocationsResponse } from './types';
import { CreatePhysicalLocationInput } from '../../API';
import { DeleteLocationsResponse } from '../../generated-sources/internal-api/models/DeleteLocationsResponse';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getLocationsByWorkspaceId = async (workspaceId: string) => {
  return handleAPIRequest<LocationsResponse>(`${API_PUBLIC}/getLocationsByWorkspaceId`, {
    workspaceId,
  });
};

export const getLocationById = async (id: string) => {
  return handleAPIRequest<LocationResponse>(`${API_PUBLIC}/getLocationById`, {
    id,
  });
};

export const upsertLocations = async (locations: CreatePhysicalLocationInput[]) => {
  return handleAPIRequest<void>(`${API_PUBLIC}/upsertLocations`, {
    locations,
  });
};

export const deleteLocations = async (payload: DeleteLocationsRequestPayload) => {
  return handleAPIRequest<DeleteLocationsResponse>(`${API_PUBLIC}/deleteLocations`, payload);
};
