import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  AddressInput,
  BackgroundType,
  CreateJourneyInput,
  CreateUserEventInput,
  CustomFieldInput,
  CustomFieldType,
  CustomType,
  InPersonType,
  JourneyConditionType,
  JourneyDestinationType,
  JourneyFormFieldInput,
  JourneyFormFieldType,
  JourneyPageType,
  JourneyStepInput,
  JourneyStepType,
  LocationInput,
  LocationType,
  PhoneCallType,
  PhysicalLocation,
  TimeUnit,
  TimeZoneType,
  UpdateBookingPageInput,
  WhereInput,
} from '../../API';
import { CURRENT_TIME_ZONE } from '../../types/constants';
import { AgendaType, EventSteps, GetMeetingURLParamsType, NavigationStep } from './types';
import { validatePhoneNumber } from '../../services/utils';
import { validateEmail } from '../../services/EmailService';
import { getUrlParams } from '../../services/URLService';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

const mapTimeUnitToDayjs = (unit: TimeUnit): dayjs.ManipulateType => {
  switch (unit) {
    case TimeUnit.DAY:
      return 'day';
    case TimeUnit.HOUR:
      return 'hour';
    case TimeUnit.MINUTE:
    default:
      return 'minute'; // Default to 'minute' if unknown unit
  }
};

// Calculates the updated event start and end time based on the provided agenda, event, and start time
export const calculateUpdatedEvent = (agenda: AgendaType, event: CreateUserEventInput, startTimeValue: number) => {
  const timeZone = event.timeZone || CURRENT_TIME_ZONE;
  const startTime = dayjs(startTimeValue).tz(timeZone);
  const durationCount = agenda.bookingPage?.when?.duration?.count || 0;
  const durationTimeUnit = agenda.bookingPage?.when?.duration?.timeUnit || TimeUnit.MINUTE;

  // map the TimeUnit to a format accepted by dayjs
  const dayjsUnit = mapTimeUnitToDayjs(durationTimeUnit);

  // calculate endTime based on duration
  const endTime = startTime.add(durationCount, dayjsUnit);

  return { startTime: startTime.format(), endTime: endTime.format() } as Partial<CreateUserEventInput>;
};

export const calculateViewDate = (startTime: string) => dayjs.utc(startTime).startOf('month').valueOf();

// export const startDateOfSpots = (viewDate: Date) => {
//   // if the view date is the same as the current day, round up to the nearest 15 minutes and return the timestamp
//   return dayjs().isSame(dayjs(viewDate), 'day')
//     ? dayjs()
//         .startOf('hour')
//         .add(Math.ceil(dayjs().minute() / 15) * 15, 'minute')
//         .valueOf()
//     : dayjs(viewDate).startOf('day').valueOf(); // Otherwise, return the start of the view date
// };

// export const endDateOfSpots = (viewDate: Date) => {
//   // return the end of the month for the given view date as a timestamp
//   return dayjs(viewDate).endOf('month').valueOf();
// };

export const agendaStartOfMonth = (viewDate: Date | number) => dayjs.utc(viewDate).startOf('month').valueOf();

export const agendaEndOfMonth = (viewDate: Date | number) => dayjs.utc(viewDate).endOf('month').valueOf();

// export const calculateAvailableLocationTypes = (
//   locationTypes: (LocationType | null)[] | null | undefined,
//   hostVideoIntegrations: string[] | undefined
// ) =>
//   (locationTypes || []).filter(
//     (type) =>
//       type &&
//       (!Object.values(IntegrationType)
//         .map((integrationType) => integrationType.toString())
//         .includes(type.toString()) ||
//         hostVideoIntegrations?.includes(type.toString()))
//   );

export const formatPhoneNumber = (countryCode: string, phoneNumber: string) =>
  `+${countryCode.slice(3)}-${phoneNumber}`;

// formats saved value "US_1-12345678" to "+1-12345678"
export const formatPhoneString = (phoneString: string | null | undefined) => {
  if (!phoneString) {
    return '';
  }
  const index = phoneString.indexOf('_');
  return index ? `+${phoneString.slice(index + 1)}` : phoneString;
};

export const isSpotsHaveSelectedTime = (spots: string[] | undefined, time: string) =>
  Boolean(spots?.find((spot) => dayjs(parseInt(spot)).isSame(dayjs(time))));

/* export const calculateEventDate = (agenda: AgendaType, stateEventDate: string) => {
  const timeZone = agenda.event?.timeZone || CURRENT_TIME_ZONE;
  const eventDate = agenda.event?.eventDate || stateEventDate || dayjs().tz(timeZone).format('YYYY-MM-DD');
  const spotsLength = agenda.spots?.length || 0;

  if (spotsLength === 0) {
    return eventDate;
  }

  const eventDateValue = dayjs.tz(eventDate, timeZone).valueOf();
  const startTime = (agenda.spots && parseInt(agenda.spots[0])) || eventDateValue;
  const endTime = (agenda.spots && parseInt(agenda.spots[spotsLength - 1])) || eventDateValue;

  return eventDateValue < startTime || eventDateValue > endTime
    ? dayjs.tz(startTime, timeZone).format('YYYY-MM-DD')
    : eventDate;
}; */

export const calculateInitialTimeZone = (agenda: AgendaType, stateTimeZone: string | null | undefined) => {
  if (agenda.event) {
    return agenda.event.timeZone;
  }

  if (agenda.bookingPage) {
    return agenda.bookingPage?.calendar?.timeZoneType === TimeZoneType.DISPLAY_SELECTED
      ? agenda.bookingPage?.calendar?.defaultTimeZone
      : CURRENT_TIME_ZONE;
  }

  return stateTimeZone;
};

export const calculateInitialEventLocation = (
  bookingPage: UpdateBookingPageInput | undefined,
  locations?: PhysicalLocation[]
) =>
  ({
    type:
      bookingPage?.where?.defaultLocationType ||
      bookingPage?.where?.locationTypes?.[0] ||
      LocationType.VIDEO_CONFERENCE,
    settings:
      bookingPage?.where?.defaultLocationType === LocationType.IN_PERSON &&
      bookingPage?.where?.inPersonType === InPersonType.LOCATION &&
      locations?.length === 1
        ? locations[0].id
        : bookingPage?.where?.defaultLocationType === LocationType.IN_PERSON &&
          bookingPage?.where?.inPersonType === InPersonType.CUSTOM_ADDRESS &&
          bookingPage?.where?.customAddress
        ? bookingPage?.where?.customAddress
        : bookingPage?.where?.defaultLocationType === LocationType.PHONE_CALL &&
          bookingPage?.where?.phoneCallType === PhoneCallType.CUSTOM_PHONE &&
          bookingPage?.where?.customPhone
        ? bookingPage?.where?.customPhone
        : null,
    address:
      bookingPage?.where?.defaultLocationType === LocationType.IN_PERSON &&
      bookingPage?.where?.inPersonType === InPersonType.LOCATION &&
      locations?.length === 1
        ? (locations[0].address as AddressInput)
        : null,
  } as LocationInput);

const validatePhoneField = (field: CustomFieldInput) => {
  if (!field.value) {
    return false;
  }
  const phone = field.value.slice(field.value.indexOf('-') + 1);
  return (!field.required && phone.length === 0) || validatePhoneNumber(phone);
};

const validatePhoneFieldPrepopulate = (field: CustomFieldInput) => {
  if (!field?.enabled || !field.value) {
    return true;
  }
  const phone = field.value.slice(field.value.indexOf('-') + 1);
  return phone.length === 0 || validatePhoneNumber(phone);
};

export const validateCustomField = (field: CustomFieldInput | null) => {
  if (!field || (!field.required && field.fieldType !== CustomFieldType.PHONE)) {
    return true;
  }
  if (!field.value) {
    return false;
  }
  switch (field.fieldType) {
    case CustomFieldType.EMAIL:
      return validateEmail(field.value);
    case CustomFieldType.PHONE:
      return validatePhoneField(field);
    case CustomFieldType.NAME:
    case CustomFieldType.LOCATION:
    case CustomFieldType.CUSTOM:
      return field.type === CustomType.PHONE ? validatePhoneField(field) : Boolean(field.value);
  }
};

export const validateCustomFieldPrepopulate = (field: CustomFieldInput | null) => {
  if (!field?.enabled || (field.fieldType !== CustomFieldType.EMAIL && field.type !== CustomType.PHONE)) {
    return true;
  }
  switch (field.fieldType) {
    case CustomFieldType.EMAIL:
      return !field.value || validateEmail(field.value);
    case CustomFieldType.PHONE:
      return validatePhoneFieldPrepopulate(field);
    case CustomFieldType.CUSTOM:
      return field.type === CustomType.PHONE ? validatePhoneFieldPrepopulate(field) : true;
  }
};

export const customFieldHasValue = (field: CustomFieldInput | null) => {
  if (!field?.value) {
    return false;
  }
  if (field.type !== CustomType.PHONE) {
    return true;
  }
  const phone = field.value.slice(field.value.indexOf('-') + 1);
  return phone.length > 0;
};

//TODO: refactor: move location.settings calculation to backend, improve HOST_PHONE_NUMBER case: should have selected host phone number (does not work for teams now)
export const calculateEventLocationSettings = (
  location: LocationInput | null | undefined,
  BookingPageWhere: WhereInput | null | undefined,
  hostPhone: string | null | undefined,
  customPhone: string | null | undefined,
  inviteePhone: string | null | undefined,
  inviteeLocation: string | null | undefined
) => {
  if (!location || !BookingPageWhere) {
    return location?.settings || null;
  }
  switch (location.type) {
    case LocationType.IN_PERSON:
      switch (BookingPageWhere.inPersonType) {
        case InPersonType.CUSTOM_ADDRESS:
          return BookingPageWhere.customAddress;
        case InPersonType.INVITEE_LOCATION:
          return inviteeLocation;
        case InPersonType.LOCATION:
        default:
          return location.settings || null;
      }
    case LocationType.PHONE_CALL:
      switch (BookingPageWhere.phoneCallType) {
        case PhoneCallType.CUSTOM_PHONE:
          return customPhone;
        case PhoneCallType.HOST_PHONE_NUMBER:
          return hostPhone;
        case PhoneCallType.PROMPT_INVITE:
          return inviteePhone;
        default:
          return location.settings || null;
      }
    case LocationType.VIDEO_CONFERENCE:
    default:
      return location.settings || null;
  }
};

export const getMeetingURLParams = (): GetMeetingURLParamsType => {
  const params = getUrlParams();
  const spot = params['spot'] || null;
  return { spot };
};

export const findJourneyCurrentStepForView = (steps: (JourneyStepInput | null)[]): JourneyStepInput | null => {
  if (!steps.length) {
    return null;
  }

  const lastStep = steps[steps.length - 1];

  // if last saved step is booking page or external url, show previous step (for navigation with pbp)
  if (
    steps.length > 1 &&
    lastStep?.type === JourneyStepType.DESTINATION &&
    lastStep?.destination?.type !== JourneyDestinationType.MESSAGE
  ) {
    return steps[steps.length - 2];
  }

  return lastStep;
};

export const findJourneyStepById = (
  step: JourneyStepInput | null | undefined,
  stepId: string | null | undefined
): JourneyStepInput | null => {
  if (!step || !stepId) {
    return null;
  }
  if (step.id === stepId) {
    return step;
  }
  if (step.children) {
    for (const child of step.children) {
      const foundStep = findJourneyStepById(child, stepId);
      if (foundStep) {
        return foundStep;
      }
    }
  }
  return null;
};

const updateJourneyStep = (
  step: JourneyStepInput | null,
  stepId: string,
  field: JourneyFormFieldInput
): JourneyStepInput | null => {
  if (!step) return step;

  if (step.id === stepId) {
    return {
      ...step,
      page: step.page
        ? {
            ...step.page,
            formFields: step.page.formFields?.map((formField) => (formField?.id === field.id ? field : formField)),
          }
        : step.page,
    };
  }

  if (step.children) {
    const updatedChildren = step.children.map((child) => updateJourneyStep(child, stepId, field));
    return { ...step, children: updatedChildren };
  }

  return step;
};

export const updateJourneyField = (
  journey: CreateJourneyInput | undefined,
  stepId: string,
  field: JourneyFormFieldInput
): CreateJourneyInput => {
  const updatedRoot = updateJourneyStep(journey?.root || null, stepId, field);
  return { ...journey, root: updatedRoot };
};

export const updateSavedStepsField = (
  steps: JourneyStepInput[],
  stepId: string,
  field: JourneyFormFieldInput
): JourneyStepInput[] =>
  steps.map((step) =>
    step.id === stepId
      ? {
          ...step,
          page: step.page
            ? {
                ...step.page,
                formFields: step.page.formFields?.map((formField) => (formField?.id === field.id ? field : formField)),
              }
            : step.page,
        }
      : step
  );

const calculateCondition = (
  savedSteps: JourneyStepInput[],
  conditionStep: JourneyStepInput | null
): JourneyStepInput | null => {
  if (!conditionStep?.condition) {
    return null;
  }

  const { conditionField, value, type } = conditionStep.condition;
  let conditionMet = false;

  // Handle Card page condition
  if (conditionField === 'CARD') {
    // Find the selectedCardId by iterating backwards through savedSteps
    let selectedCardId: string | null = null;
    for (let i = savedSteps.length - 1; i >= 0; i--) {
      const cards = savedSteps[i].page?.cards;
      if (cards && cards.length > 0) {
        selectedCardId = cards[0]?.id || null;
        break;
      }
    }

    if (!selectedCardId) {
      conditionMet = false;
    } else if (type === JourneyConditionType.IS) {
      conditionMet = value?.includes(selectedCardId) || false;
    } else if (type === JourneyConditionType.IS_NOT) {
      conditionMet = !value?.includes(selectedCardId) || false;
    }
  } else {
    // Search through savedSteps backwards for the form field
    let formField = null;
    for (let i = savedSteps.length - 1; i >= 0; i--) {
      formField = savedSteps[i].page?.formFields?.find((field) => field?.id === conditionField);
      if (formField) break;
    }

    if (!formField) {
      return null;
    }

    const fieldValue = formField.value || [];

    switch (type) {
      case JourneyConditionType.IS:
        conditionMet = fieldValue.some((val) => value?.includes(val));
        break;
      case JourneyConditionType.IS_NOT:
        conditionMet = fieldValue.every((val) => !value?.includes(val));
        break;
      case JourneyConditionType.CONTAINS:
        conditionMet = fieldValue.some((val) => value?.some((v) => v && val?.includes(v)));
        break;
      case JourneyConditionType.DOES_NOT_CONTAINS:
        conditionMet = fieldValue.every((val) => value?.every((v) => !v || !val?.includes(v)));
        break;
      case JourneyConditionType.STARTS_WITH:
        conditionMet = fieldValue.some((val) => value?.some((v) => v && val?.startsWith(v)));
        break;
      case JourneyConditionType.IS_EMPTY:
        conditionMet = fieldValue.length === 0;
        break;
      case JourneyConditionType.IS_NOT_EMPTY:
        conditionMet = fieldValue.length > 0;
        break;
      case JourneyConditionType.RANGE:
        if (formField.type === JourneyFormFieldType.SINGLE && value && value.length === 2) {
          const [min, max] = value;
          const numericValue = parseFloat(fieldValue[0] || '');
          conditionMet =
            !isNaN(numericValue) && numericValue >= parseFloat(min || '') && numericValue <= parseFloat(max || '');
        }
        break;
      default:
        conditionMet = false;
    }
  }

  if (conditionMet) {
    const nextStep = conditionStep.children ? conditionStep.children[0] : null;

    if (nextStep && nextStep.type === JourneyStepType.CONDITION) {
      // Recursively check the next lvl of condition step
      return calculateConditionNextStep(savedSteps, conditionStep);
    }

    // Return the next step if it's not a condition or if there are no more children
    return nextStep;
  }

  return null;
};

export const calculateConditionNextStep = (
  savedSteps: JourneyStepInput[],
  step: JourneyStepInput
): JourneyStepInput | null => {
  for (const child of step.children || []) {
    const result = child?.type === JourneyStepType.CONDITION ? calculateCondition(savedSteps, child) : child;
    if (result) {
      return result;
    }
  }
  return null;
};

export const filterJourneyStepForSave = (step: JourneyStepInput) =>
  step?.type === JourneyStepType.PAGE && step.page?.type === JourneyPageType.CARD
    ? { ...step, page: { ...step.page, cards: [] } }
    : step;

export const setNewStep = (navigationSteps: NavigationStep[], newStep: EventSteps) =>
  navigationSteps.map(
    (navigationStep) =>
      ({
        step: navigationStep.step,
        active: navigationStep.step === newStep,
        visited: navigationStep.visited || navigationStep.step === newStep,
      } as NavigationStep)
  );

export const findPreviousStep = (navigationSteps: NavigationStep[]) => {
  const currentIndex = navigationSteps.findIndex((step) => step.active);
  return navigationSteps
    .slice(0, currentIndex)
    .reverse()
    .find((step) => step.visited)?.step;
};

export const findNextStep = (navigationSteps: NavigationStep[]) => {
  const currentIndex = navigationSteps.findIndex((step) => step.active);
  return navigationSteps.slice(currentIndex + 1).find((step) => step.visited)?.step;
};

export const setPreviousStep = (navigationSteps: NavigationStep[]): NavigationStep[] => {
  const previousVisitedStep = findPreviousStep(navigationSteps);
  return previousVisitedStep ? setNewStep(navigationSteps, previousVisitedStep) : navigationSteps;
};

export const setNextStep = (navigationSteps: NavigationStep[]): NavigationStep[] => {
  const nextVisitedStep = findNextStep(navigationSteps);
  return nextVisitedStep ? setNewStep(navigationSteps, nextVisitedStep) : navigationSteps;
};

export const resetBookingPageSteps = (navigationSteps: NavigationStep[]) =>
  navigationSteps.map((navigationStep) =>
    navigationStep.step === EventSteps.JOURNEY || navigationStep.step === EventSteps.GROUP
      ? navigationStep
      : ({
          step: navigationStep.step,
          active: false,
          visited: false,
        } as NavigationStep)
  );

export const validateFormField = (field: JourneyFormFieldInput | null) => {
  if (!field?.required) {
    return true;
  }
  if (!field.value || !field.value[0]) {
    return false;
  }
  switch (field.type) {
    case JourneyFormFieldType.EMAIL:
      return validateEmail(field.value[0] || '');
    case JourneyFormFieldType.PHONE: {
      const phone = field.value[0]?.slice(field.value[0].indexOf('-') + 1) || '';
      return validatePhoneNumber(phone);
    }
    default:
      return Boolean(field.value[0]);
  }
};
export const getBackgroundTypeClassName = (type: BackgroundType | null | undefined) => {
  switch (type) {
    case BackgroundType.BLANK:
      return 'bg-blank';
    case BackgroundType.COLOR:
    case BackgroundType.FULL:
      return 'bg-full';
    case BackgroundType.TOP:
      return 'bg-top';
    case BackgroundType.LEFT:
      return 'bg-left';
    case BackgroundType.RIGHT:
      return 'bg-right';
    default:
      return '';
  }
};
