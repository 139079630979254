import { combineReducers } from 'redux';
import { SmartAlert, SmartAlertTemplate, WhenSend, WhomSend } from '../../API';
import { BookingPagesAction } from '../bookingPages';
import { bookingPagesActionTypes } from '../bookingPages/actions';
import { BookingTemplatesAction, BookingTemplatesActionTypes } from '../bookingTemplates/actions';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { SmartAlertsAction, SmartAlertsActionTypes } from './actions';
import { DEFAULT_FILTER } from './constants';
import { SelectedAlertsType, SmartTypes } from './types';
import { convertSmartTemplateToAlert } from './utils';

const smartAlerts = (state: SmartAlert[] = [], action: SmartAlertsAction | GlobalAction) => {
  switch (action.type) {
    case SmartAlertsActionTypes.GET_SMART_TYPES_SUCCESS:
      return action.payload.smartAlerts;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const smartType = (state = SmartTypes.ALERT, action: SmartAlertsAction | GlobalAction) => {
  switch (action.type) {
    case SmartAlertsActionTypes.SET_SMART_TYPE:
      return action.payload;
    case SmartAlertsActionTypes.GET_SMART_TYPES_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return SmartTypes.ALERT;
    default:
      return state;
  }
};

const smartAlert = (state: SmartAlert = {} as SmartAlert, action: SmartAlertsAction | GlobalAction) => {
  switch (action.type) {
    case SmartAlertsActionTypes.SET_SMART_ALERT:
      return action.payload;
    case SmartAlertsActionTypes.UPDATE_SMART_ALERT:
      return { ...state, ...action.payload };
    case SmartAlertsActionTypes.UPDATE_SHARE_WITH:
      return { ...state, shareWith: action.payload };
    case SmartAlertsActionTypes.UPDATE_WHEN_SEND:
      return {
        ...state,
        whenSend: state.whenSend
          ? { ...state.whenSend, ...action.payload }
          : ({ __typename: 'WhenSend', ...action.payload } as WhenSend),
      };
    case SmartAlertsActionTypes.UPDATE_WHOM_SEND:
      return {
        ...state,
        whomSend: state.whomSend
          ? { ...state.whomSend, ...action.payload }
          : ({ __typename: 'WhomSend', ...action.payload } as WhomSend),
      };
    case SmartAlertsActionTypes.GET_SMART_ALERT_SUCCESS:
      return action.payload.smartAlert;
    case SmartAlertsActionTypes.SET_SMART_ALERT_TEMPLATE:
    case SmartAlertsActionTypes.GET_SMART_ALERT_TEMPLATE_SUCCESS:
      return convertSmartTemplateToAlert(action.payload);
    case SmartAlertsActionTypes.GET_SMART_TYPES_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as SmartAlert;
    default:
      return state;
  }
};

const smartAlertTemplates = (state: SmartAlertTemplate[] = [], action: SmartAlertsAction | GlobalAction) => {
  switch (action.type) {
    case SmartAlertsActionTypes.GET_SMART_TYPES_SUCCESS:
      return action.payload.smartAlertTemplates;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const smartAlertTemplate = (
  state: SmartAlertTemplate = {} as SmartAlertTemplate,
  action: SmartAlertsAction | GlobalAction
) => {
  switch (action.type) {
    case SmartAlertsActionTypes.SET_SMART_ALERT_TEMPLATE:
      return action.payload;
    case SmartAlertsActionTypes.GET_SMART_ALERT_TEMPLATE_SUCCESS:
      return action.payload;
    case SmartAlertsActionTypes.UPDATE_SMART_TEMPLATE:
      return { ...state, ...action.payload } as SmartAlertTemplate;
    case SmartAlertsActionTypes.GET_SMART_TYPES_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {} as SmartAlertTemplate;
    default:
      return state;
  }
};

const bookingPageIds = (state: string[] = [], action: SmartAlertsAction | GlobalAction) => {
  switch (action.type) {
    case SmartAlertsActionTypes.SET_BOOKING_PAGE_IDS:
      return action.payload;
    case SmartAlertsActionTypes.GET_SMART_ALERT_SUCCESS:
      return action.payload.bookingPages
        .filter((page) => page.smartAlertIds?.includes(action.payload.smartAlert.id))
        .map((page) => page.id);
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const bookingTemplateIds = (state: string[] = [], action: SmartAlertsAction | GlobalAction) => {
  switch (action.type) {
    case SmartAlertsActionTypes.SET_BOOKING_TEMPLATE_IDS:
      return action.payload;
    case SmartAlertsActionTypes.GET_SMART_ALERT_SUCCESS:
      return action.payload.bookingTemplates
        .filter((template) => template.smartAlertIds?.includes(action.payload.smartAlert.id))
        .map((template) => template.id);
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const filter = (state = DEFAULT_FILTER, action: SmartAlertsAction | GlobalAction) => {
  switch (action.type) {
    case SmartAlertsActionTypes.SET_FILTER:
      return { ...state, ...action.payload };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_FILTER;
    default:
      return state;
  }
};

const selectedAlerts = (state: SelectedAlertsType[] = [], action: SmartAlertsAction | GlobalAction) => {
  switch (action.type) {
    case SmartAlertsActionTypes.SELECT_ALERTS:
      return action.payload;
    case SmartAlertsActionTypes.GET_SMART_TYPES_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const cloneName = (state = '', action: SmartAlertsAction | GlobalAction) => {
  switch (action.type) {
    case SmartAlertsActionTypes.SET_CLONE_NAME:
      return action.payload;
    case SmartAlertsActionTypes.GET_SMART_TYPES_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const detachAlert = (state = false, action: SmartAlertsAction | GlobalAction) => {
  switch (action.type) {
    case SmartAlertsActionTypes.SET_DETACH_ALERT:
      return action.payload;
    case SmartAlertsActionTypes.GET_SMART_TYPES_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const withoutWarning = (
  state: string[] = [],
  action: SmartAlertsAction | GlobalAction | BookingPagesAction | BookingTemplatesAction
) => {
  switch (action.type) {
    case SmartAlertsActionTypes.ADD_TO_WITHOUT_WARNING:
      return [...state, action.payload];
    case bookingPagesActionTypes.GET_BOOKING_PAGE_REQUEST:
    case BookingTemplatesActionTypes.GET_BOOKING_TEMPLATE_REQUEST:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const lastLoadTime = (state = 0, action: SmartAlertsAction | GlobalAction) => {
  switch (action.type) {
    case SmartAlertsActionTypes.GET_SMART_TYPES_SUCCESS:
      return new Date().getTime();
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return 0;
    default:
      return state;
  }
};

const isFetching = (state = false, action: SmartAlertsAction | GlobalAction) => {
  switch (action.type) {
    case SmartAlertsActionTypes.GET_SMART_TYPES_REQUEST:
    case SmartAlertsActionTypes.CLONE_SMART_ALERT_REQUEST:
    case SmartAlertsActionTypes.DELETE_SMART_TYPES_REQUEST:
    case SmartAlertsActionTypes.GET_SMART_ALERT_REQUEST:
    case SmartAlertsActionTypes.UPSERT_SMART_TYPE_REQUEST:
    case SmartAlertsActionTypes.GET_SMART_ALERT_TEMPLATE_REQUEST:
      return true;
    case SmartAlertsActionTypes.GET_SMART_TYPES_SUCCESS:
    case SmartAlertsActionTypes.GET_SMART_TYPES_FAIL:
    case SmartAlertsActionTypes.CLONE_SMART_ALERT_SUCCESS:
    case SmartAlertsActionTypes.CLONE_SMART_ALERT_FAIL:
    case SmartAlertsActionTypes.DELETE_SMART_TYPES_SUCCESS:
    case SmartAlertsActionTypes.DELETE_SMART_TYPES_FAIL:
    case SmartAlertsActionTypes.GET_SMART_ALERT_SUCCESS:
    case SmartAlertsActionTypes.GET_SMART_ALERT_FAIL:
    case SmartAlertsActionTypes.GET_SMART_ALERT_TEMPLATE_SUCCESS:
    case SmartAlertsActionTypes.GET_SMART_ALERT_TEMPLATE_FAIL:
    case SmartAlertsActionTypes.UPSERT_SMART_TYPE_SUCCESS:
    case SmartAlertsActionTypes.UPSERT_SMART_TYPE_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};
const error = (state: unknown | null = null, action: SmartAlertsAction | GlobalAction) => {
  switch (action.type) {
    case SmartAlertsActionTypes.GET_SMART_TYPES_FAIL:
    case SmartAlertsActionTypes.CLONE_SMART_ALERT_FAIL:
    case SmartAlertsActionTypes.DELETE_SMART_TYPES_FAIL:
    case SmartAlertsActionTypes.GET_SMART_ALERT_FAIL:
    case SmartAlertsActionTypes.UPSERT_SMART_TYPE_FAIL:
    case SmartAlertsActionTypes.GET_SMART_ALERT_TEMPLATE_FAIL:
      return action.error;
    case SmartAlertsActionTypes.GET_SMART_TYPES_REQUEST:
    case SmartAlertsActionTypes.CLONE_SMART_ALERT_REQUEST:
    case SmartAlertsActionTypes.CLONE_SMART_ALERT_SUCCESS:
    case SmartAlertsActionTypes.GET_SMART_ALERT_REQUEST:
    case SmartAlertsActionTypes.UPSERT_SMART_TYPE_REQUEST:
    case SmartAlertsActionTypes.GET_SMART_ALERT_TEMPLATE_REQUEST:
      return null;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  error,
  smartAlerts,
  smartType,
  smartAlert,
  smartAlertTemplates,
  smartAlertTemplate,
  bookingPageIds,
  bookingTemplateIds,
  filter,
  selectedAlerts,
  cloneName,
  detachAlert,
  withoutWarning,
  lastLoadTime,
});
