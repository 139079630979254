// import './App.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { GlobalError, ToastNotification } from './components/common';
import { GuideAds } from './components/promo/guide/GuideAds';
import { GuideTour } from './components/promo/guide/GuideTour';
import { InstallModal } from './components/promo/installModal/installModal';
import { GoogleAuthRedirect, Login, MicrosoftAuthRedirect, SignUp } from './components/public';
import {
  Account,
  AccountDetails,
  Accounts,
  Availability,
  Billing,
  BookedMeetings,
  BookingPages,
  BookingTemplates,
  BuyLicensesPage,
  CalendarConnections,
  ChargebeePoC,
  ConnectIntegrationPage,
  EditBookingPage,
  EditBookingTemplate,
  EditGroupBookingPage,
  EditJourney,
  EditLocation,
  EditSmartAlert,
  EditWorkspace,
  Integration,
  Journeys,
  LicenseExpired,
  Locations,
  LocationsImport,
  OPSConsoleUsers,
  OrgDetails,
  Orgs,
  PreSelectSmartAlert,
  PublicBookingPage,
  PublicRoute,
  QuickSetup,
  Roles,
  SmartAlerts,
  StaffDetails,
  StaffPage,
  Teams,
  Tests,
  UserDetails,
  Users,
  ViewAsUser,
  Workspaces,
} from './pages';
import { OPSConsoleRoute, Path, PrivateRoute } from './routing';
import { navigationService } from './services/NavigationService';
import { authenticationActions, authenticationSelectors } from './store/authentication';
import { notificationsSelectors } from './store/notifications';
import { userSettingsSelectors } from './store/userSettings';

export default function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const toastNotificationsQueue = useSelector(notificationsSelectors.selectToastNotificationsQueue);
  const isSumo1Admin = useSelector(authenticationSelectors.selectIsSumo1Admin);
  const isOpsConsole = useSelector(authenticationSelectors.selectIsOpsConsole);
  const isGuideTourFinished = useSelector(userSettingsSelectors.selectIsGuideTourFinished);
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);
  const isPathIgnoredForGuideTour = [
    Path.PublicBookingPageRoot,
    Path.LicenseExpired,
    Path.QuickSetup,
    Path.Login,
    Path.MicrosoftAuthRedirect,
    Path.GoogleAuthRedirect,
    Path.OPSConsole,
  ].some((pagePath) => {
    const fixedPath = pagePath.startsWith('/') ? pagePath : '/' + pagePath;
    return (location.pathname + '/').startsWith(fixedPath + '/') || location.pathname === Path.Landing;
  });

  // set up navigation service to use across application
  const navigate = useNavigate();
  useEffect(() => {
    navigationService.setNavigate(navigate);
  }, [navigate]);

  useEffect(() => {
    const isOpsConsoleNew = isSumo1Admin && location.pathname.startsWith(Path.OPSConsole);
    if (isOpsConsoleNew !== isOpsConsole) {
      // hide crispChat for OPS Console
      const crispChat = document.querySelector('.crisp-client');
      if (crispChat) {
        if (isOpsConsoleNew) {
          crispChat.classList.add('hidden');
        } else {
          crispChat.classList.remove('hidden');
        }
      }
      dispatch(authenticationActions.setIsOpsConsole(isOpsConsoleNew));
    }
  }, [isSumo1Admin, location]);

  return (
    <>
      <Routes>
        <Route path={Path.OPSConsole} element={<OPSConsoleRoute />}>
          <Route path={Path.OPSConsoleAccounts} element={<Accounts />} />
          <Route path={Path.OPSConsoleAccount} element={<AccountDetails />} />
          <Route path={Path.OPSConsoleOrgs} element={<Orgs />} />
          <Route path={Path.OPSConsoleOrgDetails} element={<OrgDetails />} />
          <Route path={Path.OPSConsoleStaff} element={<StaffPage />} />
          <Route path={Path.OPSConsoleStaffDetails} element={<StaffDetails />} />
          <Route path={Path.OPSConsoleUsers} element={<OPSConsoleUsers />} />
          <Route path={Path.OPSConsoleUserDetails} element={<UserDetails />} />
          <Route path={Path.OPSConsoleBilling} />
          <Route path={Path.OPSConsoleViewAsUser} element={<ViewAsUser />} />
        </Route>
        <Route element={<PublicRoute />}>
          <Route index element={<SignUp />} />
          <Route path={Path.Login} element={<Login />} />
          <Route path={Path.MicrosoftAuthRedirect} element={<MicrosoftAuthRedirect />} />
          <Route path={Path.GoogleAuthRedirect} element={<GoogleAuthRedirect />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path={Path.Account} element={<Account />} />
          <Route path={Path.Billing} element={<Billing />} />
          <Route path={Path.EditBookingTemplate} element={<EditBookingTemplate />} />
          <Route path={Path.EditWorkspace} element={<EditWorkspace />} />
          <Route path={Path.BookingTemplates} element={<BookingTemplates />} />
          <Route path={Path.Roles} element={<Roles />} />
          <Route path={Path.Users} element={<Users />} />
          <Route path={Path.Workspaces} element={<Workspaces />} />
          <Route path={Path.Teams} element={<Teams />} />
          <Route path={Path.BuyLicenses} element={<BuyLicensesPage />} />
          <Route path={Path.Availability} element={<Availability />} />
          <Route path={Path.BookingPages} element={<BookingPages />} />
          <Route path={Path.CalendarConnections} element={<CalendarConnections />} />
          <Route path={Path.ConnectIntegration} element={<ConnectIntegrationPage />} />
          <Route path={Path.EditBookingPage} element={<EditBookingPage />} />
          <Route path={Path.EditGroupBookingPage} element={<EditGroupBookingPage />} />
          <Route path={Path.Integration} element={<Integration />} />
          <Route path={Path.BookedMeetings} element={<BookedMeetings />} />
          <Route path={Path.QuickSetup} element={<QuickSetup />} />
          <Route path={Path.BillingTests} element={<ChargebeePoC />} />
          <Route path={Path.Tests} element={<Tests />} />
          <Route path={Path.LicenseExpired} element={<LicenseExpired />} />
          <Route path={Path.SmartAlerts} element={<SmartAlerts />} />
          <Route path={Path.PreSelectSmartAlert} element={<PreSelectSmartAlert />} />
          <Route path={Path.EditSmartAlert} element={<EditSmartAlert />} />
          <Route path={Path.Locations} element={<Locations />} />
          <Route path={Path.LocationsImport} element={<LocationsImport />} />
          <Route path={Path.EditLocation} element={<EditLocation />} />
          <Route path={Path.Journeys} element={<Journeys />} />
          <Route path={Path.EditJourney} element={<EditJourney />} />
        </Route>
        <Route path={Path.UnknownPath} element={<GlobalError />} />
        <Route path={Path.PublicBookingPage} element={<PublicBookingPage />} />
        <Route path={Path.PublicGroupBookingPage} element={<PublicBookingPage />} />
        <Route path={Path.PublicJourneyPage} element={<PublicBookingPage />} />
        <Route path={Path.Event} element={<PublicBookingPage />} />
      </Routes>
      {isPathIgnoredForGuideTour ? null : (
        <>
          {!isGuideTourFinished && isLicenseActive ? <GuideAds /> : null}
          <GuideTour />
          <InstallModal />
        </>
      )}
      {Boolean(toastNotificationsQueue.length) && <ToastNotification />}
    </>
  );
}
