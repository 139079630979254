import { t } from '../../../i18n/i18n';

export default {
  installInboxPart1: t('BookingStats:INSTALL_INBOX_PART1'),
  installInboxPart2: t('BookingStats:INSTALL_INBOX_PART2'),
  installInboxPart3: t('BookingStats:INSTALL_INBOX_PART3'),
  installInboxPart4: t('BookingStats:INSTALL_INBOX_PART4'),
  installInboxPart5: t('BookingStats:INSTALL_INBOX_PART5'),
  updateGoogleIntegrationDescription: t('BookingStats:UPDATE_GOOGLE_INTEGRATION_DESCRIPTION'),
  updateGoogleIntegrationButton: t('BookingStats:UPDATE_GOOGLE_INTEGRATION_BUTTON'),
};
