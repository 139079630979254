import { t } from '../../i18n/i18n';

export default {
  title: t('License:BUY_LICENSES'),
  back: t('Common:BACK'),
  monthly: t('OPSConsoleOrgs:TERM_MONTHLY'),
  annually: t('OPSConsoleOrgs:TERM_ANNUALLY'),
  term: t('OPSConsoleOrgs:TERM_TITLE'),
  licenses: t('License:LICENSES'),
  buyLicensesMessage: t('Billing:BUY_LICENSES_MESSAGE'),
  berUserMonth: t('Billing:PER_USER_MONTH'),
  save: t('Common:SAVE'),
  next: t('Common:NEXT'),
  cancel: t('Common:CANCEL'),
  licenseMessagePart1: t('Billing:LICENSE_MESSAGE_PART_1'),
  licenseMessagePart2: t('Billing:LICENSE_MESSAGE_PART_2'),
  licenseMessagePart3: t('Billing:LICENSE_MESSAGE_PART_3'),
  paymentMethod: t('Billing:PAYMENT_METHOD'),
  card: t('Billing:CARD'),
  byInvoice: t('Billing:BY_INVOICE'),
  payByInvoiceMinValueOrderTooltip: t('Billing:PAY_BY_INVOICE_MIN_VALUE_ORDER_TOOLTIP'),
};
