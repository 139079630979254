import { CodeBracketIcon, EllipsisVerticalIcon, LinkIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Tooltip } from 'primereact/tooltip';
import { TooltipEvent } from 'primereact/tooltip/tooltipoptions';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateGroupBookingPageInput } from '../../../API';
import { Path } from '../../../routing';
import { navigationService } from '../../../services/NavigationService';
import { getUrlOrigin } from '../../../services/URLService';
import { addToWebsiteModalActions, bookingPagesActions, bookingPageSelectors } from '../../../store/bookingPages';
import {
  groupBookingPagesActions,
  groupDeactivateModalActions,
  groupDeleteModalActions,
} from '../../../store/groupBookingPages';
import { AppDispatch } from '../../../store/rootStore';
import labels from './labels';
import { TOAST_SUCCESS_DURATION } from '../../../types/constants';

type GroupBookingPageCardProps = {
  groupBookingPage: UpdateGroupBookingPageInput;
};
export const GroupBookingPageCard = ({ groupBookingPage }: GroupBookingPageCardProps) => {
  const { id, name, shortLink, active } = groupBookingPage;
  const dispatch = useDispatch<AppDispatch>();
  const linkedBookingPages = useSelector(bookingPageSelectors.selectLinkedBookingPages(groupBookingPage));
  const menu = useRef<Menu>(null);
  const copyTooltip = useRef<Tooltip>(null);

  let blockCardClick = false;

  const [linkCopied, setLinkCopied] = useState(false);
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (linkCopied) {
      timer = setTimeout(() => {
        setLinkCopied(false);
        copyTooltip.current?.hide();
      }, TOAST_SUCCESS_DURATION);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [linkCopied]);

  const handleCardClick = () => {
    if (blockCardClick) {
      blockCardClick = false;
      return;
    }
    handleEdit();
  };

  const handleEdit = () => {
    navigationService.navigateTo(Path.EditGroupBookingPage.replace(':groupBookingPageId', id));
  };

  const handleActivate = () => {
    dispatch(groupBookingPagesActions.setGroupBookingPage(groupBookingPage));
    dispatch(groupBookingPagesActions.activateGroupBookingPageRequest());
  };

  const handleDeactivate = () => {
    dispatch(groupBookingPagesActions.setGroupBookingPage(groupBookingPage));
    dispatch(groupDeactivateModalActions.openModal());
  };

  const handleActivateButton = () => {
    blockCardClick = true;
    handleActivate();
  };

  const handleClone = () => {
    dispatch(groupBookingPagesActions.setGroupBookingPage(groupBookingPage));
    dispatch(groupBookingPagesActions.cloneGroupBookingPageThunk());
  };

  const handleDelete = () => {
    dispatch(groupBookingPagesActions.setGroupBookingPage(groupBookingPage));
    dispatch(groupDeleteModalActions.openModal());
  };

  const handleCopyLink = (event: React.MouseEvent<HTMLButtonElement>) => {
    blockCardClick = true;
    const bookingPageUrl = shortLink
      ? shortLink
      : getUrlOrigin() + Path.PublicGroupBookingPage.replace(':groupBookingPageId', id);
    navigator.clipboard.writeText(bookingPageUrl);

    handleShowTooltip({
      originalEvent: event,
      target: event.currentTarget,
    });
  };

  const handleShowTooltip = (event: TooltipEvent) => {
    copyTooltip.current?.show(event);
    setLinkCopied(true);
  };

  const handleAddToWebsite = () => {
    blockCardClick = true;
    dispatch(bookingPagesActions.setAddToWebsiteLink(Path.PublicGroupBookingPage.replace(':groupBookingPageId', id)));
    dispatch(addToWebsiteModalActions.openModal());
  };

  const menuItems: MenuItem[] = [
    { label: labels.edit, command: handleEdit },
    {
      label: active ? labels.deactivate : labels.activate,
      command: active ? handleDeactivate : handleActivate,
    },
    { label: labels.clone, command: handleClone },
    { label: labels.delete, command: handleDelete },
  ];

  return (
    <>
      <div className={`relative`} onClick={handleCardClick}>
        <div className="sumo-card h-full w-full absolute " style={{ rotate: '-2deg' }} />
        <div className="sumo-card h-full w-full absolute" style={{ rotate: '-1deg' }} />
        <div className={`sumo-card action-card flex flex-column h-full relative`}>
          <div className="flex pt-22px px-18px">
            <div className="flex-1 text-title-lg-med">{name}</div>
            <div className="flex gap-2px -my-1px h-24px">
              <div
                className="-my-4px -mr-16px action-button"
                onClick={(e) => {
                  blockCardClick = true;
                  menu.current?.toggle(e);
                }}
              >
                <Menu ref={menu} model={menuItems} popup appendTo="self" className="scroll-menu" />
                <EllipsisVerticalIcon className="icon-20px" />
              </div>
            </div>
          </div>

          <div style={{ minHeight: '76px' }}>
            <div className="text-overlapping-two-lines pt-4px px-18px text-body-s-reg text-heavy-60">
              {linkedBookingPages.map((page, index) => (
                <Fragment key={`name_${page.id}`}>
                  {index > 0 && (
                    <span className="w-4px h-4px border-radius-2px mx-6px bg-heavy-50 vertical-align-middle inline-block" />
                  )}
                  <span>{page.what?.customName}</span>
                </Fragment>
              ))}
            </div>
          </div>

          <div className="card-delimeter flex-1" />

          <div className="flex justify-content-between align-items-center p-10px pl-18px">
            <div className="flex gap-4px">
              {linkedBookingPages.map((page, index) =>
                index < 4 || (index === 4 && linkedBookingPages.length === 5) ? (
                  <div key={`color_${page.id}`} className="p-8px bg-heavy-1 border-radius-6px">
                    <div
                      className="w-16px h-16px border-radius-4px"
                      style={{ backgroundColor: page.what?.color || '' }}
                    />
                  </div>
                ) : index === 4 ? (
                  <div
                    key={`color_${page.id}`}
                    className="p-8px bg-heavy-1 border-radius-6px text-body-s-reg text-heavy-60"
                  >
                    +{linkedBookingPages.length - 4}
                  </div>
                ) : undefined
              )}
            </div>

            <div className="flex align-items-center gap-8px h-38px">
              {active ? (
                <>
                  <Button
                    className="button-icon"
                    outlined
                    onClick={handleAddToWebsite}
                    tooltip={labels.addToSite}
                    tooltipOptions={{ position: 'top' }}
                  >
                    <CodeBracketIcon className="icon-18px" />
                  </Button>
                  <Tooltip
                    ref={copyTooltip}
                    position="top"
                    pt={{ text: { className: 'bg-egg-blue-dark text-primary-white text-label-s-reg' } }}
                  >
                    {labels.copiedLink}
                  </Tooltip>
                  <Button className="button-icon gap-6px" outlined onClick={handleCopyLink}>
                    <LinkIcon className="icon-18px" />
                    <div>{labels.copyLink}</div>
                  </Button>
                </>
              ) : (
                !active && <Button label={labels.activate} outlined severity="success" onClick={handleActivateButton} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
