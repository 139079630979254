export const OPSConsoleOrgs = Object.freeze({
  ACCOUNT_TITLE: `Conta`,
  ACTIVE: `Ativo`,
  ADDRESS_LINE_1: `Linha de endereço 1`,
  ADDRESS_LINE_2: `Linha de endereço 2`,
  ADOPTION_PERCENT: `% Adotado`,
  AMOUNT_TITLE: `ARR`,
  BILLING_EMAIL: `E-mail de cobrança`,
  BOOKED_NUMBER: `# Reservado`,
  CUSTOMER: `Cliente`,
  DAYS_LEFT_TITLE: `Dias Restantes`,
  EXPIRED: `Expirado`,
  EXPIRE_TRIAL: `Expirar Teste`,
  EXPORT: `Exportar`,
  EXTEND_TRIAL: `Estender Teste`,
  FILTER_ALL_ORGS: `Todas as Organizações`,
  FILTER_EXPIRED_SOON: `Expira em Breve`,
  FILTER_LOW_ADOPT: `Baixa Adoção`,
  LICENSES_NUMBER: `# de Licenças`,
  LAST_ACTIVE_TITLE: `Última Atividade`,
  ORG_ID_TITLE: `ID da Organização`,
  PAYMENT_LATE: `Pagamento Atrasado`,
  SEARCH_PLACEHOLDER: `Pesquisar por nome da conta, e-mail do usuário ou ID da organização`,
  STATUS_TITLE: `Status`,
  SUSPENDED: `Suspenso`,
  SUBSCRIPTION_ID: 'ID da subscrição',
  TERM_TITLE: `Prazo`,
  TERM_ALL: `Todos`,
  TERM_ANNUAL: `Anual`,
  TERM_TRIAL: `Testes`,
  TERM_MONTHLY: `Mensal`,
  TERM_ANNUALLY: `Anualmente`,
  TEST: `Teste`,
  TITLE: `Organizações`,
  TRIAL: `Teste`,
  TYPE_TITLE: `Tipo`,
  VIEW_ORG: `Visualizar Organização`,
});

export const OrgsToastsNotifications = Object.freeze({
  CREATE_PAY_LATER_LICENSE_SUCCESS_TOAST: `Assinatura criada com sucesso`,
  CREATE_PAY_LATER_LICENSE_SUCCESS_MESSAGE: `Aguarde até 1 minuto`,
  GET_ORGS_FAIL_MESSAGE: `Falha ao obter as organizações`,
  GET_ORG_DETAILS_FAIL_MESSAGE: `Falha ao obter detalhes da organização`,
  SAVE_ORG_DETAILS_SUCCESS_MESSAGE: `Sucesso ao salvar detalhes da organização`,
  SAVE_ORG_DETAILS_FAIL_MESSAGE: `Falha ao salvar detalhes da organização`,
  DELETE_ORG_SUCCESS_MESSAGE: `Organização excluída com sucesso`,
  DELETE_ORG_FAIL_MESSAGE: `Falha ao excluir organização`,
  EXTEND_LICENSE_SUCCESS_MESSAGE: `Sucesso ao estender a licença`,
  EXTEND_LICENSE_FAIL_MESSAGE: `Falha ao estender a licença`,
  EXPIRE_TRIAL_SUCCESS_MESSAGE: `Sucesso ao expirar o teste`,
  EXPIRE_TRIAL_FAIL_MESSAGE: `Falha ao expirar o teste`,
  CONVERT_TO_TEST_SUCCESS_MESSAGE: `Sucesso ao converter para teste`,
  CONVERT_TO_TEST_FAIL_MESSAGE: `Falha ao converter para teste`,
  CONVERT_TO_PAY_LATER_SUCCESS_MESSAGE: `Assinatura Pay Later do cliente criada com sucesso`,
  CONVERT_TO_PAY_LATER_FAIL_MESSAGE: `Falha na solicitação de assinatura de pagamento posterior do cliente`,
  GET_ORGS_LOGS_FAIL_MESSAGE: `Falha na solicitação dos registros de ações das organizações`,
  GET_ORG_LOGS_FAIL_MESSAGE: `Falha na solicitação de registros de ações da organização`,
});

export const OPSConsoleOrgDetails = Object.freeze({
  ACCOUNT_NAME: `Nome da Conta`,
  ADOPTION_PERCENT_WEEKLY: `% Adoção semanal`,
  ADOPTION_PERCENT_MONTHLY: `% Adoção mensal`,
  ACTIVE_USERS_NUMBER: `# de Usuários Atribuídos`,
  AMOUNT_PER_USER: `Por Usuário / Por Mês`,
  BOOKED_NUMBER: `# de Reuniões Agendadas`,
  CONVERT_TO_CUSTOMER: `Converter para Cliente`,
  CONVERT_TO_TEST: `Converter para Teste`,
  DAYS_REMAINING: `Dias Restantes`,
  EDIT_ORG: `Editar Organização`,
  EXPIRATION_DATE: `Data de Expiração`,
  FEATURE_PRICING_TIER: `Característica/Nível de Preços`,
  FULL_ACCESS: `Acesso Completo`,
  LICENSES_NUMBER: `# de Licenças`,
  ORG_ID: `ID da Organização`,
  ORG_NOTE: `Nota da Organização`,
  ORG_STATUS: `Status da Organização`,
  ORG_TITLE: `Organização`,
  ORG_TYPE: `Tipo de Organização`,
  PARTIAL_ACCESS: `Acesso Parcial`,
  PAYMENT_TERM: `Termo de Pagamento`,
  PAYMENT_TITLE: `Pagamento`,
  PAYMENT_TYPE: `Tipo de Pagamento`,
  START_DATE: `Data de Início`,
  TERM: `Prazo`,
  TERMS_TITLE: `Termos`,
  TOTAL_AMOUNT: `ARR`,
  USER_ADOPTION: `Adoção de Usuário`,
  WEEKLY_ADOPTION: `Adoção Semanal`,
  ADOPTION_CHART_TOOLTIP: `A adoção é calculada comparando o número de licenças compradas com o número de usuários que criaram/editaram uma configuração (uma página de reservas, modelo, Meeting Booker) ou agendaram um compromisso nos últimos 7 dias.`,
  TOTAL_AVERAGE: `Média total`,
});

export const OrgDetailsLicenses = Object.freeze({
  CREATE_LICENSE: `Criar Licença`,
  LICENSES_NUMBER: `# de Licenças`,
  PURCHASED_DATE: `Data de Compra`,
  PURCHASED_NAME: `Nome do Comprador`,
  TITLE: `Licenças`,
});

export const OrgDetailsUsers = Object.freeze({
  TERM_TRIAL: `Teste`,
  VIEW: `Ver`,
  VIEW_ALL_PART1: `Ver Todos`,
  VIEW_ALL_PART2: `Registros`,
  VIEW_LESS: `Ver Menos`,
});

export const OrgExtendTrialModal = Object.freeze({
  EXTEND_DESCRIPTION: `Estender # de Dias`,
  EXTEND_MESSAGE: `Não é possível estender mais. O teste já foi estendido para 30 dias.`,
  EXTEND_TITLE: `Estender`,
  SAVE_AND_EXTEND: `Salvar e Estender`,
  RULES_DESCRIPTION_PART1: `Os testes gratuitos têm duração de 15 dias.`,
  RULES_DESCRIPTION_PART2: `A equipe de suporte pode estender por mais 15 dias.`,
  RULES_DESCRIPTION_PART3: `Após o vencimento, o cliente ainda pode fazer login, mas verá apenas a Página de Cobrança.`,
  RULES_DESCRIPTION_PART4: `Incentive-os a comprar apenas 1 licença mensalmente.`,
  RULES_TITLE: `Regras`,
  TITLE: `Estender Teste`,
});

export const OrgExpireTrialModal = Object.freeze({
  EXPIRE_DESCRIPTION: `Sim, desejo forçar a expiração deste teste.`,
  EXPIRE_NOW: `Expirar Agora`,
  EXPIRE_TITLE: `Expirar`,
  RULES_DESCRIPTION_PART1: `Se você notar que um concorrente ou alguém está executando um teste que não deveria, você pode forçar a expiração do teste.`,
  RULES_DESCRIPTION_PART2: `Eles receberão um erro ao fazer login, sendo informados para entrar em contato com as vendas ou suporte.`,
  RULES_TITLE: `Regras`,
  TITLE: `Expirar Teste`,
});

export const OrgConvertTrialModal = Object.freeze({
  AMOUNT_PER_USER: `Por Usuário / Por Mês`,
  ANNUAL_CONTRACT: `Contrato Anual`,
  BILLING_TITLE: `Faturamento - Fatura do Ano 1`,
  CONTRACT_END_DATE: `Data de Término do Contrato`,
  CONTRACT_START_DATE: `Data de Início do Contrato`,
  CONVERT_TO_CUSTOMER: `Converter para Cliente`,
  IMMEDIATE_PROCESS_DESCRIPTION: `Incentive os clientes a pagar com cartão de crédito no aplicativo clicando em "Comprar Licenças" na lista de usuários, o que os converterá automaticamente.`,
  IMMEDIATE_PROCESS_TITLE: `Processo Imediato`,
  INVOICE_DUE_DATE: `Data de Vencimento da Fatura`,
  MANUAL_PROCESS_DESCRIPTION_PART1: `Somente operações podem realizar essa função.`,
  MANUAL_PROCESS_DESCRIPTION_PART2: `Para >30 licenças, prazo anual, os clientes podem pagar via ACH, Wire ou Cheque.`,
  MANUAL_PROCESS_DESCRIPTION_PART3: `Os clientes devem ter assinado o contrato (e o PO é opcional).`,
  MANUAL_PROCESS_TITLE: `Processo Manual`,
  NET_TERMS: `Termos NET`,
  RULES_TITLE: `Regras`,
  SALESFORCE_OPPORTUNITY: `Oportunidade Salesforce`,
  SALESFORCE_OPPORTUNITY_PLACEHOLDER: `Insira o hiperlink aqui`,
  TITLE: `Converter Teste em Cliente Pagante`,
  TOTAL_AMOUNT: `Total Anual`,
  USER_LICENSES_NUMBER: `# de Licenças de Usuário`,
  WARNING_MESSAGE: `Esta ação não pode ser desfeita.`,
});

export const OrgDeleteModal = Object.freeze({
  TITLE: `Excluir organização`,
  DESCRIPTION: `Esta organização será excluída com todos os dados`,
});

export const OrgsLicenseChart = Object.freeze({
  TITLE: `Compra de Licença`,
  FOR: `para `,
  MONTH_1: `1 Mês`,
  MONTHS_3: `3 Meses`,
  MONTHS_6: `6 Meses`,
  MONTHS_12: `12 Meses`,
  YEAR_TO_DATE: `Ano até hoje`,
  MONTH_TO_DATE: `Mês até hoje`,
});

export const OrgsChanges = Object.freeze({
  CHANGES_TITLE: `Alterações`,
  ADDED: `Adicionado`,
  REDUCE: `Reduzir`,
  LICENSE: `Licença`,
  LICENSES: `Licenças`,
  CHANGED_LABEL: `Prazo alterado de`,
  SCHEDULED_CHANGED_LABEL: `Prazo agendado alterado de`,
  CHANGES_SCHEDULED_FOR: `Alterações programadas para`,
});
