export const LookAndFeel = Object.freeze({
  BACKGROUND_LABEL: `Arrière-plan`,
  BUTTONS_LABEL: `Boutons`,
  FONT_COLORS_TITLE: `Couleurs de police`,
  FONT_FAMILIES_TITLE: `Familles de polices`,
  HEADER_FONT_LABEL: `Police d'en-tête`,
  HEADER_LABEL: `En-tête`,
  MAIN_TEXT_FONT_LABEL: `Police du texte principal`,
  MAIN_TEXT_LABEL: `Texte principal`,
  PRIMARY_LABEL: `Principal`,
  SITE_COLORS_TITLE: `Couleurs du site`,
  SUB_HEADER_FONT_LABEL: `Police du sous-en-tête`,
  SUB_HEADER_LABEL: `Sous-en-tête`,
});

export const QuickSetupCalendarConnected = Object.freeze({
  DESCRIPTION: `SUMO vérifiera automatiquement votre calendrier pour les périodes occupées et ajoutera de nouvelles réunions au fur et à mesure qu'elles seront planifiées. Vous pouvez toujours déconnecter votre calendrier plus tard.`,
  INSTRUCTION_STEP_A1: `Nous vérifierons `,
  INSTRUCTION_STEP_A2: ` pour les conflits.`,
  INSTRUCTION_STEP_B: `Nous ajouterons des réunions au calendrier pour `,
  INSTRUCTION_STEP_C: `Nous chiffrerons toutes les données en transit et vous donnerons des options concernant vos données.`,
  INSTRUCTION_TITLE: `Voici comment SUMO fonctionnera avec `,
  STEP_MESSAGE: `Faisons-le!`,
  TITLE: `Votre calendrier est connecté!`,
});

export const QuickSetupChromeExtensionStep = Object.freeze({
  ACCEPT_TERMS_PART1: `J'accepte les`,
  ACCEPT_TERMS_PART2: `Conditions d'utilisation`,
  ACCEPT_TERMS_PART3: `et la`,
  ACCEPT_TERMS_PART4: `Politique de confidentialité`,
  BENEFITS_DESCRIPTION: `La meilleure expérience SUMO1 consiste à utiliser SUMO depuis votre boîte de réception, en installant l'extension Chrome. Utilisez SUMO dans :`,
  BENEFITS_LIST_PART1: `Google Gmail & Calendrier`,
  BENEFITS_LIST_PART2: `Boîte de réception & Calendrier Microsoft`,
  BENEFITS_LIST_PART3: `Salesforce CRM`,
  BENEFITS_TITLE: `Avantages`,
  CHROME_EXTENSION: `Extension Chrome`,
  REQUIREMENTS_DESCRIPTION: `Vous devez utiliser le navigateur Google Chrome.`,
  REQUIREMENTS_TITLE: `Exigences`,
  SUMO1_FOR_GOOGLE: `Installer "SUMO1 pour Gmail"`,
  SUMO1_FOR_MICROSOFT: `Installer "SUMO1 pour Outlook"`,
  TITLE: `Installer l'extension Chrome`,
});

export const QuickSetupSetupAvailabilityStep = Object.freeze({
  TITLE_USER: `Définir votre disponibilité`,
  MESSAGE_USER: `Créez le planning par défaut initial indiquant quand vous êtes généralement disponible pour des rendez-vous.`,
  MINIMUM_DAYS_ERROR_TEXT: `Veuillez sélectionner au moins un jour avec des heures disponibles`,
  SETUP_DAYS_INPUT_LABEL: `Jours disponibles`,
  SETUP_HOURS_INPUT_LABEL: `Heures disponibles`,
  STEP_MESSAGE: `Continuez comme ça!`,
});

export const QuickSetupSetupPhoneCallsStep = Object.freeze({
  DESCRIPTION: `Pour les appels programmés, quel numéro les invités doivent-ils composer? La plupart des utilisateurs fournissent leur mobile pour les alertes SMS, mais vous pouvez mettre à jour cela plus tard.`,
  PHONE_DETAILS_LABELS: `Informations supplémentaires ou instructions`,
  PHONE_DETAILS_PLACEHOLDER: `Ex: J'ai hâte de vous rencontrer. Envoyez-moi un SMS à tout moment.`,
  PHONE_PLACEHOLDER: `Numéro de téléphone`,
  STEP_MESSAGE: `Vous y êtes presque!`,
  TITLE: `Définir le numéro de téléphone`,
});

export const QuickSetupToastsNotifications = Object.freeze({
  SAVE_QUICK_SETUP_ERROR_TOAST: `Échec de la demande d'enregistrement de la configuration rapide`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_DESC: `Vous êtes maintenant prêt pour une planification facile.`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_TITLE: `Configuration terminée!`,
});

export const QuickSetupVideoConferenceStep = Object.freeze({
  DEFAULT_DESCRIPTION: `Conférence vidéo par défaut`,
  DESCRIPTION: `Optionnel, mais recommandé pour les réunions virtuelles.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  STEP_MESSAGE: `Faisons-le!`,
  TITLE: `Connecter un compte de vidéoconférence`,
  ZOOM_TITLE: `Réunions ZOOM`,
});

export const QuickSetupYourBrandStep = Object.freeze({
  DESCRIPTION: `Téléchargez votre logo pour qu'il apparaisse sur toutes les pages de réservation. Vous pouvez effectuer d'autres réglages de branding dans l'espace de travail plus tard.`,
  SAVE_BUTTON: `Enregistrer & terminer la configuration`,
  STEP_MESSAGE: `Vous y êtes presque!`,
  TITLE: `Ajoutez votre logo`,
});
