import { useDispatch, useSelector } from 'react-redux';
import {
  billingSelectors,
  billingActions,
  billingAddressModalActions,
  ALLOWED_PAY_BY_INVOICE_OF_AMOUNT,
  PaymentMethod,
} from '../../../store/billing';
import { InputTextarea } from 'primereact/inputtextarea';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import labels from './labels';
import { Button } from 'primereact/button';
import { BillingAddressModal } from '../billingAddressModal/BillingAddressModal';
import { openPortal } from '../../../services/ChargebeeService';
import { Skeleton } from 'primereact/skeleton';
import { useRef } from 'react';
import { SelectItem } from 'primereact/selectitem';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { PaymentSource } from '../paymentSource/PaymentSource';

export const BillingDetails = () => {
  const dispatch = useDispatch();
  const billingAddress = useSelector(billingSelectors.selectCustomerBillingAddressText);
  const customerBillingAddress = useSelector(billingSelectors.selectCustomerBillingAddress);
  const isFetching = useSelector(billingSelectors.selectIsFetching);
  const isDeletingPaymentMethodFetching = useSelector(billingSelectors.selectIsDeletingPaymentMethodFetching);
  const nextBillAmount = useSelector(billingSelectors.selectNextBillAmount);
  const isAutoCollection = useSelector(billingSelectors.selectIsAutoCollection);
  const allPaymentSources = useSelector(billingSelectors.selectAllPaymentSources);

  const selectAddPaymentMethodRef = useRef<any | null>(null);

  const handleEditPaymentMethod = () => {
    openPortal(() => {
      // Chargebee portal closed
      dispatch(billingActions.getBillingRequest());
      dispatch(billingActions.getItemPricesRequest());
    }, true);
  };

  const handleBillingAddressEdit = () => {
    dispatch(billingActions.setBillingAddress({ ...customerBillingAddress }));
    dispatch(billingAddressModalActions.openModal());
  };

  const handleDeletePaymentSource = (id: string) => {
    if (id === PaymentMethod.INVOICE) {
      dispatch(billingActions.updatePaymentMethodRequest(PaymentMethod.CARD));
    } else {
      dispatch(billingActions.deletePaymentSourceRequest(id));
    }
  };

  const getPaymentMethodsOptions = (): SelectItem[] => {
    const options = [{ label: labels.card, value: 'card' }];

    if (nextBillAmount >= ALLOWED_PAY_BY_INVOICE_OF_AMOUNT && isAutoCollection) {
      options.push({
        label: labels.byInvoice,
        value: 'invoice',
      });
    }

    return options;
  };

  const addPaymentMethod = (event: DropdownChangeEvent) => {
    if (event.value === 'card') {
      handleEditPaymentMethod();
    } else {
      dispatch(billingActions.updatePaymentMethodRequest(PaymentMethod.INVOICE));
    }
  };

  return (
    <>
      <BillingAddressModal />

      <div className="flex flex-wrap -mx-20px -my-12px">
        {isFetching || isDeletingPaymentMethodFetching ? (
          <div className="w-12 lg:w-6 px-20px py-12px flex gap-16px">
            <Skeleton className="w-360px h-42px" />
          </div>
        ) : (
          <div className="w-12 lg:w-6 px-20px py-12px flex flex-column gap-16px">
            <div className="text-title-xs-med">{labels.paymentMethod}</div>
            {allPaymentSources.map((paymentSource, index) => {
              return (
                <PaymentSource
                  key={paymentSource.id}
                  paymentSource={paymentSource}
                  isDefault={index === 0}
                  onEdit={handleEditPaymentMethod}
                  onDelete={handleDeletePaymentSource}
                  showDelete={allPaymentSources.length > 1}
                />
              );
            })}

            <div style={{ paddingRight: 72 }}>
              <div className="flex-left-center gap-4px">
                <div className="flex-1 add-button-input" onClick={() => selectAddPaymentMethodRef.current?.show()}>
                  <PlusIcon className="icon-16px" />
                </div>
              </div>
              <Dropdown
                ref={selectAddPaymentMethodRef}
                options={getPaymentMethodsOptions()}
                onChange={addPaymentMethod}
                className="w-full h-0 border-none opacity-0"
              />
            </div>
          </div>
        )}
        {isFetching ? (
          <div className="w-12 lg:w-6 px-20px py-12px flex gap-16px">
            <Skeleton className="w-360px h-42px" />
          </div>
        ) : (
          <div className="w-12 lg:w-6 px-20px py-12px flex flex-column gap-16px">
            <div className="text-title-xs-med">{labels.billingAddress}</div>
            <div className="flex gap-4px">
              <InputTextarea value={billingAddress} disabled autoResize className="flex-1" />
              <Button className="action-button" onClick={handleBillingAddressEdit}>
                <PencilIcon className="icon-16px" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
