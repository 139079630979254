export const LookAndFeel = Object.freeze({
  BACKGROUND_LABEL: `Fondo`,
  BUTTONS_LABEL: `Botones`,
  FONT_COLORS_TITLE: `Colores de fuente`,
  FONT_FAMILIES_TITLE: `Familias de fuentes`,
  HEADER_FONT_LABEL: `Fuente de encabezado`,
  HEADER_LABEL: `Encabezado`,
  MAIN_TEXT_FONT_LABEL: `Fuente del texto principal`,
  MAIN_TEXT_LABEL: `Texto principal`,
  PRIMARY_LABEL: `Primario`,
  SITE_COLORS_TITLE: `Colores del sitio`,
  SUB_HEADER_FONT_LABEL: `Fuente del subencabezado`,
  SUB_HEADER_LABEL: `Subencabezado`,
});

export const QuickSetupCalendarConnected = Object.freeze({
  DESCRIPTION: `SUMO comprobará automáticamente tu calendario en busca de horas ocupadas y añadirá nuevas reuniones a medida que se programen. Siempre puedes desconectar tu calendario más tarde.`,
  INSTRUCTION_STEP_A1: `Verificaremos `,
  INSTRUCTION_STEP_A2: ` en busca de conflictos.`,
  INSTRUCTION_STEP_B: `Añadiremos reuniones al calendario para `,
  INSTRUCTION_STEP_C: `Encriptaremos todos los datos en tránsito y te daremos opciones con respecto a tus datos.`,
  INSTRUCTION_TITLE: `Así funcionará SUMO con `,
  STEP_MESSAGE: `¡Hagámoslo!`,
  TITLE: `¡Tu calendario está conectado!`,
});

export const QuickSetupChromeExtensionStep = Object.freeze({
  ACCEPT_TERMS_PART1: `Acepto los`,
  ACCEPT_TERMS_PART2: `Términos de Uso`,
  ACCEPT_TERMS_PART3: `y la`,
  ACCEPT_TERMS_PART4: `Política de Privacidad`,
  BENEFITS_DESCRIPTION: `La mejor experiencia con SUMO1 es usar SUMO desde tu bandeja de entrada, instalando la Extensión de Chrome. Usa SUMO en:`,
  BENEFITS_LIST_PART1: `Google Gmail & Calendario`,
  BENEFITS_LIST_PART2: `Microsoft Bandeja de entrada & Calendario`,
  BENEFITS_LIST_PART3: `Salesforce CRM`,
  BENEFITS_TITLE: `Beneficios`,
  CHROME_EXTENSION: `Extensión de Chrome`,
  REQUIREMENTS_DESCRIPTION: `Debes usar el navegador Google Chrome.`,
  REQUIREMENTS_TITLE: `Requisitos`,
  SUMO1_FOR_GOOGLE: `Instalar "SUMO1 para Gmail"`,
  SUMO1_FOR_MICROSOFT: `Instalar "SUMO1 para Outlook"`,
  TITLE: `Instalar Extensión de Chrome`,
});

export const QuickSetupSetupAvailabilityStep = Object.freeze({
  TITLE_USER: `Establecer tu disponibilidad`,
  MESSAGE_USER: `Crea el horario predeterminado inicial que indica cuándo estás típicamente disponible para citas.`,
  MINIMUM_DAYS_ERROR_TEXT: `Por favor selecciona al menos un día con horas disponibles`,
  SETUP_DAYS_INPUT_LABEL: `Días disponibles`,
  SETUP_HOURS_INPUT_LABEL: `Horas disponibles`,
  STEP_MESSAGE: `¡Sigue así!`,
});

export const QuickSetupSetupPhoneCallsStep = Object.freeze({
  DESCRIPTION: `Para las llamadas programadas, ¿a qué número deben llamar los invitados? La mayoría de los usuarios proporcionan su móvil para alertas por SMS, pero puedes actualizar esto más tarde.`,
  PHONE_DETAILS_LABELS: `Información adicional o Instrucción`,
  PHONE_DETAILS_PLACEHOLDER: `Ej: Espero con ansias la reunión. Envíame un SMS en cualquier momento.`,
  PHONE_PLACEHOLDER: `Número de teléfono`,
  STEP_MESSAGE: `¡Ya casi llegas!`,
  TITLE: `Establecer número de teléfono`,
});

export const QuickSetupToastsNotifications = Object.freeze({
  SAVE_QUICK_SETUP_ERROR_TOAST: `Error al guardar la configuración rápida`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_DESC: `Ahora estás listo para programar fácilmente.`,
  SAVE_QUICK_SETUP_SUCCESS_TOAST_TITLE: `¡Configuración completa!`,
});

export const QuickSetupVideoConferenceStep = Object.freeze({
  DEFAULT_DESCRIPTION: `Videoconferencia predeterminada`,
  DESCRIPTION: `Opcional, pero recomendada para reuniones virtuales.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  STEP_MESSAGE: `¡Hagámoslo!`,
  TITLE: `Conectar cuenta de videoconferencia`,
  ZOOM_TITLE: `Reuniones de ZOOM`,
});

export const QuickSetupYourBrandStep = Object.freeze({
  DESCRIPTION: `Sube tu logo para que aparezca en todas las páginas de reserva. Puedes hacer más configuraciones de marca en el espacio de trabajo más tarde.`,
  SAVE_BUTTON: `Guardar y completar configuración`,
  STEP_MESSAGE: `¡Ya casi llegas!`,
  TITLE: `Añade tu logo`,
});
