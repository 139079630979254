import { useRef } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, Step } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { GuideType, promoActions, promoSelectors } from '../../../store/promo';
import { userSettingsActions } from '../../../store/userSettings';
import { getGuideSteps } from './constants';
import { Tooltip } from './GuideTooltip';
import labels from './labels';
import type { GuideStepData } from './types';

export const GuideTour = () => {
  const dispatch = useDispatch();
  const joyrideRef = useRef<Joyride>(null);
  const isGuideRunning = useSelector(promoSelectors.selectIsGuideRunning);
  const isOverlayVisible = useSelector(promoSelectors.selectIsOverlayVisible);
  const guideStepIndex = useSelector(promoSelectors.selectGuideStepIndex);
  const guideType = useSelector(promoSelectors.selectGuideType);
  const guideTypes: Record<GuideType, Step[]> = {
    all: getGuideSteps({ all: true }),
    showAgain: getGuideSteps({ showAgain: true }),
  };
  const guideSteps = guideTypes[guideType];

  const handleOnActionClose = () => {
    dispatch(promoActions.stopGuide());
    dispatch(userSettingsActions.setIsMenuOpened(false));
    dispatch(userSettingsActions.setIsGuiderPopupOverUserMenu(false));
    dispatch(userSettingsActions.updateUserSettings({ isGuideTourFinished: true }));
    dispatch(userSettingsActions.saveUserSettingsRequest({ showToast: false }));
  };

  const handleOnEventSkip = () => {
    handleOnActionClose();
    dispatch(promoActions.setGuideStepIndex(0));
  };

  const handleOnEventTourEnd = () => {
    handleOnEventSkip();
    // dispatch(installModalActions.openModal());
  };

  const handleJoyrideCallback = async (props: CallBackProps) => {
    const { type, index, action, step } = props;
    const data = (step.data || {}) as GuideStepData | Record<string, never>;

    const eventFunctions = {
      [EVENTS.TOUR_START]: data?.onEventTourStart,
      [EVENTS.STEP_BEFORE]: data?.onEventStepBefore,
      [EVENTS.BEACON]: data?.onEventBeacon,
      [EVENTS.TOOLTIP]: data?.onEventTooltip,
      [EVENTS.STEP_AFTER]: data?.onEventStepAfter,
      [EVENTS.TOUR_END]: data?.onEventTourEnd,
      [EVENTS.TOUR_STATUS]: data?.onEventTourStatus,
      [EVENTS.TARGET_NOT_FOUND]: data?.onEventTargetNotFound,
      [EVENTS.ERROR]: data?.onEventError,
    } as const;

    const actionFunctions = {
      [ACTIONS.INIT]: data?.onActionInit,
      [ACTIONS.START]: data?.onActionStart,
      [ACTIONS.STOP]: data?.onActionStop,
      [ACTIONS.RESET]: data?.onActionReset,
      [ACTIONS.PREV]: data?.onActionPrev,
      [ACTIONS.NEXT]: data?.onActionNext,
      [ACTIONS.GO]: data?.onActionGo,
      [ACTIONS.CLOSE]: data?.onActionClose,
      [ACTIONS.SKIP]: data?.onActionSkip,
      [ACTIONS.UPDATE]: data?.onActionUpdate,
    } as const;

    const onEvent = eventFunctions[type];
    const onAction = actionFunctions[action];
    const onBefore = data?.onBefore;
    const onAfter = data?.onAfter;

    if (onBefore) await onBefore(props);
    if (onEvent) await onEvent(props);
    if (onAction) await onAction(props);

    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      if (action === ACTIONS.NEXT || action === ACTIONS.PREV) {
        const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
        dispatch(promoActions.setGuideStepIndex(nextStepIndex));
      }
    }
    if (action === ACTIONS.CLOSE) {
      handleOnActionClose();
    }
    if (type === EVENTS.TOUR_END) {
      if (action === ACTIONS.NEXT) {
        handleOnEventTourEnd();
      } else if (action === ACTIONS.SKIP) {
        handleOnEventSkip();
      }
    }

    if (onAfter) await onAfter(props);
  };

  return (
    <Joyride
      ref={joyrideRef}
      steps={guideSteps}
      locale={{
        back: labels.back,
        close: labels.close,
        last: labels.last,
        next: labels.next,
        nextLabelWithProgress: labels.nextLabelWithProgress,
        open: labels.open,
        skip: labels.skip,
      }}
      callback={handleJoyrideCallback}
      floaterProps={{
        styles: {
          arrow: {
            length: 12,
            spread: 14,
          },
        },
      }}
      styles={{
        options: {
          zIndex: 1000,
        },
      }}
      tooltipComponent={Tooltip}
      disableOverlay={!isOverlayVisible}
      continuous
      stepIndex={guideStepIndex}
      run={isGuideRunning}
    />
  );
};
