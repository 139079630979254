import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';
import { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Team } from '../../API';
import { ConfirmationModal, SectionHeader, SumoTable } from '../../components/common';
import { EditTeamModal } from '../../components/teams';
import { Path } from '../../routing';
import { formatDateTimeThroughGap } from '../../services/DateService';
import { authenticationSelectors } from '../../store/authentication';
import { errorActions } from '../../store/error/actions';
import { ErrorCodes } from '../../store/error/types';
import {
  DEFAULT_FILTER,
  DEFAULT_TEAM,
  editTeamModalActions,
  StatusOptions,
  teamsActions,
  teamsSelectors,
} from '../../store/teams';
import { usersSelectors } from '../../store/users';
import { userSettingsSelectors } from '../../store/userSettings';
import { workspacesSelectors } from '../../store/workspaces';
import labels from './labels';

export const Teams = () => {
  const dispatch = useDispatch();
  const workspaceId = useSelector(authenticationSelectors.selectWorkspaceId);

  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat);
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat);
  const workspacesNamesById = useSelector(authenticationSelectors.selectTenantWorkspacesNamesById);
  const currentUser = useSelector(usersSelectors.selectCurrentUserNameOrEmail);
  const isRead = useSelector(userSettingsSelectors.selectTeamsRead);
  const isCreate = useSelector(userSettingsSelectors.selectTeamsCreate);
  const isEdit = useSelector(userSettingsSelectors.selectTeamsEdit);
  const isDelete = useSelector(userSettingsSelectors.selectTeamsDelete);
  const isReadOnly = useSelector(userSettingsSelectors.selectIsReadOnlyTeam);
  const workspaceOptions = useSelector(workspacesSelectors.selectWorkspaceOptionItems);
  const userOptions = useSelector(teamsSelectors.selectCreatedByFilterOptions);
  const userIds = useSelector(usersSelectors.selectUserIds);

  const lastLoadTime = useSelector(teamsSelectors.selectLastLoadTime);
  const isSpinnerFetching = useSelector(teamsSelectors.selectIsSpinnerFetching);
  const isSkeletonFetching = useSelector(teamsSelectors.selectIsSkeletonFetching);
  const sharedForRead = useSelector(teamsSelectors.selectSharedForReadTeam);
  const isTeamActive = useSelector(teamsSelectors.selectIsTeamActive);
  const teamName = useSelector(teamsSelectors.selectTeamName);
  const filteredTeams = useSelector(teamsSelectors.selectFilteredTeams);
  const isDefaultFilter = useSelector(teamsSelectors.selectIsDefaultFilter);
  const filterWorkspaces = useSelector(teamsSelectors.selectFilterWorkspaces);
  const filterCreatedBy = useSelector(teamsSelectors.selectFilterCreatedBy);
  const filterStatus = useSelector(teamsSelectors.selectFilterStatus);
  const isFilterActive = useSelector(teamsSelectors.selectIsFilterInUse(userOptions, StatusOptions));

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);

  useEffect(() => {
    if (isRead) {
      dispatch(teamsActions.getTeamsPageRequest());
    } else {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
    }
  }, []);

  useEffect(() => {
    lastLoadTime && isDefaultFilter && resetFilters();
  }, [lastLoadTime]);

  const resetFilters = () => {
    dispatch(
      teamsActions.setFilter({
        ...DEFAULT_FILTER,
        workspaceIds: [workspaceId],
        createdBy: userOptions.map((option) => option.value),
        active: StatusOptions.map((option) => option.value),
      })
    );
  };

  const menuItems = [
    {
      visible: isEdit && !sharedForRead,
      label: labels.edit,
      command: () => {
        dispatch(editTeamModalActions.openModal());
      },
    },
    {
      visible: isEdit && !sharedForRead,
      label: isTeamActive ? labels.deactivate : labels.activate,
      command: () => {
        if (isTeamActive) {
          setDeactivateModalOpen(true);
        } else {
          dispatch(teamsActions.deactivateTeamRequest());
        }
      },
    },
    {
      visible: isDelete && !sharedForRead,
      label: labels.delete,
      command: () => {
        setDeleteModalOpen(true);
      },
    },
    {
      visible: isReadOnly || sharedForRead,
      label: labels.view,
      command: () => {
        dispatch(editTeamModalActions.openModal());
      },
    },
  ];

  const handleDeleteCloseModal = () => {
    setDeleteModalOpen(false);
  };

  const handleDelete = () => {
    dispatch(teamsActions.deleteTeamRequest());
    setDeleteModalOpen(false);
  };

  const handleDeactivateCloseModal = () => {
    setDeactivateModalOpen(false);
  };

  const handleDeactivate = () => {
    dispatch(teamsActions.deactivateTeamRequest());
    setDeactivateModalOpen(false);
  };

  const createTeam = () => {
    //TODO: need to handle createdBy, createdAt, updatedAt in backend only
    const time = new Date().toISOString();
    dispatch(
      teamsActions.setSelectedTeam({
        ...DEFAULT_TEAM,
        workspaceId,
        createdBy: currentUser,
        createdAt: time,
        updatedAt: time,
      })
    );
    dispatch(editTeamModalActions.openModal());
  };

  const handleSelectWorkspaces = (e: MultiSelectChangeEvent) => {
    dispatch(teamsActions.setFilter({ workspaceIds: e.target.value }));
  };

  const handleSelectCreatedBy = (e: MultiSelectChangeEvent) => {
    dispatch(teamsActions.setFilter({ createdBy: e.target.value }));
  };

  const handleSelectStatus = (e: MultiSelectChangeEvent) => {
    dispatch(teamsActions.setFilter({ active: e.target.value }));
  };

  const renderActions = (data: Team) => {
    const menu = createRef<Menu>();
    return (
      <div
        className="action-button"
        onClick={(e) => {
          dispatch(teamsActions.setSelectedTeam(data));
          menu.current?.toggle(e);
        }}
      >
        <Menu ref={menu} model={menuItems} popup popupAlignment="right" />
        <EllipsisVerticalIcon className="icon-20px" />
      </div>
    );
  };

  return (
    <div>
      <EditTeamModal />

      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader
          title={labels.title}
          loading={isSpinnerFetching}
          searchPaths={[Path.Teams]}
          buttonLabel={labels.createTeam}
          onButtonClick={createTeam}
          hideButton={!isCreate}
          filters={[
            {
              label: labels.workspace,
              value: filterWorkspaces,
              options: workspaceOptions,
              onChange: handleSelectWorkspaces,
            },
            {
              label: labels.createdBy,
              value: filterCreatedBy,
              options: userOptions,
              onChange: handleSelectCreatedBy,
              disabled: !userOptions.length,
            },
            {
              label: labels.status,
              value: filterStatus,
              options: StatusOptions,
              onChange: handleSelectStatus,
            },
          ]}
          onFilterReset={resetFilters}
          isFilterActive={isFilterActive}
        />

        <SumoTable isCard loading={isSkeletonFetching} value={filteredTeams} actionsBody={renderActions}>
          <Column field="name" header={labels.name} sortable bodyClassName="text-overflow-ellipsis max-w-150px" />
          <Column
            field="members"
            header={labels.members}
            sortable
            bodyClassName="text-overflow-ellipsis max-w-90px"
            body={(data: Team) => data?.members?.filter(item => userIds.includes(item?.userId)).length || ''}
          />
          <Column
            field="workspaceId"
            header={labels.workspace}
            sortable
            bodyClassName="text-overflow-ellipsis max-w-150px"
            body={(data: Team) => (data?.workspaceId ? workspacesNamesById[data.workspaceId] || labels.deleted : '')}
          />
          <Column
            field="createdBy"
            header={labels.createdBy}
            sortable
            bodyClassName="text-overflow-ellipsis max-w-90px"
          />
          <Column
            field="active"
            header={labels.status}
            sortable
            bodyClassName="text-overflow-ellipsis max-w-90px"
            body={(data: Team) => (
              <span
                className={classNames('border-radius-6px py-6px px-8px', {
                  'bg-egg-blue-light text-egg-blue-dark': !!data?.active,
                  'bg-heavy-10 text-heavy-60': !data?.active,
                })}
              >
                {data?.active ? labels.active : labels.inactive}
              </span>
            )}
          />
          <Column
            field="createdAt"
            header={labels.createdDate}
            sortable
            bodyClassName="text-overflow-ellipsis max-w-150px"
            body={(data: Team) => formatDateTimeThroughGap(data?.createdAt, dateFormat || '', timeFormat || '')}
            frozen
          />
        </SumoTable>
      </div>

      <ConfirmationModal
        title={labels.deactivateTeamTitle}
        description={`${labels.deactivateTeamDesc} "${teamName}"?`}
        visible={deactivateModalOpen}
        onClose={handleDeactivateCloseModal}
        onCancel={handleDeactivateCloseModal}
        onConfirm={handleDeactivate}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />
      <ConfirmationModal
        title={labels.deleteTeamTitle}
        description={`${labels.deleteTeamDesc} "${teamName}"?`}
        visible={deleteModalOpen}
        onClose={handleDeleteCloseModal}
        onCancel={handleDeleteCloseModal}
        onConfirm={handleDelete}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />
    </div>
  );
};
