import { createSelector } from 'reselect';
import { authenticationSelectors } from '../authentication';
import { userSettingsSelectors } from '../userSettings';
import { Path } from '../../routing';
import labels from '../../components/private/auth/labels';
import {
  ArrowLeftCircleIcon,
  BellIcon,
  BuildingOffice2Icon,
  CalendarIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  CubeIcon,
  CursorArrowRaysIcon,
  InformationCircleIcon,
  LockClosedIcon,
  MapPinIcon,
  PowerIcon,
  Square2StackIcon,
  Square3Stack3DIcon,
  Squares2X2Icon,
  SquaresPlusIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';
import { MenuItem } from 'primereact/menuitem';
import urlConstants from '../../shared/JSON/urlConstants.json';

const selectHasPeopleSection = createSelector(
  userSettingsSelectors.selectUsersRead,
  userSettingsSelectors.selectTeamsRead,
  userSettingsSelectors.selectIsRoleLoaded,
  (isUsersRead, isTeamsRead, isRoleLoaded) => isRoleLoaded && (isUsersRead || isTeamsRead)
);

const selectOpsConsoleMenuItems = createSelector(
  authenticationSelectors.selectIsOpsConsole,
  userSettingsSelectors.selectIsQuickSetupFinished,
  (isOpsConsole, isQuickSetupFinished) => {
    if (!isOpsConsole) return [];
    return [
      {
        label: labels.openMySumo1,
        id: 'open-my-sumo1-link',
        url: isQuickSetupFinished ? Path.BookingPages : Path.QuickSetup,
        data: ArrowLeftCircleIcon,
      },
      {
        label: labels.accountsTitle,
        id: 'accounts-link',
        url: Path.OPSConsoleAccounts,
        data: Squares2X2Icon,
      },
      {
        label: labels.orgsTitle,
        id: 'ops-console-link',
        url: Path.OPSConsoleOrgs,
        data: BuildingOffice2Icon,
      },
      {
        label: labels.users,
        id: 'users-link',
        url: Path.OPSConsoleUsers,
        data: UsersIcon,
      },
      {
        label: labels.staffTitle,
        id: 'staff-link',
        url: Path.OPSConsoleStaff,
        data: UserCircleIcon,
      },
    ] as MenuItem[];
  }
);

const selectLicenseExpiredMenuItems = createSelector(
  authenticationSelectors.selectIsLicenseActive,
  authenticationSelectors.selectIsSumo1Admin,
  authenticationSelectors.selectIsViewAsUser,
  userSettingsSelectors.selectIsSuperAdmin,
  (isLicenseActive, isSumoONEAdmin, isViewAsUserMode, isSuperAdmin) => {
    if (isLicenseActive !== false) return [];
    const menuItems: MenuItem[] = [];
    if (isSumoONEAdmin && !isViewAsUserMode) {
      menuItems.push({
        label: labels.opsConsole,
        id: 'ops-console-link',
        url: Path.OPSConsoleOrgs,
        data: WrenchScrewdriverIcon,
      });
    }
    if (isSuperAdmin) {
      menuItems.push(
        {
          label: labels.users,
          id: 'users-link',
          url: Path.Users,
          data: UsersIcon,
        },
        {
          label: labels.billingTitle,
          id: 'billing-link',
          url: Path.Billing,
          data: CreditCardIcon,
        }
      );
    }
    return menuItems;
  }
);

const selectPrivateMenuItems = createSelector(
  authenticationSelectors.selectIsSumo1Admin,
  authenticationSelectors.selectIsViewAsUser,
  userSettingsSelectors.selectIsSuperAdmin,
  userSettingsSelectors.selectUsersRead,
  userSettingsSelectors.selectBookingTemplatesRead,
  userSettingsSelectors.selectWorkspacesRead,
  userSettingsSelectors.selectTeamsRead,
  userSettingsSelectors.selectIsRoleLoaded,
  (isSumoONEAdmin, isViewAsUserMode, isSuperAdmin, isUsersRead, isBookingTemplatesRead, isWorkspacesRead, isTeamsRead, isRoleLoaded) => {
    const menuItems: MenuItem[] = [];
    if (isSumoONEAdmin && !isViewAsUserMode) {
      menuItems.push({
        label: labels.opsConsole,
        id: 'ops-console-link',
        url: Path.OPSConsoleOrgs,
        data: WrenchScrewdriverIcon,
      });
    }
    menuItems.push(
      {
        label: labels.bookingPagesTitle,
        id: 'booking-pages-link',
        url: Path.BookingPages,
        data: Squares2X2Icon,
      },
      {
        label: labels.bookedMeetingsTitle,
        id: 'booked-meetings-link',
        url: Path.BookedMeetings,
        data: CalendarIcon,
      }
    );
    if (isBookingTemplatesRead) {
      menuItems.push({
        label: labels.bookingTemplatesTitle,
        id: 'booking-templates-link',
        url: Path.BookingTemplates,
        data: Square3Stack3DIcon,
      });
    }
    menuItems.push(
      {
        label: labels.smartAlerts,
        id: 'smart-alerts-link',
        url: Path.SmartAlerts,
        data: BellIcon,
      },
      {
        label: labels.journeyBuilder,
        id: 'journey-builder-link',
        url: Path.Journeys,
        data: SquaresPlusIcon,
      },
      {
        label: labels.integrationPageTitle,
        id: 'integration-link',
        url: Path.Integration,
        data: Square2StackIcon,
      }
    );
    if (isRoleLoaded) {
      if (isUsersRead) {
        menuItems.push({
          label: labels.users,
          id: 'users-link',
          url: Path.Users,
          data: UsersIcon,
        });
      }
      if (isTeamsRead) {
        menuItems.push({
          label: labels.teamsTitle,
          id: 'teams-link',
          url: Path.Teams,
          data: UserGroupIcon,
        });
      }
    }
    if (isSuperAdmin) {
      menuItems.push({
        label: labels.roles,
        id: 'roles-link',
        url: Path.Roles,
        data: LockClosedIcon,
      });
    }
    if (isWorkspacesRead) {
      menuItems.push({
        label: labels.workspacesTitle,
        id: 'workspaces-link',
        url: Path.Workspaces,
        data: CubeIcon,
      });
    }
    menuItems.push({
      label: labels.locationsTitle,
      id: 'locations-link',
      url: Path.Locations,
      data: MapPinIcon,
    });
    if (isSuperAdmin) {
      menuItems.push({
        label: labels.billingTitle,
        id: 'billing-link',
        url: Path.Billing,
        data: CreditCardIcon,
      });
    }
    return menuItems;
  }
);

const selectNavigationMenuItems = createSelector(
  selectOpsConsoleMenuItems,
  selectLicenseExpiredMenuItems,
  selectPrivateMenuItems,
  authenticationSelectors.selectIsOpsConsole,
  authenticationSelectors.selectIsLicenseActive,
  (
    opsConsoleMenuItems,
    licenseExpiredMenuItems,
    privateMenuItems,
    isOpsConsole,
    isLicenseActive,
  ) => {
    if (isOpsConsole) {
      return opsConsoleMenuItems;
    }
    if (isLicenseActive === false) {
      return licenseExpiredMenuItems;
    }
    return privateMenuItems;
  }
);

const selectUserMenuItems = createSelector(
  authenticationSelectors.selectIsOpsConsole,
  userSettingsSelectors.selectIsQuickSetupFinished,
  authenticationSelectors.selectIsLicenseActive,
  (isOpsConsole, isQuickSetupFinished, isLicenseActive) => {
    const userMenuItems: MenuItem[] = [];

    if (isQuickSetupFinished && !isOpsConsole && isLicenseActive) {
      userMenuItems.push(
        {
          label: labels.guidedTour,
          id: 'guided-tour-link',
          data: CursorArrowRaysIcon,
        },
        {
          label: labels.availability,
          id: 'availability-link',
          url: Path.Availability,
          data: CalendarIcon,
        },
        {
          label: labels.calendarConnections,
          id: 'calendar-connections-link',
          url: Path.CalendarConnections,
          data: Cog6ToothIcon,
        },
        {
          label: labels.profile,
          id: 'profile-link',
          url: Path.Account,
          data: UserCircleIcon,
        },
        {
          label: labels.helpCenter,
          id: 'help-center-link',
          url: urlConstants.KNOWLEDGE_BASE_URL,
          target: 'blank',
          data: InformationCircleIcon,
        }
      );
    }

    userMenuItems.push({
      label: labels.logOut,
      id: 'log-out-link',
      data: PowerIcon,
    });

    return userMenuItems;
  }
);

export const menuSelectors = {
  selectHasPeopleSection,
  selectNavigationMenuItems,
  selectUserMenuItems,
};
