import { ShareWith, SmartAlert, SmartAlertTemplate, WhenSend, WhomSend } from '../../API';
import { CLONE_NAME_POSTFIX } from '../../types/constants';
import { bookingPagesActions, confirmOverrideSmartAlertModalActions } from '../bookingPages';
import { bookingTemplatesActions } from '../bookingTemplates';
import { AppThunk } from '../rootStore';
import { WHOM_SEND } from './constants';
import { cloneSmartTypesModalActions } from './modal';
import { smartAlertsSelectors } from './selectors';
import {
  RemoveFromSmartAlertAndCreateNewType,
  SelectedAlertsType,
  SmartAlertFilterType,
  SmartAlertResponse,
  SmartTypes,
  SmartTypesResponse,
  UpsertSmartTypeFrom,
} from './types';

export enum SmartAlertsActionTypes {
  GET_SMART_TYPES_REQUEST = 'smartAlerts/GET_SMART_TYPES_REQUEST',
  GET_SMART_TYPES_SUCCESS = 'smartAlerts/GET_SMART_TYPES_SUCCESS',
  GET_SMART_TYPES_FAIL = 'smartAlerts/GET_SMART_TYPES_FAIL',
  SET_SMART_ALERT = 'smartAlerts/SET_SMART_ALERT',
  SET_SMART_ALERT_TEMPLATE = 'smartAlerts/SET_SMART_ALERT_TEMPLATE',
  SET_FILTER = 'smartAlerts/SET_FILTER',
  SELECT_ALERTS = 'smartAlerts/SELECT_ALERTS',
  CLONE_SMART_ALERT_REQUEST = 'smartAlerts/CLONE_SMART_ALERT_REQUEST',
  CLONE_SMART_ALERT_SUCCESS = 'smartAlerts/CLONE_SMART_ALERT_SUCCESS',
  CLONE_SMART_ALERT_FAIL = 'smartAlerts/CLONE_SMART_ALERT_FAIL',
  SET_CLONE_NAME = 'smartAlerts/SET_CLONE_NAME',
  DELETE_SMART_TYPES_REQUEST = 'smartAlerts/DELETE_SMART_TYPES_REQUEST',
  DELETE_SMART_TYPES_SUCCESS = 'smartAlerts/DELETE_SMART_TYPES_SUCCESS',
  DELETE_SMART_TYPES_FAIL = 'smartAlerts/DELETE_SMART_TYPES_FAIL',
  SET_SMART_TYPE = 'smartAlerts/SET_SMART_TYPE',
  GET_SMART_ALERT_REQUEST = 'smartAlerts/GET_SMART_ALERT_REQUEST',
  GET_SMART_ALERT_SUCCESS = 'smartAlerts/GET_SMART_ALERT_SUCCESS',
  GET_SMART_ALERT_FAIL = 'smartAlerts/GET_SMART_ALERT_FAIL',
  GET_SMART_ALERT_TEMPLATE_REQUEST = 'smartAlerts/GET_SMART_ALERT_TEMPLATE_REQUEST',
  GET_SMART_ALERT_TEMPLATE_SUCCESS = 'smartAlerts/GET_SMART_ALERT_TEMPLATE_SUCCESS',
  GET_SMART_ALERT_TEMPLATE_FAIL = 'smartAlerts/GET_SMART_ALERT_TEMPLATE_FAIL',
  UPDATE_SMART_ALERT = 'smartAlerts/UPDATE_SMART_ALERT',
  UPDATE_DEEP_SMART_ALERT = 'smartAlerts/UPDATE_DEEP_SMART_ALERT',
  UPDATE_SHARE_WITH = 'smartAlerts/UPDATE_SHARE_WITH',
  UPDATE_WHEN_SEND = 'smartAlerts/UPDATE_WHEN_SEND',
  UPDATE_WHOM_SEND = 'smartAlerts/UPDATE_WHOM_SEND',
  UPDATE_SMART_TEMPLATE = 'smartAlerts/UPDATE_SMART_TEMPLATE',
  UPSERT_SMART_TYPE_REQUEST = 'smartAlerts/UPSERT_SMART_TYPE_REQUEST',
  UPSERT_SMART_TYPE_SUCCESS = 'smartAlerts/UPSERT_SMART_TYPE_SUCCESS',
  UPSERT_SMART_TYPE_FAIL = 'smartAlerts/UPSERT_SMART_TYPE_FAIL',
  REMOVE_FROM_SMART_ALERT_AND_CREATE_NEW = 'smartAlerts/REMOVE_FROM_SMART_ALERT_AND_CREATE_NEW',
  SET_BOOKING_PAGE_IDS = 'smartAlerts/SET_BOOKING_PAGE_IDS',
  SET_BOOKING_TEMPLATE_IDS = 'smartAlerts/SET_BOOKING_TEMPLATE_IDS',
  SET_DETACH_ALERT = 'smartAlerts/SET_DETACH_ALERT',
  ADD_TO_WITHOUT_WARNING = 'smartAlerts/ADD_TO_WITHOUT_WARNING',
}

export type SmartAlertsAction =
  | { type: SmartAlertsActionTypes.GET_SMART_TYPES_REQUEST }
  | { type: SmartAlertsActionTypes.GET_SMART_TYPES_SUCCESS; payload: SmartTypesResponse }
  | { type: SmartAlertsActionTypes.GET_SMART_TYPES_FAIL; error: unknown }
  | { type: SmartAlertsActionTypes.SET_SMART_ALERT; payload: SmartAlert }
  | { type: SmartAlertsActionTypes.SET_SMART_ALERT_TEMPLATE; payload: SmartAlertTemplate }
  | { type: SmartAlertsActionTypes.SET_FILTER; payload: Partial<SmartAlertFilterType> }
  | { type: SmartAlertsActionTypes.SELECT_ALERTS; payload: SelectedAlertsType[] }
  | { type: SmartAlertsActionTypes.CLONE_SMART_ALERT_REQUEST }
  | { type: SmartAlertsActionTypes.CLONE_SMART_ALERT_SUCCESS }
  | { type: SmartAlertsActionTypes.CLONE_SMART_ALERT_FAIL; error: unknown }
  | { type: SmartAlertsActionTypes.SET_CLONE_NAME; payload: string }
  | { type: SmartAlertsActionTypes.DELETE_SMART_TYPES_REQUEST; payload: SelectedAlertsType[] }
  | { type: SmartAlertsActionTypes.DELETE_SMART_TYPES_SUCCESS }
  | { type: SmartAlertsActionTypes.DELETE_SMART_TYPES_FAIL; error: unknown }
  | { type: SmartAlertsActionTypes.SET_SMART_TYPE; payload: SmartTypes }
  | { type: SmartAlertsActionTypes.GET_SMART_ALERT_REQUEST; payload: string }
  | { type: SmartAlertsActionTypes.GET_SMART_ALERT_SUCCESS; payload: SmartAlertResponse }
  | { type: SmartAlertsActionTypes.GET_SMART_ALERT_FAIL; error: unknown }
  | { type: SmartAlertsActionTypes.GET_SMART_ALERT_TEMPLATE_REQUEST; payload: string }
  | { type: SmartAlertsActionTypes.GET_SMART_ALERT_TEMPLATE_SUCCESS; payload: SmartAlertTemplate }
  | { type: SmartAlertsActionTypes.GET_SMART_ALERT_TEMPLATE_FAIL; error: unknown }
  | { type: SmartAlertsActionTypes.UPDATE_SMART_ALERT; payload: Partial<SmartAlert> }
  | { type: SmartAlertsActionTypes.UPDATE_SMART_TEMPLATE; payload: Partial<SmartAlertTemplate> }
  | { type: SmartAlertsActionTypes.UPSERT_SMART_TYPE_REQUEST; payload: UpsertSmartTypeFrom }
  | { type: SmartAlertsActionTypes.UPSERT_SMART_TYPE_SUCCESS }
  | { type: SmartAlertsActionTypes.UPSERT_SMART_TYPE_FAIL; error: unknown }
  | { type: SmartAlertsActionTypes.SET_BOOKING_PAGE_IDS; payload: string[] }
  | { type: SmartAlertsActionTypes.SET_BOOKING_TEMPLATE_IDS; payload: string[] }
  | { type: SmartAlertsActionTypes.SET_DETACH_ALERT; payload: boolean }
  | { type: SmartAlertsActionTypes.ADD_TO_WITHOUT_WARNING; payload: string }
  | { type: SmartAlertsActionTypes.UPDATE_SHARE_WITH; payload: ShareWith[] }
  | { type: SmartAlertsActionTypes.UPDATE_WHEN_SEND; payload: Partial<WhenSend> }
  | { type: SmartAlertsActionTypes.UPDATE_WHOM_SEND; payload: Partial<WhomSend> }
  | {
      type: SmartAlertsActionTypes.REMOVE_FROM_SMART_ALERT_AND_CREATE_NEW;
      payload: RemoveFromSmartAlertAndCreateNewType;
    };

const getSmartTypesRequest = (): SmartAlertsAction => ({ type: SmartAlertsActionTypes.GET_SMART_TYPES_REQUEST });
const getSmartTypesSuccess = (payload: SmartTypesResponse): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.GET_SMART_TYPES_SUCCESS,
  payload,
});
const getSmartTypesFail = (error: unknown): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.GET_SMART_TYPES_FAIL,
  error,
});

const setSmartAlert = (payload: SmartAlert): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.SET_SMART_ALERT,
  payload,
});
const setSmartAlertTemplate = (payload: SmartAlertTemplate): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.SET_SMART_ALERT_TEMPLATE,
  payload,
});

const setFilter = (payload: Partial<SmartAlertFilterType>): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.SET_FILTER,
  payload,
});

const selectAlerts = (payload: SelectedAlertsType[]): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.SELECT_ALERTS,
  payload,
});

const cloneSmartAlertRequest = (): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.CLONE_SMART_ALERT_REQUEST,
});
const cloneSmartAlertSuccess = (): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.CLONE_SMART_ALERT_SUCCESS,
});
const cloneSmartAlertFail = (error: unknown): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.CLONE_SMART_ALERT_FAIL,
  error,
});
const setCloneName = (payload: string): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.SET_CLONE_NAME,
  payload,
});
const cloneSmartAlertThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const cloneName = smartAlertsSelectors.selectSmartAlertName(state) + CLONE_NAME_POSTFIX;
  dispatch(setSmartType(SmartTypes.ALERT));
  dispatch(setCloneName(cloneName));
  dispatch(cloneSmartTypesModalActions.openModal());
};

const cloneSmartAlertTemplateThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const cloneName = smartAlertsSelectors.selectSmartAlertTemplateName(state) + CLONE_NAME_POSTFIX;
  dispatch(setSmartType(SmartTypes.TEMPLATE));
  dispatch(setCloneName(cloneName));
  dispatch(cloneSmartTypesModalActions.openModal());
};

const deleteSmartTypesRequest = (payload: SelectedAlertsType[]): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.DELETE_SMART_TYPES_REQUEST,
  payload,
});
const deleteSmartTypesSuccess = (): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.DELETE_SMART_TYPES_SUCCESS,
});
const deleteSmartTypesFail = (error: unknown): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.DELETE_SMART_TYPES_FAIL,
  error,
});

const setSmartType = (payload: SmartTypes): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.SET_SMART_TYPE,
  payload,
});

const getSmartAlertRequest = (payload: string): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.GET_SMART_ALERT_REQUEST,
  payload,
});
const getSmartAlertSuccess = (payload: SmartAlertResponse): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.GET_SMART_ALERT_SUCCESS,
  payload,
});
const getSmartAlertFail = (error: unknown): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.GET_SMART_ALERT_FAIL,
  error,
});

const getSmartAlertTemplateRequest = (payload: string): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.GET_SMART_ALERT_TEMPLATE_REQUEST,
  payload,
});
const getSmartAlertTemplateSuccess = (payload: SmartAlertTemplate): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.GET_SMART_ALERT_TEMPLATE_SUCCESS,
  payload,
});
const getSmartAlertTemplateFail = (error: unknown): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.GET_SMART_ALERT_TEMPLATE_FAIL,
  error,
});

const updateSmartAlert = (payload: Partial<SmartAlert>): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.UPDATE_SMART_ALERT,
  payload,
});
const updateShareWith = (payload: ShareWith[]): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.UPDATE_SHARE_WITH,
  payload,
});
const updateWhenSend = (payload: Partial<WhenSend>): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.UPDATE_WHEN_SEND,
  payload,
});
const updateWhomSend = (payload: Partial<WhomSend>): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.UPDATE_WHOM_SEND,
  payload,
});
const updateSmartTemplate = (payload: Partial<SmartAlertTemplate>): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.UPDATE_SMART_TEMPLATE,
  payload,
});

const removeFromSmartAlertAndCreateNew = (payload: RemoveFromSmartAlertAndCreateNewType): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.REMOVE_FROM_SMART_ALERT_AND_CREATE_NEW,
  payload,
});

const upsertSmartTypeRequest = (payload: UpsertSmartTypeFrom): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.UPSERT_SMART_TYPE_REQUEST,
  payload,
});
const upsertSmartTypeSuccess = (): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.UPSERT_SMART_TYPE_SUCCESS,
});
const upsertSmartTypeFail = (error: unknown): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.UPSERT_SMART_TYPE_FAIL,
  error,
});

const setBookingPageIds = (payload: string[]): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.SET_BOOKING_PAGE_IDS,
  payload,
});
const setBookingTemplateIds = (payload: string[]): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.SET_BOOKING_TEMPLATE_IDS,
  payload,
});

const setDetachAlert = (payload: boolean): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.SET_DETACH_ALERT,
  payload,
});
const addToWithoutWarning = (payload: string): SmartAlertsAction => ({
  type: SmartAlertsActionTypes.ADD_TO_WITHOUT_WARNING,
  payload,
});

// TODO: move to /thunks please
const updateWhomSendThunk =
  (smartAlert: SmartAlert, value: WHOM_SEND, isActive: boolean, upsertType: UpsertSmartTypeFrom): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const showWarning = smartAlertsSelectors.selectShowWarningModal(
      smartAlert.id,
      upsertType === UpsertSmartTypeFrom.BOOKING_PAGE
    )(state);
    dispatch(setSmartType(SmartTypes.ALERT));
    dispatch(setSmartAlert(smartAlert));

    const newValue = smartAlert?.whomSend?.[value] ? { ...smartAlert.whomSend[value], isActive } : { isActive };
    dispatch(updateWhomSend({ [value]: newValue }));
    if (showWarning) {
      // refresh bp/bt lists for the latest warning data
      dispatch(bookingPagesActions.getBookingPagesRequest());
      dispatch(bookingTemplatesActions.getBookingTemplatesRequest());
      dispatch(confirmOverrideSmartAlertModalActions.openModal({ upsertType }));
    } else {
      dispatch(updateWhomSend({ [value]: newValue }));
      dispatch(upsertSmartTypeRequest(UpsertSmartTypeFrom.NONE));
    }
  };

// TODO: move to /thunks please
const personalizeSaveThunk =
  (
    smartAlert: SmartAlert,
    personalizeType: WHOM_SEND,
    upsertType: UpsertSmartTypeFrom,
    body: string,
    subject?: string
  ): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const showWarning = smartAlertsSelectors.selectShowWarningModal(
      smartAlert.id,
      upsertType === UpsertSmartTypeFrom.BOOKING_PAGE
    )(state);
    dispatch(setSmartType(SmartTypes.ALERT));
    dispatch(setSmartAlert(smartAlert));

    const newValue =
      smartAlert?.whomSend && smartAlert.whomSend[personalizeType]
        ? { ...smartAlert.whomSend[personalizeType], subject, body }
        : { subject, body };
    dispatch(updateWhomSend({ [personalizeType]: newValue }));
    if (showWarning) {
      dispatch(confirmOverrideSmartAlertModalActions.openModal({ upsertType }));
    } else {
      dispatch(upsertSmartTypeRequest(UpsertSmartTypeFrom.NONE));
    }
  };

export const smartAlertsActions = {
  getSmartTypesRequest,
  getSmartTypesSuccess,
  getSmartTypesFail,

  setSmartAlert,
  setSmartAlertTemplate,

  setFilter,
  selectAlerts,

  cloneSmartAlertRequest,
  cloneSmartAlertSuccess,
  cloneSmartAlertFail,
  setCloneName,
  cloneSmartAlertThunk,
  cloneSmartAlertTemplateThunk,

  deleteSmartTypesRequest,
  deleteSmartTypesSuccess,
  deleteSmartTypesFail,

  setSmartType,

  getSmartAlertRequest,
  getSmartAlertSuccess,
  getSmartAlertFail,
  getSmartAlertTemplateRequest,
  getSmartAlertTemplateSuccess,
  getSmartAlertTemplateFail,

  updateSmartAlert,
  updateShareWith,
  updateWhenSend,
  updateWhomSend,
  updateSmartTemplate,

  removeFromSmartAlertAndCreateNew,

  upsertSmartTypeRequest,
  upsertSmartTypeSuccess,
  upsertSmartTypeFail,

  setBookingPageIds,
  setBookingTemplateIds,

  setDetachAlert,
  addToWithoutWarning,

  updateWhomSendThunk,
  personalizeSaveThunk,
};
