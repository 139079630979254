export const Common = Object.freeze({
  ACCEPT_INVITE: `Aceptar invitación`,
  ACCESS_RESTRICT: `Lo siento, no tienes autorización para acceder a esta página`,
  ACTIVATE: `Activar`,
  ACTIVE: `Activo`,
  ADDRESS: `Dirección`,
  ALL: `Todo`,
  AND: `Y`,
  ARE_YOU_SURE: `¿Estás seguro?`,
  AUTHORISE: `Autorizar`,
  AVAILABLE: `Disponible`,
  BACK: `Volver`,
  CALENDAR_DAYS: `Días de calendario`,
  CANCEL: `Cancelar`,
  CHOOSE_DATE: `Elegir fecha`,
  CITY: `Ciudad`,
  CLEAR: `Limpiar`,
  CLEAR_FILTER: `Limpiar filtro`,
  CLICK_HERE: `Haga clic aquí`,
  CLONE: `Clonar`,
  CLONE_NAME_POSTFIX: ` (copia)`,
  CLOSE: `Cerrar`,
  COMMON: `Común`,
  COMPLETE: `Completar`,
  CONNECT: `Conectar`,
  CONNECTED: `Conectado`,
  CONFIRM: `Confirmar`,
  CONFIRM_DELETE: `Confirmar eliminación`,
  COMING_SOON: `Próximamente`,
  COPY: `Copiar`,
  COPY_LINK: `Copiar enlace`,
  COPIED_LINK: `¡Copiado!`,
  COUNTINUE: `Continuar`,
  COUNTRY: `País`,
  CREATE: `Crear`,
  CUSTOM: `Personalizado`,
  CUSTOMIZE: `Personalizar`,
  DATE: `Fecha`,
  DATE_RANGE: `Rango de fechas`,
  DAYS: `días`,
  DEACTIVATE: `Desactivar`,
  DEFAULT: `Predeterminado`,
  DELETE: `Eliminar`,
  DELETED: `ELIMINADO`,
  DESCRIPTION: `Descripción`,
  DISABLE: `Desactivar`,
  DISCONNECT: `Desconectar`,
  DOWNLOAD: `Descargar`,
  EDIT: `Editar`,
  EMAIL: `Correo electrónico`,
  EMAIL_ALL_SMALL: `correo electrónico`,
  EMPTY_ERROR: `No puede estar vacío`,
  EMPTY_LIST: `Parece que esta lista está vacía.`,
  ENABLE: `Habilitar`,
  ERROR: `Error`,
  EXPORT: `Exportar`,
  FILTER: `Filtrar`,
  FIRST_NAME: `Nombre`,
  FRIDAYS: `Viernes`,
  FROM: `desde`,
  GOOGLE: `Google`,
  HOURS: `horas`,
  HOUR_SHORTENED: `hrs`,
  INACTIVE: `Inactivo`,
  INFO: `Información`,
  INTERNAL: `Interno`,
  LAST_NAME: `Apellido`,
  LEARN_MORE: `Más información`,
  LOCKED: `Bloqueado`,
  LOGIN: `Iniciar sesión`,
  LOG_OUT: `Cerrar sesión`,
  MICROSOFT: `Microsoft`,
  MINUTE_SHORTENED: `mins`,
  MINUTES: `minutos`,
  MONDAYS: `Lunes`,
  MY: `Mi`,
  MY_ACCOUNT: `Mi cuenta`,
  NAME: `Nombre`,
  NAME_IS_DUPLICATE: `¡Ups! Este nombre ya se ha utilizado. Por favor, ingrese uno diferente.`,
  NEXT: `Siguiente`,
  NONE: `Ninguno`,
  NO: `No`,
  NO_CANCEL: `No, cancelar`,
  NO_RESULTS_FOUND: `No se encontraron resultados`,
  NEVER: 'Nunca',
  OFF: `Apagar`,
  ON: `Encender`,
  ADD: `Añadir`,
  ONLY_ONE_ACCOUNT: `Solo puedes tener una cuenta de SUMO1 por dirección de correo electrónico.`,
  PASSWORD: `Contraseña`,
  PHONE_NUMBER: `Número de teléfono`,
  PICTURE_FORMATS: `svg, jpg, gif, png`,
  PICTURE_SIZE: `Tamaño máximo 5 MB`,
  PREVIEW: `Vista previa`,
  QUICK_SETUP: `Configuración rápida`,
  REGISTER: `Registrarse`,
  RESET: `Restablecer`,
  RESET_FILTER: `Restablecer filtro`,
  ROLES: `Roles`,
  SATURDAYS: `Sábados`,
  SAVE: `Guardar`,
  SAVE_CHANGES: `Guardar cambios`,
  SAVE_AND_CLOSE: `Guardar y cerrar`,
  SEARCH: `Buscar`,
  SECOND_SHORTENED: `seg`,
  SELECT: `Seleccionar`,
  SELECT_ALL: `Seleccionar todo`,
  SELECTED: `Seleccionado`,
  SET_UP: `Configurar`,
  SHARE: `Compartir`,
  SIGN_OUT: `Cerrar sesión`,
  SIGN_UP: `Registrarse`,
  STATE: `Estado`,
  STATUS: `Estado`,
  SUCCESS: `Éxito`,
  SUNDAYS: `Domingos`,
  SUSPENDED: `Suspendido`,
  TIMEZONE: `Zona horaria`,
  THURSDAYS: `Jueves`,
  TIMES: `Veces`,
  TODAY: `Hoy`,
  TO: `hasta`,
  FOR: `para`,
  TUESDAYS: `Martes`,
  TYPE: `Tipo`,
  UNAVAILABLE: `No disponible`,
  UNLINK: `Desvincular`,
  UNSAVED_CHANGES: `Se perderán todos los cambios no guardados.`,
  UNSELECT: `Deseleccionar`,
  UPLOAD: `Subir`,
  UPLOAD_PICTURE: `Subir imagen`,
  UPDATE: `Actualizar`,
  USER: `Usuario`,
  USERS: `Usuarios`,
  VIEW: `Ver`,
  YES: `Sí`,
  YES_CANCEL: `Sí, Cancelar`,
  YES_DELETE: `Sí, eliminar`,
  WARNING: `Advertencia`,
  WEDNESDAYS: `Miércoles`,
  WEEKDAYS: `Días de la semana`,
  TOTAL: `Total`,
  ZIP: `Código postal`,
  CHARACTERS: `Caracteres`,
});

export const ErrorPage = Object.freeze({
  TITLE_403: `403 - Error prohibido`,
  TITLE_404: `404 - Página no encontrada`,
  TITLE_500: `500 - Error interno del servidor`,
  MESSAGE_403_1: `No tienes permiso para acceder a `,
  MESSAGE_403_2: ` en este servidor.`,
  MESSAGE_404_1: `La URL solicitada `,
  MESSAGE_404_2: ` no se encontró en este servidor.`,
  MESSAGE_500_1: `Hubo un error. Eso es todo lo que sabemos. Por favor, espera y `,
  MESSAGE_500_2: `intenta recargar`,
  MESSAGE_500_3: ` de nuevo.`,
  HOME_BUTTON: `Inicio`,
});

export const PrivateRoute = Object.freeze({
  GUIDED_TOUR: `Guided Tour`,
  ACCOUNT: `Cuenta`,
  ACCOUNT_SETTINGS: `Configuración de cuenta`,
  PROFILE: `Perfil`,
  ADMIN_SETTINGS: `Configuración de administrador`,
  AVAILABILITY: `Disponibilidad`,
  CALENDAR_CONNECTIONS: `Conexiones de calendario`,
  HELP_CENTER: `Centro de Ayuda`,
  HOME: `Inicio`,
  USER_SETUP: `Configuración de usuario`,
  USER_SETTINGS: `Configuración de usuario`,
  INTEGRATION: `Integración`,
});

export const PublicLayout = Object.freeze({
  FOOTER_TEXT_PART_1: `© Derechos de autor 2008-`,
  FOOTER_TEXT_PART_2: ` ForceBrain, Inc. Todos los derechos reservados.`,
  PRIVACY_POLICY: `Política de privacidad`,
  SECURITY_POLICY: `Política de seguridad`,
  TERMS: `Términos`,
});

export const AppMenu = Object.freeze({
  ACCOUNTS_TITLE: `Cuentas`,
  BILLING_TITLE: `Facturación`,
  OPERATIONS_TITLE: `Operaciones`,
  ORGS_TITLE: `Organizaciones`,
  SMART_ALERTS: `Alertas Inteligentes`,
  STAFF_TITLE: `Personal`,
  SUPPORT_TITLE: `Soporte`,
  TOOLS_TITLE: `Herramientas`,
  OPEN_MY_SUMO1: `Abrir Mi SUMO1`,
  OPS_CONSOLE: `Consola OPS`,
  JOURNEY_TITLE: `Constructor de Viajes`,
  MEETINGS_TITLE: `Reuniones`,
  PEOPLE_TITLE: `Personas`,
  ORGANIZATION_TITLE: `Organización`,
});

export const LicenseExpired = Object.freeze({
  BOOK_MEETING_DESCRIPTION: `Programa una llamada o reunión virtual con un experto`,
  BOOK_MEETING_TITLE: `Reservar una reunión`,
  CALL_DESCRIPTION_PART1: `Contáctenos al 800-708-1790,`,
  CALL_DESCRIPTION_PART2: `de lunes a viernes, de 8am a 5pm EST`,
  CALL_TITLE: `Llámanos`,
  CHAT_DESCRIPTION: `Haga clic en el icono "Chatee con nosotros" en la parte inferior derecha para iniciar la conversación`,
  CHAT_TITLE: `Chatear ahora`,
  CONTACT_TITLE: `Contáctenos para obtener ayuda`,
  DESCRIPTION_ADMIN_TRIAL: `El período de prueba gratuita de su organización ha terminado. Puede actualizarlo haciendo clic en la opción a continuación o contactarnos para obtener más información.`,
  DESCRIPTION_ADMIN_PAID: `Su licencia ha expirado. Para continuar usando SUMO1 sin interrupciones, compre una nueva licencia.`,
  DESCRIPTION_PART1_TRIAL: `El período de prueba gratuita de su organización ha terminado. Contacte al administrador de su organización`,
  DESCRIPTION_PART1_PAID: `El período de licencia de su organización ha terminado. Contacte al administrador de su organización`,
  DESCRIPTION_PART2: `para más ayuda.`,
  EMAIL_DESCRIPTION: `Póngase en contacto con experts@sumoscheduler.com para obtener ayuda`,
  EMAIL_TITLE: `Envíenos un correo electrónico`,
  EXPIRED: `Caducado`,
  RENEW_LICENSES: `Renovar licencias`,
  TITLE: `Licencia caducada`,
  TITLE_TRIAL: `Prueba gratuita caducada`,
  TRIAL_IS_OVER: `La prueba ha terminado`,
  UPGRADE_NOW: `Actualizar ahora`,
  VIEW_PRICING_PAGE: `Ver página de precios`,
});

export const BookingStats = Object.freeze({
  CANCELS: `Cancelaciones`,
  MEETINGS_BOOKED: `Reuniones reservadas`,
  REMINDERS_SENT: `Recordatorios enviados`,
  RESCHEDULES: `Reprogramaciones`,
  TITLE: `Mis Estadísticas`,
  INSTALL_INBOX_PART1: `Instala la `,
  INSTALL_INBOX_PART2: `extensión de Chrome`,
  INSTALL_INBOX_PART3: ` o el `,
  INSTALL_INBOX_PART4: `complemento de Edge`,
  INSTALL_INBOX_PART5: ` para enviar enlaces de invitación desde Gmail o Outlook Web.`,
  INVITE_PEOPLE_DESCRIPTION: `Agrega usuarios a SUMO1 para aprovechar la co-organización, la reserva de equipos, la reserva en nombre de y más.`,
  INVITE_PEOPLE_TITLE: `Invitar personas`,
  CALENDAR_WARNING_DESCRIPTION: `Su calendario no está conectado. Tiene el riesgo de hacer una doble reserva si no conecta un calendario.`,
  CALENDAR_WARNING_BUTTON: `Conectar calendario`,
  UPDATE_GOOGLE_INTEGRATION_DESCRIPTION: `¡Por favor, actualice las configuraciones de integración de Google para aprovechar al máximo las funciones de la aplicación!`,
  UPDATE_GOOGLE_INTEGRATION_BUTTON: `Actualizar integración de Google`,
});

export const StartEasyBooking = Object.freeze({
  STEP1_DESCRIPTION: `Añade un enlace "Reservar conmigo" a tu firma de correo electrónico.`,
  STEP1_TITLE: `Configurar firma de correo electrónico`,
  STEP2_DESCRIPTION: `Envía una invitación a una reunión a alguien con la extensión de Chrome.`,
  STEP2_TITLE: `Enviar invitación con un clic`,
  STEP3_DESCRIPTION: `¿Qué más deberían probar desde el primer día?`,
  STEP3_TITLE: `Probar otra función`,
  TITLE: `Comienza con la reserva fácil`,
});

export const Table = Object.freeze({
  PAGE: `Página`,
  OF: `de`,
  OUT_OF: `de`,
});

export const IconPicker = Object.freeze({
  SELECT_TITLE: `Seleccionar un ícono`,
  OPTIONAL: `opcional`,
});
