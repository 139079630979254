export const Common = Object.freeze({
  ACCEPT_INVITE: `Accept Invite`,
  ACCESS_RESTRICT: `Sorry, you are not authorized to access this page`,
  ACTIVATE: `Activate`,
  ACTIVE: `Active`,
  ADDRESS: `Address`,
  ALL: `All`,
  AND: `And`,
  ARE_YOU_SURE: `Are you sure?`,
  AUTHORISE: `Authorise`,
  AVAILABLE: `Available`,
  BACK: `Back`,
  CALENDAR_DAYS: `Calendar days`,
  CANCEL: `Cancel`,
  CHOOSE_DATE: `Choose Date`,
  CITY: `City`,
  CLEAR: `Clear`,
  CLEAR_FILTER: `Clear Filter`,
  CLICK_HERE: `Click here`,
  CLONE: `Clone`,
  CLONE_NAME_POSTFIX: ` (copy)`,
  CLOSE: `Close`,
  COMMON: `Common`,
  COMPLETE: `Complete`,
  CONNECT: `Connect`,
  CONNECTED: `Connected`,
  CONFIRM: `Confirm`,
  CONFIRM_DELETE: `Confirm Delete`,
  COMING_SOON: `Coming soon`,
  COPY: `Copy`,
  COPY_LINK: `Copy link`,
  COPIED_LINK: `Copied!`,
  COUNTINUE: `Countinue`,
  COUNTRY: `Country`,
  CREATE: `Create`,
  CUSTOM: `Custom`,
  CUSTOMIZE: `Customize`,
  DATE: `Date`,
  DATE_RANGE: `Date range`,
  DAYS: `days`,
  DEACTIVATE: `Deactivate`,
  DEFAULT: `Default`,
  DELETE: `Delete`,
  DELETED: `DELETED`,
  DESCRIPTION: `Description`,
  DISABLE: `Disable`,
  DISCONNECT: `Disconnect`,
  DOWNLOAD: `Download`,
  EDIT: `Edit`,
  EMAIL: `Email`,
  EMAIL_ALL_SMALL: `email`,
  EMPTY_ERROR: `Can't be empty`,
  EMPTY_LIST: `Looks like this list is empty.`,
  ENABLE: `Enable`,
  ERROR: `Error`,
  EXPORT: `Export`,
  FILTER: `Filter`,
  FIRST_NAME: `First name`,
  FRIDAYS: `Fridays`,
  FROM: `from`,
  GOOGLE: `Google`,
  HOURS: `hours`,
  HOUR_SHORTENED: `hrs`,
  INACTIVE: `Inactive`,
  INFO: `Info`,
  INTERNAL: `Internal`,
  LAST_NAME: `Last name`,
  LEARN_MORE: `Learn More`,
  LOCKED: `Locked`,
  LOGIN: `Login`,
  LOG_OUT: `Log out`,
  MICROSOFT: `Microsoft`,
  MINUTE_SHORTENED: `mins`,
  MINUTES: `minutes`,
  MONDAYS: `Mondays`,
  MY: `My`,
  MY_ACCOUNT: `My account`,
  NAME: `Name`,
  NAME_IS_DUPLICATE: `Oops! This name has already been used. Please enter a different one.`,
  NEXT: `Next`,
  NONE: `None`,
  NO: `No`,
  NO_CANCEL: `No, cancel`,
  NO_RESULTS_FOUND: `No results found`,
  NEVER: 'Never',
  OFF: `Off`,
  ON: `On`,
  ADD: `Add`,
  ONLY_ONE_ACCOUNT: `You may only have one SUMO1 account per email address.`,
  PASSWORD: `password`,
  PHONE_NUMBER: `Phone number`,
  PICTURE_FORMATS: `svg, jpg, gif, png`,
  PICTURE_SIZE: `Max size 5 MB`,
  PREVIEW: `Preview`,
  QUICK_SETUP: `Quick Setup`,
  REGISTER: `Register`,
  RESET: `Reset`,
  RESET_FILTER: `Reset Filter`,
  ROLES: `Roles`,
  SATURDAYS: `Saturdays`,
  SAVE: `Save`,
  SAVE_CHANGES: `Save Changes`,
  SAVE_AND_CLOSE: `Save and close`,
  SEARCH: `Search`,
  SECOND_SHORTENED: `sec`,
  SELECT: `Select`,
  SELECT_ALL: `Select All`,
  SELECTED: `Selected`,
  SET_UP: `Set up`,
  SHARE: `Share`,
  SIGN_OUT: `Sign out`,
  SIGN_UP: `Sign Up`,
  STATE: `State`,
  STATUS: `Status`,
  SUCCESS: `Success`,
  SUNDAYS: `Sundyas`,
  SUSPENDED: `Suspended`,
  TIMEZONE: `TimeZone`,
  THURSDYAS: `Thursdays`,
  TIMES: `Times`,
  TODAY: `Today`,
  TO: `to`,
  FOR: `for`,
  TUESDAYS: `Tuesdays`,
  TYPE: `Type`,
  UNAVAILABLE: `Unavailable`,
  UNLINK: `Unlink`,
  UNSAVED_CHANGES: `Any unsaved changes will be lost.`,
  UNSELECT: `Unselect`,
  UPLOAD: `Upload`,
  UPLOAD_PICTURE: `Upload picture`,
  USER: `User`,
  USERS: `Users`,
  UPDATE: `Update`,
  VIEW: `View`,
  YES: `Yes`,
  YES_CANCEL: `Yes, Cancel`,
  YES_DELETE: `Yes, delete`,
  WARNING: `Warning`,
  WEDNESDAYS: `Wednesdays`,
  WEEKDAYS: `Weekdays`,
  TOTAL: `Total`,
  ZIP: `ZIP`,
  CHARACTERS: `Characters`,
});

export const ErrorPage = Object.freeze({
  TITLE_403: `403 - Forbidden Error`,
  TITLE_404: `404 - Page Not Found`,
  TITLE_500: `500 - Internal Server Error`,
  MESSAGE_403_1: `You do not have permission to access `,
  MESSAGE_403_2: ` on this server.`,
  MESSAGE_404_1: `The request URL `,
  MESSAGE_404_2: ` was not found on this server.`,
  MESSAGE_500_1: `The was an error. That's all we know. Please wait and `,
  MESSAGE_500_2: `try reloading`,
  MESSAGE_500_3: ` again.`,
  HOME_BUTTON: `Home`,
});

export const PrivateRoute = Object.freeze({
  GUIDED_TOUR: `Guided Tour`,
  ACCOUNT: `Account`,
  ACCOUNT_SETTINGS: `Account Settings`,
  PROFILE: `Profile`,
  ADMIN_SETTINGS: `Admin Settings`,
  AVAILABILITY: `Availability`,
  CALENDAR_CONNECTIONS: `Calendar Connections`,
  HELP_CENTER: `Help Center`,
  HOME: `Home`,
  USER_SETUP: `User Setup`,
  USER_SETTINGS: `User Settings`,
  INTEGRATION: `Integration`,
});

export const PublicLayout = Object.freeze({
  FOOTER_TEXT_PART_1: `© Copyright 2008-`,
  FOOTER_TEXT_PART_2: ` ForceBrain, Inc.  All rights reserved.`,
  PRIVACY_POLICY: `Privacy Policy`,
  SECURITY_POLICY: `Security Policy`,
  TERMS: `Terms`,
});

export const AppMenu = Object.freeze({
  ACCOUNTS_TITLE: `Accounts`,
  BILLING_TITLE: `Billing`,
  OPERATIONS_TITLE: `Operations`,
  ORGS_TITLE: `Orgs`,
  SMART_ALERTS: `Smart Alerts`,
  STAFF_TITLE: `Staff`,
  SUPPORT_TITLE: `Support`,
  TOOLS_TITLE: `Tools`,
  OPEN_MY_SUMO1: `Open My SUMO1`,
  OPS_CONSOLE: `OPS Console`,
  JOURNEY_TITLE: `Journey Builder`,
  MEETINGS_TITLE: `Meetings`,
  PEOPLE_TITLE: `People`,
  ORGANIZATION_TITLE: `Organization`,
});

export const LicenseExpired = Object.freeze({
  BOOK_MEETING_DESCRIPTION: `Schedule a call or virtual meeting with an expert`,
  BOOK_MEETING_TITLE: `Book a Meeting`,
  CALL_DESCRIPTION_PART1: `Contact us at 800-708-1790,`,
  CALL_DESCRIPTION_PART2: `Monday to Friday, 8am-5pm EST`,
  CALL_TITLE: `Call Us`,
  CHAT_DESCRIPTION: `Click the "Chat with us" icon in the bottom right to start conversation`,
  CHAT_TITLE: `Chat Now`,
  CONTACT_TITLE: `Contact us for assistance`,
  DESCRIPTION_ADMIN_TRIAL: `Your organization's free trial period has ended. You can upgrade it by clicking the option below or contact us for further information.`,
  DESCRIPTION_ADMIN_PAID: `Your license has expired. To continue using SUMO1 without any interruptions, please purchase a new license.`,
  DESCRIPTION_PART1_TRIAL: `Your organization's free trial period has ended. Contact your organization's administrator`,
  DESCRIPTION_PART1_PAID: `Your organization's licenses period has ended. Contact your organization's administrator`,
  DESCRIPTION_PART2: `for further assistance.`,
  EMAIL_DESCRIPTION: `Reach out to experts@sumoscheduler.com for help`,
  EMAIL_TITLE: `Email Us`,
  EXPIRED: `Expired`,
  RENEW_LICENSES: `Renew Licenses`,
  TITLE: `License Expired`,
  TITLE_TRIAL: `Free Trial Expired`,
  TRIAL_IS_OVER: `Trial is over`,
  UPGRADE_NOW: `Upgrade Now`,
  VIEW_PRICING_PAGE: ` View Pricing Page`,
});

export const BookingStats = Object.freeze({
  CANCELS: `Cancels`,
  MEETINGS_BOOKED: `Meetings Booked`,
  REMINDERS_SENT: `Reminders Sent`,
  RESCHEDULES: `Reschedules`,
  TITLE: `My Stats`,
  INSTALL_INBOX_PART1: `Install the `,
  INSTALL_INBOX_PART2: `Chrome extension`,
  INSTALL_INBOX_PART3: ` or `,
  INSTALL_INBOX_PART4: `Edge add-on`,
  INSTALL_INBOX_PART5: ` to send invite links from Gmail or Outlook Web.`,
  INVITE_PEOPLE_DESCRIPTION: `Add users to SUMO1 to take advantage of co-hosting, team booking, booking on behalf of, and more.`,
  INVITE_PEOPLE_TITLE: `Invite People`,
  CALENDAR_WARNING_DESCRIPTION: `Your calendar is not connected. You have a risk of double booking if you do not connect a calendar.`,
  CALENDAR_WARNING_BUTTON: `Connect Calendar`,
  UPDATE_GOOGLE_INTEGRATION_DESCRIPTION: `Please update the Google Integration settings to take full advantage of the app's features!`,
  UPDATE_GOOGLE_INTEGRATION_BUTTON: `Update Google Integration`,
});

export const StartEasyBooking = Object.freeze({
  STEP1_DESCRIPTION: `Add a "Book with me" link to your email signature.`,
  STEP1_TITLE: `Setup Email Signature`,
  STEP2_DESCRIPTION: `Send a meeting invite to someone with the chrome extension.`,
  STEP2_TITLE: `Send 1-Click Invite`,
  STEP3_DESCRIPTION: `What else should they test from day 1?`,
  STEP3_TITLE: `Test Another Feature`,
  TITLE: `Start Easy Booking`,
});

export const Table = Object.freeze({
  PAGE: `Page`,
  OF: `of`,
  OUT_OF: `out of`,
});

export const IconPicker = Object.freeze({
  SELECT_TITLE: `Select an Icon`,
  OPTIONAL: `optional`,
});
